import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Column, Row } from 'common/widgets/grid'
import { DetailViewPage } from 'system/utils/view'
import { CardView, GridFieldView, LinkFieldView } from 'common/widgets/view'
import { UserView } from 'modules/security/users/widgets'
import { Container } from 'common/widgets/container'
import { Avatar } from 'common/widgets/avatar'
import {
  AddButton,
  DeleteIconButton,
  EditIconButton,
} from 'common/widgets/button'
import { SearchInput } from 'common/widgets/search'
import { PagedDataSource } from 'common/widgets/data-source'
import { OverlayForm } from 'common/widgets/overlay'
import { SimpleFormAction } from 'common/widgets/form/utils'
import { SelectField, TextAreaField } from 'common/widgets/form/field'
import { useService } from 'common/service/context'
import { MemberSelect } from 'common/widgets/contacts'

import { ContactView } from '../widgets/member'

import { TeamMembers } from './list'

export const TeamDetailViewPage = () => {
  return (
    <DetailViewPage
      url="/contact/teams"
      navUrl="/master-data/contacts/teams"
      title={(e) => e?.name}
      editable
    >
      {(data) => {
        return (
          <>
            <TeamDetails team={data} />
            <MembersList team={data} />
          </>
        )
      }}
    </DetailViewPage>
  )
}

const TeamDetails = ({ team }) => {
  const { t } = useTranslation()
  const teamleader = team.related_contacts.find(
    (e) => e.relation_type === 'TEAM_LEAD'
  )
  return (
    <CardView title="Details" titleDivider>
      <Row>
        <Column n={4} m={4} s={12}>
          <h3>{t('Team')}</h3>
          <GridFieldView
            n={12}
            m={12}
            s={12}
            label="Team name"
            value={team.name}
          />
          <GridFieldView
            n={12}
            m={6}
            s={12}
            label="Comment"
            value={team.comment}
          />
        </Column>
        <Column n={4} m={4} s={12}>
          <GridFieldView n={12} m={6} s={12}>
            <h3>{t('Teamlead')}</h3>
          </GridFieldView>
          <GridFieldView n={12} m={12} s={12} label="Team leader">
            {teamleader && <ContactView contact={teamleader.related_contact} />}
          </GridFieldView>
          <GridFieldView n={12} m={12} s={12} label="Created by">
            <UserView user={team.creator_user} />
          </GridFieldView>
        </Column>
        <Column n={4} m={4} s={12}>
          <GridFieldView n={12} m={6} s={12}>
            <h3>{t('Members')}</h3>
          </GridFieldView>
          <GridFieldView n={12} m={12} s={12} label="Members">
            <TeamMembers team={team} />
          </GridFieldView>
        </Column>
      </Row>
    </CardView>
  )
}

const MembersList = ({ team }) => {
  const { t } = useTranslation()
  const [addOverlayOpen, setAddOverlayOpen] = useState(false)
  const [selectedMember, setSelectedMember] = useState(null)
  const service = useService()

  const handleAssignMemberToTeam = async (values) => {
    const [result, error] = await service.post(
      `/contact/teams/${team.id}/memberships`,
      values
    )
    if (result && !error) {
      setAddOverlayOpen(false)
    }

    return [result, error]
  }

  const handleEditMemberToTeam = async (values) => {
    const [result, error] = await service.put(
      `/contact/teams/${team.id}/memberships/${selectedMember.membership.id}`,
      values
    )
    if (result && !error) {
      setAddOverlayOpen(false)
      setSelectedMember(null)
    }

    return [result, error]
  }

  return (
    <PagedDataSource url={`/contact/teams/${team.id}/members`}>
      {({ data, reload }) => (
        <CardView>
          <Container flex repel grow style={{ alignItems: 'center' }}>
            <Column>
              <h1
                style={{
                  color: '#376DF4',
                  fontWeight: 'bold',
                  fontSize: '25px',
                }}
              >
                {team.team_size}
              </h1>
              <h5 style={{ marginBottom: '10px' }}>{t('Active members')}</h5>
            </Column>
            <Container flex gap="10px">
              <SearchInput />
              <AddButton default onClick={() => setAddOverlayOpen(true)} />
            </Container>
          </Container>
          <Container flex vertical gap="10px" divider>
            {data?.map((member, i) => (
              <Container key={i} flex repel style={{ alignItems: 'center' }}>
                <Container flex gap="10px">
                  <Avatar
                    text={member.system_user.fullname}
                    imgSrc={member.system_user.photo}
                    size="60px"
                  />
                  <Column>
                    <LinkFieldView
                      url={`/master-data/contacts/${member.id}`}
                      value={<h3>{member.fullname}</h3>}
                    />
                    <h4>{member.job_title}</h4>
                    <h5>{member.system_user?.email}</h5>
                    <h5>{member.system_user?.phone_number}</h5>
                    <h5>{member.membership.description}</h5>
                  </Column>
                </Container>
                <Container flex gap="10px">
                  <EditIconButton
                    onClick={() => {
                      setSelectedMember(member)
                      setAddOverlayOpen(true)
                    }}
                  />
                  <DeleteIconButton
                    onClick={async () => {
                      await service.delete(
                        `/contact/teams/${team.id}/members/${member.id}`
                      )
                      reload()
                    }}
                  />
                </Container>
              </Container>
            ))}
          </Container>
          {addOverlayOpen && (
            <AssignMembersToTeamOverlay
              team={team}
              member={selectedMember}
              open={addOverlayOpen}
              onClose={() => {
                setAddOverlayOpen(false)
                setSelectedMember(null)
                reload()
              }}
              onSubmit={!selectedMember ? handleAssignMemberToTeam : null}
              onEdit={selectedMember ? handleEditMemberToTeam : null}
            />
          )}
        </CardView>
      )}
    </PagedDataSource>
  )
}

const AssignMembersToTeamOverlay = ({
  member,
  team,
  open,
  onClose,
  onSubmit,
  onEdit,
}) => {
  const [selectedMember, setSelectedMember] = useState(member)

  return (
    <OverlayForm
      title={member ? 'Edit personal' : 'Add personal'}
      data={{
        member_id: member?.id,
        description: member?.membership?.description,
        is_lead: member?.membership?.is_lead,
      }}
      open={open}
      onClose={() => onClose()}
    >
      <Row>
        {!member && (
          <Column n={6} s={12}>
            <SearchInput />
            <PagedDataSource
              url={`teams/${team.id}/members/assignable`}
              limit={5}
            >
              {({ data }) =>
                data?.map((e, i) => (
                  <MemberSelect
                    key={i}
                    disabled={member}
                    selectedMember={selectedMember}
                    onSelectChange={() => setSelectedMember(e)}
                    contact={e}
                  />
                ))
              }
            </PagedDataSource>
          </Column>
        )}
        <Column n={!member ? 6 : 12} s={12}>
          <TextAreaField
            name="description"
            label="Description"
            rows={15}
            style={{ marginBottom: '15px' }}
          />
          <SelectField
            name="is_lead"
            items={[
              { key: true, title: 'Team leader' },
              { key: false, title: 'Member' },
            ]}
          />
        </Column>
      </Row>

      <SimpleFormAction
        onAdd={onSubmit}
        onUpdate={onEdit}
        //disabled={(ctx) => !ctx.values.json.member_id}
      />
    </OverlayForm>
  )
}

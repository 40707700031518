import { useEffect } from 'react'
import { useMap } from 'react-leaflet'
import * as L from 'leaflet'
import '@geoman-io/leaflet-geoman-free'
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'
import { useLeafletContext } from '@react-leaflet/core'

import i18n from 'common/i18n/core'

// Do not initialize
// See: https://www.geoman.io/docs/getting-started/free-version#opt-in
//L.PM.setOptIn(true)

const DefaultIcon = new L.Icon({
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
  iconAnchor: [12.5, 41],
  shadowAnchor: [12.5, 41],
})

export const Controls = ({
  onMarkerAdd,
  onMarkerRemove,
  onMarkerChange,
  onGeofenceAdd,
  onGeofenceRemove,
  onGeofenceChange,
  singleMarker,
}) => {
  const map = useMap()
  const context = useLeafletContext()

  // The backend API expects "lat" and "lon", not "lng"
  // For some context read https://macwright.com/lonlat/
  const toLatLonArray = (latlngs) => {
    const converted = []
    Object.entries(latlngs).forEach(([k, { lat, lng }]) => {
      converted.push([lat, lng])
    })
    return converted
  }

  const toLatLon = ({ lat, lng }) => ({ lat, lon: lng })

  const onCreated = async (e) => {
    e.layer.on('pm:edit', onEdited)
    if (e.shape === 'Marker') {
      onMarkerAdd && onMarkerAdd(e, toLatLon(e.marker.getLatLng()))
      singleMarker && removePreviousLayers(L.Marker, e.layer)
    } else if (e.shape === 'Polygon') {
      onGeofenceAdd && onGeofenceAdd(e, toLatLonArray(e.layer.getLatLngs()[0]))
      singleMarker && removePreviousLayers(L.Polygon, e.layer)
    }
  }

  const onEdited = async (e) => {
    if (e.shape === 'Marker') {
      onMarkerChange && onMarkerChange(e, toLatLon(e.layer.getLatLng()))
    } else if (e.shape === 'Polygon') {
      onGeofenceChange &&
        onGeofenceChange(e, toLatLonArray(e.layer.getLatLngs()[0]))
    }
  }

  const onRemoved = (e) => {
    if (e.shape === 'Marker') {
      onMarkerRemove && onMarkerRemove(e, toLatLon(e.layer.getLatLng()))
    } else if (e.shape === 'Polygon') {
      onGeofenceRemove &&
        onGeofenceRemove(e, toLatLonArray(e.layer.getLatLngs()[0]))
    }
  }

  const removePreviousLayers = (shape, current) => {
    const leafletContainer = context.layerContainer || context.map
    leafletContainer.eachLayer((layer) => {
      if (layer instanceof shape && layer !== current) {
        const tt = layer.getTooltip()
        tt && layer.unbindTooltip()
        layer.clearAllEventListeners()
        leafletContainer.removeLayer(layer)
      }
    })
  }

  useEffect(() => {
    map.pm.setLang(i18n.language)
    map.pm.setGlobalOptions({
      continueDrawing: false,
      markerStyle: {
        icon: DefaultIcon,
      },
    })
    map.pm.addControls({
      position: 'topright',
      drawMarker: true,
      drawPolygon: true,
      editMode: true,
      removalMode: true,
      drawCircleMarker: false,
      drawPolyline: false,
      drawRectangle: false,
      drawCircle: false,
      drawText: false,
      cutPolygon: false,
      rotateMode: false,
      snappable: true,
      snapDistance: 5,
    })

    map.on('pm:create', onCreated)
    map.on('pm:remove', onRemoved)

    // Attach event handlers to initial layers (pins and geofences)
    for (const layer of map.pm.getGeomanLayers()) {
      layer.on('pm:edit', onEdited)
      //layer.on('pm:dragend', onChanged)
    }

    return () => {
      map.pm.removeControls()
      map.off('pm:create', onCreated)
      map.off('pm:remove', onRemoved)
      for (const layer of map.pm.getGeomanLayers()) {
        layer.off('pm:edit', onEdited)
        //layer.off('pm:dragend', onChanged)
      }
    }
  }, [])

  return null
}

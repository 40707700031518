import { Avatar } from 'common/widgets/avatar'
import { SimpleDataTable, SimpleColumn } from 'common/data-table/simple'

export const ConsumablesListPage = () => {
  return (
    <SimpleDataTable
      name="consumables"
      url="/items/consumables?select=base_item(id,name),cost_center(id,code)"
      title="Consumables"
      showAddBtn
      addTooltip="Add consumable"
      navBaseUrl="/master-data/equipments/consumables"
      archive
    >
      <SimpleColumn fixed>
        {(r) => <Avatar text={r.name} id={r.image?.id} />}
      </SimpleColumn>
      <SimpleColumn sortable n={12} s={12} field="name" header />
      <SimpleColumn
        n={3}
        s={6}
        sortable
        field="maincategory"
        label="Main group"
        filterUrl="/items/consumables/maincategory"
      />
      <SimpleColumn
        n={3}
        s={6}
        sortable
        field="category"
        label="Group"
        filterUrl="/items/consumables/category"
      />
      <SimpleColumn
        n={2}
        s={6}
        sortable
        field="subcategory"
        label="Subgroup"
        filterUrl="/items/consumables/subcategory"
      />
      <SimpleColumn
        n={2}
        s={6}
        sortable
        label="Packaging unit"
        field="packaging_unit"
      />
      <SimpleColumn
        n={2}
        s={6}
        sortable
        label="Quantity unit"
        field="quantity_unit"
      />
      <SimpleColumn
        n={3}
        s={6}
        sortable
        label="Stock level"
        field="stock_level"
      />
      <SimpleColumn
        n={3}
        s={6}
        sortable
        label="Min stock level"
        field="min_stock"
      />
      <SimpleColumn n={2} s={6} sortable label="Reserved" field="reserved" />
      <SimpleColumn n={2} s={6} sortable label="Storage" field="storage" />
      <SimpleColumn n={2} s={6} sortable label="Supplier" field="supplier" />
    </SimpleDataTable>
  )
}

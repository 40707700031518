import {
  useEffect,
  useRef,
  useState,
  createContext,
  useContext,
  useMemo,
} from 'react'
import { X } from 'react-feather'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import { ZIndexEnum } from 'common/utils/enumerations'
import { Button, CloseIconButton } from 'common/widgets/button'
import { SearchInput } from 'common/widgets/search'

import { Container } from './container'
import { Form, useDebugContext } from './form/context'

// Context to let overlay children realize they are inside an overlay (modal)
// Previously we had a global app-wide overlay context, which I removed, since
// it was global. This context is local to overlays and could be nexted. The
// purpose is to let some components such as SimpleFormAction to know that they
// are displayed in modal mode and therefore let them adjust their settings without
// passing many props.
const OverlayContext = createContext(null)
export const useOverlayContext = () => useContext(OverlayContext)

export const Overlay = ({
  name,
  open,
  onClose,
  children,
  title,
  subtitle,
  header,
  ...rest
}) => {
  const { t } = useTranslation()

  // A reference to self in order to recognize events raised by children
  const myRef = useRef()

  // A workaround to make sure we close the overlay only when mousedown
  // and up are both inside the overlay wrapper
  const [downClicked, setDownClicked] = useState(false)

  // Set of states for the overlay context
  const [overlayOpen, setOverlayOpen] = useState(open)

  // Get debug elements to display on the overlay
  const debug = useDebugContext()

  // Update the internal open state on prop change
  useEffect(() => setOverlayOpen(open), [open])

  // Handle closing from context children
  const close = () => {
    setOverlayOpen(false)
    if (onClose) {
      onClose()
    }
  }

  // Store an object as context value but wrap it in useMemo in order to
  // maintain referential equity. See the following link for more information:
  // https://legacy.reactjs.org/docs/context.html#caveats
  const providerValue = useMemo(
    () => ({
      open: overlayOpen,
      close,
    }),
    [overlayOpen]
  )

  document.addEventListener('keydown', (e) => {
    if (e.code === 'Escape') {
      if (overlayOpen) {
        onClose && onClose()
      }
    }
  })

  const handleClose = () => {
    setOverlayOpen(false)
    if (onClose) {
      onClose()
    }
  }

  return (
    <OverlayContext.Provider value={providerValue}>
      <OverlayContainer
        open={overlayOpen}
        onClick={(e) => {
          // Only propagate if the target of the event is not one of our descendants
          if (!(myRef.current && !myRef.current.contains(e.target))) {
            e.stopPropagation()
          }
        }}
        onMouseDown={(e) => {
          // Only close if the target of the event is not one of our descendants
          if (myRef.current && !myRef.current.contains(e.target)) {
            setDownClicked(true)
          }
        }}
        onMouseUp={(e) => {
          // Only close if target is not of of my descendants
          if (myRef.current && !myRef.current.contains(e.target)) {
            if (downClicked) {
              onClose && onClose()
            }
          }
          setDownClicked(false)
        }}
      >
        <OverlayContentContainer ref={myRef} offset={50} {...rest}>
          {debug}
          <OverlayFormHeader>
            {title && (
              <Container flex vertical>
                <h2>{t(title)}</h2>
                {subtitle && <h4>{subtitle}</h4>}
              </Container>
            )}
            <CloseIconButton
              noborder
              onClick={handleClose}
              style={{ marginRight: '10px' }}
              color="#e5e8eb"
            />
            {header}
          </OverlayFormHeader>
          {children}
        </OverlayContentContainer>
      </OverlayContainer>
    </OverlayContext.Provider>
  )
}

export const OverlayForm = ({
  name,
  title,
  open,
  onSubmit,
  data,
  children,
  debug,
  ...rest
}) => {
  // Registers keydown to submit the form when pressing Enter
  useEffect(() => {
    const handleKeyDown = (e) => {
      // Learning note:
      // This line was used as the only statemet for this arrow function,
      // without braces (i.e. () => ()). However, this would return a value
      // when this handler is called. That value would determine whether other
      // event handlers should be called or not. This is a remnant of old DOM
      // apparently (DOM 0).
      // (e) => e.target.key === 'Enter' && onSubmit ? onSubmit(e) : null
      if (open && e.target.key === 'Enter' && onSubmit) {
        onSubmit(e)
      }
    }
    if (open) {
      window.addEventListener('keydown', handleKeyDown)
    } else {
      window.removeEventListener('keydown', handleKeyDown)
    }

    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [open])

  if (!Array.isArray(children)) {
    children = [children]
  }

  const header = []
  const body = []
  const footer = []
  const other = []

  // Be aware that these type checks might break in a production build
  // depending on mangling options. Currently these three function names
  // are excluded in .terserrc config file for terser which parcel uses.
  children.forEach((c, index) => {
    if (c) {
      // each item of an array should always have a unique key property.
      c.key = index
    }
    if (c?.type?.name === OverlayHeader.name) {
      header.push(c)
    } else if (c?.type?.name === OverlayBody.name) {
      body.push(c)
    } else if (c?.type?.name === OverlayFooter.name) {
      footer.push(c)
    } else {
      other.push(c)
    }
  })
  console.assert(
    header.length <= 1,
    'Programming error: only one header is allowed.'
  )
  console.assert(
    body.length <= 1,
    'Programming error: only one body is allowed.'
  )
  console.assert(
    footer.length <= 1,
    'Programming error: only one footer is allowed.'
  )

  if (!body.length) {
    body.push(<OverlayBody key={1}>{other}</OverlayBody>)
  } else {
    body.concat(other)
  }

  return (
    <Form data={data} debug={debug} {...rest}>
      <Overlay name={name} open={open} title={title} header={header} {...rest}>
        {body}
        {footer}
      </Overlay>
    </Form>
  )
}

/**
 * Renders a simple overlay to get confirmation from user.
 * @param {boolean} open flag to show or hide overlay
 * @param {Function} onConfirm callback to confirm button click
 * @param {Function} onReject callback to reject button click
 * @param {string} title overlay title
 * @param {Array} children overlay content
 * @returns ReactElement
 */
export const ConfirmOverlay = ({
  open,
  onConfirm,
  onReject,
  title,
  children,
}) => {
  const { t } = useTranslation()
  return (
    <OverlayForm open={open} onClose={onReject} title={title ? title : ''}>
      <OverlayBody>{children}</OverlayBody>
      <OverlayFooter repel>
        <Button text={t('Yes')} onClick={onConfirm} danger />
        <Button text={t('No')} onClick={onReject} />
      </OverlayFooter>
    </OverlayForm>
  )
}

// Do NOT export this. Use title and subtitle props instead.
const OverlayHeader = ({ children, ...rest }) => (
  <OverlayHeaderContainer {...rest}>{children}</OverlayHeaderContainer>
)
export const OverlayBody = ({ children, ...rest }) => (
  <OverlayBodyContainer vertical {...rest}>
    {children}
  </OverlayBodyContainer>
)
export const OverlayFooter = ({ children, ...rest }) => (
  <OverlayFooterContainer {...rest}>{children}</OverlayFooterContainer>
)

export const SystemOverlay = ({
  name,
  title,
  open,
  onClose,
  onSearch,
  children,
  ...rest
}) => {
  const myRef = useRef()
  const { t } = useTranslation()

  // We want to prevent renders before first open. This way we can
  // avoid some service calls before the first load.
  const [openedOnce, setOpenedOnce] = useState(open)

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.code === 'Escape') {
        if (open) {
          onClose && onClose()
        }
      }
    }
    const handleMouseUp = (e) => {
      // Only close if target is not of of my descendants
      if (myRef.current && !myRef.current.contains(e.target)) {
        onClose && onClose()
      }
    }
    if (open) {
      document.addEventListener('mouseup', handleMouseUp)
      document.addEventListener('keydown', handleEscape)
    } else {
      document.removeEventListener('mouseup', handleMouseUp)
      document.removeEventListener('keydown', handleEscape)
    }

    // On first open, set the flag
    if (open && !openedOnce) {
      setOpenedOnce(true)
    }

    // and finally make sure they are removed when we are unmounted
    return () => {
      document.removeEventListener('mouseup', handleMouseUp)
      document.removeEventListener('keydown', handleEscape)
    }
  }, [open])

  if (!openedOnce) {
    return null
  }

  return (
    <StyledSystemOverlayContainer
      ref={myRef}
      vertical
      padding="10px"
      open={open}
      {...rest}
    >
      <OverlayContext.Provider value="system">
        <div
          onMouseUp={(e) => {
            // Don't let our catch-all handler on the document trigger and
            // cause us to close by not passing the mouseup event further.
            e.stopPropagation()
          }}
        >
          <Container
            flex
            repel
            style={{ alignItems: 'center', paddingBottom: '10px' }}
          >
            {typeof title === 'string' ? <h1>{t(title)}</h1> : title}
            <OverlayCloseButton
              onClick={onClose}
              style={{ marginRight: '5px' }}
            />
          </Container>
          {onSearch && (
            <SearchInput
              onChange={(search) => onSearch(search)}
              stopPropagation
            />
          )}
          {children}
        </div>
      </OverlayContext.Provider>
    </StyledSystemOverlayContainer>
  )
}

/************************************
 * Styled Components from now on only
 * Do not export anything from now on
 ************************************/

const OverlayCloseButton = styled(X)`
  cursor: pointer;
`

const OverlayContainer = styled.div`
  position: fixed; /* Sit on top of the page content */
  display: ${(props) =>
    props.open ? 'block' : 'none'}; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(33, 33, 36, 0.75);
  z-index: ${ZIndexEnum.ABOVE_MOST}; /* Specify a stack order in case you're using a different order for other elements */

  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
  }
  cursor: default;
`

const OverlayContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: ${(props) => 100 - props.offset}%;
  box-shadow: 0px 3px 24px #00000029;
  border: 0.5px solid #e5e8eb;
  border-radius: 5px;
  background: #ffffff;
  transform: translate(-${(props) => props.offset}%, -50%);
  z-index: ${ZIndexEnum.ABOVE_ALL};

  @media (max-width: 768px) {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translate(0, 0);
    overflow-y: auto;
    display: flex;
  }
`

const OverlayHeaderContainer = styled(Container)`
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  padding: 20px;
`

const OverlayBodyContainer = styled(Container)`
  display: flex;
  padding: 20px;
  padding-top: 0px;
  flex: 1 1 auto;
  @media (max-width: 768px) {
    ${(props) =>
      !props.grow &&
      css`
        flex: 0;
      `}
  }
`

const OverlayFooterContainer = styled(Container)`
  border-top: 1px solid #eeeeee;
  padding: 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    padding: 20px;
  }
`

const OverlayFormHeader = styled.div`
  height: 64px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h2,
  h3,
  h4 {
    padding-left: 20px;
  }
`

const StyledSystemOverlayContainer = styled(Container)`
  overflow: scroll;
  position: fixed;
  right: 0;
  top: 60px;
  display: ${(props) => (props.open || props.opacity > 0 ? 'flex' : 'none')};
  gap: 5px;
  /* This z-index does not solve conflicts with other positioned elements which
   * are not part of the current stacking context, e.g. within main content area.
   * For those, see the z-index set on the main Content component.
   **/
  z-index: ${ZIndexEnum.ABOVE_ALL};
  width: calc(40% - 24px);
  @media (max-width: 768px) {
    width: 100%;
  }
  height: calc(100% - 60px);
  background: #ffffff;
  flex: 0 1 auto;
  border-left: 1px solid #eeeeee;
  box-sizing: border-box;
  transition: 0.3s;
`

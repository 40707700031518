import React from 'react'

import { useService } from 'common/service/context'
import { Form } from 'common/widgets/form/context'

import { SmallScaleEquipmentFormContent } from './form'

/**
 * Renders page for adding a small scale equipment
 *
 * @returns ReactElement
 */
export const SmallScaleEquipmentAddPage = () => {
  const service = useService()

  const handleAdd = async (values) => {
    values['process'] = 'COMMISSIONING'
    return await service.post('items/resources/', values)
  }

  return (
    <Form>
      <SmallScaleEquipmentFormContent onAdd={handleAdd} />
    </Form>
  )
}

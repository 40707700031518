import { useParams } from 'react-router-dom'

import { Form } from 'common/widgets/form/context'
import { DataSource } from 'common/widgets/data-source'
import { useService } from 'common/service/context'

import { ContactQualificationFormContent } from './form'

export const ContactQualificationEditPage = () => {
  const { id } = useParams()
  const service = useService()

  const fetch = async () => await service.get(`/contact/qualification/${id}`)

  const handleSave = async (values) =>
    await service.put(`/contact/qualification/${id}`, values)

  return (
    <DataSource
      fetch={fetch}
      render={({ data, loading }) => (
        <>
          {data && (
            <Form data={data}>
              <ContactQualificationFormContent id={id} onUpdate={handleSave} />
            </Form>
          )}
        </>
      )}
    />
  )
}

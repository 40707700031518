import { Route, Routes, Navigate } from 'react-router-dom'

import { useSession } from 'common/session/context'

import { RegisterPage } from './login/sign_up'
import { LoginPage } from './login/sign_in'
import { PasswordResetPage, ForgottenPasswordPage } from './login/password'

export const UserRoutes = () => {
  const ctx = useSession()

  // None of these routes should render if users is already
  // logged in. This is less confusing and more straightforward.
  // User can logout and come back if needed.
  if (ctx.isLoggedIn()) {
    return <Navigate to="/" />
  }
  return (
    // These routes would get "/users" prefix as defined in app.js
    <Routes>
      <Route path="/sign_in" element={<LoginPage />} />
      <Route path="/sign_out" element={<SignOut />} />
      <Route path="/sign_up" element={<RegisterPage />} />
      <Route path="/password/new" element={<ForgottenPasswordPage />} />
      <Route path="/password/reset" element={<PasswordResetPage />} />
      <Route path="*" element={<p>Page not found.</p>} />
    </Routes>
  )
}

const SignOut = () => {
  const { logout } = useSession()
  // will navigate to sign_in
  logout()
}

import React from 'react'

import { Form } from 'common/widgets/form/context'
import {
  NumericField,
  SelectField,
  TextField,
  TextAreaField,
} from 'common/widgets/form/field'
import { Row, Column } from 'common/widgets/grid'
import { SectionView } from 'common/widgets/view'
import {
  EquipmentCategoryFreeField,
  EquipmentMaincategoryFreeField,
  EquipmentSubcategoryFreeField,
} from 'modules/master-data/equipments/widgets/category'
import { CostCenterSelectField } from 'modules/master-data/cost-centers/widgets'

import { OrderableField } from '../widgets/orderable'
import { AccountingGroupField } from '../widgets/accounting-group'
import { AccountingTypeField } from '../widgets/accounting-type'
import { QuantityUnitField } from '../widgets/quantity-unit'
import { ItemTypeEnum, FormworkAccountingTypeEnum } from '../utils'

import { SourceOfSupplyField } from './widgets/source-of-supply'
import { EquipmentConditionField } from './widgets/condition'

export const FormworksForm = ({ onAdd, ...rest }) => {
  return (
    <Form
      baseUrl="/items/formworks"
      showActions
      readOnly={false}
      auto
      debug
      {...rest}
    >
      <Row>
        <Column n={6}>
          <SectionView title="Master data">
            <TextField label="Label" name="name" mandatory />
            <TextField label="Inventory number" name="inventory_number" />
            <EquipmentMaincategoryFreeField
              label="Main group"
              mandatory
              params={[{ type: ItemTypeEnum.FORMWORK }]}
            />
            <EquipmentCategoryFreeField
              label="Group"
              mandatory
              dependable={!onAdd}
              params={[{ type: ItemTypeEnum.FORMWORK }]}
            />
            <EquipmentSubcategoryFreeField
              label="Subgroup"
              dependable={!onAdd}
              params={[{ type: ItemTypeEnum.FORMWORK }]}
            />
            <SelectField
              label="Commission type"
              name="commission_type"
              mandatory
              items={[
                { key: 'COMMISSIONER', title: 'COMMISSIONER' },
                { key: 'SELF', title: 'SELF' },
              ]}
            />
            <TextField label="Supplier" name="supplier" />

            <SourceOfSupplyField labe="Supplier" mandatory />
            <OrderableField label="Orderable" mandatory selected={false} />
          </SectionView>
        </Column>
        <Column n={6}>
          <SectionView title="Accounting and stock">
            <CostCenterSelectField
              label="Cost center"
              name="cost_center_id"
              removable
            />
            <AccountingTypeField
              label="Accounting type"
              typeEnum={FormworkAccountingTypeEnum}
              mandatory
            />
            <NumericField
              labe="Price when new"
              name="cost_rate"
              preDecimalScale={4}
              decimalScale={2}
              mandatory
            />
            <QuantityUnitField mandatory label="Quantity unit" />
            <NumericField
              label="Total stock"
              name="total_amount"
              preDecimalScale={4}
            />
            <TextField label="Storage" name="storage" />
            <EquipmentConditionField label="Condition" />
            <AccountingGroupField
              label="Accounting group"
              params={[{ type: 'FORMWORK' }]}
            />
          </SectionView>
        </Column>
        <Column n={6}>
          <SectionView title="Dimensions and cargo">
            <NumericField label="Weight" name="weight" preDecimalScale={4} />
            <TextField label="Packaging unit" name="packaging_unit" />
            <TextField label="Transportation" name="transportation" />
            <TextField
              label="Clear inner dimensions (LxWxH)"
              name="dimensions_1"
            />
            <TextField label="Outer dimensions (LxWxH))" name="dimensions_2" />
            <TextField
              label="Maximum outer dimensions (LxWxH)"
              name="dimensions_3"
            />
            <TextAreaField label="Comment" name="comment" rows="4" />
          </SectionView>
        </Column>
      </Row>
    </Form>
  )
}

import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { NoSessionTemplate } from 'modules/security/login/template'
import { Form } from 'common/widgets/form/context'
import { useSession } from 'common/session/context'
import { useToast } from 'system/toast/context'
import { useService } from 'common/service/context'
import { SubmitButton, BackButton } from 'common/widgets/button'
import { Container } from 'common/widgets/container'
import { EmailField } from 'common/widgets/form/field'

import { AuthFormHeader } from './widgets'
import { RegisterForm } from './sign_up'
import styles from './login.module.css'

export const PasswordResetPage = () => {
  const session = useSession()
  const { toasts } = useToast()
  const query = new URLSearchParams(useLocation().search)
  const token = query.get('token')
  const email = query.get('email')

  if (!token) {
    toasts.error('Registration token missing.')
  }

  const handleSubmit = async (data) => {
    const [, error] = await session.resetPassword({
      password: data.password,
      token,
    })
    if (!error && email) {
      toasts.success('Password successfully reset.')
      session.login({ email, password: data.password })
    }
  }

  return (
    <NoSessionTemplate>
      {token ? (
        <Form onSubmit={handleSubmit}>
          <RegisterForm email={query.get('email')} passwordReset />
        </Form>
      ) : (
        'Password reset token missing.'
      )}
    </NoSessionTemplate>
  )
}

export const ForgottenPasswordPage = () => {
  const { t } = useTranslation(['security'])
  const service = useService()
  const { toasts } = useToast()

  const resetPassword = async (data) => {
    const [, error] = await service.post('security/authn/reset-password', data)
    if (!error) {
      toasts.success(t('Password reset request sent. Check your emails.'))
    }
  }

  return (
    <NoSessionTemplate>
      <Form onSubmit={resetPassword} className={styles.login}>
        <Container
          style={{ display: 'grid', gridAutoRows: 'max-content' }}
          vertical
          maxWidth="420px"
          gap="10px"
        >
          <AuthFormHeader />
          <EmailField name="email" label="E-Mail" mandatory />
          <SubmitButton text="Reset password" />
          <BackButton type="button" text="Back to login" />
        </Container>
      </Form>
    </NoSessionTemplate>
  )
}

export const formatUserName = (user) => {
  if (user?.first_name && user?.last_name) {
    return `${user.first_name} ${user.last_name}`
  } else if (user?.first_name) {
    return user.first_name
  } else if (user?.last_name) {
    return user.last_name
  } else if (user?.id) {
    return `User ${user.id}`
  }
  return '-'
}

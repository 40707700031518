import { Form } from 'common/widgets/form/context'
import { TextField, TextAreaField, FileField } from 'common/widgets/form/field'
import { CardView } from 'common/widgets/view'

export const DeviceForm = ({ ...rest }) => {
  return (
    <Form baseUrl="/telematics" showActions auto {...rest}>
      <CardView>
        <TextField name="id" label="Id" />
        <TextField name="name" label="Name" mandatory />
        <TextField name="resource_id" label="Equipment" nullable />
        <FileField
          name="photo"
          idField="photo_id"
          label="Photo"
          accept="image/png, image/jpeg"
          preview
        />
        <TextAreaField name="description" label="Description" rows="5" />
      </CardView>
    </Form>
  )
}

import axios from 'axios'
import React, { createContext, useContext, useState, useEffect } from 'react'

import { useToast } from 'system/toast/context'

export const LOCAL_STORAGE_TOKEN_KEY = 'access_token'

const ServiceContext = createContext()

var errorHandlers = []

// API should be loadable from server and user settings too
axios.defaults.baseURL =
  localStorage.getItem('api') ?? `${window.location.origin}/api/`

export const ServiceProvider = ({ children }) => {
  const [offline, setOffline] = useState(false)
  const { toasts } = useToast()

  console.log(`* API Endpoint: ${axios.defaults.baseURL}`)

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'api') {
        axios.defaults.baseURL = localStorage.getItem(event.key)
      }
    }
    window.addEventListener('storage', handleStorageChange)
    return () => window.removeEventListener('storage', handleStorageChange)
  }, [])

  const addErrorHandler = (handler) => {
    if (!errorHandlers.includes(handler)) {
      errorHandlers = [...errorHandlers, handler]
    }
  }

  const removeErrorHandler = (handler) =>
    (errorHandlers = errorHandlers.filter((e) => e !== handler))

  const makeConfig = (headers, path) => {
    const config = {}
    const bearerToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)
    if (bearerToken !== null) {
      config.headers = { Authorization: `Bearer ${bearerToken}` }
    }
    return headers ? { ...config, ...headers } : config
  }

  const wrapHttpCall = async (f) => {
    // For error handling see:
    // https://axios-http.com/docs/handling_errors
    try {
      const response = await f().catch((error) => {
        if (error.response) {
          console.error('error.response', error.response)
          toasts.error(error.response.data?.message || error.response.data)
          setOffline(error.response.status == 504)
        } else if (error.request) {
          console.error('error.request', error.request)
          toasts.error(error?.message || 'API error, check the console.')
        } else {
          console.error('Error', error.message)
          toasts.error(error?.message || 'API error, check the console.')
        }
        console.error(error.config)
        //setOffline(true)
        throw error
      })
      // Extracts total records count
      const totalCount = response.headers['x-total-count']
        ? parseInt(response.headers['x-total-count'])
        : 0
      // Everything is OK
      setOffline(false)
      return [response, null, totalCount]
    } catch (error) {
      // Error happend
      errorHandlers.forEach((handler) =>
        handler(error?.response?.status, error?.response?.data?.message)
      )
      return [null, error, 0]
    }
  }

  const get = async (path, params, headers) => {
    // Try to convert non-array parameters into an array of k,v
    if (params && !Array.isArray(params)) {
      params = Object.entries(params).map((q) => ({ [q[0]]: q[1] }))
    }
    let endpoint = path
    const sep = path.includes('?') ? '&' : '?'
    if (params && params.length > 0) {
      let queryComponent = params
        // undefined and NaN are invalid values and should not be sent to the API.
        // If they reache here there is an error in our code. We remove them but print
        // an warning instead.
        .filter(isValidParam)
        .map((p) => convertToKVString(p))
        .join('&')
      endpoint = `${path}${sep}${queryComponent}`
    }

    return await wrapHttpCall(
      async () => await axios.get(endpoint, makeConfig(headers, path))
    )
  }

  /**
   * Convert to multipart if uploading files.
   *
   * This function will copy form data under a "payload" form field.
   **/
  const prepare = (data, headers) => {
    // Make sure data is not empty
    data = dropInvalidEntries(data)

    // A flag to send multipart POST request if files are present
    let hasFiles = false

    // FormData instance to upload multipart/form-data if necessary
    const requestData = new FormData()

    // Browser provides us with FileList when files are being submitted
    for (const part of Object.keys(data)) {
      if (data[part] instanceof FileList) {
        hasFiles = true
        for (const f of data[part]) {
          // We add files as a list to the FormData
          requestData.append(part, f)
        }
        // We have to remove the FileList from the JSON payload
        delete data[part]
      } else {
        // We add other fields as normal form fields to the request
        requestData.set(part, data[part])
      }
    }
    // Finally, we add everything else as a special `payload' JSON
    // string to the request. This is somewhat a hack not to disturb
    // much on our client in order to submit both data and files.
    requestData.set('payload', JSON.stringify(data))

    const requestHeaders = makeConfig(headers)
    if (hasFiles) {
      // For files we have to use multipart/form-data content type
      requestHeaders.headers['Content-Type'] = 'multipart/form-data'
    }
    return [hasFiles ? requestData : data, requestHeaders]
  }

  const post = async (path, data, headers, newApiUrl) => {
    if (newApiUrl && newApiUrl != axios.defaults.baseURL) {
      axios.defaults.baseURL = newApiUrl
    }
    console.log(
      'going to post data with axios',
      data,
      axios.defaults.baseURL,
      newApiUrl
    )
    // prepare data, mostly for processing files
    const [pData, pHeaders] = prepare(data, headers)
    return await wrapHttpCall(
      async () => await axios.post(path, pData, pHeaders)
    )
  }

  const put = async (path, data, headers) => {
    // prepare data, mostly for processing files
    const [pData, pHeaders] = prepare(data, headers)

    return await wrapHttpCall(
      async () => await axios.put(path, pData, pHeaders)
    )
  }

  const patch = async (path, data, headers) => {
    // prepare data, mostly for processing files
    const [pData, pHeaders] = prepare(data, headers)

    return await wrapHttpCall(
      async () => await axios.patch(path, pData, pHeaders)
    )
  }

  const remove = async (path) =>
    await wrapHttpCall(async () => await axios.delete(path, makeConfig()))

  return (
    <ServiceContext.Provider
      value={{
        addErrorHandler,
        removeErrorHandler,
        get,
        post,
        put,
        patch,
        delete: remove,
        offline,
        apiUrl: axios.defaults.baseURL,
      }}
    >
      {children}
    </ServiceContext.Provider>
  )
}

export const useService = () => {
  return useContext(ServiceContext)
}

const convertToKVString = (entry) => {
  let kv = Object.keys(entry).map((key) => {
    if (key !== 'search') {
      if (Array.isArray(entry[key]) && entry[key].length > 0) {
        return entry[key].map((a) => `${key}=${a}`).join('&')
      }
      return `${key}=${entry[key]}`
    } else {
      return `${key}=${encodeURIComponent(entry[key])}`
    }
  })
  return kv[0]
}

/**
 * Filter out parameters with "undefined" or NaN values.
 **/
const isValidParam = (param) => {
  const values = Object.values(param)
  // An array with a single member (each param is a key/value combo)
  if (values && values.length == 1) {
    // An array with a single undefined member
    if (values[0] === undefined) {
      console.warn('Invalid value (undefined) removed from parameters: ', param)
      return false
      // An array with a single NaN member
    } else if (Number.isNaN(values[0])) {
      console.warn('Invalid value (NaN) removed from parameters: ', param)
      return false
    }
  }
  return true
}

const dropInvalidEntries = (data) => {
  data ||= {}
  for (const [k, v] of Object.entries(data)) {
    if (v === undefined || Number.isNaN(v)) {
      console.warn(`Invalid value [${v}] for [${k}] removed from data.`)
      delete data[k]
    }
  }
  return data
}

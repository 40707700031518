import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useService } from 'common/service/context'
import {
  FoldButtons,
  DeleteIconButton,
  CancelButton,
  AddIconButton,
  ConfirmIconButton,
  FlagIconButton,
  SaveButton,
  CancelIconButton,
  DetailsIconButton,
  ArchiveIconButton,
  SignButton,
  LoadIconButton,
  CommissionIconButton,
} from 'common/widgets/button'
import { useToast } from 'system/toast/context'
import { OverlayBody, OverlayFooter, OverlayForm } from 'common/widgets/overlay'
import { NumericInput } from 'common/widgets/input'
import { Row } from 'common/widgets/grid'
import { GridFieldView } from 'common/widgets/view'
import { formatDateRange } from 'common/utils/format'
import {
  NumericField,
  TextField,
  FieldWrapper,
  DateRangeField,
} from 'common/widgets/form/field'
import { Select } from 'common/widgets/select'
import { addDays } from 'common/utils/date'

import { ResetPickedUpItemOverlay } from './overlays/cancelation'
import { ItemSearchOverlay } from './overlays/search'

/*
 * Triple dot action menu for a single delivery.
 * */
export const DeliveryTripleDotMenu = ({ delivery, onChange, onCancel }) => {
  const service = useService()
  const { toasts } = useToast()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const archive = async (data) => {
    await service.put(`kommission/delivery/${data.id}`, {
      archived_at: data.archived_at ? null : new Date(),
    })

    if (onChange) {
      onChange()
    }
    data.archived_at
      ? toasts.success(
          `${t('Delivery note')} ${data.order.number}/${data.number} ${t(
            'sucessfully activated.'
          )}`
        )
      : toasts.success(
          `${t('Delivery note')} ${data.order.number}/${data.number} ${t(
            'sucessfully archived.'
          )}`
        )
  }

  return (
    <FoldButtons wrap>
      {delivery?.editable && (
        <CommissionIconButton
          title="Commission more items"
          tooltip="Commission items"
          white
          onClick={(e) => {
            e.stopPropagation()
            navigate(`/yard/commission/deliveries/${delivery.id}/add`)
          }}
        />
      )}
      {delivery?.transportable && (
        <LoadIconButton
          white
          tooltip="Pickup"
          onClick={(e) => {
            e.stopPropagation()
            navigate(`/yard/commission/deliveries/${delivery.id}/pickup`)
          }}
        />
      )}
      {delivery?.signable && (
        <SignButton
          white
          tooltip="Handover"
          onClick={(e) => {
            e.stopPropagation()
            navigate(`/yard/commission/deliveries/${delivery.id}/sign`)
          }}
        />
      )}
      {delivery?.cancelable && (
        <CancelIconButton
          white
          tooltip="Cancelation"
          onClick={(e) => {
            e.stopPropagation()
            onCancel && onCancel(delivery)
          }}
        />
      )}
      {delivery?.archivable && (
        <ArchiveIconButton
          white
          tooltip="Archive"
          onClick={async (e) => {
            e.stopPropagation()
            await archive(delivery)
            onChange && onChange(delivery)
          }}
          archived={delivery.archived_at}
        />
      )}
      <DetailsIconButton
        tooltip="Details"
        white
        onClick={() => navigate(`/yard/commission/deliveries/${delivery.id}`)}
      />
    </FoldButtons>
  )
}

/*
 * Triple dot action menu for a single delivery item.
 * */
export const DeliveryItemTripleDotMenu = ({
  delivery,
  orderedItem,
  deliveryItem,
  showAddBtn,
  showDelBtn,
  showRstBtn,
  showFaultBtn,
  showConfirmBtn,
  onChange,
  onItemFault,
  onItemConfirm,
}) => {
  const { t } = useTranslation()
  const service = useService()

  // States for displaying modals
  const [showItemSearchOverlay, setShowItemSearchOverlay] = useState(false)
  const [showPickupOveraly, setShowPickupOverlay] = useState(null)
  const [showResetOverlay, setShowResetOverlay] = useState(false)
  const [showFaultOverlay, setShowFaultOverlay] = useState(false)
  const [confirmed, setConfirmed] = useState(false)
  const [faulty, setFaulty] = useState(false)

  if (
    !(showAddBtn || showDelBtn || showRstBtn || showFaultBtn || showConfirmBtn)
  ) {
    return null
  }
  const baseitem = orderedItem ? orderedItem.item : deliveryItem?.baseitem

  return (
    <>
      <FoldButtons wrap>
        {showAddBtn && (
          <AddIconButton
            onClick={() => {
              if (!baseitem?.is_smallscale) {
                setShowPickupOverlay(orderedItem?.item)
              } else {
                setShowItemSearchOverlay(true)
              }
            }}
          />
        )}
        {showDelBtn && deliveryItem && (
          <DeleteIconButton
            onClick={async () => {
              await service.delete(
                `kommission/delivery/items/${deliveryItem.id}?hard-delete=true`
              )
              onChange && onChange()
            }}
          />
        )}
        {showRstBtn && (
          <DeleteIconButton
            onClick={() => {
              setShowResetOverlay(true)
            }}
          />
        )}
        {showFaultBtn && (
          <FlagIconButton
            title={t('Report damage')}
            danger={faulty}
            onClick={() => {
              setShowFaultOverlay(!showFaultOverlay)
            }}
          />
        )}
        {showConfirmBtn && (
          <ConfirmIconButton
            color={confirmed ? '#2DCE18' : '#8b8b8b'}
            onClick={() => {
              setConfirmed(!confirmed)
              setFaulty(confirmed)
              onItemConfirm && onItemConfirm(deliveryItem, !confirmed)
            }}
          />
        )}
      </FoldButtons>
      {/* Below are a set of overlays used in menu actions */}
      <ItemSearchOverlay
        open={showItemSearchOverlay && orderedItem.item.is_smallscale}
        groupId={orderedItem.item.group_id}
        ignoreItems={delivery?.items}
        onClose={() => setShowItemSearchOverlay(false)}
        onSelect={async (item) => {
          setShowPickupOverlay(item)
          setShowItemSearchOverlay(true)
        }}
      />
      {showPickupOveraly && (
        <PickUpItemsOverlay
          delivery={delivery}
          // sending the original item as ordered item is fundamentally wrong. because every time
          // the user selects an item, the exact selected item must be sent to server. otherwise
          // server would always raise errors due to item not being available. so we have to replace
          // the item with the newly selected one.
          orderedItem={{ ...orderedItem, item: showPickupOveraly }}
          deliveryItem={deliveryItem}
          onClose={() => setShowPickupOverlay(null)}
          onChange={onChange}
        />
      )}
      {showResetOverlay && (
        <ResetPickedUpItemOverlay
          open={orderedItem || deliveryItem}
          orderedItem={orderedItem}
          deliveryItem={deliveryItem}
          onClose={() => setShowResetOverlay(false)}
          reload={onChange}
        />
      )}
      {showFaultOverlay && (
        <FaultsOverlay
          enable={onItemFault ? true : false}
          deliveryItem={deliveryItem}
          onSubmit={(...args) => {
            onItemFault(...args)
            setFaulty(true)
            setConfirmed(false)
          }}
          onClose={() => setShowFaultOverlay(false)}
        />
      )}
    </>
  )
}

const PickUpItemsOverlay = ({
  delivery,
  orderedItem,
  deliveryItem,
  onChange,
  onClose,
}) => {
  const { t } = useTranslation()
  const service = useService()
  const [amount, setAmount] = useState(1)
  const [bookingStart, setBookingStart] = useState(null)
  const [bookingEnd, setBookingEnd] = useState(null)

  const handleItemPickUp = async () => {
    /**
     * If there is a delivery item existing we are going to edit it.
     */
    if (deliveryItem) {
      const [, error] = await service.put(
        `kommission/delivery/items/${deliveryItem.id}`,
        {
          // Increasing the commissioned amount (new + previous)
          // Since we are replacing previous amount
          amount: amount, // + deliveryItem?.amount || 0,
        }
      )

      if (!error) {
        setAmount(1)
        onChange && onChange()
        onClose()
      }

      /**
       * If there is no delivery item existing we are going to create one.
       */
    } else {
      const [, error] = await service.post(`kommission/delivery/items`, {
        amount: amount,
        baseitem_id: orderedItem.item.id,
        delivery_id: delivery.id,
        ordered_item_id: orderedItem?.id,
        tmp_start: deliveryItem?.booking_start
          ? deliveryItem?.booking_start
          : bookingStart,
        tmp_end: deliveryItem?.booking_end
          ? deliveryItem?.booking_end
          : bookingEnd,
      })

      if (!error) {
        setAmount(1)
        onChange && onChange()
        onClose()
      }
    }
  }

  return (
    <OverlayForm open onClose={onClose} title={t('Commissioned amount')}>
      <OverlayBody>
        <Row>
          <GridFieldView
            n={6}
            s={6}
            label="Item"
            value={orderedItem.item.name}
          />
          <GridFieldView
            n={6}
            s={6}
            label="Quantity unit"
            value={orderedItem.item.quantity_unit}
          />
          <GridFieldView
            n={6}
            s={6}
            label="Ordered amount"
            value={
              orderedItem ? orderedItem?.original_amount : deliveryItem?.amount
            }
          />
          {orderedItem.item.is_largescale || orderedItem.item.is_formwork ? (
            <GridFieldView
              n={6}
              s={6}
              label="Booking period"
              value={
                orderedItem
                  ? formatDateRange(
                      orderedItem?.booking_start,
                      orderedItem?.booking_end
                    )
                  : formatDateRange(
                      deliveryItem?.tmp_start,
                      deliveryItem?.tmp_end
                    )
              }
            />
          ) : orderedItem.item.is_smallscale ? (
            <GridFieldView n={12} s={12} label="Booking period">
              <DateRangeField
                onChange={(ctx, dates) => {
                  setBookingStart(addDays(dates[0], 1))
                  setBookingEnd(addDays(dates[1], 1))
                }}
              />
            </GridFieldView>
          ) : (
            <GridFieldView
              n={6}
              s={6}
              label="Packaging unit"
              value={orderedItem.item.packaging_unit}
            />
          )}
          <GridFieldView n={12} s={12} label="Amount">
            <NumericInput
              disabled={orderedItem.item.is_resource}
              value={amount}
              onChange={(event) => setAmount(parseInt(event.target.value))}
            />
          </GridFieldView>
        </Row>
      </OverlayBody>
      <OverlayFooter repel gap="10px">
        <SaveButton
          default
          text="Attach"
          disabled={amount < 1}
          onClick={handleItemPickUp}
        />
        <CancelButton onClick={onClose} />
      </OverlayFooter>
    </OverlayForm>
  )
}

const FaultsOverlay = ({ enable, deliveryItem, onSubmit, onClose }) => {
  var formData
  // If the overlay is not enabled then show the fault info from the delivery item
  if (!enable && deliveryItem?.fault) {
    formData = {
      amount: deliveryItem?.fault.amount,
      type: deliveryItem?.fault.type,
      comment: deliveryItem?.fault.comment,
    }
    // if the overlay is enabled then use default input data
  } else {
    formData = {
      amount: 1,
      type: 'DEFECT',
      comment: '',
    }
  }

  const handleFaultSubmit = async (values) => {
    onSubmit &&
      onSubmit(deliveryItem, {
        amount: parseInt(values.json.amount),
        type: values.json.type,
        comment: values.json.comment,
      })
    onClose && onClose()
  }

  return (
    <OverlayForm
      open={deliveryItem}
      data={formData}
      title="Faults"
      onClose={onClose}
    >
      <OverlayBody gap="5px">
        <NumericField
          label="Amount"
          autoFocus
          name="amount"
          mandatory
          disabled={!enable}
        />
        <FaultTypeField enable={enable} />
        <TextField
          label="Comment"
          autoFocus
          name="comment"
          disabled={!enable}
        />
      </OverlayBody>
      {enable && (
        <OverlayFooter repel>
          <SaveButton
            default
            text="Attach"
            disabled={(ctx) =>
              parseInt(ctx.values.json.amount) <= 0 ||
              deliveryItem?.amount < parseInt(ctx.values.json.amount)
            }
            onClick={(e, ctx) => handleFaultSubmit(ctx.values)}
          />
          <CancelButton onClick={onClose} />
        </OverlayFooter>
      )}
    </OverlayForm>
  )
}

const FaultTypeField = ({ enable }) => {
  const items = {
    'Missing amount': 'MISSING',
    'Defective amount': 'DEFECT',
  }
  const mapped = Object.entries(items).map(([title, key]) => {
    return { key, title: title }
  })

  return (
    <FieldWrapper name="type">
      {(getValue, setValue) => (
        <Select
          selected={getValue()}
          items={mapped}
          onSelectChange={(item) => {
            if (enable === true) {
              setValue(item.key)
            }
          }}
        />
      )}
    </FieldWrapper>
  )
}

import { register } from 'system/container'

import { ContactsList } from './list'
import { ContactAddPage } from './add'
import { ContactEditPage } from './edit'

register({
  menu: true,
  render: () => <ContactsList />,
  title: 'Contacts',
  path: '/master-data/contacts',
})

register({
  render: () => <ContactAddPage />,
  title: 'New',
  path: '/master-data/contacts/add',
})

register({
  render: () => <ContactEditPage />,
  title: 'Edit',
  path: '/master-data/contacts/:id/edit',
})

import { useService } from 'common/service/context'
import { DataSource } from 'common/widgets/data-source'
import { DropDownField } from 'common/widgets/form/field'

/**
 * List of base-item categories.
 */
export const CategorySelectField = ({ id, name, ...rest }) => {
  const service = useService()
  return (
    <DataSource fetch={async () => await service.get('/pim/categories')}>
      {({ data }) => {
        return (
          <DropDownField
            name={name}
            items={data
              ?.filter((cat) => cat.id != id)
              .map((cat) => ({ key: cat.id, title: cat.name }))}
            removable
            {...rest}
          />
        )
      }}
    </DataSource>
  )
}

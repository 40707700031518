import { Form } from 'common/widgets/form/context'
import { useService } from 'common/service/context'

import { TeamsFormContent } from './form'

export const TeamsAddPage = () => {
  const service = useService()

  const handleAdd = async (values) => {
    const [result, error] = await service.post(`/contact/teams`, values)

    return [result, error]
  }

  return (
    <Form>
      <TeamsFormContent onAdd={handleAdd} />
    </Form>
  )
}

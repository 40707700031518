import { Avatar } from 'common/widgets/avatar'
import { SimpleDataTable, SimpleColumn } from 'common/data-table/simple'
import { QueryProvider } from 'common/query/context'
import { useMediaQuery } from 'common/utils/hooks'

import { AssetDistributionMap } from '../widgets/map'

export const SmallScaleEquipmentListPage = () => {
  const isRowBased = useMediaQuery('(max-width: 768px)')

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: isRowBased ? '1fr' : '1fr 1fr',
        gap: 0,
        padding: 0,
      }}
    >
      <QueryProvider
        name="small-scales-list-provider"
        url="/items/resources?process=COMMISSIONING"
        params={{
          select:
            'base_item(name,category,subcategory,maincategory),cost_center(id,code)',
        }}
      >
        <SimpleDataTable
          name="smallscale"
          title="Small scale equipments"
          showAddBtn
          addTooltip="Add small scale"
          navBaseUrl="/master-data/equipments/small-scales"
          archive
          style={{ padding: '10px' }}
        >
          <SimpleColumn fixed>
            {(r) => <Avatar text={r.name} id={r.image?.id} />}
          </SimpleColumn>
          <SimpleColumn
            sortable
            n={12}
            s={12}
            parentField="base_item"
            field="name"
            header
          />
          <SimpleColumn
            n={3}
            s={6}
            sortable
            field="maincategory"
            parentField="base_item"
            label="Main group"
            filterUrl="items/resources/maincategory?process=COMMISSIONING"
          />
          <SimpleColumn
            n={3}
            s={6}
            sortable
            field="category"
            parentField="base_item"
            label="Group"
            filterUrl="items/resources/category?process=COMMISSIONING"
          />
          <SimpleColumn
            n={2}
            s={6}
            sortable
            field="subcategory"
            parentField="base_item"
            label="Subgroup"
            filterUrl="items/resources/subcategory?process=COMMISSIONING"
          />
          <SimpleColumn
            n={2}
            s={6}
            sortable
            label="Inventory number"
            field="inventory_number"
          />
        </SimpleDataTable>
        <AssetDistributionMap
          visible={!useMediaQuery('(max-width: 768px)')}
          style={{
            position: 'sticky',
            top: 0,
            borderRadius: '0',
            height: 'calc(100vh - 60px)',
          }}
        />
      </QueryProvider>
    </div>
  )
}

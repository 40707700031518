import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { SystemOverlay } from 'common/widgets/overlay'
import { useSession } from 'common/session/context'
import { Container } from 'common/widgets/container'
import { LogoutButton } from 'common/widgets/button'
import { formatDistanceFromNow } from 'common/utils/format'
import { UserEmailChangeConfirmation } from 'common/widgets/email-change'
import { CommentView } from 'common/widgets/view'
import { Avatar } from 'common/widgets/avatar'

export const UserOverlay = ({ name, open, onClose }) => {
  const { t } = useTranslation()
  const ctx = useSession()
  const navigate = useNavigate()

  return (
    <SystemOverlay
      open={open}
      name={name}
      onClose={onClose}
      title="User profile"
    >
      <Container flex>
        <Avatar
          imgSrc={ctx.currentUser?.photo}
          text={ctx.currentUser?.fullname}
          size="100px"
          borderRadius="10px"
        />
        <Container flex vertical padding="15px 20px">
          <h3>{ctx.currentUser?.fullname}</h3>
          <h5>{ctx.currentUser?.email}</h5>
          {ctx.currentUser?.last_login && (
            <h5>
              {t('Last login {{duration}} ago', {
                duration: formatDistanceFromNow(ctx.currentUser?.last_login),
              })}
            </h5>
          )}

          <a
            href={`/security/users/${ctx.currentUser?.id}/edit`}
            rel="noreferrer"
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              navigate(`/security/users/${ctx.currentUser?.id}/edit`)
            }}
          >
            <h3 style={{ color: 'blue', textDecoration: 'underline' }}>
              {t('Edit profile')}
            </h3>
          </a>
        </Container>
      </Container>
      <Container gap="10px" flex padding="0px 0px 15px 0px">
        {ctx.currentUser?.pending_email && (
          <>
            <CommentView
              warning
              comment={
                t('Email change confirmation required for') +
                ` ${ctx.currentUser?.pending_email}`
              }
            />
            <UserEmailChangeConfirmation user={ctx.currentUser} />
          </>
        )}
      </Container>
      <LogoutButton danger onClick={ctx.logout} />
    </SystemOverlay>
  )
}

import { register } from 'system/container'

import { RequestsPage } from './shrinks'
import { ExtendRequestsPage } from './extensions'

register({
  menu: true,
  title: 'Requests',
  path: '/disposition/requests',
})

register({
  menu: true,
  render: () => <RequestsPage />,
  title: 'Booking shrinks',
  path: '/disposition/requests/shrink',
})

register({
  menu: true,
  render: () => <ExtendRequestsPage />,
  title: 'Booking extensions',
  path: '/disposition/requests/extend',
})

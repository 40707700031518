import { useTranslation } from 'react-i18next'

import {
  TextField,
  EmailField,
  PhoneNumberField,
  CheckBoxField,
  FileField,
  NewPasswordField,
} from 'common/widgets/form/field'
import { SimpleFormAction } from 'common/widgets/form/utils'
import { Row, Column } from 'common/widgets/grid'
import { CommentView, SectionView } from 'common/widgets/view'
import { useForm } from 'common/widgets/form/context'
import { useSession } from 'common/session/context'

import { UserRoleSelectField } from './roles'

export const UserFormContent = ({ onAdd, onUpdate, onRemove }) => {
  const { t } = useTranslation(['security'])
  const { values } = useForm()
  const { currentUser, refresh } = useSession()

  return (
    <>
      <SectionView title="Details">
        <Row>
          <Column title="Profile picture" n={12} m={12} s={12}>
            <FileField
              name="photo"
              accept="image/png, image/jpeg, image/webp"
              preview
              previewSize="128px"
              nullable
            />
          </Column>
          <Column n={12} m={12} s={12} flex gap="10px">
            <TextField name="first_name" label="First name" />
            <TextField name="last_name" label="Last name" />
          </Column>
          <Column n={12} m={12} s={12} flex gap="10px">
            <EmailField name="email" label="Email" mandatory />
            {(onAdd || onUpdate) && (
              <NewPasswordField name="password" label="Password" />
            )}
            <PhoneNumberField name="phone_number" label="Phone number" />
          </Column>
          {onAdd && (
            <Column n={12} m={12} s={12}>
              <CheckBoxField
                name="send_invitation"
                label="Invitation"
                i18nScope="security"
              >
                Send invitation E-Mail
              </CheckBoxField>
            </Column>
          )}
          {onUpdate && values?.json?.pending_email && (
            <Column n={12} m={12} s={12}>
              <CommentView
                warning
                comment={
                  t('Email change confirmation required for') +
                  ` ${values?.json?.pending_email}`
                }
              />
            </Column>
          )}
        </Row>
        <UserRoleSelectField
          name="roles"
          label="Roles"
          disabled={!currentUser?.is_privileged}
          mandatory
        />
      </SectionView>
      <SimpleFormAction
        onAdd={onAdd}
        onUpdate={onUpdate}
        onRemove={onRemove}
        onSuccess={async () => {
          // We want the toolbar image and current user to be refreshed
          // when editing the current user.
          if (currentUser && currentUser.id == values.json?.id) {
            await refresh()
          }
        }}
      />
    </>
  )
}

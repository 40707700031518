import React from 'react'
import { Circle, Layers, Package, Tool } from 'react-feather'
import { useTranslation } from 'react-i18next'

import { DropDown } from 'common/widgets/dropdown'

export const ItemTypeFilter = ({ onChange }) => {
  const { t } = useTranslation(['orders'])

  return (
    <DropDown
      onSelectChange={(value) => {
        onChange && onChange(value.key)
      }}
      title={t('All items')}
      items={[
        {
          key: null,
          title: 'All items',
          icon: <Circle />,
        },
        {
          key: 'LARGESCALE',
          title: 'Largescale',
          icon: <Tool />,
        },
        {
          key: 'SMALLSCALE',
          title: 'Smallscale',
          icon: <Tool />,
        },
        {
          key: 'FORMWORK',
          title: 'Formworks',
          icon: <Layers />,
        },
        {
          key: 'CONSUMABLE',
          title: 'Consumables',
          icon: <Package />,
        },
      ]}
      icon={<Circle />}
    />
  )
}

import styled, { keyframes } from 'styled-components'
import React, { useEffect, useState } from 'react'
import { ArrowLeft, FolderPlus, Loader, Upload } from 'react-feather'
import { useTranslation } from 'react-i18next'

import { useToast } from 'system/toast/context'

import { AddIconButton, Button } from '../button'
import { Container } from '../container'
import { OverlayBody, OverlayFooter, OverlayForm } from '../overlay'

export const FileUploadOverlay = ({
  open,
  onUpload,
  onClose,
  accept = undefined,
  maxSize = undefined,
  isPhoto = false,
  title = '',
}) => {
  const { t } = useTranslation()
  const { toasts } = useToast()
  // Keeps selected files list.
  const [selectedFiles, setSelectedFiles] = useState([])
  // Keeps state of uploading.
  const [state, setState] = useState({
    uploading: false,
    loaded: 0,
    start: null,
  })

  /**
   * Validates file type.
   * @param {any} file file object
   * @returns
   */
  const validateFileType = (file) => {
    let isValidType = true
    if (accept) {
      isValidType = file.type && accept.includes(file.type)
      if (!isValidType) {
        toasts.error(t('Provided file type is invalid.'))
      }
    }
    return isValidType
  }

  /**
   * Validates file size.
   * @param {any} file file object
   * @returns
   */
  const validateFileSize = (file) => {
    let isValidSize = true
    if (maxSize) {
      isValidSize = file.size <= maxSize
      if (!isValidSize) {
        toasts.error(
          t('Maximum acceptable file size is {{maxFileSize}}!', {
            maxFileSize: humanizeFileSize(maxSize),
          })
        )
      }
    }
    return isValidSize
  }

  /**
   * Handles uploading operation.
   */
  const handleUpload = async () => {
    if (
      validateFileType(selectedFiles[0]) &&
      validateFileSize(selectedFiles[0])
    ) {
      setState((p) => ({
        ...p,
        uploading: true,
        start: new Date(),
        loaded: 0,
      }))
      const [result, error] = await onUpload(selectedFiles[0], (progress) =>
        setState((p) => ({ ...p, loaded: progress.loaded }))
      )
      if (!error) {
        onClose()
        toasts.success(t('File successfully uploaded.'))
      } else {
        setState((p) => ({
          ...p,
          uploading: false,
          start: null,
          loaded: 0,
        }))
      }
      return result
    }
  }

  /**
   * Resets state after open value changes.
   */
  useEffect(() => {
    setSelectedFiles([])
    setState({
      uploading: false,
      loaded: 0,
      start: null,
    })
  }, [open])

  // Destructs state object
  const { uploading, loaded, start } = state
  const percentage =
    loaded > 0 &&
    selectedFiles &&
    selectedFiles.length > 0 &&
    selectedFiles[0].size > 0
      ? loaded / selectedFiles[0].size
      : 0
  const elapsed = (new Date() - start) / 1000

  let pictureSource = '#'
  if (selectedFiles.length > 0 && isPhoto) {
    pictureSource = URL.createObjectURL(selectedFiles[0])
  }

  return (
    <OverlayForm
      open={open}
      title={t(title) || (isPhoto ? t('Upload picture') : t('Upload file'))}
      onClose={onClose}
      onSubmit={onUpload}
    >
      <OverlayBody grow gap="10px">
        <FileUploadLabel grow>
          <input
            type="file"
            name="picture"
            accept={accept}
            onChange={(e) => setSelectedFiles(e.target.files)}
            onClick={(event) => {
              event.target.value = null
              setSelectedFiles([])
            }}
          />
          {(!isPhoto || selectedFiles.length <= 0) && <FolderPlus />}
          {selectedFiles.length > 0 && isPhoto && (
            <SelectedImage
              src={pictureSource}
              alt="Selected Image"
              onLoad={() => URL.revokeObjectURL(pictureSource)}
            />
          )}
        </FileUploadLabel>
        <Container>
          <Container flex repel>
            <p>{selectedFiles.length ? selectedFiles[0].name : ' '}</p>
            <p>
              {selectedFiles.length
                ? humanizeFileSize(selectedFiles[0].size)
                : ' '}
            </p>
          </Container>
          <ProgressBar percentage={100 * percentage} />
          <Container flex repel>
            <h5>
              {(uploading || loaded) && !isNaN(loaded / elapsed)
                ? `${humanizeFileSize(Math.round(loaded / elapsed))}/s`
                : ' '}
            </h5>
            <h5>
              {uploading || loaded ? `${parseInt(100 * percentage)}%` : ' '}
            </h5>
          </Container>
        </Container>
      </OverlayBody>
      <OverlayFooter repel>
        <Button
          default
          disabled={selectedFiles.length === 0 || uploading}
          onClick={handleUpload}
        >
          {uploading ? <AnimatedLoader /> : <Upload />}
          <p>{t('Upload')}</p>
        </Button>
        <Button disabled={uploading} onClick={onClose}>
          <p>{t('Cancel')}</p>
          <ArrowLeft />
        </Button>
      </OverlayFooter>
    </OverlayForm>
  )
}

export const FileUploadButton = ({ onUpload }) => {
  const [open, setOpen] = useState(false)
  return (
    <div>
      <FileUploadOverlay
        open={open}
        onUpload={onUpload}
        onClose={() => setOpen(false)}
      />
      <AddIconButton
        default
        onClick={() => {
          setOpen(true)
        }}
      />
    </div>
  )
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const AnimatedLoader = styled(Loader)`
  animation: ${rotate} 2s linear infinite;
`

export const humanizeFileSize = (size) => {
  if (size < 1024) {
    return '' + size
  } else if (size < 1024 * 1024) {
    return `${(size / 1024).toFixed(1)} kb`
  } else if (size < 1024 * 1024 * 1024) {
    return `${(size / (1024 * 1024)).toFixed(2)} MB`
  } else {
    return `${(size / (1024 * 1024 * 1024)).toFixed(2)} GB`
  }
}

const ProgressBar = ({ percentage }) => {
  return (
    <ProgressBarOuter>
      <ProgressBarInner style={{ width: `${percentage}%` }} />
    </ProgressBarOuter>
  )
}

const ProgressBarOuter = styled(Container)`
  width: 100%;
  height: 24px;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  overflow: hidden;
`
const ProgressBarInner = styled(Container)`
  background: #f9f29a;
  height: 24px;
  transition: 1s;
`

const FileUploadLabel = styled.label`
  input {
    display: none;
  }
  display: flex;
  align-items: center;
  margin: auto;
  width: 300px;
  height: 300px;
  margin: auto;
  border: 2px dashed #376df4;
  border-radius: 10px;
  box-sizing: border-box;
  background: #376df405;
  cursor: pointer;

  svg {
    margin: auto;
    color: #303030;
    width: 48px;
    height: 48px;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    flex: 1;
  }
`

const SelectedImage = styled.img`
  flex: 1;
  object-fit: contain;
  margin: auto;
  border-radius: 10px;
  display: block;
  max-width: 80%;
  max-height: 80%;
  width: auto;
  height: auto;
`

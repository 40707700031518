import { Container } from 'common/widgets/container'
import { Legend } from 'common/widgets/legend'
import { formatProjectName } from 'modules/projects/utils'
import { createEvent, FullCalendarWrapper } from 'common/widgets/calendar'
import 'modules/disposition/calendar.css'

import { BookingRequestTypeEnum } from '../utils'

export const BookingChangeCalendar = ({ request, bookings, tasks }) => {
  const events = tasks.map((r) => {
    createEvent({
      start: r.plan_date,
      end: r.end_date ? r.end_date : r.plan_date,
      title: formatProjectName(request.booking.project),
      className: 'change-event-maintenance',
      type: 'maintenance',
    })
  })
  bookings.forEach((booking) => {
    events.push(
      createEvent({
        start: booking.booking_start,
        end: booking.booking_end,
        title: formatProjectName(request.booking.project),
        className:
          booking.id === request.booking.id
            ? 'change-event-main'
            : 'change-event-booked',
        type: 'booked',
      })
    )
  })
  events.push(
    createEvent({
      start: request.booking_start,
      end: request.booking_end,
      title: formatProjectName(request.booking.project),
      className: 'change-event-current',
      type: 'current',
    })
  )

  return (
    <FullCalendarWrapper
      selectable={false}
      initialDate={request.booking_start}
      events={events}
    />
  )
}

export const BookingChangeLegends = ({ request }) => {
  return (
    <Container flex gap="10px">
      <Legend text="Original" color="#95E68B" />
      <Legend
        title={
          request.type === BookingRequestTypeEnum.EXTEND ? 'Extend' : 'Shrink'
        }
        color="#FC4242"
      />
      <Legend text="Booked" color="#376DF4" />
      <Legend text="Check" color="#F49537" />
    </Container>
  )
}

import { EditViewPage } from 'system/utils/edit'

import { CostCenterForm } from './form'

export const CostCenterEditPage = () => {
  return (
    <EditViewPage url="/accounting/cost-centers" title={(e) => e.name}>
      {(data) => <CostCenterForm data={data} />}
    </EditViewPage>
  )
}

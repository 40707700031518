import { ShoppingCart } from 'react-feather'

import { register } from 'system/container'

import { OrderListPage } from './list'
import { OrdersOverviewPage } from './overview'

// Register the orders subsystem
register({
  icon: <ShoppingCart />,
  title: 'Orders',
  // Subsystem base route, no trailing slash
  path: '/orders',
  // mainmenu priority, lower is higher
  priority: 20,
})

register({
  menu: true,
  render: () => <OrderListPage />,
  title: 'Orders',
  path: '/orders/',
})

// Register the summary page
register({
  render: () => <OrdersOverviewPage />,
  menu: true,
  title: 'Overview',
  path: '/orders/overview',
  priority: 1,
})

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Row } from 'common/widgets/grid'
import { GridFieldView, SectionView } from 'common/widgets/view'
import { Container } from 'common/widgets/container'
import { CollapsableIconButton } from 'common/widgets/button'
import { Card } from 'common/widgets/card'
import { Input } from 'common/widgets/input'
import { Tag } from 'common/widgets/tag'

import { Trip } from '../trip/trip'

// A list of logistic vehicles for internal use in this module
export const LogisticVehicles = ({
  transporters,
  activeTransporter,
  onActiveTransporterChange,
  onTourChange,
  onTripRemove,
}) => {
  const switchActiveTransporter = (transporter) => {
    onActiveTransporterChange && onActiveTransporterChange(transporter)
  }

  return (
    <Container flex vertical gap="10px">
      {transporters?.map((transporter) => (
        <LogisticVehicleCard
          key={transporter.id}
          transporter={transporter}
          active={activeTransporter?.id == transporter.id}
          onClick={() => switchActiveTransporter(transporter)}
          onTripRemove={onTripRemove}
          onTourChange={onTourChange}
        />
      ))}
    </Container>
  )
}

// Represents a single logistic vehicle in a card
const LogisticVehicleCard = ({
  transporter,
  onClick,
  onTripRemove,
  onTourChange,
  active = false,
}) => {
  const { t } = useTranslation(['logistics'])
  const [collapsed, setCollapsed] = useState(active)

  useEffect(() => {
    // always collapse when activated from parent
    setCollapsed(active)
  }, [active])

  return (
    <SectionView
      vertical
      onClick={onClick}
      style={{
        border: active ? '1px solid rgb(55, 109, 244)' : '',
      }}
    >
      <Container>
        <Card
          style={{ display: 'grid', 'grid-template-columns': '50px auto 50px' }}
        >
          <CollapsableIconButton
            style={{ gridRow: 1, gridColumn: '1' }}
            collapsed={collapsed}
            onCollapsed={(collapsed) => {
              // A short delay to let activation finishes
              setTimeout(() => {
                setCollapsed(collapsed)
              }, 10)
            }}
          />
          <Container>
            <h2 style={{ marginRight: '5px' }}>{transporter.name}</h2>
            {transporter.transports.length > 0 && (
              <Tag color="#e32d2d" text="Booked" />
            )}
          </Container>
          <>
            {transporter.transports.length > 0 &&
            transporter.transports[0].trips_count ? (
              <p style={{ margin: 'auto 0' }}>
                <strong style={{ fontSize: '1.5em' }}>
                  {transporter.transports.length > 0
                    ? `${transporter.transports[0].trips_count}`
                    : 0}
                </strong>
                <small>
                  {transporter.transports[0].trips_count > 1
                    ? t('trips')
                    : t('trip')}
                </small>
              </p>
            ) : (
              <p>
                <small>{t('No trips')}</small>
              </p>
            )}
          </>
          <Row style={{ gridRowStart: 2, gridColumn: '2 / 3' }}>
            <GridFieldView n={2} m={4} s={6} label="Driver">
              <Input
                style={{ height: '32px' }}
                defaultValue={
                  transporter.transports.length > 0
                    ? transporter.transports[0].driver_name
                    : null
                }
                onBlur={(e) => {
                  onTourChange && onTourChange({ driverName: e.target.value })
                }}
              />
            </GridFieldView>
            <GridFieldView
              n={2}
              m={4}
              s={6}
              label="Plate number"
              value={transporter.license_plate}
            />
            <GridFieldView
              n={2}
              m={4}
              s={6}
              label="Cost center"
              value={transporter.cost_center}
            />
            <GridFieldView
              n={2}
              m={4}
              s={6}
              label="Main category"
              value={transporter.maincategory}
            />
            <GridFieldView
              n={2}
              m={4}
              s={6}
              label="Category"
              value={transporter.category}
            />
          </Row>
        </Card>
        <Row style={{ gridRowStart: 3, gridColumn: '1 / 3', marginTop: '5px' }}>
          <Container grow>
            {collapsed &&
              (transporter.transports.length > 0 &&
              transporter.transports[0].trips_count > 0 ? (
                transporter.transports[0].trips.map((trip) => (
                  <Trip
                    readonly={!active}
                    trip={trip}
                    key={`${transporter.id}-${trip.id}`}
                    onRemove={onTripRemove ? (e) => onTripRemove(trip) : null}
                  />
                ))
              ) : (
                <GridFieldView
                  success
                  value={t('No trips are added to this truck yet.')}
                />
              ))}
          </Container>
        </Row>
      </Container>
    </SectionView>
  )
}

import { DataSource } from 'common/widgets/data-source'
import { DropDownField } from 'common/widgets/form/field'

export const ResponsibleOrdererField = ({
  handler,
  name = 'responsible_orderer_id',
  removable = true,
  ...rest
}) => {
  const render = ({ data, error, loading }) => {
    const items =
      data?.map((d) => {
        return {
          key: d.id,
          title:
            `${d.first_name ?? ''} ${d.last_name ?? ''}`.trim() ?? `[${d.id}]`,
        }
      }) || []
    return (
      <DropDownField
        name={name}
        items={items}
        minWidth="276px"
        removable={removable}
        {...rest}
      />
    )
  }

  // TODO: in case some specific users should be displayed, that must
  // be decided on the backend. Either by calling a different endpoint,
  // or considering the user's priviledges and projects the user is a
  // member of. Previous logic removed from this component.
  return <DataSource url="/security/users" handler={handler} render={render} />
}

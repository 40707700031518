import React from 'react'

import { URLField } from 'common/widgets/form/field'
import { Form } from 'common/widgets/form/context'
import { SectionView } from 'common/widgets/view'

import { LanguageSelectField } from './fields'

export const DeveloperSettingsForm = () => {
  return (
    <Form
      data={{
        api: localStorage.getItem('api') ?? `${window.location.origin}/api/`,
      }}
      showActions
      onSubmit={(values) => {
        console.log('values', values)
        if (values?.api) {
          const api = localStorage.getItem('api')
          console.debug(`Changing API from ${api} to ${values?.api}`)
          localStorage.setItem('api', values.api)
        }
        return [null, null]
      }}
    >
      <SectionView title="Local Settings">
        <URLField label="API Endpoint" name="api" />
        <LanguageSelectField label="Language" />
      </SectionView>
    </Form>
  )
}

export const DeveloperSettingsPage = () => {
  return <DeveloperSettingsForm />
}

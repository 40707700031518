import { register } from 'system/container'

import { QualificationsList } from './list'
import { ContactQualificationsAddPage } from './add'
import { ContactQualificationsViewPage } from './view'
import { ContactQualificationEditPage } from './edit'

register({
  render: () => <ContactQualificationsAddPage />,
  title: 'Add',
  path: '/master-data/contacts/qualifications/add',
})

register({
  menu: true,
  render: () => <QualificationsList />,
  title: 'Qualifications',
  path: '/master-data/contacts/qualifications',
})

register({
  render: () => <ContactQualificationsViewPage />,
  title: 'Details',
  path: '/master-data/contacts/qualification/:id',
})

register({
  render: () => <ContactQualificationEditPage />,
  title: 'Edit',
  path: '/master-data/contacts/qualification/:id/edit',
})

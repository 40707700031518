import { useState, useEffect } from 'react'
import { Plus, X } from 'react-feather'

import { Container } from 'common/widgets/container'
import { DataSource } from 'common/widgets/data-source'
import { SystemOverlay } from 'common/widgets/overlay'
import { Card } from 'common/widgets/card'
import { FieldWrapper } from 'common/widgets/form/field'

import styles from './users.module.css'

const RoleAddOverlay = ({ currentRoles, open, onClose, onAdd }) => {
  const currentRoleIds = currentRoles?.map((r) => r.id)

  return (
    <DataSource url="/security/roles">
      {({ data, setQueryParameter }) => (
        <SystemOverlay
          open={open}
          title="Roles"
          onSearch={(value) => setQueryParameter('search', value)}
          onClose={onClose}
        >
          <Container flex vertical gap="10px">
            {data
              ?.filter((r) => !currentRoleIds.includes(r.id))
              .map((e) => (
                <div className={styles.roles} key={e.id}>
                  <p>{e.description ?? e.key}</p>
                  <CustomPlus
                    onClick={() => {
                      onAdd(e)
                    }}
                  />
                </div>
              ))}
          </Container>
        </SystemOverlay>
      )}
    </DataSource>
  )
}

/**
 * Renders a widget to select roles.
 * @param {Array} value list of roles
 * @param {boolean} disabled flag for disabled widget
 * @param {Function} onChange callback function when value changes
 * @param {int} minRoles determines the minimum amount of roles to hide delete button.
 * @returns ReactElement
 */
export const UserRoleSelect = ({ value, disabled, onChange, minRoles = 1 }) => {
  const [roles, setRoles] = useState(value ? value : [])
  const [roleAddOverlayVisiblity, setRoleAddOverlayVisiblity] = useState(false)

  const updateRoles = (roles) => {
    setRoles(roles)
    if (onChange) {
      onChange(roles)
    }
  }

  const handleRemove = (role) =>
    updateRoles(roles.filter((r) => r.id !== role.id))

  useEffect(() => {
    const a = value ? value.map((e) => e.id) : []
    const b = roles.map((e) => e.id)
    if (JSON.stringify(a) !== JSON.stringify(b)) {
      setRoles(value ? value : [])
    }
  }, [value])

  return (
    <Container flex wrap align="center" gap="10px">
      {roles.map((e) => (
        <Card className={styles.attachedRole} key={e.id}>
          <p>{e.description ?? e.key}</p>
          {!disabled && roles.length > minRoles && (
            <X onClick={async () => handleRemove(e)} />
          )}
        </Card>
      ))}
      {!disabled && (
        <CustomPlus default onClick={() => setRoleAddOverlayVisiblity(true)} />
      )}
      {roleAddOverlayVisiblity && (
        <RoleAddOverlay
          currentRoles={roles}
          open={roleAddOverlayVisiblity}
          onAdd={async (role) => updateRoles([...roles, role])}
          onClose={() => setRoleAddOverlayVisiblity(false)}
        />
      )}
    </Container>
  )
}

export const UserRoleSelectField = ({ disabled, ...rest }) => (
  <FieldWrapper {...rest}>
    {(getValue, setValue) => (
      <UserRoleSelect
        value={getValue()}
        onChange={setValue}
        disabled={disabled}
      />
    )}
  </FieldWrapper>
)

/**
 * Plus button which ignores double clicks under 300ms
 */
const CustomPlus = ({ onClick, ...rest }) => {
  const [busy, setBusy] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setBusy(false)
    }, 300)
    return () => clearTimeout(timer)
  })

  const handleClick = (e) => {
    if (busy) return
    setBusy(true)
    onClick(e)
  }

  return <Plus className={styles.plus} onClick={handleClick} {...rest} />
}

.content {
  display: flex;
  gap: 10px;
  box-sizing: border-box;
  flex-direction: column;
  background: #efefef;
  /* Important Note: absolute elements are not properly
   * printable in different browsers due to ambiguous w3c
   * specification. See the following StackOverflow answer
   * for more information:
   * https://stackoverflow.com/a/11744948/157216
   */
  position: relative;
  padding-bottom: 60px;

  /* Set a default z-index for content. Otherwise positioned system overlays from
   * toolbar and positioned elements from contents would not respect each other's
   * z-index (because perhaps they belong to different stacking contexts). For more
   * information see: https://developer.mozilla.org/en-US/docs/Web/CSS/z-index
   **/
  z-index: 0;

  overflow: auto;
  padding: 5px;
  grid-row: 2 / 3;
}

@media (max-width: 768px) {
  .content {
    grid-column: 1 / 3;
  }
}

@media not (max-width: 768px) {
  .content {
    grid-column: 2 / 3;
  }
}

@media only print {
  .content {
    width: auto;
    height: auto;
    overflow: visible;
  }
}

.app {
  display: grid;
  grid-template-columns: 60px auto;
  grid-template-rows: 60px auto;
  height: 100vh;
}

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useService } from 'common/service/context'
import { FileUploadOverlay } from 'common/widgets/file/upload'
import { Avatar } from 'common/widgets/avatar'
import {
  DateOverlay,
  NumericOverlay,
  TextOverlay,
} from 'common/widgets/form/overlay'
import { AddressOverlay } from 'common/widgets/form/address'
import { getSingleLineAddress } from 'modules/projects/utils'
import { CheckBox } from 'common/widgets/checkbox'
import { Input } from 'common/widgets/input'
import { SingleImageField } from 'common/widgets/image'
import { useSettings } from 'system/settings/context'

import styles from './settings.module.css'

/**
 * Renders a setting image field.
 *
 * @param {object} entry the setting entry to render
 * @returns JSX.Element
 */
export const SettingImageField = ({ entry }) => {
  const { t } = useTranslation()
  return (
    <SingleImageField
      imageRef={entry.value}
      title={t(entry.key.replaceAll('.', ' '))}
      readOnly={entry.readonly || !entry.value}
    />
  )
}

/**
 * Renders a setting text field.
 *
 * @param {object} entry the setting entry to render
 * @returns JSX.Element
 */
export const SettingTextField = ({ entry }) => {
  const { t } = useTranslation()
  const [overlayVisible, setOverlayVisible] = useState(false)
  const service = useService()

  const handleSave = async (data) => {
    const [result, error] = await service.put(`settings/${entry.key}`, {
      value: data.value,
    })
    return [result, error]
  }

  return (
    <>
      <TextOverlay
        title={t(entry.key.replaceAll('.', ' '))}
        open={!entry.readonly && overlayVisible}
        onSave={handleSave}
        onClose={() => setOverlayVisible(false)}
        initialValue={entry.value}
      />
      <Input
        width="100%"
        value={entry.value || ''}
        onClick={() => setOverlayVisible(true)}
        cursor="pointer"
        readOnly
      />
    </>
  )
}

/**
 * Renders a setting address field.
 *
 * @param {object} entry the setting entry to render
 * @returns JSX.Element
 */
export const SettingAddressField = ({ entry }) => {
  const { t } = useTranslation()
  const [overlayVisible, setOverlayVisible] = useState(false)
  const [address, setAddress] = useState(null)
  const service = useService()

  useEffect(() => {
    setAddress(entry.value)
  }, [entry.value])

  const handleSave = async (data) => {
    const [result, error] = await service.put(`settings/${entry.key}`, {
      value: data?.address,
    })

    return [result, error]
  }

  return (
    <>
      <AddressOverlay
        open={!entry.readonly && overlayVisible}
        onSave={handleSave}
        onClose={() => setOverlayVisible(false)}
        initialValue={address}
        title={t(entry.key.replaceAll('.', ' '))}
      />
      <Input
        width="100%"
        value={getSingleLineAddress(address)}
        onClick={() => setOverlayVisible(true)}
        cursor="pointer"
        readOnly
      />
    </>
  )
}

/**
 * Renders a setting numeric field.
 *
 * @param {object} entry the setting entry to render
 * @returns JSX.Element
 */
export const SettingNumericField = ({ entry }) => {
  const { t } = useTranslation()
  const [overlayVisible, setOverlayVisible] = useState(false)
  const service = useService()

  const handleSave = async (data) => {
    const [result, error] = await service.put(`settings/${entry.key}`, {
      value: data.value,
    })
    return [result, error]
  }

  return (
    <>
      <NumericOverlay
        title={t(entry.key.replaceAll('.', ' '))}
        open={!entry.readonly && overlayVisible}
        onSave={handleSave}
        onClose={() => setOverlayVisible(false)}
        initialValue={entry.value}
      />
      <Input
        width="100%"
        value={entry.value || ''}
        onClick={() => setOverlayVisible(true)}
        cursor="pointer"
        readOnly
      />
    </>
  )
}

/**
 * Renders a setting integer field.
 *
 * @param {object} entry the setting entry to render
 * @returns JSX.Element
 */
export const SettingIntegerField = ({ entry }) => {
  const { t } = useTranslation()
  const [overlayVisible, setOverlayVisible] = useState(false)
  const service = useService()

  const handleSave = async (data) => {
    const [result, error] = await service.put(`settings/${entry.key}`, {
      value: data.value,
    })
    return [result, error]
  }

  return (
    <>
      <NumericOverlay
        title={t(entry.key.replaceAll('.', ' '))}
        open={!entry.readonly && overlayVisible}
        onSave={handleSave}
        onClose={() => setOverlayVisible(false)}
        initialValue={entry.value}
      />
      <Input
        width="100%"
        value={entry.value || ''}
        onClick={() => setOverlayVisible(true)}
        cursor="pointer"
        readOnly
      />
    </>
  )
}

export const SettingFileField = ({ label, entry }) => {
  const [overlayVisible, setOverlayVisible] = useState(false)
  const service = useService()
  const ctx = useSettings()

  const handleSave = async (file) => {
    const formData = new FormData()
    formData.append('file', file)
    const [result, error] = await service.put(
      `settings/${entry.key}`,
      formData,
      { 'Content-Type': 'multipart/form-data' }
    )
    ctx.set(entry.key, result?.data['value'])
    return [result, error]
  }

  return (
    <label onClick={(e) => e.preventDefault()}>
      {label}
      {!entry.readonly && overlayVisible && (
        <FileUploadOverlay
          open={!entry.readonly && overlayVisible}
          onUpload={handleSave}
          maxSize={50 * 1024 * 1024}
          onClose={() => setOverlayVisible(false)}
          title={entry.key.replaceAll('.', ' ')}
        />
      )}{' '}
      <Avatar
        text={entry.key}
        size="140px"
        id={entry.value}
        onClick={() => setOverlayVisible(true)}
        borderRadius="10px"
        cursor="pointer"
      />
    </label>
  )
}

/**
 * Renders a setting boolean field.
 *
 * @param {object} entry the setting entry to render
 * @returns JSX.Element
 */
export const SettingBooleanField = ({ entry }) => {
  // Keeps the current checked state
  const [value, setValue] = useState(!!entry.value)
  const service = useService()

  const handleSave = async (checked) => {
    const [result, error] = await service.put(`settings/${entry.key}`, {
      value: checked,
    })
    if (!error) {
      setValue(checked)
    }
    return [result, error]
  }

  return (
    <CheckBox
      value={value}
      disabled={entry.readonly}
      onChange={(checked) => handleSave(checked)}
    />
  )
}

/**
 * Renders a setting date field.
 *
 * @param {object} entry the setting entry to render
 * @returns JSX.Element
 */
export const SettingDateField = ({ entry }) => {
  const { t } = useTranslation()
  const [overlayVisible, setOverlayVisible] = useState(false)
  const service = useService()

  const handleSave = async (data) => {
    const [result, error] = await service.put(`settings/${entry.key}`, {
      value: data.value,
    })
    return [result, error]
  }

  return (
    <>
      <DateOverlay
        title={t(entry.key.replaceAll('.', ' '))}
        open={!entry.readonly && overlayVisible}
        onSave={handleSave}
        onClose={() => setOverlayVisible(false)}
        initialValue={entry.value}
      />
      <Input
        width="100%"
        value={entry.value || ''}
        onClick={() => setOverlayVisible(true)}
        cursor="pointer"
        readOnly
      />
    </>
  )
}

export const LanguageSelectField = ({ label, ...rest }) => {
  const { t, i18n } = useTranslation()

  return (
    <label {...rest}>
      {label && (
        <>
          {t(label)} <br />
        </>
      )}
      <div className="flex">
        <div
          className={
            i18n.language === 'de' ? styles.languageSelected : styles.language
          }
          selected={i18n.language === 'de'}
          onClick={() => i18n.changeLanguage('de')}
        >
          <p>{'Deutsch'}</p>
        </div>
        <div
          className={
            i18n.language === 'en-GB'
              ? styles.languageSelected
              : styles.language
          }
          onClick={() => i18n.changeLanguage('en-GB')}
        >
          <p>{'English'}</p>
        </div>
      </div>
    </label>
  )
}

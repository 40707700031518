import { register } from 'system/container'

import { RoleEditPage } from './edit'
import { RolesListPage } from './list'
import { RoleViewPage } from './view'
import { RoleForm } from './form'

register({
  render: () => <RoleForm />,
  title: 'New',
  path: '/security/roles/add',
})

register({
  render: () => <RoleEditPage />,
  title: 'Edit',
  path: '/security/roles/:id/edit',
})

register({
  menu: true,
  render: () => <RolesListPage />,
  title: 'Roles',
  path: '/security/roles',
})

register({
  // It includes scope editing too. Perhaps should be merged
  // with the edit page.
  render: () => <RoleViewPage />,
  title: 'Details',
  path: '/security/roles/:id',
})

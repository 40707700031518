import { register } from 'system/container'

import { UserAddPage } from './add'
import { UserEditPage } from './edit'
import { UsersListPage } from './list'
import { UserViewPage } from './view'
import { UserEmailChangeConfirmationPage } from './email-change-confirm'

register({
  render: () => <UserAddPage />,
  title: 'New',
  path: '/security/users/add',
})

register({
  render: () => <UserEditPage />,
  title: 'Edit',
  path: '/security/users/:id/edit',
})

register({
  menu: true,
  render: () => <UsersListPage />,
  title: 'Users',
  path: '/security/users',
})

register({
  render: () => <UserViewPage />,
  title: 'Details',
  path: '/security/users/:id',
})

register({
  render: () => <UserEmailChangeConfirmationPage />,
  title: 'Email Change Confirmation',
  path: '/security/users/confirm-email/:token',
})

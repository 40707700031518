import React from 'react'

import { FieldWrapper } from 'common/widgets/form/field'
import { Select } from 'common/widgets/select'

import { ItemSourceOfSupplyEnum } from '../../utils'

export const SourceOfSupplyField = ({ mandatory = true, toggle }) => {
  const items = Object.values(ItemSourceOfSupplyEnum).map((v) => ({
    key: v,
    title: v,
  }))

  return (
    <FieldWrapper name="transform" mandatory={mandatory}>
      {(getValue, setValue) => (
        <Select
          selected={getValue()}
          items={items}
          onSelectChange={(item) => setValue(item.key)}
          toggle
        />
      )}
    </FieldWrapper>
  )
}

import { useTranslation } from 'react-i18next'

import { dateToISOString } from 'common/utils/format'
import { FullCalendarWrapper } from 'common/widgets/calendar'
import { toDate } from 'common/utils/date'

import 'modules/disposition/calendar.css'

export const TimelineCalendar = ({
  events,
  resources,
  initialDate,
  renderResource,
  renderEvent,
  onEventClick,
  onCalendarDateChange,
  onSelect,
  onEventChange,
  resourceAreaHeaderContent,
  calendarType,
}) => {
  const { t } = useTranslation()
  /**
   * Finds related resource for the given event.
   * @param {any} event event data
   * @returns any
   */
  const getResource = (event) => {
    const resourceId = event.getResources()[0].id
    const resource = resources.find((r) => r.id === resourceId)
    return resource
  }

  return (
    <FullCalendarWrapper
      resourceAreaHeaderContent={resourceAreaHeaderContent}
      datesSet={({ start, end }) =>
        onCalendarDateChange &&
        onCalendarDateChange(dateToISOString(start), dateToISOString(end))
      }
      selectable
      editable
      initialDate={initialDate}
      initialView="customTimeline"
      titleFormat={{ week: 'short' }}
      views={{
        customTimeline: {
          type: 'resourceTimeline',
          buttonText: t('Main view'),
          duration: { weeks: 4 },
          slotDuration: { day: 1 },
          slotLabelFormat: [
            { month: 'long', year: 'numeric' },
            { week: 'short' },
            { day: 'numeric' },
          ],
          slotMinWidth: 30,
        },
      }}
      nextDayThreshold="00:00:00"
      defaultAllDay={false}
      scrollTime={{ weeks: 0 }}
      resourceLabelContent={renderResource}
      resources={resources}
      events={events}
      eventContent={renderEvent}
      calendarType={calendarType}
      eventClick={({ event }) => {
        const resource = getResource(event)
        onEventClick({
          event,
          resource,
          start: toDate(event.booking_start),
          end: toDate(event.booking_end),
        })
      }}
      select={(e) => {
        const resource = resources.find((r) => r.id === e.resource.id)
        onSelect(resource, toDate(e.booking_start), toDate(e.booking_end))
      }}
      eventChange={(e) => {
        const { event } = e
        const resource = getResource(event)
        onEventChange && onEventChange(event, resource)
      }}
      headerToolbar={{
        left: 'customTimeline',
      }}
    />
  )
}

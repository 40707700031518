import { register } from 'system/container'
import { DetailViewPage } from 'system/utils/view'
import { EditViewPage } from 'system/utils/edit'

import { CategoryList } from './list'
import { CategoryForm } from './form'

register({
  menu: true,
  render: () => <CategoryList />,
  title: 'Categories',
  path: '/master-data/pim/categories',
})

register({
  render: () => <CategoryForm />,
  title: 'New',
  path: '/master-data/pim/categories/add',
})

register({
  render: () => (
    <EditViewPage url="/pim/categories" title={(e) => e.name}>
      {(data) => <CategoryForm data={data} edit />}
    </EditViewPage>
  ),
  title: 'Edit',
  path: '/master-data/pim/categories/:id/edit',
})

register({
  render: () => (
    <DetailViewPage
      url="/pim/categories"
      navUrl="/master-data/pim/categories"
      editable
    >
      {(data) => <CategoryForm data={data} readOnly />}
    </DetailViewPage>
  ),
  title: 'View',
  path: '/master-data/pim/categories/:id',
})

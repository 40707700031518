import * as dfns from 'date-fns'
import { de, enUS } from 'date-fns/locale'

import i18n from 'common/i18n/core'

import { toDate } from './date'

const locales = { de: de, en: enUS }

/**
 * Formats date as string and considers current locale.
 *
 * @param {Date} date date instance
 * @param {string} formatStr format
 * @returns string
 */
const formatEx = (date, options) => {
  date ??= '-'
  if (date === '-') {
    return date
  }

  const formatter = new Intl.DateTimeFormat(i18n.language, options)
  if (dfns.isValid(date)) {
    return formatter.format(date)
  }
  console.error('Got invalid date to format', date)
  throw Error('Got invalid date to format')
}

/**
 * Gives back a string representing the distance between dates in human words.
 *
 * @param {Date} leftDate
 * @param {Date} rightDate
 * @returns string
 */
const formatDistanceEx = (leftDate, rightDate) => {
  // Consider using Intl.RelativeTimeFormat
  return dfns.formatDistance(leftDate, rightDate, {
    locale: locales[i18n.language],
  })
}

/**
 * Formats the given date to string.
 *
 * @param {Date} date date instance
 * @returns string
 */
export const formatDate = (date, options) => {
  // Use a specific format for the German language
  if (i18n.language == 'de') {
    // Note that these options can't be used with dateStyle and timeStyle
    // For more info see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
    options = {
      year: 'numeric',
      day: '2-digit',
      month: '2-digit',
      friendly: false,
      ...options,
    }
  }

  // If the date ends at midnight, exclude it (substract one day)
  if (options?.friendly && date) {
    date = toDate(date)
    if (
      date.getHours() == 0 &&
      date.getMinutes() == 0 &&
      date.getSeconds() == 0
    ) {
      date = dfns.add(date, { days: -1 })
    }
  }

  if (date) {
    return formatEx(dfns.isDate(date) ? date : toDate(date), options)
  }
  return ''
}

/**
 * Formats the given date time to string.
 *
 * @param {Date} date date instance
 * @returns string
 */
export const formatDateTime = (date) => {
  if (date) {
    return formatEx(dfns.isDate(date) ? date : toDate(date), {
      dateStyle: 'short',
      // "long" timeStyle will append timezone which we want to see
      // to avoid confustion when working from different timezones
      timeStyle: 'long',
    })
  }
  return ''
}

export const formatDateRange = (a, b, options) => {
  // When formatting end dates on allDay events, pass "friendly" prop
  //  to render human-friendy end dates (currently most our ranges are allDay)
  // formatDateTime always displays accurate time as received
  const { friendly = true, time = false } = options ?? {}

  // Choose formatting function based on the time flag
  const fmtFunction = time ? formatDateTime : formatDate

  if (a && b) {
    return `${fmtFunction(a)} - ${fmtFunction(b, {
      friendly,
    })}`
  } else if (a && !b) {
    return `${fmtFunction(a)} - `
  } else if (!a && b) {
    return `- ${fmtFunction(b, { friendly })}`
  } else {
    return ` - `
  }
}

/**
 * Removes whitespace from both ends of a string. Whitespace in this context is all the whitespace characters
 * (space, tab, no-break space, etc.) and all the line terminator characters (LF, CR, etc.)
 * Returns the trimmed string or if there is no value, returns null
 *
 * @returns string
 */
// export const dateToISOString = (date) => {
//   if(date) {
//     if(!isDate(date)) {
//       date = toDate(date)
//     }
//     return date?.toISOString()
//   }
//   return null
// }

// this method should be replaced with the above one when client fixed to send just date (not datetime) for fields which are only date.
export const dateToISOString = (date, urlSafe) => {
  if (date) {
    if (!dfns.isDate(date)) {
      date = toDate(date)
    }
    if (dfns.isDate(date)) {
      let offset = -date.getTimezoneOffset()
      let sign = offset >= 0 ? '+' : '-'

      const result = `${date.getFullYear().toString().padStart(4, '0')}-${(
        date.getMonth() + 1
      )
        .toString()
        .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}T${date
        .getHours()
        .toString()
        .padStart(2, '0')}:${date
        .getMinutes()
        .toString()
        .padStart(2, '0')}:${date
        .getSeconds()
        .toString()
        .padStart(2, '0')}${sign}${Math.floor(Math.abs(offset) / 60)
        .toString()
        .padStart(2, '0')}:${(Math.abs(offset) % 60)
        .toString()
        .padStart(2, '0')}`
      if (urlSafe) {
        return encodeURIComponent(result)
      } else {
        return result
      }
    }
  }

  return null
}

/**
 * Returns the name of the day in the week from the given date.
 * @returns string
 */
export const nameOfDay = (date) => {
  return date
    ? new Intl.DateTimeFormat(i18n.language, { weekday: 'long' }).format(
        toDate(date)
      )
    : null
}

/**
 * Returns the calendar week based on a date.
 * @returns int
 */
export const calendarWeek = (date) => {
  var startDate = new Date(date.getFullYear(), 0, 1)
  var days = Math.floor((date - startDate) / (24 * 60 * 60 * 1000))

  var weekNumber = Math.ceil(days / 7)
  return weekNumber
}

/**
 * Humanizes date distance from now.
 *
 * @param {Date} date offset date value
 * @returns string
 */
export const formatDistanceFromNow = (date) => {
  return date
    ? formatDistanceEx(
        new Date(),
        dfns.isValid(date) ? date : dfns.parseISO(date)
      )
    : '...'
}

/**
 * Calculate duration and format it according to the locale.
 **/
export const formatDuration = (earlierDate, laterDate) => {
  const duration = dfns.intervalToDuration({
    start: toDate(earlierDate),
    end: toDate(laterDate),
  })
  return dfns.formatDuration(duration, { locale: locales[i18n.language] })
}

export const toUnixTimeStamp = (date) => {
  // Unix timestamps are seconds, wheres JavaScript uses milliseconds
  return +date / 1000
}

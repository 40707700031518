import { Grid } from 'react-feather'

import { register } from 'system/container'
import { Column, Row } from 'common/widgets/grid'

import { ProjectStatusAnalytics } from './project-status'
import { BaseItemTypesAnalytics } from './baseitem-types'

export const LandingPage = () => (
  <Row>
    <Column n={6} s={12}>
      <ProjectStatusAnalytics />
    </Column>
    <Column n={6} s={12}>
      <BaseItemTypesAnalytics />
    </Column>
  </Row>
)

register({
  title: 'Dashboard',
  icon: <Grid />,
  // Subsystem base route, no trailing slash
  path: '/home',
  // mainmenu priority, lower is higher
  priority: 10,
})

register({
  menu: true,
  render: () => <LandingPage />,
  title: 'Overview',
  path: '/home/',
})

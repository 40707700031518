import { useNavigate } from 'react-router-dom'

import { formatDate } from 'common/utils/format'
import { SimpleColumn, SimpleDataTable } from 'common/data-table/simple'

export const CostCenterList = () => {
  return <CostCenterTable />
}

export const CostCenterTable = ({
  onAdd,
  disableAdd,
  extraButtons,
  handler,
}) => {
  const navigate = useNavigate()

  return (
    <SimpleDataTable
      name="cost-centers"
      title="Cost Centers"
      url="/accounting/cost-centers"
      params={{ select: 'creator_user:user!creator(first_name,last_name)' }}
      handler={handler}
      archive
      onAddClick={
        !disableAdd
          ? () => (onAdd ? onAdd() : navigate(`/master-data/cost-centers/add`))
          : null
      }
      navBaseUrl="/master-data/cost-centers"
    >
      <SimpleColumn s={3} n={3} field="code" label="Code" header />
      <SimpleColumn s={3} n={3} field="description" label="Description" />
      <SimpleColumn
        s={3}
        n={3}
        sortable
        field="created_at"
        label="Created at"
        value={(r) => formatDate(r?.created_at)}
      />
      <SimpleColumn
        s={3}
        n={3}
        parentField="creator_user"
        field="fullname"
        label="Created by"
      />
      {extraButtons && (
        <SimpleColumn s={1} n={1} value={(r) => extraButtons(r)} />
      )}
    </SimpleDataTable>
  )
}

import React from 'react'

import { Form } from 'common/widgets/form/context'
import { EditViewPage } from 'system/utils/edit'

import { ProjectFormContent } from './form'

export const ProjectEditPage = () => {
  return (
    <EditViewPage
      url="/projects"
      params={[
        { select: 'avatar:file!avatar(id,path),address(id,coordinates)' },
      ]}
      title={(e) => e.name}
    >
      {(data) => {
        return (
          <Form data={data} baseUrl="/projects" auto showActions>
            <ProjectFormContent />
          </Form>
        )
      }}
    </EditViewPage>
  )
}

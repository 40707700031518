import { useParams } from 'react-router-dom'

import { useService } from 'common/service/context'
import { DataSource } from 'common/widgets/data-source'
import { Form } from 'common/widgets/form/context'

import { UserFormContent } from './form'

export const UserEditPage = () => {
  const { id } = useParams()
  const service = useService()

  const update = async (values) => {
    values.roles = values?.roles?.map((r) => r.id)
    return await service.put(`security/users/${id}`, values)
  }

  return (
    <DataSource
      url={`security/users/${id}?select=roles:role(id,key,description)`}
      params={[{ select: 'roles:role(id,key)' }]}
    >
      {({ data }) => (
        <Form data={data}>
          <UserFormContent onUpdate={update} />
        </Form>
      )}
    </DataSource>
  )
}

import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { NoSessionTemplate } from 'modules/security/login/template'
import { SubmitButton } from 'common/widgets/button'
import { GridFieldView } from 'common/widgets/view'
import { Container } from 'common/widgets/container'
import { Form, useForm } from 'common/widgets/form/context'
import { NewPasswordField } from 'common/widgets/form/field'
import { useSession } from 'common/session/context'
import { useToast } from 'system/toast/context'

import { AuthFormHeader } from './widgets'

export const RegisterForm = ({
  fullname,
  email,
  passwordReset = false,
  onSubmit,
}) => {
  const { t } = useTranslation(['security'])
  const { isValid } = useForm()
  return (
    <Container
      style={{ display: 'grid', grow: '1' }}
      vertical
      maxWidth="420px"
      gap="10px"
    >
      <AuthFormHeader />
      {passwordReset ? (
        <>
          <p>{t('security.register.howto.passreset')}</p>
        </>
      ) : (
        <>
          <p>{t('security.register.howto')}</p>
          <GridFieldView n={6} label="Fullname" value={fullname} />
        </>
      )}
      <GridFieldView n={6} label="E-Mail" value={email} />
      <NewPasswordField name="password" label="Password" />
      <SubmitButton
        disabled={isValid}
        text={passwordReset ? t('Reset Password') : t('Register')}
      />
    </Container>
  )
}

export const RegisterPage = () => {
  const session = useSession()
  const { toasts } = useToast()
  const query = new URLSearchParams(useLocation().search)
  const token = query.get('token')
  const email = query.get('email')

  if (!token) {
    toasts.error('Registration token missing.')
  }

  const handleSubmit = async (data) => {
    const [, error] = await session.completeRegistration({
      password: data.password1,
      token,
    })

    if (!error && email) {
      toasts.success('Registeration compete. Welcome to Orinoco!')
      session.login({ email, password: data.password1 })
    }
  }

  return (
    <NoSessionTemplate>
      {token ? (
        <Form onSubmit={handleSubmit} autoComplete="off">
          <RegisterForm
            fullname={query.get('fullname')}
            email={query.get('email')}
          />
        </Form>
      ) : (
        'Registration token missing.'
      )}
    </NoSessionTemplate>
  )
}

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Container } from 'common/widgets/container'
import { useForm } from 'common/widgets/form/context'
import { TextField, TextAreaField, EmailField } from 'common/widgets/form/field'
import { SimpleFormAction } from 'common/widgets/form/utils'
import { CardView } from 'common/widgets/view'

import { ContactCompanyField } from './widgets/company'
import { ContactTypeField } from './widgets/type'
import { ContactTypeEnum } from './utils'
import { SystemUserSelectField } from './widgets/select'

/**
 * Renders contact's data entry form.
 *
 * @returns
 */
export const ContactFormContent = ({
  onAdd,
  onUpdate,
  onRemove,
  contactID,
}) => {
  const { t } = useTranslation()
  const form = useForm()
  const [lockName, setLockName] = useState(
    !!form?.values?.get('system_user_id')
  )
  const type = form.values.get('type')

  return (
    <>
      <Container flex wrap gap="10px">
        <CardView grow>
          <ContactTypeField
            name="type"
            defaultValue={ContactTypeEnum.PERSON}
            readonly={!!contactID}
          />
          <TextField
            label="Fullname"
            name="fullname"
            mandatory
            disabled={lockName}
          />
          {onUpdate && (
            <>
              <h5>{t('Display name')}</h5>
              <TextField name="name" />
            </>
          )}
          {type === ContactTypeEnum.PERSON && (
            <>
              <h5>{t('Company')}</h5>
              <ContactCompanyField name="parent_id" />
              <h5>{t('Job title')}</h5>
              <TextField name="job_title" />
              <h5>{t('User')}</h5>
              <SystemUserSelectField
                contactID={contactID}
                onSelectChange={(item) => {
                  if (item) {
                    form.values.set('fullname', item.title)
                    setLockName(true)
                  } else {
                    setLockName(false)
                  }
                }}
              />
            </>
          )}
          <h5>{t('Comment')}</h5>
          <TextAreaField name="comment" rows="3" />
        </CardView>
        <CardView grow>
          <h5>{t('Street')}</h5>
          <TextField name="address.street" />
          <h5>{t('Street number')}</h5>
          <TextField name="address.street_number" />
          <h5>{t('City')}</h5>
          <TextField name="address.city" />
          <h5>{t('Zipcode')}</h5>
          <TextField name="address.zip_code" />
          <h5>{t('Phone')}</h5>
          <TextField name="address.phone" />
          <h5>{t('Webpage')}</h5>
          <TextField name="address.webpage" />
          <h5>{t('EMail')}</h5>
          <EmailField name="address.email" />
          <h5>{t('Country')}</h5>
          <TextField name="address.country" />
        </CardView>
      </Container>
      <SimpleFormAction onAdd={onAdd} onUpdate={onUpdate} onRemove={onRemove} />
    </>
  )
}

import { useNavigate, useParams } from 'react-router-dom'
import { Edit } from 'react-feather'
import { useTranslation } from 'react-i18next'

import { useService } from 'common/service/context'
import { Container } from 'common/widgets/container'
import { DataSource } from 'common/widgets/data-source'
import { ArchiveIconButton, BackIconButton } from 'common/widgets/button'
import { useToast } from 'system/toast/context'

/**
 * Renders general edit page.
 *
 * @param {string} url base url for get call
 * @param {string} title page title
 * @param {Function} onArchive handles archive click buttom
 * @param {Function} onEdit handles edit click button
 * @param {boolean} overflow makes page scrollable
 * @param {Function} addExtraButtons add extra buttons to edit view
 * @returns
 */
export const EditViewPage = ({
  url,
  title,
  onArchive,
  onEdit,
  archivable,
  addExtraButtons,
  children,
  params,
  ...rest
}) => {
  const service = useService()
  const { id } = useParams()
  const { toasts } = useToast()
  const { t } = useTranslation()

  const archive = async (data) => {
    const [, error] = await service.post(`${url}/${id}`, {
      archived_at: data.archived_at ? null : new Date(),
    })
    if (!error) {
      data.archived_at
        ? toasts.success(`${t(data.name)} ${t('sucessfully activated.')}`)
        : toasts.success(`${t(data.name)} ${t('sucessfully archived.')}`)
    }
  }

  return (
    <DataSource
      url={`${url}/${id}`}
      params={params}
      render={({ data, error, loading, reload }) => (
        <>
          {data && (
            <EditViewHeader
              addExtraButtons={addExtraButtons}
              data={data}
              title={title(data)}
              onArchive={archivable ? archive : null}
              onEdit={onEdit}
            />
          )}
          {data && <>{children(data, reload)}</>}
        </>
      )}
      {...rest}
    />
  )
}

const EditViewHeader = ({
  data,
  title,
  onArchive,
  onEdit,
  addExtraButtons,
}) => {
  const navigate = useNavigate()

  return (
    <Container flex align="center" gap="10px" noprint>
      <BackIconButton onClick={() => navigate(-1)} />
      <h1 style={{ width: '100%' }}>{title}</h1>
      {onEdit && <Edit onClick={() => onEdit(data)} />}
      {onArchive && (
        <ArchiveIconButton
          onClick={() => onArchive(data)}
          archived={data.archived_at}
        />
      )}
      {addExtraButtons && addExtraButtons(data)}
    </Container>
  )
}

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useService } from 'common/service/context'
import { SimpleColumn, SimpleDataTable } from 'common/data-table/simple'
import { formatDate } from 'common/utils/format'
import { formatOrderNumber } from 'modules/orders/utils'
import { formatDeliveryNumber } from 'modules/yard/utils'
import {
  FoldButtons,
  DeleteIconButton,
  EditIconButton,
} from 'common/widgets/button'
import { ConfirmOverlay } from 'common/widgets/overlay'
import { GridFieldView } from 'common/widgets/view'
import { useToast } from 'system/toast/context'
import { Row } from 'common/widgets/grid'
import { ProjectLink } from 'modules/projects/widgets/link'

import { TripStateColor } from './trip'
import { TripState } from './utils'
import { TripCRUDOverlay } from './add'

export const TripsList = ({ reload, actions }) => {
  const { t } = useTranslation(['logistics'])
  const service = useService()
  const { toasts } = useToast()
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [selectedTrip, setSelectedTrip] = useState(null)
  const [crudVisible, setCrudVisible] = useState(false)

  // Get all planned tours
  const fetch = async (params) =>
    await service.get('logistics/trips', [...params])

  return (
    <>
      <SimpleDataTable
        name="trips"
        fetch={fetch}
        title="Trips"
        onAddClick={() => {
          setSelectedTrip(null)
          setCrudVisible((prevState) => !prevState)
        }}
        rowColor={(r) => TripStateColor[r.state]}
      >
        <SimpleColumn
          n={2}
          s={6}
          sortable
          field="date"
          label="Order number"
          value={(r) => formatOrderNumber(r.order?.number)}
          link={(r) => `/orders/${r.order?.id}`}
        />
        <SimpleColumn
          n={2}
          s={6}
          sortable
          field="delivery_number"
          label="Delivery number"
          value={(r) =>
            formatDeliveryNumber(r.order?.number, r.delivery_number)
          }
          link={(r) => `/yard/commission/deliveries/${r.delivery_id}`}
        />
        <SimpleColumn n={2} s={6} sortable field="location" label="Destination">
          {(r) =>
            r.order?.project_id ? (
              <ProjectLink
                project={{
                  name: r.location,
                  short_name: r.location,
                  id: r.order?.project_id,
                }}
              />
            ) : (
              r.location
            )
          }
        </SimpleColumn>
        <SimpleColumn
          n={2}
          s={6}
          sortable
          field="state"
          label="State"
          value={(r) => t(r.state)}
        />
        <SimpleColumn
          n={2}
          s={6}
          sortable
          field="creator_user"
          label="Creator user"
          value={(r) => r.creator_user?.fullname}
        />
        <SimpleColumn
          n={2}
          s={6}
          sortable
          field="created_at"
          label="Ordered at"
          value={(r) => formatDate(r.created_at)}
        />
        <SimpleColumn
          n={2}
          s={6}
          sortable
          field="info"
          label="Description"
          value={(r) => r.info}
        />
        <SimpleColumn fixed>
          {(r, reload) => (
            <FoldButtons>
              {r.state == TripState.PENDING && (
                <DeleteIconButton
                  white
                  onClick={() => {
                    setSelectedTrip(r)
                    setDeleteOpen(true)
                  }}
                />
              )}
              {r.state == TripState.PENDING && (
                <EditIconButton
                  white
                  onClick={() => {
                    setSelectedTrip(r)
                    setCrudVisible(true)
                  }}
                />
              )}
            </FoldButtons>
          )}
        </SimpleColumn>
        <SimpleColumn>
          {(r, reload) => (
            <ConfirmOverlay
              open={deleteOpen}
              title={t('Cancel trip?')}
              onConfirm={() => {
                setDeleteOpen(false)
                service.delete(`logistics/trips/${selectedTrip?.id}`) &&
                  reload() &&
                  toasts.success(
                    `Trip [${selectedTrip?.id}] to ${selectedTrip?.location} deleted.`
                  )
              }}
              onReject={() => {
                setDeleteOpen(false)
              }}
            >
              <Row>
                <GridFieldView
                  n={6}
                  s={6}
                  label="Destination"
                  value={selectedTrip?.location}
                />
                <GridFieldView
                  n={6}
                  s={6}
                  label="Status"
                  value={selectedTrip?.state}
                />
                <GridFieldView
                  n={6}
                  s={6}
                  label="Cost center"
                  value={selectedTrip?.cost_center}
                />
                <GridFieldView
                  n={6}
                  s={6}
                  label="Description"
                  value={selectedTrip?.description}
                />
                <GridFieldView
                  n={6}
                  s={6}
                  label="Info"
                  value={selectedTrip?.info}
                />
              </Row>
            </ConfirmOverlay>
          )}
        </SimpleColumn>
        {crudVisible && (
          <TripCRUDOverlay
            open={crudVisible}
            data={selectedTrip}
            onAdd={(data, ctx) => {
              setCrudVisible(false)
              ctx?.reload()
            }}
            onClose={() => setCrudVisible(false)}
            onUpdate={() => setCrudVisible(false)}
          />
        )}
      </SimpleDataTable>
    </>
  )
}

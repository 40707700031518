import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import ReactFlow, {
  addEdge,
  ReactFlowProvider,
  applyNodeChanges,
  applyEdgeChanges,
  useReactFlow,
} from 'reactflow'
import 'reactflow/dist/style.css'

import { useToast } from 'system/toast/context'
import { DataSource } from 'common/widgets/data-source'
import { Container } from 'common/widgets/container'
import {
  AddIconButton,
  ZoomInIconButton,
  ZoomOutIconButton,
  MaximizeIconButton,
} from 'common/widgets/button'
import { useService } from 'common/service/context'
import { getLayoutedElements } from 'common/utils/graph'

import styles from '../projects.module.css'

import ProjectPhaseNode from './node'
import FloatingEdge from './edge'
import { PhaseFormOverlay } from './overlay'
import { ProjectPhaseToolbox } from './toolbox'

export const ProjectPhaseDesignPage = () => {
  const { id } = useParams()
  const [selectedPhase, setSelectedPhase] = useState(null)
  const [phaseOverlayVisible, setPhaseOverlayVisible] = useState(false)
  const [nodeData, setNodeData] = useState(null)
  return (
    <DataSource url={`/projects/types/${id}`}>
      {({ data, reload, loading }) =>
        data && (
          <Container flex grow>
            {phaseOverlayVisible && (
              <PhaseFormOverlay
                open={phaseOverlayVisible}
                onClose={() => {
                  setPhaseOverlayVisible(false)
                  setNodeData(null)
                  reload && reload()
                }}
                type={data}
                phase={nodeData}
              />
            )}
            <ReactFlowProvider>
              <ProjectPhaseDesignCanvas
                data={data.phases}
                reload={reload}
                onPhaseSelect={(phase) => {
                  setNodeData(phase)
                  setSelectedPhase(phase)
                }}
                onEdit={(data) => {
                  setNodeData(data)
                  setPhaseOverlayVisible(true)
                }}
                onAdd={() => {
                  setNodeData(null)
                  setPhaseOverlayVisible(true)
                }}
                onRemove={() => {
                  setNodeData(null)
                  setSelectedPhase(null)
                  reload()
                }}
              />
            </ReactFlowProvider>
            <ProjectPhaseToolbox
              type={data}
              phase={selectedPhase}
              reload={reload}
              onEdit={() => {
                setNodeData(selectedPhase)
                setPhaseOverlayVisible(true)
              }}
            />
          </Container>
        )
      }
    </DataSource>
  )
}

const makeInitialElements = (phases, nodeTypes) => {
  const nodes = []
  const edges = []
  for (const phase of phases || []) {
    let el = {
      id: `${phase.id}`,
      type: 'phase',
      data: { label: phase.name, phase: phase },
      position: { x: 0, y: 0 },
    }
    nodes.push(el)
    for (const next of phase.nexts) {
      let el2 = {
        id: `${phase.id}-${next.id}`,
        type: 'float',
        data: next,
        source: `${phase.id}`,
        target: `${next.id}`,
        markerEnd: { type: 'arrowclosed' },
        animated: false,
      }
      edges.push(el2)
    }
  }
  return { nodes, edges }
}

const ProjectPhaseDesignCanvas = ({
  data,
  reload,
  onPhaseSelect,
  onAdd,
  onEdit,
  onRemove,
}) => {
  const service = useService()
  const { toasts } = useToast()
  const [nodes, setNodes] = useState([])
  const [edges, setEdges] = useState([])

  const nodeTypes = useMemo(
    () => ({
      phase: ProjectPhaseNode,
    }),
    []
  )

  const edgeTypes = useMemo(
    () => ({
      float: FloatingEdge,
    }),
    []
  )

  const onNodesChange = useCallback(
    (changes) => {
      changes.forEach((ch) => {
        let node = nodes.find((n) => n.id == ch.id)
        if (!node) {
          return
        }
        if (ch.type == 'remove') {
          service
            .delete(`/projects/phases/${ch.id}`)
            .then(() => onRemove())
            .catch((error) => toasts.error(error?.message))
        } else if (ch.type == 'select' && ch.selected) {
          onPhaseSelect(node.data.phase)
        }
      })
      setNodes((ns) => applyNodeChanges(changes, ns))
    },
    [nodes]
  )

  const onEdgesChange = useCallback(
    (changes) => {
      changes.forEach((ch) => {
        if (ch.type == 'remove') {
          let [source, target] = ch.id.split('-')
          service
            .delete(`/projects/phases/${source}/nexts/${target}`)
            .then(() => reload())
            .catch((error) => toasts.error(error?.message))
        }
      })
      setEdges((es) => applyEdgeChanges(changes, es))
    },
    [edges]
  )

  const onConnect = useCallback((connection) => {
    const { source, target } = connection
    service
      .post(`/projects/phases/${source}/nexts/${target}`)
      .then(() => {
        reload()
        setEdges((eds) => addEdge(connection, eds))
      })
      .catch((error) => toasts.error(error?.message))
  })

  useEffect(() => {
    const initialElements = makeInitialElements(data, nodeTypes)
    let nodes = getLayoutedElements(
      initialElements.nodes,
      initialElements.edges
    )
    setNodes(nodes)
    setEdges(initialElements.edges)
  }, [data])

  return (
    <Container className={styles.phaseDesignWrapper}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onInit={(instance) => instance.fitView()}
        onNodeDoubleClick={(_, node) => {
          if (node) {
            onPhaseSelect(node.data.phase)
            onEdit && onEdit(node.data.phase)
          }
        }}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
      />
      <PhaseDesignControl
        onAddPhase={() => {
          onAdd && onAdd()
        }}
      />
    </Container>
  )
}

const PhaseDesignControl = ({ onAddPhase }) => {
  const { zoomIn, zoomOut, fitView } = useReactFlow()
  return (
    <div className={styles.phaseActions}>
      <AddIconButton onClick={onAddPhase} />
      <ZoomInIconButton
        onClick={(e) => {
          e.stopPropagation()
          zoomIn()
        }}
      />
      <ZoomOutIconButton
        onClick={(e) => {
          e.stopPropagation()
          zoomOut()
        }}
      />
      <MaximizeIconButton
        onClick={(e) => {
          e.stopPropagation()
          fitView()
        }}
      />
    </div>
  )
}

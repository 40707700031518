import { useEffect, useState } from 'react'

import { SectionView } from 'common/widgets/view'
import { Container } from 'common/widgets/container'
import { useService } from 'common/service/context'
import { AddIconButton } from 'common/widgets/button'
import { RemovableTag } from 'common/widgets/tag'
import { ContactSelectOverlay } from 'modules/master-data/contacts/widgets/select'
import { Avatar } from 'common/widgets/avatar'

export const ProjectMembersView = ({ project, update, reload }) => {
  const [contactSelectVisible, setContactSelectVisible] = useState(false)
  const members = project?.members ?? []
  const service = useService()

  const makeParams = () => members.map((e) => ({ 'id[neq]': e.contact.id }))

  const [selectParams, setSelectParams] = useState(makeParams())

  const addMember = async (contact) => {
    await service.post('projects/members', {
      project_id: project.id,
      contact_id: contact.id,
    })
    await reload()
  }

  const removeMember = async (id) => {
    await service.delete(`/projects/members/${id}`)
    await reload()
  }

  useEffect(() => {
    setSelectParams(makeParams())
  }, [project?.members])

  return (
    <SectionView>
      <Container flex repel title="Project Members">
        <AddIconButton onClick={() => setContactSelectVisible(true)} />
      </Container>
      <Container flex vertical gap="5px">
        {members.map((e, i) => (
          <Container flex key={i} gap="10px">
            <Avatar
              text={e.contact.fullname}
              imgSrc={e.contact.system_user?.photo}
              size="30px"
            />
            <RemovableTag
              key={e.id}
              onRemove={async () => await removeMember(e.id)}
            >
              <p>{e.contact.fullname}</p>
            </RemovableTag>
          </Container>
        ))}
      </Container>
      {contactSelectVisible && (
        <ContactSelectOverlay
          open={contactSelectVisible}
          onClose={() => setContactSelectVisible(false)}
          onSelect={async (contact) => await addMember(contact)}
          params={selectParams}
        />
      )}
    </SectionView>
  )
}

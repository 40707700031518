import React from 'react'
import { useTranslation } from 'react-i18next'

import { useForm } from 'common/widgets/form/context'
import { Column, Row } from 'common/widgets/grid'
import { CountryDropDown } from 'common/widgets/country/dropdown'
import { OverlayForm } from 'common/widgets/overlay'
import { PhoneNumberField } from 'common/widgets/form/field'
import { CancelIconButton } from 'common/widgets/button'
import { AddressMap } from 'common/widgets/map/address'

import { Input } from '../input'

import styles from './address.module.css'

export const AddressField = ({
  name,
  label,
  showMap,
  showContactFields,
  editGeofence,
  ...rest
}) => {
  const { t } = useTranslation()
  const ctx = useForm()
  const address = ctx.values.get(name)

  function handleAddrChange(addr) {
    ctx.values.set(name, { ...addr, lat: null, lon: null })
  }

  return (
    <>
      <Row
        title={t(label)}
        className={styles.address}
        titleWidgets={
          <CancelIconButton
            tooltip="Remove address"
            onClick={() => ctx.values.set(name, null)}
          />
        }
      >
        <Column n={6} s={6}>
          <label htmlFor="addrStreet">{t('Street')}</label>
          <Input
            id="addrStreet"
            name="street"
            type="text"
            value={address?.street ?? ''}
            autoComplete="address-line1"
            onChange={(event) => {
              handleAddrChange({ ...address, street: event.target.value })
            }}
          />
        </Column>
        <Column n={6} s={6}>
          <label htmlFor="addrStreetNo">{t('Street number')}</label>
          <Input
            id="addrStreetNo"
            name="street_number"
            type="text"
            value={address?.street_number ?? ''}
            autoComplete="address-line2"
            onChange={(event) => {
              handleAddrChange({
                ...address,
                street_number: event.target.value,
              })
            }}
          />
        </Column>
        <Column n={6} s={6}>
          <label htmlFor="addrZipcode">{t('Zipcode')}</label>
          <Input
            id="addrZipcode"
            name="zip_code"
            type="text"
            value={address?.zip_code ?? ''}
            autoComplete="postal-code"
            onChange={(event) => {
              handleAddrChange({ ...address, zip_code: event.target.value })
            }}
          />
        </Column>
        <Column n={6} s={6}>
          <label htmlFor="addrCity">{t('City')}</label>
          <Input
            id="addrCity"
            name="city"
            type="text"
            value={address?.city ?? ''}
            autoComplete="address-level2"
            onChange={(event) => {
              handleAddrChange({ ...address, city: event.target.value })
            }}
          />
        </Column>
        <Column n={6} s={6}>
          <label htmlFor="addrState">{t('Province')}</label>
          <Input
            id="addrState"
            name="state"
            type="text"
            value={address?.state ?? ''}
            autoComplete="address-level1"
            onChange={(event) => {
              handleAddrChange({ ...address, state: event.target.value })
            }}
          />
        </Column>
        <Column n={6} s={6}>
          <label htmlFor="addrCountry">{t('Country')}</label>
          <CountryDropDown
            id="addrCountry"
            name="country"
            type="text"
            autoComplete="country-name"
            selected={address?.country}
            onChange={(country) => {
              handleAddrChange({ ...address, country: country?.title })
            }}
          />
        </Column>
        {showContactFields && (
          <>
            <Column n={6} s={6}>
              <label htmlFor="phone">{t('Phone')}</label>
              <PhoneNumberField
                id="phone"
                name="phone"
                type="tel"
                defaultValue={address?.phone}
              />
            </Column>
            <Column n={6} s={6}>
              <label htmlFor="fax">{t('Fax')}</label>
              <PhoneNumberField
                id="fax"
                type="tel"
                name="fax"
                defaultValue={address?.fax}
              />
            </Column>
            <Column n={6} s={6}>
              <label htmlFor="email">{t('E-Mail')}</label>
              <Input
                id="email"
                type="email"
                name="email"
                defaultValue={address?.email}
              />
            </Column>
            <Column n={6} s={6}>
              <label htmlFor="webpage">{t('Webpage')}</label>
              <Input
                id="webpage"
                type="url"
                name="webpage"
                defaultValue={address?.webpage}
              />
            </Column>
          </>
        )}
      </Row>
      {showMap && (
        <>
          {editGeofence && (
            <p>
              Use the following map to define a geofence for the location.
              (click on the map to activate zooming with scroll.)
            </p>
          )}
          <AddressMap
            address={address}
            title={t(label)}
            interactive={editGeofence}
            onAddressChange={(addr) => {
              ctx.values.set(name, addr?.normalized_address)
            }}
          />
        </>
      )}
    </>
  )
}

export const AddressOverlay = ({ initialValue, ...rest }) => {
  return (
    <OverlayForm name="address" data={initialValue || {}} showActions {...rest}>
      <AddressField name="address" />
    </OverlayForm>
  )
}

import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { useService } from 'common/service/context'
import { formatDate } from 'common/utils/format'
import { SimpleColumn, SimpleDataTable } from 'common/data-table/simple'

export const ContactPresentsList = () => {
  const { t } = useTranslation(['contacts'])
  const service = useService()
  const navigate = useNavigate()
  const { id } = useParams()

  /**
   * Fetch present records using the given query parameters.
   *
   * @param {*} params query params
   * @returns
   */
  const fetch = async (params) =>
    await service.get('contacts/presents', [
      ...params,
      { contact_id: parseInt(id) },
    ])

  return (
    <SimpleDataTable
      name="presents"
      title={t('Presents')}
      fetch={fetch}
      onAddClick={() => navigate(`/master-data/contacts/${id}/presents/add`)}
      onRowClick={(r) =>
        navigate(`/master-data/contacts/${id}/presents/${r.id}/edit`)
      }
    >
      <SimpleColumn field="event" label="Event" />
      <SimpleColumn
        s={6}
        n={6}
        sortable
        field="description"
        label="Description"
      />
      <SimpleColumn
        s={6}
        n={6}
        sortable
        field="date"
        label="Event date"
        value={(r) => formatDate(r.date)}
      />
    </SimpleDataTable>
  )
}

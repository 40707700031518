import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled, { css, keyframes } from 'styled-components'
import * as rf from 'react-feather'

import { useMediaQuery } from 'common/utils/hooks'
import { useForm } from 'common/widgets/form/context'
import { Badge } from 'common/widgets/badge'

/**
 * The base of all exported buttons in the application.
 *
 * @returns ReactElement
 **/
export const Button = ({
  children,
  onClick,
  animate,
  text,
  icon,
  disabled,
  ...rest
}) => {
  const { t } = useTranslation()
  const btnText = (text && t(text)) || ''
  const [busy, setBusy] = useState(false)

  // Buttons are often used in forms, so it makes sense to provide
  // a context by default. If user provides a function it would have
  // priority, else we get the form context by default and pass it
  // to all event handlers by default (so far only disabled and click)
  const ctx = useForm()

  if (typeof disabled == 'function') {
    disabled = disabled(ctx)
  }

  const handleAnimation = async (e, ctx) => {
    if (!busy && onClick) {
      setBusy(true)
      try {
        await onClick(e, ctx)
      } finally {
        setBusy(false)
      }
    }
  }

  const handleClick = (e) => {
    if (animate) {
      return handleAnimation(e, ctx)
    }
    if (onClick) {
      return onClick(e, ctx)
    }
  }

  return (
    <ButtonBase
      title={btnText}
      onClick={handleClick}
      {...rest}
      busy={busy}
      disabled={disabled}
    >
      {icon}
      {children}
      {btnText && <p>{btnText}</p>}
    </ButtonBase>
  )
}

const RotateAnimation = keyframes`
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
`

const ButtonBase = styled.button`
  display: flex;
  align-self: ${(props) => (props.alignSelf ? props.alignSelf : 'flex-start')};
  align-items: center;
  height: 40px;
  border-radius: 5px;
  background: #ffffff;
  border: 1px solid #376df466;
  color: #376df4;
  font: normal normal bold 14px/18px Red Hat Display;
  letter-spacing: 0.35px;

  @media (max-width: 768px) {
    width: ${(props) => (props.width ? props.width : '100%')};
  }

  cursor: pointer;
  padding: 10px;
  user-select: none;
  transition: 0.2s ease-out;

  :hover {
    background: ${(props) =>
      props.disabled
        ? 'inhert'
        : props.default
        ? '#4A7BF5'
        : props.danger
        ? '#FC5757 0% 0% no-repeat padding-box'
        : props.warning
        ? '#F5A04D'
        : props.complete
        ? '#44D331'
        : props.success
        ? '#2DCE18'
        : '#fafafa'};
  }

  p {
    color: #376df4;
    user-select: none;
    margin: 0;
    margin-left: 5px;
    margin-right: 5px;
    font: normal normal bold 14px/18px Red Hat Display;
    letter-spacing: 0.35px;
  }

  svg {
    user-select: none;
    margin-left: 5px;
    margin-right: 5px;
    width: 16px;
    height: 16px;
    color: #376df4;

    ${(props) =>
      props.busy &&
      css`
        animation-name: ${RotateAnimation};
        animation-iteration-count: infinite;
        animation-duration: 1s;
      `}
  }

  ${(props) =>
    props.danger &&
    css`
      background: #fc4242;
      border: 1px solid #fc4242;
      color: #ffffff;
      svg {
        color: #ffffff;
      }
      p {
        color: #ffffff;
      }
    `}
  ${(props) =>
    props.default &&
    css`
      background: #376df4;
      border: 1px solid #376df4;
      color: #ffffff;
      svg {
        color: #ffffff;
      }
      p {
        color: #ffffff;
      }
    `}
    ${(props) =>
    props.warning &&
    css`
      background: #f49537;
      border: 1px solid #f49537;
      color: #ffffff;
      svg {
        color: #ffffff;
      }
      p {
        color: #ffffff;
      }
    `}
      ${(props) =>
    props.success &&
    css`
      background: #2dce18;
      border: 1px solid #2dce18;
      color: #ffffff;
      svg {
        color: #ffffff;
      }
      p {
        color: #ffffff;
      }
    `} 
  ${(props) =>
    props.disabled &&
    css`
      background: #aec4fa 0% 0% no-repeat padding-box;
      border: 1px solid #aec4fa;
      color: #ffffff;
      svg {
        color: #ffffff;
      }
      p {
        color: #ffffff;
      }
    `}
  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
    `}
  ${(props) =>
    props.marginLeft &&
    css`
      margin-left: ${props.marginLeft};
    `}
  @media print {
    display: none;
  }
`

export const IconButton = ({
  title,
  tooltip,
  children,
  icon,
  disabled,
  onClick,
  badge,
  ...rest
}) => {
  const { t } = useTranslation()
  return (
    <IconButtonBase
      title={t(tooltip)}
      $disabled={disabled}
      onClick={(e) => {
        if (!disabled && onClick) {
          onClick(e)
        }
      }}
      {...rest}
    >
      {children}
      {icon}
      {badge > 0 && (
        <Badge>
          <h5>{badge}</h5>
        </Badge>
      )}
    </IconButtonBase>
  )
}

const IconButtonBase = styled.div`
  box-sizing: border-box;
  width: ${(props) => props.size || props.width || '40px'};
  height: ${(props) => props.size || props.height || '40px'};
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  border: ${(props) => (props.noborder ? '0px' : '1px')} solid
    ${(props) =>
      props.default ? '#4A7BF520' : props.danger ? '#FDA0A020' : '#8b8b8b20'};
  ${(props) =>
    props.noborder &&
    css`
      border: 0px;
    `}
  color: ${(props) =>
    props.default
      ? '#ffffff'
      : props.danger
      ? '#FC4242'
      : props.warning
      ? '#376DF4'
      : props.color || '#8b8b8b'};
  ${(props) =>
    props.background &&
    css`
      background: ${props.background};
    `}
  ${(props) =>
    props.white &&
    css`
      background: #ffffff;
    `}
  background: ${(props) =>
    props.default ? '#376DF4 0% 0% no-repeat padding-box' : ''};
  transition: 0.3s;
  cursor: ${(props) => (props.$disabled ? 'auto' : 'pointer')};
  border-radius: 5px;

  ${(props) =>
    !props.$disabled &&
    css`
      :hover {
        color: ${(props) =>
          props.default
            ? '#4A7BF5 0% 0% no-repeat padding-box'
            : props.danger
            ? '#FC42420D 0% 0% no-repeat padding-box'
            : props.warning
            ? '#376DF40D 0% 0% no-repeat padding-box'
            : '#303030'};
        background: ${(props) =>
          props.default
            ? '#4A7BF5 0% 0% no-repeat padding-box'
            : props.danger
            ? '#FFF3F3 0% 0% no-repeat padding-box'
            : props.warning
            ? '#376DF40D 0% 0% no-repeat padding-box'
            : '#f4f4f4'};
      }
    `}

  svg {
    width: ${(props) => props.svgSize || '20px'};
    height: ${(props) => props.svgSize || '20px'};
    margin: auto;
  }
  ${(props) =>
    props.marginLeft &&
    css`
      margin-left: ${props.marginLeft};
    `}

  ${(props) =>
    props.value &&
    css`
      background: #ffffff;
    `}
  h5 {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background: #4b83ff;
    color: #ffffff;
    text-align: center;
    box-sizing: border-box;
    position: absolute;
    margin-top: 0;
    padding-top: 2px;
    margin-left: -8px;
    margin-top: -40px;
  }

  opacity: ${(props) => (props.$disabled ? '0.5' : 'inherit')};

  @media print {
    display: none;
  }
`

export const CollapsableIconButton = ({
  onCollapsed,
  collapsed = false,
  ...rest
}) => {
  const [_collapsed, setCollapsed] = useState(collapsed)

  useEffect(() => setCollapsed(collapsed), [collapsed])

  return (
    <IconButton
      onClick={(e) => {
        setCollapsed((prevState) => !prevState)
        if (onCollapsed) {
          onCollapsed(!_collapsed)
        }
      }}
      {...rest}
    >
      {_collapsed ? <rf.ChevronUp /> : <rf.ChevronDown />}
    </IconButton>
  )
}

/**
 * Renders a folded buttons widget.
 * It will reveals the given list of buttons when user clicks on more icon.
 *
 * @param {Array} children list of icons as children
 * @param {Boolean} autoFlatten show no tray icon on mobile
 * @returns ReactElement
 */
export const FoldButtons = ({
  noborder,
  children,
  autoFlatten = true,
  wrap,
  style = {},
  ...rest
}) => {
  const [visible, setVisible] = useState(false)
  const isRowBased = useMediaQuery('(max-width: 768px)')
  const isPrint = useMediaQuery('print')

  // Remove non-elements
  children = children.filter((e) => e?.props)

  /**
   * Handle click on window and closes dropdown menu.
   */
  const handleWindowClick = () => visible && setVisible(false)

  /**
   * Close the menu if user presses escape.
   *
   * @param {Event} e keyboard event
   */
  const handleEscape = (e) => {
    if (e.code === 'Escape' && visible) {
      setVisible(false)
    }
  }

  /**
   * Registers a listener for window click and keydown event, also removes the listener when element gets unmounted.
   */
  useEffect(() => {
    window.addEventListener('click', handleWindowClick)
    window.addEventListener('keydown', handleEscape)
    return () => {
      window.removeEventListener('keydown', handleEscape)
      window.removeEventListener('click', handleWindowClick)
    }
  })

  // Keeps for gapping between each button
  const gap = 5
  // Width for each button
  const width = 40
  // Calculates totalWidth
  const total = (width + gap) * children.length
  // Calculates margin
  const margin = -(total + width)

  // For the time being show all buttons in wide screens
  if (!isRowBased && autoFlatten) {
    return <LeftMarginChildren $wrap={wrap}>{children}</LeftMarginChildren>
  }

  // Show nothing on print documents
  if (isPrint) {
    return null
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end', ...style }}>
      {children.length > 1 && (
        <IconButton
          onClick={(e) => {
            setVisible(!visible)
            e.stopPropagation()
          }}
          noborder={noborder}
        >
          {/* See https://styled-components.com/docs/api#transient-props */}
          <StyledMoreVertical $visible={visible} />
          <FoldItemsContainer $visible={visible} margin={margin} width={total}>
            <StyledFoldedButtons visible={visible} total={total}>
              {children}
            </StyledFoldedButtons>
          </FoldItemsContainer>
        </IconButton>
      )}
      {children.length === 1 && children[0]}
    </div>
  )
}

const FoldItemsContainer = styled.div`
  position: absolute;
  width: ${(props) => props.width}px;
  height: 40px;
  margin-left: ${(props) => props.margin}px;
  margin-top: 0;
  z-index: ${(props) => (props.$visible ? 1 : -1)};
  overflow: hidden;
  /**
   * learn more about CSS child combinator
   * https://developer.mozilla.org/en-US/docs/Web/CSS/Child_combinator
   */
  * > * {
    background-color: #efefef;
  }
  transition: 0.3s;
`

const StyledMoreVertical = styled(rf.MoreVertical)`
  display: block;
  transition: 0.3s;
  transform: ${(props) => (props.$visible ? 'rotate(90deg)' : 'rotate(0)')};
`

const StyledFoldedButtons = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  width: ${(props) => props.total}px;
  height: 40px;
  z-index: 2;
  left: ${(props) => props.total - (props.visible ? 1 : 0) * props.total}px;
  transition: 0.5s;
  /* Learn more about "Lobotomized Owls" selector at
   * https://alistapart.com/article/axiomatic-css-and-lobotomized-owls/
   */
  * + * {
    margin-left: 5px;
  }
`

const LeftMarginChildren = styled.div`
  display: flex;
  gap: 5px;
  flex-wrap: ${(props) => (props.$wrap ? 'wrap' : 'nowrap')}px;
  justify-content: flex-end;
  max-width: ${(props) => (props.$wrap ? '200px' : 'unset')};
`

/***********************************************************
 * From here onward only Buttons
 *
 * IMPORTANT: Names should describe the "function" and not the
 * look of each button. Buttons are supposed to be "semantic"
 * and be meaningful.
 ***********************************************************/

export const AddButton = ({ text = 'Add', ...rest }) => (
  <Button text={text} icon={<rf.Plus />} {...rest} />
)

export const EditButton = ({ text = 'Edit', ...rest }) => (
  <Button text={text} icon={<rf.Edit />} {...rest} />
)

export const CopyButton = ({ text = 'Copy', ...rest }) => (
  <Button text={text} icon={<rf.Copy />} {...rest} />
)

export const SaveButton = ({ text = 'Save', ...rest }) => (
  <Button text={text} icon={<rf.Save />} {...rest} />
)

export const CancelButton = ({ text = 'Cancel', ...rest }) => (
  <Button text={text} icon={<rf.X />} {...rest} />
)

export const DeleteButton = ({ text = 'Delete', ...rest }) => (
  <Button text={text} icon={<rf.Trash />} {...rest} />
)

export const RemoveButton = ({ text = 'Remove', ...rest }) => (
  <Button text={text} icon={<rf.Minus />} {...rest} />
)

export const ClearButton = ({ text = 'Clear', ...rest }) => (
  <Button text={text} icon={<rf.Delete />} {...rest} />
)

export const SubmitButton = ({ text = 'Submit', ...rest }) => (
  <Button type="submit" default text={text} icon={<rf.Command />} {...rest} />
)

export const CompleteButton = ({ text = 'Complete', ...rest }) => (
  <Button
    type="submit"
    complete
    text={text}
    icon={<rf.CheckSquare />}
    {...rest}
  />
)

export const ReopenButton = ({ text = 'Reopen', ...rest }) => (
  <Button complete text={text} icon={<rf.Repeat />} {...rest} />
)

export const ConfirmButton = ({ text = 'Confirm', ...rest }) => (
  <Button default text={text} icon={<rf.ThumbsUp />} {...rest} />
)

export const DownloadButton = ({ text = 'Download', ...rest }) => (
  <Button text={text} icon={<rf.Download />} {...rest} />
)

export const BookingButton = ({ text = 'Calendar', ...rest }) => (
  <Button text={text} icon={<rf.Calendar />} {...rest} />
)

export const ClockButton = ({ text = 'Set', ...rest }) => (
  <Button text={text} icon={<rf.Clock />} {...rest} />
)

export const YesButton = ({ ...rest }) => <ConfirmButton text="Yes" {...rest} />

export const NoButton = ({ ...rest }) => <CancelButton text="No" {...rest} />

export const BackButton = ({ text = 'Back', onClick, ...rest }) => {
  const navigate = useNavigate()
  return (
    <Button
      // If onClick is not provided navigate the the previous page
      onClick={onClick || (() => navigate(-1))}
      text={text}
      icon={<rf.ArrowLeft />}
      {...rest}
    />
  )
}

export const NextButton = ({ arrowColor, ...rest }) => {
  return (
    <Button
      icon={<rf.ArrowRight color={arrowColor ? arrowColor : ''} />}
      {...rest}
    />
  )
}

export const PreviousButton = ({ arrowColor, ...rest }) => {
  return (
    <Button
      icon={<rf.ArrowLeft color={arrowColor ? arrowColor : ''} />}
      {...rest}
    />
  )
}

export const LogoutButton = ({ text = 'Logout', ...rest }) => (
  <Button text={text} icon={<rf.LogOut />} {...rest} />
)

/************************************************************
 * From here onward only IconButtons
 *
 * IMPORTANT: Names should describe the "function" and not the
 * look of each button. Buttons are supposed to be "semantic"
 * and be meaningful.
 ***********************************************************/

export const AddIconButton = ({ ...rest }) => (
  <IconButton icon={<rf.Plus />} {...rest} />
)

export const EditIconButton = ({ ...rest }) => (
  <IconButton icon={<rf.Edit />} {...rest} />
)

export const SaveIconButton = ({ ...rest }) => (
  <IconButton icon={<rf.Save />} {...rest} />
)

export const RemoveIconButton = ({ ...rest }) => (
  <IconButton icon={<rf.Minus />} {...rest} />
)

export const DeleteIconButton = ({ ...rest }) => (
  <IconButton danger icon={<rf.Trash />} {...rest} />
)

export const CancelIconButton = ({ ...rest }) => (
  <IconButton danger icon={<rf.X />} {...rest} />
)

export const CloseIconButton = ({ ...rest }) => (
  <IconButton icon={<rf.X />} {...rest} />
)

export const ConfirmIconButton = ({ ...rest }) => (
  <IconButton icon={<rf.ThumbsUp />} {...rest} />
)

export const BackIconButton = ({ onClick, ...rest }) => {
  const navigate = useNavigate()
  return (
    <IconButton
      // If onClick is not provided navigate the the previous page
      onClick={onClick || (() => navigate(-1))}
      icon={<rf.ChevronLeft />}
      {...rest}
    />
  )
}

export const ForwardIconButton = ({ ...rest }) => (
  <IconButton icon={<rf.ChevronRight />} {...rest} />
)

export const InfoIconButton = ({ ...rest }) => (
  <IconButton icon={<rf.Info />} {...rest} />
)

export const CopyIconButton = ({ ...rest }) => (
  <IconButton icon={<rf.Copy />} {...rest} />
)

export const DetailsIconButton = ({ ...rest }) => (
  <IconButton icon={<rf.List />} {...rest} />
)

export const FlagIconButton = ({ danger, color, ...rest }) => (
  <IconButton icon={<rf.Flag color={danger ? 'red' : color} />} {...rest} />
)

export const PrintIconButton = ({ ...rest }) => (
  <IconButton icon={<rf.Printer />} {...rest} />
)

export const ArchiveIconButton = ({
  archived,
  interactive = true,
  value,
  ...rest
}) => {
  const icon =
    interactive && archived ? (
      <rf.Repeat />
    ) : (
      <rf.Archive style={{ background: archived ? '#ffffff' : 'initial' }} />
    )
  // Just another hack. Setting value will highlight the basebutton,
  // so in case the archive flag is not set, we remove the value.
  let newValue = value
  if (!value || value == 'false') {
    newValue = undefined
  }
  return <IconButton icon={icon} value={newValue} {...rest} />
}

export const FilterIconButton = ({ ...rest }) => (
  <IconButton icon={<rf.Sliders />} {...rest} />
)

export const ExportIconButton = ({ ...rest }) => (
  <IconButton icon={<rf.Download />} {...rest} />
)

export const DownloadIconButton = ({ ...rest }) => (
  <IconButton icon={<rf.Download />} {...rest} />
)

export const CommissionIconButton = ({ ...rest }) => (
  <IconButton icon={<rf.Box />} {...rest} />
)

export const SendIconButton = ({ ...rest }) => (
  <IconButton icon={<rf.Send />} {...rest} />
)

export const BookingIconButton = ({ ...rest }) => (
  <IconButton icon={<rf.Calendar />} {...rest} />
)

export const MaximizeIconButton = ({ ...rest }) => (
  <IconButton icon={<rf.Maximize />} {...rest} />
)

export const CropIconButton = ({ ...rest }) => (
  <IconButton icon={<rf.Crop />} {...rest} />
)

export const MailIconButton = ({ ...rest }) => (
  <IconButton icon={<rf.Mail />} {...rest} />
)

export const SignButton = ({ ...rest }) => (
  <IconButton icon={<rf.PenTool />} {...rest} />
)

/**
 * Represents loading goods into a transporter.
 **/
export const LoadIconButton = ({ ...rest }) => (
  <IconButton icon={<rf.Truck />} {...rest} />
)

export const MarkAsReadIconButton = ({ ...rest }) => (
  <IconButton icon={<rf.BookOpen />} {...rest} />
)

export const AdHocDeliveryIconButton = ({ ...rest }) => (
  <IconButton icon={<rf.ShoppingCart color="#888888" />} {...rest} />
)

export const FavoriteIconButton = ({ starred, ...rest }) => (
  <IconButton
    icon={
      <rf.Star
        style={{
          color: starred ? '#376df4' : '#8b8b8b',
          fill: starred ? '#376df4' : '#ffffff',
        }}
      />
    }
    {...rest}
  />
)

export const ZoomInIconButton = ({ ...rest }) => (
  <IconButton icon={<rf.ZoomIn />} {...rest} />
)

export const ZoomOutIconButton = ({ ...rest }) => (
  <IconButton icon={<rf.ZoomOut />} {...rest} />
)

export const ClockIconButton = ({ ...rest }) => (
  <IconButton icon={<rf.Clock />} {...rest} />
)

export const NotificationIconButton = ({ ...rest }) => (
  <IconButton icon={<rf.Bell />} {...rest} />
)

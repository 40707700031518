import React from 'react'

import { register } from 'system/container'

import { TransportList } from './list'
import { TransportsOverviewPage } from './add'

register({
  render: () => <TransportList />,
  menu: true,
  title: 'Transports',
  path: '/logistics/planning/transports',
})

register({
  render: () => <TransportsOverviewPage />,
  menu: false,
  title: 'New Tours',
  path: '/logistics/planning/transports/:date',
})

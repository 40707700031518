import { useEffect, useState } from 'react'

import { useQuery } from 'common/query/context'
import { ClearButton } from 'common/widgets/button'
import { Container } from 'common/widgets/container'
import { CheckBox } from 'common/widgets/checkbox'
import { SearchInput } from 'common/widgets/search'
import { ToggleSwitch } from 'common/widgets/toggle'

import styles from './dt.module.css'

/**
 * Renders filter widget for data tables.
 *
 * @param {Array} filters list of filter definition
 * @returns ReactElement
 */
export const DataTableFilterWidget = ({ filters, open, onReset }) => {
  const query = useQuery()

  // In order to prevent unnecessary reloads before first open
  const [opened, setOpened] = useState(open)

  useEffect(() => {
    // On first open, set the flag
    if (open && !opened) {
      setOpened(true)
    }
  }, [open])

  if (!opened) {
    return null
  }

  return (
    <div style={{ display: open ? 'inherit' : 'none' }}>
      <Container flex wrap gap="5px">
        {filters?.map((filter, idx) => (
          <Section key={idx} filter={filter} />
        ))}
      </Container>
      <Container flex repel height="64px" align="center" gap="10px">
        <ClearButton
          alignSelf="center"
          danger
          onClick={(e) => {
            query.parameters.clear()
            onReset && onReset()
          }}
        />
        <FilterField label="Active Filters">
          <p>{query.parameters.size}</p>
        </FilterField>
        <FilterField label="Showing">
          <p style={{ height: '25px' }}>
            {query.totalCount &&
              `${Math.min(query.limit, query.totalCount)}/${query.totalCount}`}
          </p>
        </FilterField>
      </Container>
    </div>
  )
}

const Section = ({ filter, max_items = 10 }) => {
  const [records, setRecords] = useState(null)
  const query = useQuery()
  const [filterSearch, setFilterSearch] = useState('')

  if (filter?.fetch) {
    const fetch = async () => {
      const [response, error] = await filter.fetch()
      setRecords(error ? [] : response.data)
    }
    // Make sure filters are loaded only when the widge is displayed
    if (records === null) {
      fetch()
    }
  }

  const options = []
  let key = filter.field || filter.key

  if (filter?.boolean) {
    // for boolean fields just set a signle check box and be done with it
    return (
      <div className={styles.filterSection}>
        <ToggleSwitch
          text={filter.section}
          onChange={(checked) => {
            query.parameters.set(key, checked)
          }}
        />
      </div>
    )
  }

  function getFilterValue(filter, record) {
    if (typeof filter?.value === 'function') {
      return filter.value(record)
    }
    return record
  }

  // If max items is set, tries to filter it with search string
  const showSearch = records && records.length > max_items
  const filtered = showSearch
    ? records.filter((r) => {
        let effectiveTitle
        if (typeof filter?.title === 'function') {
          effectiveTitle = filter.title(r)
        } else {
          effectiveTitle = r
        }

        return effectiveTitle
          ?.toLowerCase()
          .includes(filterSearch.toLowerCase())
      })
    : records

  // Splits the extra items
  let selectedOptions = query.parameters.getAll(key)
  const availableOptions = showSearch ? filtered.slice(0, max_items) : filtered
  availableOptions?.forEach((r, i) => {
    let defaultValue = false

    let optionEffetciveValue = r
    if (typeof filter?.value === 'function') {
      optionEffetciveValue = filter?.value(optionEffetciveValue)
    }

    selectedOptions.forEach((value) => {
      let effectiveValue
      if (typeof filter?.value === 'function') {
        effectiveValue = filter?.value(value)
      } else {
        effectiveValue = value
      }

      if (effectiveValue == value) {
        defaultValue = true
      }
    })

    let effectiveTitle
    if (typeof filter?.title === 'function') {
      effectiveTitle = filter.title(r)
    } else {
      effectiveTitle = r
    }

    if (effectiveTitle) {
      let cb = (
        <CheckBox
          key={i}
          value={selectedOptions.includes(optionEffetciveValue)}
          style={{ marginBottom: '10px' }}
          defaultValue={defaultValue}
          onChange={(c) => {
            if (c) {
              query.parameters.append(key, getFilterValue(filter, r))
            } else {
              query.parameters.deappend(key, getFilterValue(filter, r))
            }
          }}
        >
          {effectiveTitle}
        </CheckBox>
      )
      options.push(cb)
    }
  })

  return (
    <div className={styles.filterSection}>
      <h3>{filter.section}</h3>
      {showSearch && (
        <SearchInput
          value={filterSearch}
          onChange={(text) => setFilterSearch(text)}
          nodelay
        />
      )}
      {options}
    </div>
  )
}

/**
 * Renders data table field with the given label.
 *
 * @param {string} label field label
 * @param {ReactElement} children child react element
 * @returns ReactElement
 */
const FilterField = ({ label, children, ...rest }) => (
  <Container flex vertical {...rest}>
    <h5>{label}</h5>
    {children}
  </Container>
)

import { Form } from 'common/widgets/form/context'
import { useService } from 'common/service/context'

import { UserFormContent } from './form'

export const UserAddPage = () => {
  const service = useService()

  const handleAdd = async (values) => {
    const normalized = { ...values, roles: values?.roles?.map((e) => e.id) }
    return await service.post('security/users', normalized)
  }

  return (
    <Form>
      <UserFormContent onAdd={handleAdd} />
    </Form>
  )
}

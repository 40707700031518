import { Star } from 'react-feather'
import { useTranslation } from 'react-i18next'

import { RecursiveTree } from 'common/widgets/tree'
import { DataSource } from 'common/widgets/data-source'
import { DropDown } from 'common/widgets/dropdown'
import { FieldWrapper } from 'common/widgets/form/field'

export const RoleSelect = ({
  selectedRoleId,
  params,
  handler,
  onSelectChange,
  hint,
  icon,
  disabled,
}) => {
  const { t } = useTranslation()

  const render = ({ data }) => {
    const items = data
      ? data.map((role) => ({
          key: role.id,
          title: role.name,
          data: role,
        }))
      : []
    return (
      <DropDown
        minWidth="320px"
        maxWidth="320px"
        title={hint ? hint : t('Roles')}
        icon={icon ? icon : <Star />}
        items={items}
        selectedIndex={items.findIndex((r) => r.key == selectedRoleId)}
        disabled={disabled}
        onSelectChange={(e) => onSelectChange && onSelectChange(e.data)}
      />
    )
  }

  return <DataSource url="security/roles" handler={handler} render={render} />
}

export const RoleSelectField = ({ format, hint, disabled, ...rest }) => (
  <FieldWrapper format={format} {...rest}>
    {(getValue, setValue) => (
      <RoleSelect
        selectedRoleId={getValue()}
        onSelectChange={(role) => setValue(role.id)}
        hint={hint}
        disabled={disabled}
      />
    )}
  </FieldWrapper>
)

export const ScopeTree = ({ scopes, onChange, ...rest }) => {
  return (
    // In order to fetch all scopes for the tree view
    <DataSource
      // The list of all existing scopes for the tree view
      url="/security/scopes?select=id,key,description,parent_id"
      render={({ data }) => {
        // construct a datastructor for the tree widget to consume
        // containing id, key, parent_id, and children array
        const scopeTree = {}
        data.forEach((s) => {
          // We might visit children before parents, hence the checks
          // And children might be parents too, hence the expansions
          if (s.id in scopeTree) {
            scopeTree[s.id] = { ...scopeTree[s.id], ...s }
          } else {
            scopeTree[s.id] = { children: [], ...s }
          }
          if (s.parent_id in scopeTree) {
            scopeTree[s.parent_id]['children'].push(s)
          } else if (s.parent_id) {
            scopeTree[s.parent_id] = { children: [s] }
          }
        })
        return (
          <>
            {data && (
              <RecursiveTree
                tree={scopeTree}
                selectedNodes={scopes}
                // This is the view page.
                disabled={true}
                // allSelectedTitle="Everything is allowed (Super user)"
                // someSelectedTitle="Somethings are allowed"
                noneSelectedTitle="Nothing is allowed"
                onChange={onChange}
                {...rest}
              />
            )}
          </>
        )
      }}
    />
  )
}

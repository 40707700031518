import { useParams } from 'react-router-dom'

import { Form } from 'common/widgets/form/context'
import { useService } from 'common/service/context'

import { ContactPresentFormContent } from './form'

export const ContactPresentAddPage = () => {
  const service = useService()
  const { id } = useParams()

  const handleAdd = async (values) =>
    await service.post(`/contacts/presents`, {
      ...values,
      contact_id: parseInt(id),
    })

  return (
    <Form>
      <ContactPresentFormContent onAdd={handleAdd} />
    </Form>
  )
}

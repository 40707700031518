import { register } from 'system/container'

import { PendingRequestsPage } from './pending'
import { FormworkPendingRequestConfirm } from './formworks/confirm'
import { PendingRequestConfirm } from './resources/confirm'

register({
  menu: true,
  render: () => <PendingRequestsPage />,
  title: 'Bookings',
  path: '/disposition/requests/new',
})

register({
  render: () => <FormworkPendingRequestConfirm />,
  title: 'Confirm',
  path: '/disposition/requests/new/formworks/:id/confirm',
})
register({
  render: () => <PendingRequestConfirm />,
  title: 'Confirm',
  path: '/disposition/requests/new/resources/:id/confirm',
})

import React from 'react'
import { useTranslation } from 'react-i18next'

import { DataSource } from 'common/widgets/data-source'
import { formatDate } from 'common/utils/format'
import { Box, Container } from 'common/widgets/container'
import { Card } from 'common/widgets/card'
import Rain from 'assets/weather/rain1.svg'
import Fog from 'assets/weather/fog_clouds.svg'
import Snow from 'assets/weather/snow.svg'
import Thunderstorm from 'assets/weather/storm_rain_snow_light.svg'
import Sunny from 'assets/weather/sunny.svg'
import Hail from 'assets/weather/hail.svg'
import SunClouds from 'assets/weather/sun_clouds.svg'
import Clouds from 'assets/weather/clouds.svg'
import Sleet from 'assets/weather/sleet.svg'

export const WeatherForecast = ({ lon, lat }) => {
  const { t } = useTranslation()

  if (!lon || !lat) {
    return `Weather forecast unavailable`
  }

  const handleWeatherConditionAsset = (icon) => {
    const style = { marginTop: '10px', marginBottom: '10px', width: '50px' }
    if (icon === 'clear-day' || icon === 'clear-night') {
      return <Sunny style={style} />
    } else if (icon === 'fog') {
      return <Fog style={style} />
    } else if (icon === 'rain') {
      return <Rain style={style} />
    } else if (icon === 'sleet') {
      return <Sleet style={style} />
    } else if (icon === 'snow') {
      return <Snow style={style} />
    } else if (icon === 'hail') {
      return <Hail style={style} />
    } else if (icon === 'thunderstorm') {
      return <Thunderstorm style={style} />
    } else if (icon === 'partly-cloudy-day' || icon === 'partly-cloudy-night') {
      return <SunClouds style={style} />
    } else if (icon === 'cloudy') {
      return <Clouds style={style} />
    }

    return null
  }

  return (
    <DataSource url="/weather" params={[{ lon }, { lat }, { duration: 6 }]}>
      {({ data }) => {
        if (!data?.summary) {
          return t('No weather data available')
        }
        return (
          <Card grow flex style={{ background: '#376DF4' }}>
            {Object.keys(data?.summary).map((key, idx) => {
              const element = data?.summary[key]
              return (
                <Container key={idx} flex style={{ alignItems: 'start' }}>
                  <Box
                    nowidth
                    nopadding
                    right
                    color="#FFFFFF"
                    style={{ opacity: '0.5' }}
                  />
                  <Container style={{ alignItems: 'center', padding: '10px' }}>
                    <h4 style={{ color: '#FFFFFF' }}>
                      {idx === 0 ? t('Today') : formatDate(key)}
                    </h4>
                    {handleWeatherConditionAsset(element.icon)}
                    <h5
                      style={{ color: '#FFFFFF', opacity: '0.5' }}
                    >{`${element.min_temperature}°C - ${element.max_temperature}°C`}</h5>
                  </Container>
                </Container>
              )
            })}
          </Card>
        )
      }}
    </DataSource>
  )
}

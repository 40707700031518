import { register } from 'system/container'

import { BackDeliveryListPage } from './list'
import { PickupBackDeliveryViewPage } from './pickup'
import { BackDeliveryConfirmViewPage } from './confirm'
import { BackDeliveryViewPage } from './details'
import { HandoverBackDeliveryViewPage } from './handover'
import { CreateBackDeliveryAdHocViewPage } from './adhoc/add'

register({
  render: () => <BackDeliveryConfirmViewPage />,
  title: 'Back delivery',
  path: '/yard/retour/deliveries/:id/confirm',
})

register({
  render: () => <BackDeliveryViewPage />,
  title: 'Details',
  path: '/yard/retour/deliveries/:id',
})

register({
  render: () => <HandoverBackDeliveryViewPage />,
  title: 'Back delivery',
  path: '/yard/retour/deliveries/:id/handover',
})

register({
  menu: true,
  render: () => <BackDeliveryListPage />,
  title: 'Back deliveries',
  path: '/yard/retour/deliveries',
})

register({
  render: () => <PickupBackDeliveryViewPage />,
  title: 'Back delivery',
  path: '/yard/retour/deliveries/:id/pickup',
})

register({
  render: () => <CreateBackDeliveryAdHocViewPage />,
  title: 'Ad Hoc Back Delivery',
  path: '/yard/retour/deliveries/adhoc',
})

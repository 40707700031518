import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useState, useEffect, useRef } from 'react'

import Logo from 'assets/system-logo.svg'

import { usePage } from '../page/context'
import { getModules } from '../container'

import styles from './menu.module.css'

export const SystemMenu = ({ style }) => {
  const [selectedModule, setSelectedModule] = useState(null)
  const [open, setOpen] = useState(false)
  const currentPage = usePage()
  const currentModule = currentPage?.getModule()
  const menuToggle = useRef()
  useEffect(() => {
    menuToggle.current.checked = open
  }, [open])

  return (
    <div style={style}>
      <div className={styles.sideMenu}>
        <div className={styles.menu}>
          <input
            ref={menuToggle}
            type="checkbox"
            id="menu__toggle"
            className={styles.menu__toggle}
            onClick={() => setOpen(!open)}
          />
          <label className={styles.menu__btn} htmlFor="menu__toggle">
            <span></span>
          </label>
          <Logo />
        </div>
        <MainMenu
          // Get list of all modules to make module icon list.
          routes={getModules()}
          onModuleSelect={(m) => {
            setOpen(true)
            setSelectedModule(m)
          }}
          selectedModule={selectedModule}
          visible={open}
        />
      </div>
      <div
        className={styles.right}
        style={{
          left: open ? '60px' : '-200px',
          display: open ? 'inherit' : 'none',
        }}
        onMouseLeave={() => {
          // Return focus to the selected module
          setSelectedModule(currentModule)
          setOpen(false)
        }}
      >
        <SubMenu selectedModule={selectedModule} />
      </div>
      <div
        className={styles.cover}
        style={{ display: open ? 'block' : 'none' }}
        onClick={() => setOpen(false)}
      />
    </div>
  )
}

const MainMenu = ({ routes, onModuleSelect, selectedModule, visible }) => {
  const currentPage = usePage()
  const { t } = useTranslation()
  const topModules = []
  const bottomModules = []

  // For each top-level module generate a mainmenu entry
  routes
    .sort((a, b) => a.priority - b.priority)
    .forEach((m, idx) => {
      let active = selectedModule?.path
        ? selectedModule.path?.startsWith(m.path)
        : currentPage?.path.startsWith(m.path)
      // Create the DOM elements separately in order to avoid
      // duplication
      let el = (
        <div
          className={active ? styles.mainMenuItemActive : styles.mainMenuItem}
          key={idx}
          onMouseOver={(e) => {
            onModuleSelect(m)
            e.stopPropagation()
          }}
          onTouchStart={(e) => {
            onModuleSelect(m)
            e.stopPropagation()
          }}
        >
          {m.icon}
          <small className={styles.mainMenuItemText}>{t(m.title)}</small>
        </div>
      )
      // Divide elements into top and bottom groups
      m.bottom ? bottomModules.push(el) : topModules.push(el)
    })

  let optionals = {}
  if (visible) {
    // This line overrides default styles. By default screen size
    // is taken into account to show/hide modules (no show on mobile)
    // this overrides that if the toggle is pressed explicitely.
    // Empty optionals means CSS takes precedense.
    optionals = { style: { display: 'inherit' } }
  }
  return (
    <div className={styles.mainMenu} {...optionals}>
      <div style={{ height: '100%', borderBottom: '1px solid #5e8af6' }}>
        {topModules}
      </div>
      {bottomModules}
    </div>
  )
}

// The second and their level menus for any mainmenu entry
const SubMenu = ({ selectedModule }) => {
  const { t } = useTranslation()
  const page = usePage()

  if (!selectedModule) {
    return null
  }
  // TODO: This is yet a WIP, trieNode should not be exposed here
  // and this should be done in a function outside of hier
  // Filter out everything that has no menu and sort by priority
  const topLevelEntries = Object.values(selectedModule.trieNode.children)
    .filter((c) => c.data && !c.key.includes(':') && c.data.menu)
    .map((child) => child.data)
  return (
    <>
      {selectedModule?.render ? (
        <Link to={selectedModule.path} className={styles.subMenuTitle}>
          {selectedModule?.icon}
          <h4>{t(selectedModule?.title)}</h4>
        </Link>
      ) : (
        <div className={styles.subMenuTitle} style={{ cursor: 'default' }}>
          {selectedModule?.icon}
          <h4>{t(selectedModule?.title)}</h4>
        </div>
      )}

      <div
        className={styles.menuItemListContainer}
        style={{
          justifyContent: selectedModule?.getModule()?.bottom
            ? 'flex-end'
            : 'flex-start',
        }}
      >
        {topLevelEntries
          // TODO: this should be simplified and be moved out of hier
          .sort((a, b) => (t(a.title) < t(b.title) ? -1 : 1))
          .sort((a, b) => b.priority - a.priority)
          .map((p, idx) => {
            const subLevelEntries = Object.values(p.trieNode.children)
              .filter((c) => c.data && !c.key.includes(':') && c.data.menu)
              .map((child) => child.data)
            return (
              <div className={styles.subMenuItem} key={idx}>
                <Link className={styles.pageLink} to={p.path}>
                  <SubMenuItem
                    title={t(p.title)}
                    active={p.path === page?.path}
                  />
                </Link>
                {subLevelEntries
                  // TODO: dito
                  .sort((a, b) => (t(a.title) < t(b.title) ? -1 : 1))
                  .sort((a, b) => b.priority - a.priority)
                  .map((c, idx) => (
                    <Link className={styles.pageLink} key={idx} to={c.path}>
                      <SubMenuItem
                        submenu
                        title={t(c.title)}
                        active={page?.menu && c.path === page?.path}
                      />
                    </Link>
                  ))}
              </div>
            )
          })}
      </div>
    </>
  )
}

// A single submenu entry with icon and text
const SubMenuItem = ({ title, submenu, active, onClick }) => (
  <div className={styles.menuItemContainer} onClick={onClick}>
    <h4
      className={styles.menuItemText}
      style={{
        paddingLeft: submenu ? '20px' : '10px',
        color: active ? '#376DF4' : submenu ? '#8B8B8B' : '#303030',
      }}
    >
      {title}
    </h4>
  </div>
)

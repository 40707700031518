import { Container } from './container'
import { Column } from './grid'
import { Avatar } from './avatar'
import { useForm } from './form/context'

export const MemberSelect = ({
  contact,
  onSelectChange,
  selectedMember,
  disabled,
}) => {
  const { values } = useForm()

  return (
    <Container
      flex
      gap="10px"
      onClick={
        disabled
          ? null
          : () => {
              onSelectChange(contact)
              values.set('member_id', contact.id)
            }
      }
      selected={selectedMember?.id === contact.id}
    >
      <Container flex gap="10px" style={{ alignItems: 'center' }}>
        <Avatar
          text={contact.system_user.fullname}
          imgSrc={contact.system_user.photo}
          size="60px"
        />
        <Column>
          <h3>{contact.system_user.fullname}</h3>
          <h4>{contact.job_title}</h4>
          <h5>{contact.system_user?.email}</h5>
          <h5>{contact.system_user?.phone_number}</h5>
        </Column>
      </Container>
    </Container>
  )
}

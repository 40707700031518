const _temp0 = require("../../../disposition/requests/change/module.js");
const _temp1 = require("../../../disposition/requests/new/module.js");


const _temp2 = require("../../../logistics/planning/transport/module.js");
const _temp3 = require("../../../logistics/planning/trip/module.js");


const _temp4 = require("../../../master-data/contacts/absence/module.js");
const _temp5 = require("../../../master-data/contacts/presents/module.js");
const _temp6 = require("../../../master-data/contacts/qualifications/module.js");
const _temp7 = require("../../../master-data/contacts/teams/module.js");
const _temp8 = require("../../../master-data/contacts/view/module.js");

const _temp9 = require("../../../master-data/equipments/consumables/module.js");
const _temp10 = require("../../../master-data/equipments/formworks/module.js");
const _temp11 = require("../../../master-data/equipments/large-scale-equipment/module.js");
const _temp12 = require("../../../master-data/equipments/small-scale-equipment/module.js");

const _temp13 = require("../../../master-data/pim/category/module.js");


const _temp14 = require("../../../yard/commission/deliveries/module.js");

const _temp15 = require("../../../yard/retour/deliveries/module.js");


module.exports = {
  "disposition":   {
    "requests":     {
      "change": _temp0,
      "new": _temp1
    }
  },
  "logistics":   {
    "planning":     {
      "transport": _temp2,
      "trip": _temp3
    }
  },
  "master-data":   {
    "contacts":     {
      "absence": _temp4,
      "presents": _temp5,
      "qualifications": _temp6,
      "teams": _temp7,
      "view": _temp8
    },
    "equipments":     {
      "consumables": _temp9,
      "formworks": _temp10,
      "large-scale-equipment": _temp11,
      "small-scale-equipment": _temp12
    },
    "pim":     {
      "category": _temp13
    }
  },
  "yard":   {
    "commission":     {
      "deliveries": _temp14
    },
    "retour":     {
      "deliveries": _temp15
    }
  }
}
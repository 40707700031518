import React from 'react'

import { NumericField, SelectField, TextField } from 'common/widgets/form/field'
import { SimpleFormAction } from 'common/widgets/form/utils'
import { Row, Column } from 'common/widgets/grid'
import { SectionView } from 'common/widgets/view'
import {
  EquipmentCategoryFreeField,
  EquipmentMaincategoryFreeField,
  EquipmentSubcategoryFreeField,
} from 'modules/master-data/equipments/widgets/category'

import { AccountingGroupField } from '../widgets/accounting-group'
import { QuantityUnitField } from '../widgets/quantity-unit'
import { OrderableField } from '../widgets/orderable'
import { ItemTypeEnum } from '../utils'

import { StockField, MinStockField, MaxStockField } from './widgets/stockinputs'
import { ResponsibleOrdererField } from './widgets/responsible-orderer'

export const ConsumablesFormContent = ({
  onAdd,
  onUpdate,
  onRemove,
  onSuccess,
}) => {
  return (
    <>
      <Row>
        <Column>
          <SectionView title="Master data">
            <TextField label="Label" name="name" mandatory />
            <TextField label="Inventory number" name="inventory_number" />
            <EquipmentMaincategoryFreeField
              label="Main Group"
              mandatory
              params={[{ type: ItemTypeEnum.CONSUMABLE }]}
            />
            <EquipmentCategoryFreeField
              label="Group"
              mandatory
              dependable={!onAdd}
              params={[{ type: ItemTypeEnum.CONSUMABLE }]}
            />
            <EquipmentSubcategoryFreeField
              label="Subgroup"
              dependable={!onAdd}
              params={[{ type: ItemTypeEnum.CONSUMABLE }]}
            />
            <SelectField
              label="Commission type"
              name="commission_type"
              mandatory
              items={[
                { key: 'COMMISSIONER', title: 'COMMISSIONER' },
                { key: 'SELF', title: 'SELF' },
              ]}
            />
            <TextField label="Packaging unit" name="packaging_unit" />
            <QuantityUnitField mandatory label="Quantity unit" />
            <StockField label="Stock level" name="stock_level" />
            <MinStockField label="Min stock level" name="min_stock" />
            <MaxStockField label="Max stock level" name="max_stock" />
            <TextField label="Storage" name="storage" />
            <TextField label="Supplier" name="supplier" />
            <ResponsibleOrdererField label="Responsible orderer" />
            <NumericField
              label="Internal billing rate"
              name="internal_billing_rate"
              decimalScale={2}
              mandatory
            />
            <OrderableField label="Orderable" mandatory selected={false} />
            <AccountingGroupField
              label="Accounting group"
              params={[{ type: 'CONSUMABLE' }]}
            />
          </SectionView>
        </Column>
      </Row>
      <SimpleFormAction
        onAdd={onAdd}
        onUpdate={onUpdate}
        onRemove={onRemove}
        onSuccess={onSuccess}
      />
    </>
  )
}

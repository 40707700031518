import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import CanvasDraw from 'react-canvas-draw'

import { Avatar } from 'common/widgets/avatar'
import { dateToISOString } from 'common/utils/format'
import { DropDown } from 'common/widgets/dropdown'
import { useForm } from 'common/widgets/form/context'
import { CommentView } from 'common/widgets/view'
import { forceValue, validatePhoneNumber } from 'common/widgets/form/validate'
import { toDate, isPast, isFuture } from 'common/utils/date'

import { FreeChoose } from '../free-choose'
import {
  DateInput,
  DateRangeInput,
  Input,
  NumericInput,
  PasswordInput,
  TextAreaInput,
  FileInput,
} from '../input'
import { Select } from '../select'
import { ColorPickerInput } from '../color'
import { CheckBox } from '../checkbox'
import { CancelIconButton } from '../button'
import { RecursiveTree } from '../tree'

import { forceEmail } from './validate'

/**
 * Renders a field element holding the field error and the input.
 *
 * @param {string} name Name of the field.
 * @param {ReactElement} children
 * @returns ReactElement
 */
const Field = ({ name, help, warning, children }) => {
  const { errors } = useForm()
  const { t } = useTranslation()
  const error = errors.get(name)

  return (
    <>
      {children}
      {help ? (
        <CommentView comment={help} />
      ) : (
        error && <h5 className="error">{t(error)}</h5>
      )}
      {warning && <CommentView warning comment={warning} />}
    </>
  )
}

/**
 * Wraps field and form operation to abstract changes related to form logic.
 *
 * @returns ReactElement
 */
export const FieldWrapper = ({
  name,
  label,
  children,
  defaultValue,
  validate,
  normalize,
  readonly,
  format,
  help,
  warning,
  mandatory,
  // If set whitespace would be dropped from both ends. Default is true.
  trim = true,
  // won't add the value to dirty context
  ignore,
  // to circumvent some buggy child components such as reactdatepicker
  // we have to suppress labelClicks, but not for all components. Hence
  // this unfortunate prop
  labelOnClick,
  nullable,
  // extra styles for the element which wraps the children
  style = {},
  ...rest
}) => {
  const { values, readOnly } = useForm()
  const { t } = useTranslation()

  // function to consider both the provided validator and the mandatory flag
  const _validator = (value, ctxValues) => {
    let error = null
    if (mandatory) {
      error = forceValue(value)
    }
    if (!error && validate) {
      // context values should come from the caller
      error = validate(value, ctxValues)
    }
    return error
  }

  if (trim) {
    let normalizeFn = normalize
    normalize = function format(value) {
      if (typeof value == 'string') {
        value = value.trim()
      }
      if (normalizeFn) {
        return normalizeFn(value)
      } else {
        return value
      }
    }
  }

  /**
   * Handles setting value in forms.
   *
   * @param {string} value text value
   */
  const setValue = (value) => {
    name &&
      !readonly &&
      values.set(name, value, {
        validate: _validator,
        format,
        normalize,
        ignore,
      })
  }

  /**
   * Extracts value from form data and handles if there is no value.
   *
   * @param {string} alt_name alternate field to fetch its value.
   * This is to compensate for the behaviour of the file system.
   * User uploads a field such as "photo", but the server returns
   * "photo_id" and the same field wouldn't find the value set by
   * itself. By passing an alternate name we can partially fix that,
   * by making components such as FileField more complex.
   * @returns string
   */
  const getValue = (alt_name, raw) => {
    let currentValue = values.get(alt_name ?? name)
    if (raw) {
      return currentValue
    }
    if (Number.isInteger(currentValue)) {
      return currentValue
    } else {
      return currentValue || ''
    }
  }

  useEffect(() => {
    // Only set the default value if the value is undefined, all other falsy
    // values such as 0, '', empty array, and null are valid JSON values.
    if (values.get(name) === undefined) {
      setValue(defaultValue)
    }
  }, [name, defaultValue])
  return (
    <label onClick={labelOnClick}>
      {label && (
        <>
          {`${t(label)}${mandatory && !readOnly ? '*' : ''}`} <br />
        </>
      )}
      <Field name={name} help={help} warning={warning}>
        <span style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
          <span style={{ width: '100%', ...style }}>
            {children(getValue, setValue, values)}
          </span>
          {nullable && values.get(name) !== null && (
            <CancelIconButton
              onClick={(e) => {
                e.preventDefault()
                setValue(null)
              }}
            />
          )}
        </span>
      </Field>
    </label>
  )
}

export const TextAreaField = ({ disabled, onChange, ...rest }) => {
  const { readOnly } = useForm()
  return (
    <FieldWrapper {...rest}>
      {(getValue, setValue) =>
        readOnly ? (
          <pre>{getValue()}</pre>
        ) : (
          <TextAreaInput
            {...rest}
            onChange={(e) => {
              setValue(e.target.value)
              if (onChange) {
                onChange(e.target.value)
              }
            }}
            value={getValue()}
            background={disabled ? '#E2E2E2' : '#fcfcfc'}
            disabled={disabled}
          />
        )
      }
    </FieldWrapper>
  )
}

export const TextField = ({ disabled, onChange, ...rest }) => {
  const { readOnly } = useForm()
  return (
    <FieldWrapper {...rest}>
      {(getValue, setValue) =>
        readOnly ? (
          <p>{getValue()}</p>
        ) : (
          <Input
            {...rest}
            onChange={(e) => {
              setValue(e.target.value)
              if (onChange) {
                onChange(e.target.value)
              }
            }}
            value={getValue()}
            background={disabled ? '#E2E2E2' : '#fcfcfc'}
            disabled={disabled}
          />
        )
      }
    </FieldWrapper>
  )
}

/**
 * Renders a text field.
 *
 * @param {string} name Name of the field.
 * @returns ReactElement
 */
export const ColorPickerField = ({ preview, ...rest }) => (
  <FieldWrapper {...rest}>
    {(getValue, setValue) => (
      <ColorPickerInput value={getValue()} onChange={setValue} {...rest} />
    )}
  </FieldWrapper>
)

/**
 * Renders a password field.
 *
 * @param {string} name Name of the field.
 * @param {string} defaultValue Default value of the input.
 * @param {function} validate Function for validating the input value.
 * @param {function} format Function for formating the input value before changing.
 * @param {function} normalize Function for processing the input value before hand over to onSubmit function.
 * @returns ReactElement
 */
export const PasswordField = ({ format, ...rest }) => {
  return (
    <FieldWrapper format={format} {...rest}>
      {(getValue, setValue) => (
        <PasswordInput
          onChange={(e) => setValue(e.target.value)}
          value={getValue()}
          {...rest}
        />
      )}
    </FieldWrapper>
  )
}

/**
 * New password field with validation and strength check.
 */
export const NewPasswordField = ({ name, mandatory, ...rest }) => {
  const { t } = useTranslation(['security'])
  const { values } = useForm()
  const [passError, setPassError] = useState(null)
  const [p1, setP1] = useState(null)
  const [p2, setP2] = useState(null)

  const lower = new RegExp('[a-z]')
  const caps = new RegExp('[A-Z]')
  const specials = new RegExp('\\W|_')

  const validatePassword = () => {
    if (!p1 && !p2) {
      if (mandatory) {
        setPassError(t('security.register.strongpass'))
      } else {
        setPassError(null)
      }
      return false
    }

    if (!(p1 || p2)) {
      return false
    } else {
      setPassError(null)
    }

    if ((p1 || p2) && p1 !== p2) {
      setPassError(t('security.register.passmismatch'))
      return false
    }

    if ((p1 || p2).length < 8) {
      setPassError(t('security.register.shortpass'))
      return false
    }

    if (!lower.test(p1 || p2)) {
      setPassError(t('security.register.passlower'))
      return false
    }

    if (!caps.test(p1 || p2)) {
      setPassError(t('security.register.passcaps'))
      return false
    }
    if (!specials.test(p1 || p2)) {
      setPassError(t('security.register.passchars'))
      return false
    }
    return true
  }

  useEffect(() => {
    if (validatePassword()) {
      values.set(name, p1)
    } else if (values.get(name)) {
      values.unset(name)
    }
  }, [p1, p2])

  return (
    <FieldWrapper
      name={name}
      mandatory={mandatory}
      style={{ display: 'grid', gap: '5px' }}
      {...rest}
    >
      {(getValue, setValue) => (
        <>
          <PasswordInput
            name="password1"
            autoComplete="new-password"
            placeholder="Enter password"
            onChange={(e) => setP1(e.target.value)}
          />
          <PasswordInput
            name="password2"
            autoComplete="new-password"
            placeholder="Repeat password"
            onChange={(e) => setP2(e.target.value)}
          />
          {passError && <CommentView color="red" comment={t(passError)} />}
        </>
      )}
    </FieldWrapper>
  )
}

/**
 * Renders an email field.
 *
 * @param {string} name Name of the field.
 * @param {string} defaultValue Default value of the input.
 * @param {function} validate Function for validating the input value.
 * @param {function} format Function for formating the input value before changing.
 * @param {function} normalize Function for processing the input value before hand over to onSubmit function.
 * @returns ReactElement
 */
export const EmailField = ({ ...rest }) => {
  return (
    <FieldWrapper validate={forceEmail} {...rest}>
      {(getValue, setValue) => (
        <Input
          type="email"
          onChange={(e) => setValue(e.target.value)}
          value={getValue()}
          {...rest}
        />
      )}
    </FieldWrapper>
  )
}

/**
 * Renders a numeric field.
 *
 * @returns ReactElement
 */
export const NumericField = ({
  disabled,
  onChange,
  nullable = true,
  defaultValue,
  ...rest
}) => (
  <FieldWrapper defaultValue={defaultValue} {...rest}>
    {(getValue, setValue) => (
      <NumericInput
        onChange={(e) => {
          let number = e.target.valueAsNumber
          if (Number.isInteger(number)) {
            setValue(number)
          } else {
            number = nullable ? null : 0
            setValue(number)
          }
          onChange && onChange(number)
        }}
        value={getValue()}
        disabled={disabled}
        {...rest}
      />
    )}
  </FieldWrapper>
)

/**
 * Renders a date field.
 *
 * @param {string} name Name of the field.
 * @param {Date} defaultValue Default value of the input.
 * @param {function} validate Function for validating the input value.
 * @param {function} format Function for formating the input value before changing.
 * @param {function} normalize Function for processing the input value before hand over to onSubmit function.
 * @returns ReactElement
 */
export const DateField = ({
  name,
  // Not mandatory field by default
  mandatory = false,
  normalize = dateToISOString,
  // Forbid selecting past dates, off by default
  forbidPast = false,
  // Filter a range of dates
  filterDate,
  // Help text
  help,
  validate,
  // Forbid selecting future dates, off by default
  forbidFuture = false,
  ...rest
}) => {
  // Fileds are most likey used in Forms which have context.
  // Passing context by default to field functions simplifies
  // our codebase a lot and decreases its verbosity.
  const ctx = useForm()

  const forbidPastDates = (date) => {
    if (isPast(date)) {
      return 'Date is in the past'
    }
    return null
  }

  const forbidFutureDates = (date) => {
    if (isFuture(date)) {
      return 'Date is in the future'
    }
    return null
  }

  // Wrap the filterDate function and add form context to it
  let filterDateWrapper = null
  if (filterDate) {
    filterDateWrapper = (date) => filterDate(date, ctx)
  }

  // Sometimes we need the form context in our help message,
  // in such cases we can use a function as help text and the
  // result of that function would be used as help text.
  if (typeof help == 'function') {
    help = help(ctx)
  }

  return (
    <FieldWrapper
      name={name}
      validate={(date) =>
        (mandatory && forceValue(date)) ||
        (forbidPast && forbidPastDates(date)) ||
        (forbidFuture && forbidFutureDates(date)) ||
        (validate && validate(date)) ||
        null
      }
      normalize={normalize}
      mandatory={mandatory}
      // the onClick event is a hack to make reactdatepicker work. See the bug
      // report from 2017:
      // https://github.com/Hacker0x01/react-datepicker/issues/1012#issuecomment-344546012
      labelOnClick={(e) => e.preventDefault()}
      {...rest}
    >
      {(getValue, setValue) => (
        <DateInput
          value={toDate(getValue(name))}
          onChange={setValue}
          filterDate={filterDateWrapper}
          help={help}
          {...rest}
        />
      )}
    </FieldWrapper>
  )
}

/**
 * Renders a date field.
 *
 * @param {string} name Name of the field.
 * @param {Time} defaultValue Default value of the input.
 * @param {function} validate Function for validating the input value.
 * @param {function} format Function for formating the input value before changing.
 * @param {function} normalize Function for processing the input value before hand over to onSubmit function.
 * @returns ReactElement
 */
export const TimeField = ({ name, help, validate, ...rest }) => (
  <FieldWrapper name={name} validate={validate} help={help} {...rest}>
    {(getValue, setValue) => (
      <DateInput value={toDate(getValue(name))} onChange={setValue} {...rest} />
    )}
  </FieldWrapper>
)

/**
 * Renders a date range field.
 */
export const DateRangeField = ({
  name = 'date_range_field',
  mandatory = false,
  nameStart = 'booking_start',
  nameEnd = 'booking_end',
  onChange,
  excludeTime = false,
  ...rest
}) => {
  const form = useForm()

  // A single validator to enforce validating start/end dates
  const dateRangeValidator = (value) => {
    let validatedStart = mandatory
      ? forceValue(value?.startDate ?? form.values.get(nameStart))
      : null
    let validatedEnd = mandatory
      ? forceValue(value?.endDate ?? form.values.get(nameEnd))
      : null

    if (validatedStart && validatedEnd) {
      return 'Required'
    } else if (validatedStart) {
      return `${validatedStart}}`
    } else if (validatedEnd) {
      return `${validatedEnd}`
    }
    return null
  }

  // Add the expected structure to the form state
  // Notice that since our dateToISOString function ignores time we
  // are not considering timezone differences here. It would be the
  // wrong place to do that anyway.
  const prepareFormValues = (startDate, endDate) => {
    return {
      [name]: {
        value: { startDate, endDate },
        validate: dateRangeValidator,
        //mandatory,
      },
      [nameStart]: {
        value: startDate,
        normalize: dateToISOString,
      },
      [nameEnd]: {
        value: endDate,
        normalize: dateToISOString,
      },
    }
  }

  return (
    <FieldWrapper name={name} validate={dateRangeValidator} {...rest}>
      {(getValue, setValue, values) => {
        return (
          <DateRangeInput
            startDate={values.get(nameStart)}
            endDate={values.get(nameEnd)}
            excludeTime={excludeTime}
            onChange={(dates) => {
              const [start, end] = dates
              values.setMany(prepareFormValues(start, end))
              onChange && onChange(form, dates)
            }}
            {...rest}
          />
        )
      }}
    </FieldWrapper>
  )
}

/**
 * Renders a drop down field element.
 *
 * @param {string} title drop down title
 * @param {function} onSelectChange selected item key
 * @returns ReactElement
 */
export const DropDownField = ({
  title,
  items,
  selectedIndex,
  onSelectChange,
  ...rest
}) => {
  const { readOnly } = useForm()
  return (
    <FieldWrapper {...rest}>
      {(getValue, setValue) => {
        const value = getValue()
        return (
          <DropDown
            title={title}
            onSelectChange={(option) => {
              if (option === null) {
                setValue(null)
              } else {
                setValue(option.key)
              }
              if (onSelectChange) {
                onSelectChange(option?.key ?? null)
              }
            }}
            items={items}
            {...rest}
            selectedIndex={
              Number.isInteger(selectedIndex)
                ? selectedIndex
                : value !== undefined
                ? items?.findIndex((i) => i.key === value)
                : undefined
            }
            disabled={readOnly}
          />
        )
      }}
    </FieldWrapper>
  )
}

/**
 * Renders a text input element with a drop down for suggestions.
 *
 * @param {string} name Name of the field.
 * @param {List} items items for dropdown field
 * @param {string} defaultValue Default value of the input.
 * @param {function} validate Function for validating the input value.
 * @param {function} format Function for formating the input value before changing.
 * @param {function} normalize Function for processing the input value before hand over to onSubmit function.
 * @param {int} maxItems Maximum number of items which are shown on the dropdown list
 * @returns ReactElement
 */
export const FreeChooseField = ({ items, maxItems, format, ...rest }) => (
  <FieldWrapper format={format} {...rest}>
    {(getValue, setValue) => (
      <FreeChoose
        items={items}
        value={getValue()}
        onChange={setValue}
        maxItems={maxItems}
        {...rest}
      />
    )}
  </FieldWrapper>
)

/**
 * Capsulates a select widget inside form field.
 *
 * @param {string} name Name of the field.
 * @param {List} items items for dropdown field
 * @param {string} defaultValue Default value of the input.
 * @param {function} validate Function for validating the input value.
 * @param {function} format Function for formating the input value before changing.
 * @param {function} normalize Function for processing the input value before hand over to onSubmit function.
 * @returns ReactElement
 */
export const SelectField = ({ items, disabled, onSelectChange, ...rest }) => {
  return (
    <FieldWrapper {...rest}>
      {(getValue, setValue) => (
        <Select
          selected={getValue()}
          items={items}
          onSelectChange={(item) => {
            setValue(item.key)
            if (onSelectChange) {
              onSelectChange(item)
            }
          }}
          disabled={disabled}
        />
      )}
    </FieldWrapper>
  )
}

/**
 * Render a checkbox field.
 *
 * @param {string} name Name of the field.
 * @param {JSX.Element} children Children elements for the checkbox such as a label.
 * @param {object} rest Other parameters to be passed to the underlying controls.
 * @returns JSX.Element
 */
export const CheckBoxField = ({ name, children, i18nScope, ...rest }) => {
  const { t } = useTranslation([i18nScope])
  return (
    <FieldWrapper name={name} {...rest}>
      {(getValue, setValue) => (
        <CheckBox
          onChange={(checked) => setValue(checked)}
          value={getValue()}
          {...rest}
        >
          {typeof children == 'string' ? t(children) : children}
        </CheckBox>
      )}
    </FieldWrapper>
  )
}

/**
 * Renders a signature field
 *
 * @param {string} name name of the signature field
 * @param {any} handler handler object
 *
 * @returns ReactElement
 */
export const SignatureField = ({ name, handler, ...rest }) => {
  const [canvas, setCanvas] = useState(null)

  return (
    <FieldWrapper name={name} {...rest}>
      {(_, setValue) => {
        handler.clear = () => {
          canvas?.clear()
          setValue(null)
        }

        return (
          <CanvasDraw
            ref={(canvas) => setCanvas(canvas)}
            onChange={() => {
              setValue(
                canvas.getDataURL().replace('data:image/png;base64,', '')
              )
            }}
          />
        )
      }}
    </FieldWrapper>
  )
}

export const PhoneNumberField = ({ ...rest }) => {
  return <TextField validate={validatePhoneNumber} {...rest} />
}

export const AvatarField = ({ ...rest }) => {
  return (
    <FileField
      name="avatar"
      label="Avatar"
      accept="image/png, image/jpeg"
      preview
      {...rest}
    />
  )
}

/**
 * Standard HTML file input wrapped as a field.
 *
 * This field has intentionally no `setValue', because currently I
 * don't know how to do it (or it might not be intended for this
 * input type).
 **/
export const FileField = ({
  name,
  accept,
  preview,
  // idField is used when file should be fetched separately
  idField = null,
  previewSize = null,
  ...rest
}) => {
  const { readOnly } = useForm()
  const [previewData, setPreviewData] = useState()
  if (!accept?.includes('image')) {
    preview = false
  }
  return (
    <FieldWrapper name={name} style={{ display: 'grid', gap: '5px' }} {...rest}>
      {(getValue, setValue) => {
        const fileData = getValue(name)
        let previewWidget = null
        if (previewData) {
          previewWidget = <Avatar imgSrc={previewData} size={previewSize} />
        } else if (fileData?.path) {
          previewWidget = <Avatar imgSrc={fileData.path} size={previewSize} />
        } else if (idField) {
          previewWidget = <Avatar id={getValue(idField)} size={previewSize} />
        }
        if (!getValue(name)) {
          previewWidget = null
        }
        return (
          <>
            {preview && previewWidget}
            <FileInput
              onChange={async (e) => {
                const files = []
                for (const f of e.target.files) {
                  files.push({
                    name: f.name,
                    size: f.size,
                    type: f.type,
                    lastModified: f.lastModified,
                  })
                  if (preview) {
                    const reader = new FileReader()
                    reader.addEventListener(
                      'load',
                      () => {
                        // convert image file to base64 string
                        setPreviewData(reader.result)
                      },
                      false
                    )
                    reader.readAsDataURL(f)
                  }
                }
                setValue(e.target.files)
              }}
              accept={accept}
              disabled={readOnly}
              value={getValue(name)}
              {...rest}
            />
          </>
        )
      }}
    </FieldWrapper>
  )
}

export const URLField = ({ ...rest }) => {
  const ref = useRef()
  return (
    <FieldWrapper
      validate={() => {
        if (!ref.current.checkValidity()) {
          return ref.current.validationMessage
        }
      }}
      {...rest}
    >
      {(getValue, setValue) => (
        <Input
          ref={ref}
          type="url"
          onChange={(e) => setValue(e.target.value)}
          value={getValue()}
          {...rest}
        />
      )}
    </FieldWrapper>
  )
}

export const RecursiveTreeField = ({ name, tree, selectedNodes, ...rest }) => {
  const { readOnly } = useForm()
  return (
    <FieldWrapper name={name} {...rest}>
      {(getValue, setValue) => {
        return (
          <RecursiveTree
            tree={getValue(name)}
            selectedNodes={selectedNodes}
            showSelectedOnly={readOnly}
            {...rest}
          />
        )
      }}
    </FieldWrapper>
  )
}

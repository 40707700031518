import React from 'react'
import styled, { css } from 'styled-components'

import { stringToColor } from 'common/utils/color'
import { RemoteImage } from 'common/widgets/image'

const DEFAULT_AVATAR_SIZE = '72px'

/**
 * Shortens the given string name.
 *
 * @param {string} value name or anything
 * @returns string
 */
const abbreviateText = (value) => {
  if (value?.length <= 3) {
    return value
  }
  // Removing all spaces. It will be used if the string has only special characters.
  let fallbackText = value?.replace(/ +/g, '')
  // Keeping only alphabets (including German), numbers and space characters.
  let text = value?.toLowerCase().replace(/[^a-zA-Z0-9 äöüÄÖÜß]+/g, '')
  // Reducing consecutive spaces into a single space character.
  text = text?.replace(/  +/g, ' ')
  text =
    text?.length > 0 && text?.trim() != ''
      ? text
      : fallbackText?.length > 0
      ? fallbackText
      : '??'
  const texts = text
    .trim()
    .split(' ')
    .filter((e) => e.length > 0)

  const word1 = texts.length > 0 ? texts[0] : '??'
  const word2 =
    texts.length > 1 ? texts[1] : word1.length > 1 ? word1[1] : word1[0]
  return `${word1[0]}${word2[0]}`.toUpperCase()
}

export const Avatar = ({
  id,
  text = '-',
  imgSrc,
  mini,
  size,
  shorten = true,
  ...rest
}) => {
  if (id && imgSrc) {
    console.assert('Either "id" or "imgSrc" should be provided, not both.')
    return null
  }
  if (imgSrc && !imgSrc.startsWith('data') && !imgSrc.startsWith('/api')) {
    imgSrc = `/api/${imgSrc}`
  }
  return (
    <AvatarContainer
      background={id || imgSrc ? '' : stringToColor(text)}
      size={mini ? '32px' : size}
      {...rest}
    >
      {id && (
        <RemoteImage id={id} thumbnail>
          {(data) => <AvatarImage src={data} />}
        </RemoteImage>
      )}
      {imgSrc && <AvatarImage src={imgSrc} />}
      {!id && !imgSrc && (
        <StyledH1 mini={mini || parseInt(size) <= 32}>
          {shorten ? abbreviateText(text) : text}
        </StyledH1>
      )}
    </AvatarContainer>
  )
}

const AvatarImage = styled.img`
  flex: 1;
  margin: auto;
  display: block;
  max-width: ${(props) => props.size || DEFAULT_AVATAR_SIZE};
  max-height: ${(props) => props.size || DEFAULT_AVATAR_SIZE};
  width: auto;
  height: auto;
  border-radius: ${(props) => props.borderRadius || '5px'};
  object-fit: contain;
`

const StyledH1 = styled.h1`
  margin: auto;
  color: #ffffff;
  ${(props) =>
    props.mini &&
    css`
      font-size: 16px;
      line-height: 18px;
    `}
`

export const AvatarContainer = styled.div`
  background: ${(props) => props.background};
  border-radius: ${(props) => props.borderRadius || '5px'};
  display: flex;
  width: ${(props) => props.size || DEFAULT_AVATAR_SIZE};
  height: ${(props) => props.size || DEFAULT_AVATAR_SIZE};
  img {
    display: block;
    object-fit: contain;
    max-width: ${(props) => props.size || DEFAULT_AVATAR_SIZE};
    max-height: ${(props) => props.size || DEFAULT_AVATAR_SIZE};
    width: auto;
    height: auto;
  }
  align-items: center;
  flex: 0 0 auto;
  cursor: ${(props) =>
    props.onMouseUp || props.onClick ? 'pointer' : 'default'};
`

import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useService } from 'common/service/context'
import { CardView, FieldView } from 'common/widgets/view'
import { Column, Row } from 'common/widgets/grid'
import { Container } from 'common/widgets/container'
import { AddButton, AddIconButton, CancelButton } from 'common/widgets/button'
import { OverlayBody, OverlayFooter, OverlayForm } from 'common/widgets/overlay'

import { formatItemNameForOrder } from '../select/list'

import { OrderItemFields } from './form'

export const RelatedItemsView = ({ order, relateds, frequent }) => {
  const { t } = useTranslation(['orders'])
  const [selectedItem, setSelectedItem] = useState(null)
  const title = frequent ? 'Frequently ordered together' : 'Related items'

  return (
    <Container flex vertical gap="10px" padding="10px" title={title}>
      <ItemAddOverlay
        order={order}
        baseitem={selectedItem}
        onClose={() => setSelectedItem(null)}
      />
      {relateds?.map((item, i) => (
        <CardView padding="10px" repel key={i} flex>
          <Container>
            <p>{formatItemNameForOrder(item)}</p>
            <Row>
              <Column n={6} s={6}>
                <FieldView label="Type" value={t(item.type)} />
              </Column>
              <Column n={6} s={6}>
                <FieldView label="Main group" value={item.maincategory} />
              </Column>
              <Column n={6} s={6}>
                <FieldView label="Group" value={item.category} />
              </Column>
              <Column n={6} s={6}>
                <FieldView label="Subgroup" value={item.subcategory} />
              </Column>
            </Row>
          </Container>
          <Container flex align="start">
            <AddIconButton onClick={() => setSelectedItem(item)} />
          </Container>
        </CardView>
      ))}
    </Container>
  )
}

/**
 * Renders and overlay to add selected item to the order.
 * @param {any} item selected item
 * @param {Function} onClose callback function to close
 * @returns ReactElement
 */
const ItemAddOverlay = ({ order, baseitem, onClose }) => {
  const { t } = useTranslation(['orders'])
  const service = useService()

  /**
   * Calls a post call and creates a new ordered item.
   *
   * @param {any} data ordered item data
   * @returns
   */
  const handleAdd = async (data) => {
    const normalized = { ...data, item: baseitem.id }
    const [result, error] = await service.post(
      `/orders/${order.id}/items`,
      normalized
    )
    if (!error) {
      onClose()
    }
    return result
  }

  return (
    <OverlayForm
      title={t('Add item')}
      open={baseitem !== null}
      onClose={onClose}
    >
      <OverlayBody>
        <OrderItemFields baseitem={baseitem} />
      </OverlayBody>
      <OverlayFooter repel>
        <AddButton
          default
          onClick={async (e, ctx) => await handleAdd(ctx.values.json)}
          disabled={(ctx) => !ctx.ready}
        />
        <CancelButton onClick={onClose} />
      </OverlayFooter>
    </OverlayForm>
  )
}

import { useParams } from 'react-router-dom'

import { register } from 'system/container'
import { useService } from 'common/service/context'
import { BasketForm } from 'modules/orders/form/basket/view'
import { OrderableItemList } from 'modules/orders/form/select/list'
import { DataSource } from 'common/widgets/data-source'
import { useMediaQuery } from 'common/utils/hooks'

import { OrderedItemEditPage } from './items/edit'
import { OrderedItemAddPage } from './items/add'

const OrderViewPage = () => {
  const service = useService()
  // Extract id from url params
  const { id } = useParams()

  /**
   * Fetches the given order or cart if there is no order id provided.
   *
   * @returns any
   */
  const fetch = async () =>
    id ? await service.get(`orders/${id}`) : await service.get('orders/cart')

  return (
    <DataSource
      fetch={fetch}
      render={({ data, reload }) => <OrderPage order={data} reload={reload} />}
    />
  )
}

const OrderPage = ({ order, reload }) => {
  // Extract id from url params
  const { id } = useParams()
  const isRowBased = useMediaQuery('(max-width: 768px)')

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: isRowBased ? '1fr' : '3fr 2fr',
        gap: '0',
        padding: 0,
      }}
    >
      <BasketForm
        order={order}
        reload={reload}
        edit={id ? true : false}
        style={{
          position: isRowBased ? 'normal' : 'sticky',
          top: '0',
          alignSelf: 'start',
          padding: '10px',
        }}
      />
      <OrderableItemList
        order={order}
        style={{
          backgroundColor: 'white',
          padding: '10px',
        }}
      />
    </div>
  )
}

register({
  render: () => <OrderViewPage />,
  title: 'Edit',
  path: '/orders/:id/edit',
})

register({
  menu: true,
  // Component should have a different key when it is mounted
  // under multiple routes, otherwise it will not reload
  render: () => <OrderViewPage key="cart" />,
  title: 'Cart',
  path: '/orders/cart',
  priority: -1,
})

register({
  render: () => <OrderedItemEditPage />,
  title: 'Edit',
  path: '/orders/:orderId/edit/:orderedItemId',
})

register({
  render: () => <OrderedItemEditPage />,
  title: 'Edit',
  path: '/orders/cart/:orderId/edit/:orderedItemId',
})

register({
  render: () => <OrderedItemAddPage />,
  title: 'Article',
  path: '/orders/:orderId/add/:baseItemId',
})

register({
  render: () => <OrderedItemAddPage />,
  title: 'Article',
  path: '/orders/cart/:orderId/add/:baseItemId',
})

import { Calendar, ShoppingCart } from 'react-feather'
import { useTranslation } from 'react-i18next'

import { OverlayBody, OverlayFooter, OverlayForm } from 'common/widgets/overlay'
import { formatItemName } from 'modules/master-data/equipments/utils'
import { CommentView, GridFieldView } from 'common/widgets/view'
import { Container } from 'common/widgets/container'
import { Column, Row } from 'common/widgets/grid'
import {
  DeleteButton,
  BackButton,
  AddButton,
  SaveButton,
} from 'common/widgets/button'
import { formatProjectName } from 'modules/projects/utils'
import { DateField, NumericField, SelectField } from 'common/widgets/form/field'
import { useForm } from 'common/widgets/form/context'
import { toDate, differenceInDays } from 'common/utils/date'
import { sum } from 'common/utils/collection'
import { formatDate } from 'common/utils/format'

export const SourceOverlay = ({
  request,
  source,
  id,
  sources,
  data,
  onClose,
  onUpdate,
  onRemove,
  onAdd,
}) => {
  const { t } = useTranslation()
  // Calculates available amount for this source
  const available =
    source.amount -
    sum(
      sources
        .filter(
          (e) =>
            e.baseitem.id === source.baseitem.id &&
            e.source_project.id === source.project.id
        )
        .map((e) => parseInt(e.amount))
    )
  // Calculates overbooked amount
  const overdraft = request.amount - sum(sources.map((e) => parseInt(e.amount)))
  // Calculates remained amount from original amount
  const remained = Math.max(0, overdraft)
  return (
    <OverlayForm
      open={id !== null}
      onClose={onClose}
      data={data}
      title={onAdd ? 'Add source' : 'Edit'}
    >
      <OverlayBody>
        <Row>
          <Column n={12} s={12}>
            <h3>{formatItemName(source.baseitem)}</h3>
          </Column>
          <GridFieldView n={6} s={6} label="Source of supply">
            <h4>{formatProjectName(source.project)}</h4>
          </GridFieldView>
          <GridFieldView n={6} s={6} label="Available">
            <h4>{available}</h4>
          </GridFieldView>
          <GridFieldView n={6} s={6} label="Remained amount">
            {(ctx) => <h4>{remained - ctx.values.get('amount', 0)}</h4>}
          </GridFieldView>
          <GridFieldView n={6} s={6} label="Amount">
            <NumericField name="amount" mandatory />
          </GridFieldView>
          <GridFieldView n={6} s={6} label="Start*">
            <DateField
              name="start"
              mandatory
              filterDate={(date, ctx) => date <= toDate(ctx.values.get('end'))}
              help={(ctx) =>
                formatDate(request.booking_start) !==
                  formatDate(ctx.values.get('booking_start')) &&
                formatDate(request.booking_start)
              }
            />
          </GridFieldView>
          <GridFieldView n={6} s={6} label="End*">
            <DateField
              name="end"
              mandatory
              inclusive
              filterDate={(date, ctx) =>
                date >= toDate(ctx.values.get('start'))
              }
              help={(ctx) =>
                formatDate(request.booking_end, { friendly: true }) !==
                  formatDate(ctx.values.get('end'), { friendly: true }) &&
                formatDate(request.booking_end, { friendly: true })
              }
            />
          </GridFieldView>
          <GridFieldView n={12} label="Can be replaced">
            <SelectField
              name="replaceable"
              items={[
                { key: 'true', title: 'Replaceable' },
                { key: 'false', title: 'Not replaceable' },
              ]}
            />
          </GridFieldView>
          <Column n={12}>
            <Duration request={request} />
            <OverBook request={request} overdraft={overdraft} />
          </Column>
        </Row>
      </OverlayBody>
      <OverlayFooter repel gap="10px">
        {onAdd && (
          <AddButton
            default
            disabled={(ctx) =>
              !ctx.ready ||
              differenceInDays(
                ctx.values.json.booking_end,
                ctx.values.json.booking_start
              ) <= 0 ||
              ctx.values.json.amount <= 0
            }
            onClick={async (e, ctx) =>
              await onAdd({
                ...ctx.values.json,
                baseitem: source.baseitem,
                source_project: source.project,
              })
            }
          />
        )}
        {onUpdate && (
          <Container flex gap="10px">
            <SaveButton
              default
              disabled={(ctx) =>
                !ctx.ready ||
                differenceInDays(
                  ctx.values.json.booking_end,
                  ctx.values.json.booking_start
                ) <= 0 ||
                ctx.values.json.amount <= 0
              }
              text={t('Update')}
              onClick={async (e, ctx) => {
                await onUpdate(id, {
                  ...ctx.values.json,
                  baseitem: source.baseitem,
                  source_project: source.project,
                })
              }}
            />
            <DeleteButton onClick={() => onRemove(id)} />
          </Container>
        )}
        <BackButton onClick={onClose} style={{ marginLeft: 'auto' }} />
      </OverlayFooter>
    </OverlayForm>
  )
}

const Duration = ({ request }) => {
  const { t } = useTranslation()
  const { values } = useForm()
  const { start, end } = values.json
  // Calculates differences in days
  const days = differenceInDays(end, start)
  // Calculates differences in days for original request
  const original = differenceInDays(request.booking_end, request.booking_start)
  return (
    <CommentView
      warning={original !== days}
      icon={<Calendar />}
      comment={
        original !== days
          ? t(
              'Booking is for [{{days}}] day(s) but request is for [{{original}}] day/days!',
              { days, original }
            )
          : t('Booking is for [{{days}}] day(s).', { days })
      }
    />
  )
}

const OverBook = ({ request, overdraft }) => {
  const { t } = useTranslation()
  const { values } = useForm()
  const { amount } = values.json
  return (
    <Container>
      {overdraft - amount < 0 ? (
        <CommentView
          warning
          comment={t('Amount of [{{overdraft}}] is overbooked!', {
            amount: request.amount,
            overdraft: Math.abs(overdraft),
          })}
        />
      ) : (
        <CommentView
          icon={<ShoppingCart />}
          comment={`${t('Ordered amount')}: ${request.amount}`}
        />
      )}
    </Container>
  )
}

import { useTranslation } from 'react-i18next'

import { FieldView, GridFieldView, SectionView } from 'common/widgets/view'
import { Row } from 'common/widgets/grid'

import { formatItemName, getEquipmentNavPath } from '../utils'

import { ItemTypeCommentView } from './type'

/**
 * Renders a simple view for all equipments.
 *
 * @param {any} equipment equipment data
 * @returns ReactElement
 */
export const EquipmentSimpleView = ({ equipment }) => {
  const { t } = useTranslation(['equipments'])
  const value = (e, dfl) => (e ? e : dfl !== undefined ? dfl : '-')

  return (
    <SectionView grow="0">
      <FieldView
        href={getEquipmentNavPath(equipment)}
        value={formatItemName(equipment)}
      />
      <ItemTypeCommentView equipment={equipment} />
      <Row>
        <GridFieldView
          n={4}
          s={6}
          label="Main group"
          value={equipment.maincategory}
        />
        <GridFieldView n={4} s={6} label="Group" value={equipment.category} />
        <GridFieldView
          n={4}
          s={6}
          label="Subgroup"
          value={value(equipment.subcategory)}
        />
        <GridFieldView
          n={4}
          s={6}
          label="Cost center"
          value={value(equipment.cost_center)}
        />
        <GridFieldView
          n={4}
          s={6}
          label="Accounting group"
          value={t(value(equipment.accounting_group))}
        />
        <GridFieldView
          n={4}
          s={6}
          label="Inventory number"
          value={t(value(equipment.inventory_number))}
        />
        {equipment.is_consumable && (
          <GridFieldView
            n={4}
            s={6}
            label="Reserved amount"
            value={value(equipment.reserved)}
          />
        )}
      </Row>
      {!equipment.is_resource && (
        <>
          <h3>{t('Inventory')}</h3>
          <Row>
            <GridFieldView
              n={4}
              s={6}
              visible={equipment.is_consumable}
              label="Quantity unit"
              value={value(equipment.quantity_unit)}
            />
            <GridFieldView
              n={4}
              s={6}
              visible={equipment.is_consumable}
              label="Storage"
              value={value(equipment.storage)}
            />
            <GridFieldView
              visible={equipment.is_formwork}
              n={4}
              s={6}
              label="Price when new"
              value={value(equipment.cost_rate)}
            />
            <GridFieldView
              visible={equipment.is_formwork}
              n={4}
              s={6}
              label="Total stock"
              value={equipment.total_amount}
            />
            <GridFieldView
              visible={equipment.is_consumable || equipment.is_formwork}
              n={4}
              s={6}
              label="Stock level"
              value={equipment.stock_level}
            />
            <GridFieldView
              visible={equipment.is_consumable}
              n={4}
              s={6}
              label="Internal billing rate"
              value={value(equipment.internal_billing_rate)}
            />
          </Row>
        </>
      )}
      {equipment.is_formwork && (
        <>
          <h3>{t('Dimensions and cargo')}</h3>
          <Row>
            <GridFieldView
              n={4}
              s={6}
              label="Packaging unit"
              value={value(equipment.packaging_unit)}
            />
            <GridFieldView
              n={4}
              s={6}
              label="Transportation"
              value={value(equipment.transportation)}
            />
            <GridFieldView
              n={4}
              s={6}
              label="Clear inner dimensions"
              value={value(equipment.dimensions_1)}
            />
            <GridFieldView
              n={4}
              s={6}
              label="Outer dimensions"
              value={value(equipment.dimensions_2)}
            />
            <GridFieldView
              n={4}
              s={6}
              label="Maximum outer dimensions"
              value={value(equipment.dimensions_3)}
            />
          </Row>
        </>
      )}
    </SectionView>
  )
}

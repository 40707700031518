import { register } from 'system/container'

import { ChangeRequestConfirm } from './confirm'

register({
  render: () => <ChangeRequestConfirm backUrl="/disposition/requests/shrink" />,
  title: 'Confirm',
  path: '/disposition/requests/shrink/:id/confirm',
})

register({
  render: () => <ChangeRequestConfirm backUrl="/disposition/requests/extend" />,
  title: 'Confirm',
  path: '/disposition/requests/extend/:id/confirm',
})

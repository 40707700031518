import { useState } from 'react'

import {
  TextAreaField,
  NumericField,
  DateRangeField,
  DateField,
  SelectField,
} from 'common/widgets/form/field'
import { forceIntRange } from 'common/widgets/form/validate'
import { SimpleFormAction } from 'common/widgets/form/utils'
import { Row, Column } from 'common/widgets/grid'
import { SectionView } from 'common/widgets/view'
import { EquipmentSimpleView } from 'modules/master-data/equipments/widgets/simple-view'
import { OverlayForm } from 'common/widgets/overlay'
import { dateToISOString } from 'common/utils/format'
import { ItemSearch } from 'modules/yard/commission/deliveries/overlays/adhoc'

export const OrederedItemsCRUDOverlay = ({ open, onClose, onSelect }) => {
  const [selectedItem, setSelectedItem] = useState(null)

  return (
    <OverlayForm
      open={open}
      onClose={() => {
        onClose()
        setSelectedItem(null)
      }}
      title="Item"
    >
      {selectedItem ? (
        <OrderedItemContent item={selectedItem} onAdd={onSelect} />
      ) : (
        <ItemSearch
          onSelect={setSelectedItem}
          onClose={() => {
            setSelectedItem(null)
            onClose && onClose()
          }}
        />
      )}
    </OverlayForm>
  )
}

const OrderedItemContent = ({ item, onAdd }) => {
  const calcMaxItemAmount = () => {
    if (item?.is_largescale) {
      return 1
    } else if (item?.is_smallscale || item?.is_formwork) {
      return 100
    } else {
      return 100000
    }
  }
  return (
    <SectionView>
      <Row>
        <Column n={12} s={12}>
          <EquipmentSimpleView equipment={item} />
        </Column>
        <Column n={6} s={6}>
          <NumericField
            label="Amount"
            name="amount"
            validate={(value) => forceIntRange(value, 1, calcMaxItemAmount())}
            mandatory
          />
        </Column>
        {item && item?.is_consumable && (
          <Column n={6} s={6}>
            <DateField
              label="Fixed date"
              name="fixed_date"
              mandatory
              clearable
            />
          </Column>
        )}
        {item && !item?.is_consumable && (
          <Column n={6} s={6}>
            <DateRangeField label="Booking period" mandatory clearable />
          </Column>
        )}
        <Column n={12} m={12} s={12}>
          <SelectField
            label="Picking Method"
            disabled={false}
            name="requires_delivery"
            items={[
              { key: false, title: 'Selfpick' },
              { key: true, title: 'Delivery' },
            ]}
          />
        </Column>
        <Column n={12} s={12}>
          <TextAreaField label="Comment" name="comment" rows={4} />
        </Column>
      </Row>
      <SimpleFormAction
        onAdd={(values) => {
          const formValues = {
            item_id: item.id,
            amount: values.amount,
            comment: values.comment,
            requires_delivery: values.requires_delivery,
          }
          if (item && !item?.is_consumable) {
            formValues['booking_start'] = dateToISOString(values.booking_start)
            formValues['booking_end'] = dateToISOString(values.booking_end)
          } else if (item && item?.is_consumable) {
            formValues['fixed_date'] = dateToISOString(values.fixed_date)
          }
          return onAdd(formValues)
        }}
      />
    </SectionView>
  )
}

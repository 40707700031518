import { createContext, useContext, useState, useEffect } from 'react'
import * as rht from 'react-hot-toast'

import {
  SimpleErrorMessage,
  SuccessMessage,
  WarningMessage,
} from 'common/widgets/message'

const MAX_DEFAULT_TIMEOUT = 2
const MAX_WARN_TIMEOUT = 3
const MAX_ERROR_TIMEOUT = 5

// Create a context object for toasts
const ToastContext = createContext()

/*
 * Provider for using app-level toasts
 *
 * This provider separates toasts from system pages. This
 * allows us to publish toasts upon API errors system-wide,
 * without having to post them one by one in every module.
 */
export const ToastProvider = ({ data, children }) => {
  // Get state for system messages (toasts)
  const [toasts, setToasts] = useState([])

  // Add a new system message to toasts queue
  const pushToast = (msgType, message) => {
    //setToasts([{ msgType, date: new Date(), message }])
  }

  // Calc elapsed time since message was pushed
  const elapsed = (toast) => (new Date() - toast.date) / 1000

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (toasts.length) {
        // Keep toasts which are younger than MAX_ERROR_TIMEOUT
        setToasts(
          toasts.filter((t) => {
            if (t.msgType == 'error') {
              return elapsed(t) < MAX_ERROR_TIMEOUT
            } else if (t.msgType == 'warn') {
              return elapsed(t) < MAX_WARN_TIMEOUT
            } else {
              return elapsed(t) < MAX_DEFAULT_TIMEOUT
            }
          })
        )
      }
    }, MAX_DEFAULT_TIMEOUT * 1000)
    return () => clearTimeout(timerId)
  }, [toasts])

  return (
    <ToastContext.Provider
      value={{
        toasts: {
          error: (message) => {
            pushToast('error', <SimpleErrorMessage flat error={{ message }} />),
              rht.toast.error(message, { position: 'bottom-right' })
          },
          success: (message) => {
            pushToast('success', <SuccessMessage flat message={message} />),
              rht.toast.success(message, { position: 'bottom-right' })
          },
          warn: (message) => {
            pushToast('warn', <WarningMessage flat message={message} />),
              rht.toast.error(message, {
                position: 'bottom-right',
                icon: '⚠️',
                duration: 3000,
              })
          },
          list: () =>
            toasts.filter((t) => {
              if (t.msgType == 'error') {
                return elapsed(t) < MAX_ERROR_TIMEOUT
              } else if (t.msgType == 'warn') {
                return elapsed(t) < MAX_WARN_TIMEOUT
              } else {
                return elapsed(t) < MAX_DEFAULT_TIMEOUT
              }
            }),
          clear: () => setToasts([]),
          dismiss: rht.toast.dismiss,
        },
        ...data,
      }}
    >
      {children}
    </ToastContext.Provider>
  )
}

/**
 * The active toast context
 *
 * @returns ToastContext
 */
export const useToast = () => useContext(ToastContext)

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useService } from 'common/service/context'
import { Form, useForm } from 'common/widgets/form/context'
import { Row } from 'common/widgets/grid'
import { GridFieldView, SectionView } from 'common/widgets/view'
import { ProjectSelectField } from 'modules/projects/widgets/select'
import { groupBackDeliveryItems } from 'modules/yard/utils'
import { formatDate } from 'common/utils/format'

/**
 * Renders the Adhook Back Delivery detail info.
 *
 * @param {any} setBakDeliveryItems setter for the backdelivery items
 * @param {any} setProjectId setter for the selected project id
 *
 * @returns ReactElement
 */
export const BackAdHocDeliveryForm = ({
  setBackDeliveryItems,
  setProjectId,
}) => (
  <Form>
    <BackAdHocDeliveryFormFields
      setBackDeliveryItems={setBackDeliveryItems}
      setProjectId={setProjectId}
    />
  </Form>
)

const BackAdHocDeliveryFormFields = ({
  setBackDeliveryItems,
  setProjectId,
}) => {
  const { t } = useTranslation()
  const service = useService()
  const { values } = useForm()

  // Fetch the backdelivery based on the selected project id.
  useEffect(() => {
    const fetchData = async () => {
      const [result, error] = await service.get(
        `back_delivery/commissionable/${values.json.project_id}`
      )
      if (!error) {
        // Set the grouped backdelivery items
        setBackDeliveryItems(groupBackDeliveryItems(result.data))
        // Sets the selected project id
        setProjectId(values.json.project_id)
      }
    }
    if (values.json.project_id) {
      fetchData()
    } else {
      setBackDeliveryItems([])
    }
  }, [values.json.project_id])

  return (
    <SectionView>
      <h1>{t('Back delivery data')}</h1>
      <Row>
        <GridFieldView
          n={4}
          label="Target location"
          value={t('Building yard')}
        />
        <GridFieldView n={4} label="Date" value={formatDate(new Date())} />
        <GridFieldView
          n={4}
          label={t(`Project`)}
          render={<ProjectSelectField name="project_id" />}
        />
      </Row>
    </SectionView>
  )
}

import './ganttchart.css'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import InteractionPlugin from '@fullcalendar/interaction'
import ResourceTimelinePlugin from '@fullcalendar/resource-timeline'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'

import { useService } from 'common/service/context'
import { addDays, toDate } from 'common/utils/date'
import { FullCalendarWrapper } from 'common/widgets/calendar'
import { Container } from 'common/widgets/container'
import { dateToISOString } from 'common/utils/format'
import { Column } from 'common/widgets/grid'
import { GridFieldView } from 'common/widgets/view'

export const ProjectGanttChart = ({ project }) => {
  const service = useService()
  const navigate = useNavigate()
  const [startDate, setStartDate] = useState(addDays(new Date(), -14))
  const [endDate, setEndDate] = useState(addDays(new Date(), 14))
  const [contacts, setContacts] = useState([])
  const [tasks, setTasks] = useState([])

  const loadData = async () => {
    const apis = [
      { key: 'contacts', url: '/contact?internal=y' },
      {
        key: 'tasks',
        url: `/tasks?project_id=${
          project?.id
        }&start_date[gte]=${dateToISOString(
          startDate,
          true
        )}&start_date[lte]=${dateToISOString(endDate, true)}`,
      },
    ]

    const results = await Promise.all(
      apis.map(async (e) => {
        const [result, error] = await service.get(e.url)
        return [e.key, result, error]
      })
    )

    results?.filter((e) => {
      const [key, result, error] = e
      const data = error ? [] : result.data
      if (key === 'contacts') {
        buildResources(data)
      }
      if (key === 'tasks') {
        buildEvents(data)
      }
    })
  }

  const buildResources = (data) => {
    const resources = []
    data?.forEach((contact) => {
      const entry = {
        id: contact.id,
        assignee_user_id: contact.id,
        title: contact?.fullname,
      }
      resources.push(entry)
    })
    setContacts(resources)
  }

  const buildEvents = (data) => {
    const events = []
    data?.forEach((record) => {
      if (record?.schedule && record?.assignee_user_id) {
        events.push({
          id: record.id,
          resourceId: record.assignee_user_id,
          assignee_user_id: record?.assignee_user_id,
          title: record?.title,
          allDay: true,
          state: record.status,
          start: toDate(record?.schedule?.start_date),
          end: toDate(record?.schedule?.due_date),
          className: 'chart-event',
        })
      }
    })
    setTasks(events)
  }

  useEffect(() => {
    loadData()
  }, [startDate, endDate])

  return (
    <FullCalendarWrapper
      datesSet={(data) => {
        setStartDate(addDays(data.booking_start, -14))
        setEndDate(addDays(data.booking_end, 14))
      }}
      plugins={[
        dayGridPlugin,
        listPlugin,
        ResourceTimelinePlugin,
        InteractionPlugin,
      ]}
      initialView="resourceTimeline"
      headerToolbar={{
        left: 'resourceTimeline,listMonth',
        center: 'title',
        right: 'prev,next today',
      }}
      views={{
        resourceTimeline: {
          duration: { weeks: 1 },
          slotDuration: { day: 1 },
          slotLabelFormat: [
            { month: 'long', year: 'numeric' },
            { week: 'short' },
            { day: 'numeric' },
          ],
        },
      }}
      resourceAreaHeaderContent={({ view }) => (
        <ResourceHeaderContent {...view} />
      )}
      refetchResourcesOnNavigate={false}
      resourceLabelContent={({ fieldValue, view, resource }) => {
        return (
          <ResourceLabelContent
            fieldValue={fieldValue}
            data={resource}
            tasks={tasks}
            isMember={
              project.members?.find(
                (e) =>
                  e.contact.id ===
                  resource?._resource?.extendedProps.assignee_user_id
              ) != null
            }
          />
        )
      }}
      resources={contacts}
      events={tasks}
      eventContent={({ event }) => <EventCell event={event} />}
      eventClick={(selection) => {
        navigate(`/projects/tasks/${selection.event._def.publicId}`)
      }}
      selectable={true}
      unselectAuto={true}
      select={(props) => {
        const start = props.start
        const end = props.end
        const resource = props.resource._resource.extendedProps
        navigate(
          `/projects/tasks/add?start=${dateToISOString(
            start,
            true
          )}&end=${dateToISOString(end, true)}&project_id=${
            project.id
          }&assignee_user_id=${resource.assignee_user_id}`
        )
      }}
      resourceAreaWidth="15%"
      contentHeight={850}
    />
  )
}

/**
 * Renders the Resource header element
 *
 * @returns ReactElement
 */
const ResourceHeaderContent = () => {
  const { t } = useTranslation()
  return <h3 style={{ padding: '20px' }}>{t('Contacts')}</h3>
}

/**
 * Renders the resource element
 *
 * @param {any} fieldValue user info
 * @param {data} data resource object
 *
 * @returns ReactElement
 */
const ResourceLabelContent = ({ fieldValue, data, tasks, isMember }) => {
  const { t } = useTranslation()
  /// Extracted resource object from the calendar
  const resource = data?._resource?.extendedProps
  const todo = tasks.filter(
    (e) =>
      e.assignee_user_id === resource.assignee_user_id && e.state !== 'Closed'
  )?.length

  return (
    <Column flex style={{ paddingLeft: '20px' }}>
      <GridFieldView n={12}>
        <Container>
          <h4>{t(fieldValue)}</h4>
        </Container>
      </GridFieldView>
      {isMember && (
        <GridFieldView n={12}>
          <h5>{t('Member')}</h5>
        </GridFieldView>
      )}
      <GridFieldView n={12}>
        <h5>
          {todo} {t('Active Tasks')}
        </h5>
      </GridFieldView>
    </Column>
  )
}

/**
 * Renders the event content
 *
 * @param {any} event event object
 *
 * @returns ReactElement
 */
const EventCell = ({ event }) => {
  const task = event._def.extendedProps
  return (
    <Container
      grow
      style={{ background: task.state === 'Open' ? '#FDA0A0' : '#BABABA' }}
    >
      <h4 style={{ color: 'white' }}>{event.title}</h4>
    </Container>
  )
}

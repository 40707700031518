import { Form } from 'common/widgets/form/context'
import { useSession } from 'common/session/context'
import { formatDate } from 'common/utils/format'
import { useQueryParams } from 'common/utils/routing'

import { AdHocFormBody } from './details'

export const AdHocDeliveryPage = () => {
  const { currentUser } = useSession()
  const { baseitemId } = useQueryParams()

  return (
    <Form
      data={{
        recipient: currentUser.fullname,
        delivery_date: formatDate(new Date()),
        source_project_id: null,
        project_id: null,
        baseitemId: baseitemId,
      }}
    >
      <AdHocFormBody />
    </Form>
  )
}

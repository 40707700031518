import { useState, useRef, useEffect } from 'react'

/*
  A tree-like widget. Considers three states:
  - checked
  - unchecked
  - indeterminate
  This component uses native html inputs and states.
  Each input node should have:
  id, key, parent_id, and children arary.
*/
export const RecursiveTree = ({
  tree,
  selectedNodes,
  allSelectedTitle,
  someSelectedTitle,
  noneSelectedTitle,
  onChange,
  disabled = false,
  showSelectedOnly = false,
}) => {
  // Make sure we are dealing with a tree
  const [nodes] = useState(() => {
    const _orphans = []
    for (const k in tree) {
      if (tree[k].parent_id == null) {
        _orphans.push(tree[k])
      }
    }
    if (_orphans.length > 1) {
      _orphans.forEach((n) => (n.parent_id = -1))
      tree[-1] = {
        id: -1,
        key: 'All',
        parent_id: null,
        children: _orphans,
      }
    }
    return tree
  })

  const recursiveCheck = (checkedState, node, checked) => {
    checkedState[node.id] = checked
    // Pickup the node from the nodes list which contains
    // the list of children
    nodes[node.id]?.children?.forEach((c) => {
      checkedState = recursiveCheck(checkedState, c, checked)
    })
    return checkedState
  }

  const reverseCheck = (checkedState, node) => {
    const parent = nodes[node.parent_id]
    if (!parent) {
      return checkedState
    }
    let checkCount = 0
    let indeterminateCount = 0
    parent?.children?.forEach((c) => {
      if (checkedState[c.id]) {
        checkCount++
      } else if (checkedState[c.id] == null) {
        indeterminateCount++
      }
    })
    if (checkCount == parent?.children?.length) {
      // All children are checked
      checkedState[parent.id] = true
    } else if (checkCount + indeterminateCount > 0) {
      // Only some of the children are checked, parent should be
      // indeterminate
      checkedState[parent.id] = null
    } else {
      checkedState[parent.id] = false
    }
    return reverseCheck(checkedState, nodes[parent.id])
  }

  selectedNodes ??= []

  const selectedById =
    selectedNodes?.reduce((accumulator, current) => {
      accumulator[current.id] = current
      return accumulator
    }, {}) || {}
  // We have three states for each checkbox: checked, unchecked, and
  // indeterminate, wich is when some children are selected, not all
  // We cover that in a different state for simplification
  const [checked, setChecked] = useState(() => {
    const checkedState = Object.values(nodes).reduce((accumulator, current) => {
      accumulator[current.id] = current.id in selectedById || false
      return accumulator
    }, {})
    // A rather inefficient way to update indeterminate states.
    Object.values(nodes).forEach((n) => reverseCheck(checkedState, n))
    return checkedState
  })
  const [collapsed, setCollapsed] = useState({})

  const handleCheckChange = (e, node) => {
    e.preventDefault()
    e.stopPropagation()

    setChecked((prev) => {
      const next = { ...recursiveCheck(prev, node, e.target.checked) }
      // We need a second run to uncheck parents if needed
      const final = { ...reverseCheck(next, node) }
      onChange && onChange(Object.values(tree).filter((n) => checked[n.id]))
      return final
    })
  }

  const handleCollapseChange = (e, node) => {
    e.stopPropagation()
    e.preventDefault()
    setCollapsed({ ...collapsed, [node.id]: !collapsed[node.id] })
  }

  const renderTree = (nodes, parentId = null, level = 0) => {
    return nodes
      .filter((item) => item.parent_id === parentId)
      .map((item) => {
        const show = showSelectedOnly
          ? checked[item.id] == true || checked[item.id] == null
          : true
        return (
          <div key={item.id}>
            <RecursiveTreeRow
              node={item}
              level={level}
              collapsed={collapsed[item.id]}
              onCollapseChange={handleCollapseChange}
              checked={checked[item.id]}
              onCheckChange={handleCheckChange}
              disabled={disabled}
              show={show}
            />
            {!collapsed[item.id] && renderTree(nodes, item.id, level + 1)}
          </div>
        )
      })
  }
  let header = ''
  if (checked[-1]) {
    header = allSelectedTitle || '*'
  } else if (checked[-1] == null) {
    header = someSelectedTitle || '-'
  } else {
    header = noneSelectedTitle || ' '
  }
  return (
    <>
      {header && <h1>{header}</h1>}
      <>{renderTree(Object.values(nodes), null, 0)}</>
    </>
  )
}

const RecursiveTreeRow = ({
  node,
  level,
  collapsed,
  onCollapseChange,
  checked,
  onCheckChange,
  disabled,
  show,
}) => {
  const ref = useRef()
  const [_checked, setChecked] = useState(checked)

  useEffect(() => {
    if (checked == null) {
      ref.current.indeterminate = true
      setChecked(false)
    } else {
      ref.current.indeterminate = false
      setChecked(checked)
    }
  }, [checked])

  let collapseSign = <>&nbsp;&nbsp;&nbsp;</>
  if (node.children.length > 0) {
    collapseSign = collapsed ? '[+]' : '[-]'
  }
  return (
    <div style={{ display: show ? 'flex' : 'none', marginLeft: level * 20 }}>
      <span
        onClick={(e) => onCollapseChange(e, node)}
        style={{ cursor: 'pointer' }}
      >
        <pre>{collapseSign}</pre>
      </span>
      <input
        ref={ref}
        type="checkbox"
        style={{ marginLeft: '5px' }}
        checked={_checked}
        onChange={(e) => onCheckChange(e, node)}
        disabled={disabled}
      />
      <span style={{ marginLeft: 5 }}>{node.key}</span>
    </div>
  )
}

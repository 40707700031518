import { register } from 'system/container'

import { AbsenceListPage } from './list'
import { AbsenceAddPage } from './add'
import { AbsenceEditPage } from './edit'
import { AbsenceDetailViewPage } from './view'

register({
  menu: true,
  render: () => <AbsenceListPage />,
  title: 'Absences',
  path: '/master-data/contacts/absences',
})

register({
  render: () => <AbsenceAddPage />,
  title: 'New',
  path: '/master-data/contacts/absences/add',
})

register({
  render: () => <AbsenceEditPage />,
  title: 'Edit',
  path: '/master-data/contacts/absences/:id/edit',
})

register({
  render: () => <AbsenceDetailViewPage />,
  title: 'Details',
  path: '/master-data/contacts/absences/:id',
})

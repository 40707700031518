import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useService } from 'common/service/context'
import { formatDate } from 'common/utils/format'
import { SimpleColumn, SimpleDataTable } from 'common/data-table/simple'
import { formatProjectName } from 'modules/projects/utils'
import {
  FoldButtons,
  DetailsIconButton,
  EditIconButton,
  ArchiveIconButton,
  CommissionIconButton,
} from 'common/widgets/button'
import { useToast } from 'system/toast/context'
import { OrderStateColor } from 'modules/orders/utils'

/**
 * Renders a widget and list available orders.
 *
 * @returns ReactElement
 */
export const OrderList = () => {
  const { t } = useTranslation(['orders'])
  const service = useService()
  const navigate = useNavigate()
  const { toasts } = useToast()

  // Calls a put call and archives the given recortd
  const archiveOrder = async (r) => {
    await service.put(`orders/${r.id}`, {
      archived_at: r.archived_at === null ? new Date() : null,
    })
    r.archived_at
      ? toasts.success(
          `${t('Order')} ${t(r.number)} ${t('sucessfully activated.')}`
        )
      : toasts.success(
          `${t('Order')} ${t(r.number)} ${t('sucessfully archived.')}`
        )
  }

  /**
   * Get or create delivery note
   *
   * @param {any} order Order object
   *
   * @returns Array[result, error]
   */
  const getOrCreateDeliveryNote = async (orderId) => {
    const [result, error] = await service.post(`kommission/delivery`, {
      order_id: orderId,
    })

    if (!error && result) {
      navigate(`/yard/commission/deliveries/${result.data.id}/add`)
    }

    return [result, error]
  }

  /**
   * Renders the commission order buttons
   *
   * @param {any} order order object
   * @param {any} reload reloads the page
   *
   * @returns ReactElement
   */
  const renderActionButtons = (order, reload) => (
    <FoldButtons>
      {order?.archivable && (
        <ArchiveIconButton
          tooltip="Archive"
          onClick={async () => {
            await archiveOrder(order)
            await reload()
          }}
          archived={order.archived_at}
        />
      )}
      {order.commissionable && (
        <CommissionIconButton
          tooltip="Commissioning"
          white
          onClick={() => getOrCreateDeliveryNote(order.id)}
        />
      )}
      <DetailsIconButton
        tooltip="Details"
        white
        onClick={() => navigate(`/orders/${order.id}`)}
      />
    </FoldButtons>
  )

  return (
    <OrderListPage
      actions={(order, reload) => renderActionButtons(order, reload)}
    />
  )
}

/**
 *  Renders the Order page
 *
 * @returns ReactElement
 */
const OrderListPage = ({ actions }) => {
  const { t } = useTranslation(['orders'])
  const service = useService()
  const navigate = useNavigate()
  const { toasts } = useToast()

  // Calls a put call and archives the given recortd
  const archiveOrder = async (r) => {
    await service.put(`orders/${r.id}`, {
      archived_at: r.archived_at === null ? new Date() : null,
    })
    r.archived_at
      ? toasts.success(`${t('Ordered item')} ${t('sucessfully activated.')}`)
      : toasts.success(`${t('Ordered item')} ${t('sucessfully archived.')}`)
  }

  return (
    <SimpleDataTable
      url="/orders"
      name="orderedItems"
      archive
      title="Ordered items"
      rowColor={(r) => OrderStateColor[r.state]}
    >
      <SimpleColumn
        n={3}
        s={6}
        sortable
        field="project_id"
        label="Project"
        value={(r) => formatProjectName(r.project)}
        link={(r) => (r.project_id ? `/projects/${r.project_id}` : null)}
      />
      <SimpleColumn
        n={3}
        s={6}
        sortable
        label="Category"
        value={(r) => r.baseitem_types?.map((e) => t(e.type)).join(', ')}
      />
      <SimpleColumn
        n={3}
        s={6}
        sortable
        field="creator_user_id"
        label="Creator"
        value={(r) => r.creator.fullname}
        link={(r) => `/security/users/${r.creator?.id}`}
      />
      <SimpleColumn
        n={3}
        s={6}
        sortable
        field="ordered_at"
        label="Ordered at"
        value={(r) => formatDate(r.ordered_at)}
      />
      <SimpleColumn
        n={3}
        s={6}
        sortable
        field="requires_delivery"
        label="Delivery option"
        value={(r) =>
          r.requires_delivery ? t('Requires delivery') : t('Self-pickup')
        }
      />
      <SimpleColumn
        n={3}
        s={6}
        sortable
        field="state"
        label="Status"
        value={(r) => t(r.state)}
        filterUrl="/orders/states"
      />
      <SimpleColumn
        n={3}
        s={6}
        sortable
        field="planned_delivery_date"
        label="Planned delivery date"
        value={(r) => formatDate(r.planned_delivery_date)}
      />
      <SimpleColumn
        n={3}
        s={6}
        label="Transport Status"
        value={(r) =>
          r.transport_planned ? `${r.transport_count} PLANNED` : 'NOT PLANNED'
        }
      />
      <SimpleColumn fixed>
        {(r, reload) => (
          <div style={{ width: '150px' }}>
            {actions ? (
              actions(r, reload)
            ) : (
              <FoldButtons>
                {r.archivable && (
                  <ArchiveIconButton
                    tooltip="Archive"
                    white
                    onClick={async () => {
                      await archiveOrder(r)
                      await reload()
                    }}
                    archived={r.archived_at}
                  />
                )}
                {r.editable && (
                  <EditIconButton
                    tooltip="Edit"
                    white
                    onClick={() => navigate(`/orders/${r.id}/edit`)}
                  />
                )}
                <DetailsIconButton
                  tooltip="Details"
                  white
                  onClick={() => navigate(`/orders/${r.id}`)}
                />
              </FoldButtons>
            )}
          </div>
        )}
      </SimpleColumn>
    </SimpleDataTable>
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'

import { useService } from 'common/service/context'
import { SimpleColumn, SimpleDataTable } from 'common/data-table/simple'
import { formatDistanceFromNow, formatDate } from 'common/utils/format'

export const TransportList = ({ title, transports }) => {
  const { t } = useTranslation(['logistics'])
  const service = useService()

  // Fetch planned tours
  const fetch = async (params) =>
    transports
      ? [{ data: transports }, null]
      : await service.get('logistics/transports', [...params])

  return (
    <SimpleDataTable
      name="transports"
      fetch={fetch}
      title={title || t('Transports')}
    >
      <SimpleColumn
        n={3}
        s={6}
        sortable
        field="date"
        label="Delivery date"
        value={(r) => formatDate(r.date)}
      />
      <SimpleColumn
        n={3}
        s={6}
        sortable
        label="Transporter name"
        field="transporter_name"
        value={(r) => r.resource.name}
      />
      <SimpleColumn
        n={3}
        s={6}
        sortable
        label="Driver name"
        field="driver_name"
        value={(r) => r.driver_name}
      />
      <SimpleColumn
        n={3}
        s={6}
        sortable
        label="Plate number"
        field="license_plate"
        value={(r) => r.license_plate}
      />
      <SimpleColumn
        n={3}
        s={6}
        sortable
        field="creator_user"
        label="Creator user"
        value={(r) => r.creator_user?.fullname}
      />
      <SimpleColumn
        n={3}
        s={6}
        sortable
        field="created_at"
        label="Created at"
        value={(r) => formatDistanceFromNow(r.created_at)}
      />
      <SimpleColumn
        n={3}
        s={6}
        sortable
        field="cost_center"
        label="Cost center"
        value={(r) => r.cost_center}
      />
      <SimpleColumn
        n={3}
        s={6}
        sortable
        field="trip_count"
        label="Trip count"
        value={(r) => r.trips.length}
      />
    </SimpleDataTable>
  )
}

.filterSection {
  flex: 1 1 250px;
  background: #ffffff;
  padding: 10px;
}

.dtLink {
  width: fit-content;
}

.dtLink h3 {
  display: inline-block;
  font-size: 16px;
  letter-spacing: 0.32px;
  color: #303030;
  text-decoration: underline;
  text-decoration-style: dotted;
  text-decoration-color: #bbb;
  text-decoration-thickness: 0.5px;
  width: fit-content;
  transition: 0.5s;
}

.dtLink h3:hover {
  background: #f5f5f5;
  color: #000;
  border-radius: 4px;
}

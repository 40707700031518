import { useService } from 'common/service/context'
import { BuildInfo } from 'system/info'

import styles from './system.module.css'

export const RootTemplate = ({ children }) => {
  const { offline } = useService()

  return (
    <>
      {offline && <div className={styles.banner}>Gateway Timeout</div>}
      {children}
      <BuildInfo />
    </>
  )
}

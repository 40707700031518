import { useTranslation } from 'react-i18next'

import { DetailViewPage } from 'system/utils/view'
import { GridFieldView, SectionView } from 'common/widgets/view'
import { Row } from 'common/widgets/grid'
import { formatDate } from 'common/utils/format'
import { ColorPickerInput } from 'common/widgets/color'
import { DocumentsView } from 'common/widgets/document'

export const ContactQualificationsViewPage = () => {
  const { t } = useTranslation()
  return (
    <DetailViewPage
      url="/contact/qualification"
      navUrl="/master-data/contacts/qualification"
      title={(e) => e.name}
      removable
      editable
    >
      {(data, reload) => (
        <SectionView>
          <Row>
            <GridFieldView n={12}>
              <h2>{t('Details')}</h2>
            </GridFieldView>
            <GridFieldView
              n={6}
              s={12}
              label="Created at"
              value={formatDate(data.created_at)}
            />
            <GridFieldView
              n={6}
              s={12}
              label="Created by"
              value={data.creator_user.fullname}
            />
            <GridFieldView n={6} s={12} title="Color">
              <ColorPickerInput
                name="color"
                disabled
                value={data.color}
                style={{ marginBottom: '10px' }}
              />
            </GridFieldView>
            <GridFieldView
              n={6}
              s={12}
              label="Description"
              value={data.comment}
            />
            <GridFieldView n={12} s={12} label="Documents">
              {data.attachments && data.attachments.length > 0 && (
                <DocumentsView docRef={data.attachments[0].ref} />
              )}
            </GridFieldView>
          </Row>
        </SectionView>
      )}
    </DetailViewPage>
  )
}

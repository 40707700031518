import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { Box } from 'react-feather'

import { useService } from 'common/service/context'
import { formatDistanceFromNow } from 'common/utils/format'
import { SimpleColumn, SimpleDataTable } from 'common/data-table/simple'
import {
  FoldButtons,
  IconButton,
  EditIconButton,
  ArchiveIconButton,
  DeleteIconButton,
} from 'common/widgets/button'
import { ConfirmOverlay } from 'common/widgets/overlay'
import { useQuery } from 'common/query/context'
import { useToast } from 'system/toast/context'

import { ProjectTypeFormOverlay } from './overlay'

export const ProjectTypesListPage = () => {
  const { t } = useTranslation()
  const service = useService()
  const navigate = useNavigate()
  const [overlayVisible, setOverlayVisible] = useState(false)
  const [currentRecord, setCurrentRecord] = useState(null)
  const [itemToRemove, setItemToRemove] = useState(null)
  const { toasts } = useToast()

  const handler = { reload: null }

  const archive = async (r) => {
    await service.put(`projects/types/${r.id}`, {
      archived_at: r.archived_at === null ? new Date() : null,
    })
    r.archived_at
      ? toasts.success(`${t('Project template')} ${r.name} ${t('unarchived.')}`)
      : toasts.success(`${t('Project template')} ${r.name} ${t('archived.')}`)
  }

  return (
    <SimpleDataTable
      name="projecttypes"
      archive
      title={t('Project templates')}
      url="projects/types"
      onAddClick={() => {
        setCurrentRecord(null)
        setOverlayVisible(true)
      }}
      handler={handler}
    >
      <SimpleColumn s={12} n={12} sortable>
        {(r) => <h3>{r.name}</h3>}
      </SimpleColumn>
      <SimpleColumn
        s={6}
        n={4}
        sortable
        field="description"
        label="Description"
      />
      <SimpleColumn
        s={6}
        n={4}
        sortable
        field="created_at"
        label="Creation date"
        value={(r) => formatDistanceFromNow(r.created_at)}
      />
      <SimpleColumn
        s={12}
        n={4}
        sortable
        field="creator_user_id"
        label="Creator user"
        value={(r) => r.creator_user.fullname}
      />
      <SimpleColumn fixed>
        {(r, reload) => (
          <FoldButtons>
            <EditIconButton
              tooltip="Edit"
              onClick={() => {
                setCurrentRecord(r)
                setOverlayVisible(true)
              }}
            />
            <DeleteIconButton
              tooltip="Delete"
              disabled={!r.removable}
              danger
              onClick={() => setItemToRemove(r)}
            />
            <ArchiveIconButton
              tooltip="Archive"
              onClick={async () => {
                await archive(r)
                await reload()
              }}
              archived={r.archived_at}
            />
            <IconButton
              tooltip="Design"
              onClick={() => navigate(`/master-data/projects/types/${r.id}`)}
              icon={<Box />}
            />
          </FoldButtons>
        )}
      </SimpleColumn>
      {/* The layouts use the query and therefore should be inside the table */}
      {overlayVisible && (
        <ProjectTypeFormOverlay
          data={currentRecord}
          open={overlayVisible || currentRecord !== null}
          onClose={() => {
            setOverlayVisible(false)
            setCurrentRecord(null)
          }}
        />
      )}
      {itemToRemove && (
        <RemoveConfirmOverlay
          itemToRemove={itemToRemove}
          onClose={() => setItemToRemove(null)}
        />
      )}
    </SimpleDataTable>
  )
}

/**
 * Render a simple confirm overlay for removing type.
 * @param {any} itemToRemove type instance to remove
 * @param {Function} onClose callback to close the overlay
 * @returns ReactElement
 */
const RemoveConfirmOverlay = ({ itemToRemove, onClose }) => {
  const { t } = useTranslation(['projects'])
  const { reload } = useQuery()
  const service = useService()

  const handleRemove = async () => {
    const [result, error] = await service.delete(
      `/projects/types/${itemToRemove.id}`
    )
    if (!error) {
      onClose()
      await reload()
    }
    return [result, error]
  }

  return (
    <ConfirmOverlay
      open={itemToRemove !== null}
      onConfirm={async () => await handleRemove()}
      onReject={onClose}
      title={t('Delete Confirmation')}
    >
      <p>
        {t('Please confirm that you want to delete project type [{{name}}].', {
          name: itemToRemove?.name,
        })}
      </p>
    </ConfirmOverlay>
  )
}

/*
 * Tour planning module.
 *
 * Tour planning is about assigning different delivery tasks to a limited
 * number of transport vehicles on certain days. It starts normally with
 * a calendar view, where user can pick up a date to plan tours for that
 * day. Besides, user can see an overview of already planned tours and of
 * course their details, by clicking on the specific tour entry in the
 * calendar.
 *
 * This page is the starting point for top-level components of this module.
 */
import { register } from 'system/container'

import { TourPlanningPage } from './calendar'

// Register the top most component, i.e. the home page
register({
  menu: true,
  render: () => 'Tour Planning module',
  title: 'Tour Planning',
  path: '/logistics/planning',
})

// Register the top most component, i.e. the home page
register({
  render: () => <TourPlanningPage />,
  menu: true,
  title: 'Calendar',
  path: '/logistics/planning/calendar',
  priority: 1,
})

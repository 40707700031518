import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'

import { useQuery } from 'common/query/context'
import { Column, Row } from 'common/widgets/grid'
import { FieldView, RowView } from 'common/widgets/view'
import { Container } from 'common/widgets/container'
import { useService } from 'common/service/context'

import { DataTable } from './core'
import { RecordProvider, useRecord } from './record'
import { useDataTable } from './context'
import { FilterFieldView } from './widgets'
import styles from './dt.module.css'

export const SimpleDataTable = ({
  name,
  // baseUrl for navigation according to our convention:
  // details: navBaseURL/
  // add: navBaseUrl/add
  // del: navBaseUrl/:id
  // if the variable is a function, navBaseUrl will be first
  // evaluated and then the convention above would be applied
  navBaseUrl,
  onRowClick,
  filters,
  border,
  divide,
  background,
  rowNumbers,
  rowColor,
  children,
  ...rest
}) => {
  const { t } = useTranslation()
  const service = useService()

  // Filters sortable columns and maps them to a list of sortable field
  const sortables = Array.isArray(children)
    ? children
        .filter((e) => e?.props && e.props.sortable && e.props.label)
        .map((e) => ({
          key: e.props.field,
          title: e.props.label,
          default: e.props.default,
        }))
    : []

  // Finds filterable columns and maps them to a list of fields
  let effectiveFilters = Array.isArray(children)
    ? children
        .filter(
          (e) =>
            e?.props && e.props.field && (e.props.filter || e.props.filterUrl)
        )
        .map((e, idx) => {
          console.assert(
            !(e.props?.filterUrl && e.props?.fetch),
            'Programming error. "fetch" and "filterUrl" props are mutually exclusive.'
          )
          let fetch = e.props?.fetch
          if (e.props?.filterUrl) {
            fetch = async (params) =>
              await service.get(e.props?.filterUrl, params)
          }

          return {
            key: idx,
            field: e.props.field,
            section: e.props.label,
            title: e.props.title ? e.props.title : (e) => t(e),
            value: e.props.filter,
            fetch: fetch,
          }
        })
    : []

  if (filters) {
    effectiveFilters = effectiveFilters.concat(filters)
  }

  const before = []
  const after = []
  const columns = []
  const other = []

  Array.isArray(children) &&
    children.forEach((c) => {
      // Filters columns to show them before records
      if (c?.props && c.props?.before) {
        before.push(c)
        // Filters columns to show them after records
      } else if (c?.props && c.props?.after) {
        after.push(c)
        // Filters columns to show them in each row
        // Broken: these are all functional componends, i.e. functions
        // Any other functional component could be converted to a function
        // with the same name in a production build at least with Parcel.
        // Therefore, this statement will be true for all functional comps.
      } else if (c?.props && c.type.name === SimpleColumn.name) {
        columns.push(c)
      } else {
        // Anything else which is not a function.
        other.push(c)
      }
    })

  return (
    <DataTable
      name={name}
      sortables={sortables}
      filters={effectiveFilters}
      navBaseUrl={navBaseUrl}
      {...rest}
    >
      {({ records }) => (
        <>
          {before}
          {records?.map((r, idx) => (
            <DataTableRecordLayout
              key={idx}
              record={r}
              columnSpecs={columns}
              rowIndex={idx}
              onRowClick={onRowClick}
              navBaseUrl={navBaseUrl}
              border={border}
              divide={divide}
              background={background ? background(r, idx) : null}
              rowNumbers={rowNumbers}
              rowColor={rowColor}
            />
          ))}
          {other}
          {after}
        </>
      )}
    </DataTable>
  )
}

const DataTableRecordLayout = ({
  record,
  // specs used to build the layout of each row (e.g. SimpleColumn, etc.)
  columnSpecs,
  rowIndex,
  onRowClick,
  rowNumbers,
  navBaseUrl,
  rowColor,
}) => {
  const layout = []
  var columns = []

  if (rowNumbers) {
    columns.push(
      <Column key={rowIndex}>
        <RowNumberBadge>
          <p>{rowIndex + 1}</p>
        </RowNumberBadge>
      </Column>
    )
  }
  const navUrl =
    record?.id && navBaseUrl ? `${navBaseUrl}/${record.id}` : undefined

  for (const [index, child] of columnSpecs.entries()) {
    if (child.props.fixed) {
      if (columns.length > 0) {
        layout.push(<Row key={`row-${index}`}>{columns}</Row>)
        columns = []
      }
      layout.push(
        <Container key={index} flex fixed>
          {child}
        </Container>
      )
    } else if (child.props.n) {
      columns.push(
        <Column
          key={index}
          n={child.props.n}
          m={child.props.m}
          s={child.props.s}
        >
          {navUrl && child.props.header ? (
            <Link
              to={navUrl}
              onClick={() => setLastClickedRow(record.id)}
              className={styles.dtLink}
            >
              {child}
            </Link>
          ) : (
            child
          )}
        </Column>
      )
    } else {
      columns.push(
        <Column key={index} n={12} m={12} s={12}>
          {child}
        </Column>
      )
    }
  }

  if (columns.length > 0) {
    layout.push(<Row key={layout.length + 1}>{columns}</Row>)
  }

  const { lastClickedRow, setLastClickedRow } = useDataTable()

  const handleRowClick = (r) => {
    setLastClickedRow(r.id)
    onRowClick(r)
  }

  return (
    <RecordProvider r={record}>
      <CustomRowView
        onClick={onRowClick ? () => handleRowClick(record) : undefined}
        highlight={
          lastClickedRow !== null &&
          lastClickedRow !== undefined &&
          lastClickedRow == record.id
        }
        color={rowColor ? rowColor(record) : undefined}
      >
        {layout}
      </CustomRowView>
    </RecordProvider>
  )
}

/**
 * Renders a column for each record.
 *
 * @param {String} field field name
 * @param {String} label field label
 * @param {boolean} sortable flag for sort
 * @param {String} sortKey sort key
 * @param {Function} filter gets value for filtering
 * @param {Function} value gets value to show
 * @param {Array} children list of children
 * @param {Function} link generates a link to follow
 * @param {Function} fetch uses this fetch function to retrive filter values
 * @param  {...any} rest other porps
 * @returns
 */
export const SimpleColumn = ({
  field,
  // in case the value obtained by "field" is in a JSON object
  parentField,
  label,
  sortable,
  sortKey,
  filter,
  value,
  children,
  link,
  fetch,
  // A Url to fetch filter values if filter flag is set
  filterUrl,
  title,
  // if set, make the value a header and skip the label
  header,
  ...rest
}) => {
  console.assert(
    !(filterUrl && fetch),
    'Programming error. "fetch" and "filterUrl" props are mutually exclusive.'
  )
  const service = useService()
  if (filterUrl) {
    fetch = async (params) => await service.get(filterUrl, params)
  }
  const record = useRecord()
  const { reload } = useQuery()
  const filterable = !!field && !!filter && !!fetch

  function getTextValue() {
    if (typeof value == 'function') {
      return value(record)
    }
    if (typeof title == 'function') {
      return title(record[field])
    }
    const newValue = record[parentField]
      ? record[parentField][field]
      : record[field]
    return value?.toString() ?? newValue?.toString()
  }

  // Choose a default label based on the field
  if (!label && field && !header) {
    label = `${(field[0] ?? '').toUpperCase()}${field.substring(1) ?? ''}`
  }

  // The following code has an strong assumption that getTextValue always returns
  // a string. Nothing stops anyone from providing JSX however (which is why I'm
  // writing this). For example div can not appear under p tag (which was the case).
  // So the typeof check at the end tries to prevent that, but for sure there are
  // other edge cases. Assumptions should be controlled (asserted) at least.
  const textValue = getTextValue()

  if (label) {
    return filterable || sortable ? (
      <FilterFieldView
        sortKey={sortable || sortKey ? (sortKey ? sortKey : field) : undefined}
        fieldKey={field}
        fieldValue={filter ? record[field] : undefined}
        label={label}
        value={textValue}
        href={link ? link(record) : null}
      >
        {children && children(record, reload)}
      </FilterFieldView>
    ) : (
      <FieldView
        label={label}
        value={textValue}
        href={link ? link(record) : null}
      >
        {typeof children === 'function' ? children(record, reload) : children}
      </FieldView>
    )
  } else if (children) {
    return typeof children === 'function' ? children(record, reload) : children
  } else {
    return link ? (
      <Link to={link(record)} onClick={(e) => e.stopPropagation()}>
        {textValue}
      </Link>
    ) : typeof textValue == 'string' ? (
      header ? (
        <h3>{textValue}</h3>
      ) : (
        <p>{textValue}</p>
      )
    ) : (
      textValue
    )
  }
}

const CustomRowView = styled(RowView)`
  ${(props) =>
    props.nopadding &&
    css`
      padding: 0;
    `}
  ${(props) =>
    !props.highlight &&
    props.background &&
    css`
      background: ${props.background};
    `}
    gap: 10px;
  ${(props) =>
    props.color &&
    css`
      border-left: 10px solid ${props.color};
    `}
`
const RowNumberBadge = styled(Container)`
  border-radius: 5px;
  background: #9ab5f9;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  margin-top: 2px;

  p {
    color: #ffffff;
  }
`

import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

import { Container } from 'common/widgets/container'
import { DataSource } from 'common/widgets/data-source'
import { useService } from 'common/service/context'
import { SaveButton } from 'common/widgets/button'

import {
  calculateFaultAmount,
  extractFaults,
  groupBackDeliveryItems,
} from '../../utils'

import { BackDeliveredItems } from './items'
import {
  PickBackdeliveryItemOverlay,
  SubmitBackDeliveryOverlay,
} from './overlays/add'
import { CreateBackDeliveryForm } from './details'
import { BackDeliverySummaryOverlay } from './overlays/summary'

/**
 * Renders the back deliveries view page.
 *
 * @param {any} project Current project object
 * @param {any} reload reloads the page
 *
 * @returns ReactElemnt
 */
export const BackDeliveryCreateViewPage = ({ project, reload }) => {
  const service = useService()

  /**
   * Fetches the project inventory items
   *
   * @returns [array]
   */
  const fetch = async () =>
    await service.get(`back_delivery/commissionable/${project?.id}`)

  return (
    <DataSource fetch={fetch}>
      {({ data }) =>
        data && (
          <BackDeliveryCreateContent
            project={project}
            data={data}
            reload={reload}
          />
        )
      }
    </DataSource>
  )
}

const BackDeliveryCreateContent = ({ project, data, reload }) => {
  const service = useService()
  const navigate = useNavigate()
  const [backdeliveryItems] = useState(groupBackDeliveryItems(data))
  const [targetProjectId, setTargetProjectId] = useState(null)
  const [addBackdeliveryItem, setAddBackdeliveryItem] = useState(null)
  const [summaryOverlayVisible, setSummaryOverlayVisible] = useState(false)
  const [
    submitBackDeliveryOverlayVisible,
    setSubmitBackDeliveryOverlayVisible,
  ] = useState(false)
  const [forDelivery, setForDelivery] = useState(true)

  const handleBackDeliveryItemAdd = (backDeliveryItem) => {
    setAddBackdeliveryItem(backDeliveryItem)
  }

  const resources = backdeliveryItems?.filter(
    (item) => item.baseitem?.is_resource
  )
  const formworks = backdeliveryItems?.filter(
    (item) => item.baseitem?.is_formwork
  )
  return (
    <>
      <CreateBackDeliveryForm
        currentProjectId={project.id}
        setTargetProjectId={setTargetProjectId}
        forDelivery={forDelivery}
        setForDelivery={setForDelivery}
      />
      <Container flex vertical grow>
        {resources.length > 0 && (
          <BackDeliveredItems
            onAdd={handleBackDeliveryItemAdd}
            backDeliveryItems={resources}
            title="RESOURCE"
          />
        )}
        {formworks.length > 0 && (
          <BackDeliveredItems
            onAdd={handleBackDeliveryItemAdd}
            backDeliveryItems={formworks}
            title="FORMWORK"
          />
        )}
      </Container>
      <PickBackdeliveryItemOverlay
        onClose={() => setAddBackdeliveryItem(null)}
        backdeliveryItem={addBackdeliveryItem}
      />
      {summaryOverlayVisible && (
        <BackDeliverySummaryOverlay
          open={summaryOverlayVisible}
          backDeliveryItems={backdeliveryItems.filter(
            (e) => e.commissionedAmount > 0
          )}
          projectId={project.id}
          onSubmit={async () => {
            if (forDelivery) {
              const data = {
                project_id: project.id,
                dest_project_id: targetProjectId ? targetProjectId : null,
                items: backdeliveryItems
                  .filter((e) => e.commissionedAmount > 0)
                  .map((e) => ({
                    baseitem_id: e.baseitem.id,
                    booking_id: e.booking.id,
                    amount: e.commissionedAmount,
                    ok: e.commissionedAmount - calculateFaultAmount(e),
                    faults: extractFaults(e),
                  })),
              }
              const [result, error] = await service.post(`back_delivery`, {
                ...data,
              })

              if (result && !error) {
                navigate(-1)
              }
            } else {
              setSubmitBackDeliveryOverlayVisible(true)
            }
          }}
          onClose={() => setSummaryOverlayVisible(false)}
        />
      )}
      <SubmitBackDeliveryOverlay
        open={submitBackDeliveryOverlayVisible}
        onClose={() => setSubmitBackDeliveryOverlayVisible(false)}
        backDeliveryItems={backdeliveryItems.filter(
          (e) => e.commissionedAmount > 0
        )}
        projectId={project.id}
        targetProjectId={targetProjectId}
        forDelivery={forDelivery}
      />
      <Container>
        <SaveButton
          disabled={
            backdeliveryItems.filter((item) => item.commissionedAmount > 0)
              .length === 0
          }
          default
          onClick={() => {
            setSummaryOverlayVisible(true)
          }}
        />
      </Container>
    </>
  )
}

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Container } from 'common/widgets/container'
import { DataSource } from 'common/widgets/data-source'
import { CardView, GridFieldView } from 'common/widgets/view'
import { DropDown } from 'common/widgets/dropdown'
import { Column, Row } from 'common/widgets/grid'
import { formatDateRange, formatDate } from 'common/utils/format'

import { StatsPie, VerticalDivider } from './widgets'

/**
 * Renders statistics page for absence.
 *
 * @returns ReactElement
 */
export const AbsenceStats = () => {
  const { t } = useTranslation()
  const [selectedPeriod, setSelectedPeriod] = useState('TODAY')
  const [params, setParams] = useState([{ period_type: 'TODAY' }])

  const periods = [
    {
      key: 'TODAY',
      title: 'Today',
    },
    {
      key: 'TOMORROW',
      title: 'Tomorrow',
    },
    {
      key: 'CURRENT_WEEK',
      title: 'Current week',
    },
    {
      key: 'CURRENT_MONTH',
      title: 'Current month',
    },
    {
      key: 'CURRENT_YEAR',
      title: 'Current year',
    },
    {
      key: 'NEXT_WEEK',
      title: 'Next week',
    },
    {
      key: 'NEXT_MONTH',
      title: 'Next month',
    },
    {
      key: 'PREVIOUS_WEEK',
      title: 'Previous week',
    },
    {
      key: 'PREVIOUS_MONTH',
      title: 'Previous month',
    },
    {
      key: 'PREVIOUS_YEAR',
      title: 'Previous year',
    },
  ]

  return (
    <CardView>
      <Container flex repel>
        <h1>{t('Absences')}</h1>
        <Container flex align="center">
          <DropDown
            style={{ minWidth: '240px' }}
            title="Period"
            items={periods}
            onSelectChange={(value) => {
              setSelectedPeriod(value?.key)
              if (value) {
                setParams([{ period_type: value.key }])
              }
            }}
            selectedIndex={periods.findIndex((e) => e.key === selectedPeriod)}
          />
        </Container>
      </Container>
      <Statistics params={params} />
    </CardView>
  )
}

const Statistics = ({ params = [{ period_type: 'TODAY' }] }) => {
  return (
    <DataSource url="/contact/absences/stats" params={params}>
      {({ data, reload }) =>
        data && (
          <Row style={{ paddingBottom: '10px' }}>
            <Column n={2.5} m={12} s={12}>
              <StatsPie
                data={[
                  {
                    label: 'Available',
                    value: data.available,
                    color: '#3D6AEF',
                  },
                  { label: 'Absent', value: data.absent, color: '#DF2DC3' },
                ]}
                metricValue={data.employees}
                metricText="Employees"
              />
            </Column>
            <Column n={9.5} m={12} s={12}>
              <h1
                style={{
                  fontWeight: 'bold',
                  fontSize: '22px',
                  paddingBottom: '20px',
                }}
              >
                {data.absence_start !== data.absence_end
                  ? formatDateRange(data.absence_start, data.absence_end)
                  : formatDate(data.absence_start)}
              </h1>
              <Container flex style={{ paddingBottom: '20px' }}>
                <ColoredText
                  color="#3D6AEF"
                  text="Available"
                  value={data.available}
                />
                <ColoredText
                  color="#DF2DC3"
                  text="Absent"
                  value={data.absent}
                />
              </Container>
              <Container flex>
                <ColoredText
                  color="#36CC35"
                  text="Vacation"
                  value={data.approved.VACATION}
                />
                <ColoredText
                  color="#F94F4A"
                  text="Sick leave"
                  value={data.approved.ILLNESS}
                />
                <ColoredText
                  color="#723398"
                  text="School"
                  value={data.approved.SCHOOL}
                />
                <ColoredText
                  color="#4974F0"
                  text="Special"
                  value={data.approved.SPECIAL_LEAVE}
                />
                <ColoredText
                  color="#F4AC65"
                  text="Pending"
                  value={data.pending}
                />
              </Container>
            </Column>
          </Row>
        )
      }
    </DataSource>
  )
}

const ColoredText = ({ color, text, value }) => {
  const { t } = useTranslation()

  return (
    <>
      <VerticalDivider
        color="#E9E9E9"
        style={{ marginRight: '10px', width: '3px' }}
      />
      <GridFieldView n={2} s={4} style={{ minWidth: '120px' }}>
        <h1
          style={{
            fontWeight: '900',
            fontSize: '30px',
            color: color,
          }}
        >
          {value}
        </h1>
        <h1
          style={{
            fontSize: '17px',
            color: '#939393',
          }}
        >
          {t(text)}
        </h1>
      </GridFieldView>
    </>
  )
}

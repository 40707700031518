import React from 'react'

import { useService } from 'common/service/context'
import { Form } from 'common/widgets/form/context'

import { ConsumablesFormContent } from './form'

export const ConsumablesAddPage = () => {
  const service = useService()

  const handleAdd = async (values) => {
    return await service.post('items/consumables/', values)
  }

  return (
    <Form>
      <ConsumablesFormContent onAdd={handleAdd} />
    </Form>
  )
}

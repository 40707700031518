/**
 * Formats the delivery number based on the delivery data.
 *
 * @param {any} delivery delivery object
 * @returns string
 */
export const formatDeliveryNumber = (orderNumber, deliveryNumber) =>
  orderNumber && deliveryNumber
    ? String(orderNumber).padStart(12, '0') +
      '/' +
      String(deliveryNumber).padStart(2, '0')
    : ''

/**
 * Formats the back delivery number based on the back delivery data.
 *
 * @param {any} backDelivery delivery object
 * @returns string
 */
export const formatBackDeliveryNumber = (backDelivery) =>
  String(backDelivery?.id).padStart(12, '0')

/**
 * Groups the formworks in the backdelivery
 *
 * @param {array} items backdelivery items array
 * @returns array
 */
export const groupBackDeliveryItems = (items) => {
  var backdeliveryItems = items
    ?.map((e) => ({
      baseitem: e.baseitem,
      amount: e.checkin_amount - e.checkout_amount - e.onway_amount,
      booking: e.booking,
      booking_start: e.booking.booking_start,
      booking_end: e.booking.booking_end,
      faults: [],
    }))
    .filter((e) => e.amount > 0)
  /// Proccessed inventory items
  const invetoryItems = []
  /// Grouping the inventory items so that we combine their amounts if there are more than one identical items
  while (backdeliveryItems?.length > 0) {
    /// Inventory item to be proccessed
    const inventoryItem = backdeliveryItems[0]
    /// Group only formworks
    if (inventoryItem.baseitem?.is_formwork) {
      /// Get all items that are the same as the @inventoryItem
      const filteredItems = backdeliveryItems.filter(
        (e) =>
          e.baseitem.name === inventoryItem.baseitem.name &&
          e.baseitem.inventory_number ===
            inventoryItem.baseitem.inventory_number
      )
      /// Combined amount of all items that are the same as the @inventoryItem
      var amount = 0
      /// Adding the amount of each identical item
      for (var i = 0; i < filteredItems.length; i++) {
        const filteredItem = filteredItems[i]
        amount = amount + filteredItem.amount
      }
      inventoryItem.amount = amount
      invetoryItems.push(inventoryItem)
      /// If the @inventoryItem is not formwork just add it without grouping
    } else {
      invetoryItems.push(inventoryItem)
    }
    /// Removes all items which are identical to @inventory item from the list of the inventory items
    backdeliveryItems = backdeliveryItems.filter(
      (e) =>
        !(
          e.baseitem.name === inventoryItem.baseitem.name &&
          e.baseitem.inventory_number ===
            inventoryItem.baseitem.inventory_number
        )
    )
  }

  return invetoryItems
}

/**
 * Calculates the reported fault amount for a back delivery item
 *
 * @param {any} backdeliveryItem back delivery item
 *
 * @returns int
 */
export const calculateFaultAmount = (backdeliveryItem) => {
  var amount = 0
  if (backdeliveryItem.faults) {
    for (var i = 0; i < backdeliveryItem.faults.length; i++) {
      amount = amount + backdeliveryItem.faults[i].amount
    }
  }

  return amount
}

/**
 * Extracts the reported faults from the backdelivery item
 *
 * @param {any} backdeliveryItem back delivery item
 *
 * @returns map
 */
export const extractFaults = (backdeliveryItem) => {
  if (backdeliveryItem.faults) {
    return backdeliveryItem.faults.map((e) => ({
      amount: e.amount,
      type: e.type,
      comment: e.comment,
    }))
  }

  return []
}

/**
 * Delivery note color states
 */
export const DeliveryStateColor = {
  COMMISSIONING: '#BABABA',
  READY_FOR_PICKUP: '#AEC4FA',
  READY_FOR_TRANSPORT: '#4A7BF5',
  TRANSPORT: '#F9F29A',
  DELIVERED: '#2DCE18',
  CANCELED: '#FC4242',
}

export const BackDeliveryStateColor = {
  READY: '#BABABA',
  TRANSPORT: '#F9F29A',
  CHECKED: '#2DCE18',
  DELIVERED: '#4A7BF5',
  CANCELED: '#FC4242',
}

import { EditViewPage } from 'system/utils/edit'

import { RoleForm } from './form'

export const RoleEditPage = () => (
  <EditViewPage
    url="/security/roles"
    params={[{ select: 'scopes:scope(id,key,description,parent_id)' }]}
    title={(e) => e.name}
  >
    {(data) => <RoleForm data={data} />}
  </EditViewPage>
)

import { Form } from 'common/widgets/form/context'
import {
  TextField,
  TextAreaField,
  FileField,
  CheckBoxField,
} from 'common/widgets/form/field'
import { CardView, Panel } from 'common/widgets/view'

import { CategorySelectField } from './widgets'

export const CategoryForm = ({ edit, data, ...rest }) => {
  return (
    <Form baseUrl="/pim/categories" showActions auto data={data} {...rest}>
      <CardView>
        <FileField
          name="photo"
          idField="photo_id"
          label="Photo"
          accept="image/png, image/jpeg"
          preview
        />
        <Panel>
          <TextField name="name" label="Name" mandatory />
          <CategorySelectField
            id={data?.id}
            name="parent_id"
            label="Parent group"
          />
        </Panel>
        <Panel>
          <CheckBoxField name="is_unique" label="Has a unique product?" />
          <CheckBoxField name="requires_booking" label="Requires booking?" />
          <CheckBoxField name="is_consumable" label="Is consumable?" />
          <CheckBoxField name="is_orderable" label="Is orderable?" />
          <CheckBoxField name="self_commission" label="Self commission?" />
        </Panel>
        <TextAreaField name="description" label="Description" rows="5" />
      </CardView>
    </Form>
  )
}

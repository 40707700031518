.noSessionTemplate {
  display: flex;
  flex-grow: 1;
  height: 100%;
}

.splash {
  margin: auto;
  width: 100%;
  height: auto;
}

.splashArea {
  display: flex;
  flex-grow: 1;
  max-width: 50vw;
  flex-direction: column;
  padding: 20px;
  padding-top: 20%;
  z-index: -1;
}

@media (max-width: 980px) {
  .splashArea {
    opacity: 0.2;
    position: fixed;
    bottom: 30px;
    width: 100vw;
    max-width: 100vw;
  }
}

.right {
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 20px;
  padding-top: 15%;
}

.right h1 {
  font: normal normal bold 24px/31px Red Hat Display;
}
.right h3 {
  font: normal normal normal 16px/21px Red Hat Display;
  opacity: 0.5;
  padding-bottom: 40px;
}

import { Container } from 'common/widgets/container'
import { Avatar } from 'common/widgets/avatar'
import { FieldView } from 'common/widgets/view'
import { formatUserName } from 'modules/security/users/utils'

export const ContactView = ({ contact }) => (
  <Container flex gap="10px">
    <Avatar
      imgSrc={contact.system_user?.photo}
      text={contact.system_user?.fullname}
      size="24px"
    />
    <FieldView
      href={`/master-data/contacts/${contact.id}`}
      value={formatUserName(contact.system_user)}
    />
  </Container>
)

import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useService } from 'common/service/context'
import { dateToISOString } from 'common/utils/format'
import { FullCalendarWrapper } from 'common/widgets/calendar'

const PlanningCalendar = ({ onDateClick, onEventClick }) => {
  const service = useService()
  let events = []

  const fetchEvents = async (
    { start, end, startStr, endStr, timeZone },
    successCallback,
    failureCallback
  ) => {
    const [response, error] = await service.get(
      `/logistics/transports?date[gte]=${dateToISOString(
        start,
        true
      )}&date[lte]=${dateToISOString(end, true)}`
    )
    if (error) {
      failureCallback()
    } else {
      events = response.data
      successCallback(
        response.data?.map((record, index) => ({
          title: record?.resource?.name,
          date: record?.date,
          id: record.id,
          allDay: true,
        }))
      )
    }
  }

  return (
    <FullCalendarWrapper
      events={fetchEvents}
      dateClick={onDateClick}
      eventClick={(event) =>
        onEventClick(event, events.filter((e) => e.id == event.event.id)[0])
      }
    />
  )
}

export const TourPlanningPage = () => {
  const navigate = useNavigate()
  return (
    <PlanningCalendar
      onDateClick={(event) =>
        navigate(`/logistics/planning/transports/${event.dateStr}`)
      }
      onEventClick={(event, transport) => {
        transport
          ? navigate(
              `/logistics/planning/transports/${dateToISOString(
                event.event.start,
                true
              )}?transporter_id=${transport.resource.id}`
            )
          : console.error('Expected a transport but got nothing instead.')
      }}
    />
  )
}

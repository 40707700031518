{
  "orinoco Solutions": "Digitale Betriebsmittelverwaltung",
  "Please login to your account.": "Bitte loggen Sie sich in Ihr Konto ein.",
  "Please login to access {{from}}.": "Bitte loggen Sie sich ein, um auf {{from}} zuzugreifen",
  "Users": "Benutzer",
  "First name": "Vorname",
  "Last name": "Nachname",
  "Projects": "Projekte",
  "Resources": "Betriebsmittel",
  "Sort": "Sortieren",
  "Showing": "Anzeige",
  "Active Filters": "Aktive Filter",
  "Amount": "Menge",
  "Equipment": "Ausrüstung",
  "Equipments": "Ausrüstungen",
  "Small scale equipments": "Kleingeräte",
  "Large scale equipments": "Großgeräte",
  "Consumables": "Verbrauchsstoffe",
  "Formworks": "Rüstung und Schalung",
  "FORMWORK": "Rüstung und Schalung",
  "Overview": "Übersicht",
  "per_day": "Pro Tag",
  "per_hour": "Pro Stunde",
  "per_month": "Pro Monat",
  "Yes": "Ja",
  "No": "Nein",
  "Label": "Bezeichnung",
  "Master data": "Stammdaten",
  "Inventory number": "Inventarnummer",
  "Accounting group": "Kostenart",
  "Accounting type": "Verrechnungstyp",
  "rental": "Miete",
  "buy": "Kauf",
  "without": "Ohne",
  "Comment": "Anmerkung",
  "own": "Eigen",
  "shared": "Fremd",
  "Orderable": "Bestellbar",
  "New": "Neu",
  "new": "Neu",
  "used": "Gebraucht",
  "Sign in": "Anmelden",
  "Remember me": "Angemeldet bleiben",
  "Forgot password": "Passwort vergessen",
  "Forgot your password?": "Passwort vergessen",
  "Reset password": "Passwort zurücksetzen",
  "Back to login": "Zurück zur Anmeldung",
  "Password": "Passwort",
  "Weight": "Gewicht",
  "Dimensions and cargo": "Abmessungen und Ladung",
  "Identification": "Identifikation",
  "Bookings history": "Buchungshistorie",
  "Accessories": "Zubehör",
  "Print": "Drucken",
  "QR code": "QR Code",
  "Documents": "Dokumente",
  "Size": "Größe",
  "Last modification date": "Letztes Änderungsdatum",
  "Preview": "Vorschau",
  "Creation date": "Erstellungsdatum",
  "Back deliveries": "Rücklieferscheine",
  "Backdeliveries": "Rücklieferscheine",
  "Back Delivery Notes": "Rücklieferscheine",
  "Back delivery notes": "Rücklieferscheine",
  "Back delivery note": "Rücklieferschein",
  "Delivery note": "Lieferschein",
  "Orders overview": "Bestellungen Überblick",
  "Orders list": "Bestellungen",
  "Contacts": "Kontakte",
  "Add": "Hinzufügen",
  "Tags": "Stichwörter",
  "Tag": "Stichwort",
  "Edit": "Bearbeiten",
  "Upload": "Hochladen",
  "Back": "Zurück",
  "Cancel": "Abbrechen",
  "Remove": "Löschen",
  "Company": "Unternehmen",
  "Fullname": "Vollständiger Name",
  "State": "Status",
  "States": "Status",
  "Required": "erforderlich",
  "Country": "Land",
  "Zipcode": "Postleitzahl",
  "Attach": "Hinzufügen",
  "Address": "Anschrift",
  "Job title": "Position",
  "Phone": "Telefon",
  "Webpage": "Webseite",
  "Files": "Dateien",
  "Save": "Speichern",
  "Street": "Straße",
  "Street number": "Hausnummer",
  "Zip code": "PLZ",
  "City": "Ort",
  "Security": "Sicherheit",
  "Commission": "Kommissionierung",
  "Delivery option": "Lieferoption",
  "Planned date": "Liefertermin",
  "Orderer": "Besteller",
  "Order data": "Bestelldaten",
  "Delivery data": "Lieferdaten",
  "Order number": "Bestellnummer",
  "Item": "Artikel",
  "Ordered amount": "Bestellte Menge",
  "Open amount": "Offene Menge",
  "Commissioned amount": "Kommissionierte Menge",
  "Delivered amount": "Gelieferte Menge",
  "Canceled amount": "Stornierte Menge",
  "Packaging unit": "Verpackungseinheit",
  "System": "System",
  "Modules": "Module",
  "Send date": "Absendedatum",
  "Favorite": "Favorit",
  "Roles": "Rollen",
  "Permissions": "Berechtigungen",
  "Results per page": "Ergebnisse pro Seite",
  "Faster": "Schneller",
  "Slower": "Langsamer",
  "Master projects": "Projektstamm",
  "My projects": "Projekte",
  "Project status": "Projektstatus",
  "Billing Data": "Abrechnung",
  "maintenance": "Instandhaltung",
  "Maintenance": "Instandhaltung",
  "disposition": "Disposition",
  "webshop": "Bestellung",
  "project": "Projekt",
  "damage": "Schadensmeldung",
  "consumable": "Verbrauchsstoffe",
  "kommission": "Kommissionierung",
  "Booking period": "Buchungszeitraum",
  "Requires delivery": "Lieferung",
  "Does not require delivery": "Selbstabholung",
  "Logisticer": "Logistiker",
  "Pick-up location": "Abholort",
  "Recipient signature": "Empfänger Unterschrift",
  "Building yard": "Bauhof",
  "Cancel item": "Artikel stornieren?",
  "Cancel order": "Bestellung wurde bereits bearbeitet. Möchten Sie diese wirklich komplett stornieren?",
  "Fixed date": "Fixtermin",
  "Earliest required date": "Spätestens",
  "Latest required date": "Frühestens",
  "Warning": "Warnung",
  "Target project": "Zielprojekt",
  "COMMISSIONING": "In Kommissionierung",
  "READY": "Bereit zur Lieferung",
  "READY_FOR_PICKUP": "Bereit zur Abholung",
  "READY_FOR_TRANSPORT": "Bereit zur Lieferung",
  "TRANSPORT": "Lieferung",
  "DELIVERED": "Ausgeliefert",
  "CANCELED": "Storniert",
  "CHECKED": "Geprüft",
  "Back delivery data": "Rücklieferscheindaten",
  "Ordered items": "Bestellte Artikel",
  "Delivery notes": "Lieferscheine",
  "Reset delivery item`s amount": "Menge des Liefergegenstandes zurücksetzen",
  "Remove delivery item": "Liefergegenstand entfernen",
  "Cancel delivery": "Lieferung stornieren",
  "Cancel back delivery": "Rückkommissionierung stornieren",
  "Edit commissioning item": "Provisionsposition bearbeiten",
  "Scan inventory number": "Inventarnummer eingeben",
  "Complete": "Abschließen",
  "Handover": "Ausliefern",
  "Ad Hoc Delivery": "Ad-hoc-Lieferung",
  "Recipient": "Empfänger",
  "Dispositioned amount": "Disponierte Menge",
  "SELF": "Selbstentnahme",
  "COMMISSIONER": "Ausgabe",
  "Commission type": "Kommissionierungsart",
  "There are [{{overflow}}] more results...": "Es gibt [{{overflow}}] weitere Einträge...",
  "Missing amount": "Fehlende Menge",
  "Defective amount": "Defekte Menge",
  "conresma.security.authenticate.exceptions.AuthenticationFailed": "Anmeldung nicht möglich. Bitte wenden Sie Sich an Ihren Administrator.",
  "Defect statement": "Mängelangabe",
  "Faults": "Mängelangabe",
  "For order": "in Ordnung",
  "For testing": "zur Prüfung",
  "For repair": "zur Reparatur",
  "For removal": "zur Entsorgung",
  "Confirm": "Bestätigen",
  "There are no available telematics.": "Es sind keine Telematicsgeräte vorhanden.",
  "Back delivery number": "Rücklieferungsnummer",
  "Ad Hoc Back Delivery": "Ad-hoc-Rücklieferung",
  "Back Delivery": "Rückkommissionierung",
  "Back delivery": "Rückkommissionierung",
  "Quantity unit": "Mengeneinheit",
  "Storage": "Lagerort",
  "Stock level": "Bestand",
  "Min stock level": "Min. Bestand",
  "Max stock level": "Max. Bestand",
  "Reserved": "Reserviert",
  "Supplier": "Lieferant",
  "Commissioning": "Kommissionierung",
  "Disposition": "Disposition",
  "Process": "Arbeitsprozess",
  "Cost rate": "Verrechnungssatz (Euro/Abrechnungseinheit)",
  "Balance sheet depreciation": "Bilanz. Abschreibungsdauer (Monate)",
  "Calculated Depreciation (Months)": "Kalk. Abschreibungsdauer (Monate)",
  "Billing": "Abrechnung",
  "Serial number": "Seriennummer",
  "Telematics data": "Telematikdaten",
  "Telematics id": "Identifikation Telematikeinheit",
  "Master data diverse": "Weitere Stammdaten",
  "Manufacturer": "Hersteller-/Typenbezeichnung",
  "License plate": "Kennzeichen",
  "Plate number": "Kennzeichen",
  "Engine power": "Maschinenleistung (kW)",
  "Average fuel consumption": "Durchschnittlicher Kraftstoffverbrauch (l/h)",
  "Internal billing rate": "Innerbetrieblicher Verrechnungssatz (€/Mengeneinheit)",
  "Source of supply": "Bezugsquelle",
  "Accounting and stock": "Lager und Abrechnung",
  "Price when new": "Neuwert",
  "Total stock": "Gesamtbestand",
  "Condition": "Zustand",
  "Transportation": "Transportmittel",
  "Clear inner dimensions (LxWxH)": "Lichte Innenmaße (LxBxH)",
  "Outer dimensions (LxWxH)": "Außenmaße (LxBxH)",
  "Maximum outer dimensions (LxWxH)": "Max. Außenmaße (LxBxH)",
  "Last updated": "Letzte Aktualisierung",
  "Ambient temperature": "Umgebungstemperatur",
  "Battery capacity": "Batteriekapazität",
  "Battery level (SOC)": "Ladezustand (SOC)",
  "Responsible orderer": "Verantwortlicher Besteller",
  "Billing data": "Abrechnung und Verantwortung",
  "Inventory": "Baustellenbestand",
  "Booking": "Buchungen",
  "Group": "Gruppe",
  "Name": "Bezeichnung",
  "Short name": "Kurzbezeichnung",
  "Status": "Status",
  "Cost center": "Kostenstelle",
  "Start": "Start",
  "End": "Ende",
  "Senior site manager": "Oberbauleiter",
  "Site manager": "Bauleiter",
  "Site foreman": "Polier",
  "SUBSISTENCE_SMALL": "Auslösung",
  "SUBSISTENCE_LARGE": "Verpflegungszuschuss",
  "NONE": "Keine",
  "Date": "Datum",
  "Start - End": "Start - Ende",
  "Shortest distance": "Kürzeste Entfernung (km)",
  "Subsistence": "Auslösung",
  "Contract volume": "Bauvolumen",
  "Subcontractor": "Subunternehmer",
  "Rent rate": "Leistungsverrechnungssatz (%pro Monat)",
  "Loading rate": "Ladesatz",
  "ONGOING": "Laufende Baustelle",
  "ACCOUNTED": "Schlussgerechnet",
  "COMMISSIONED": "Neue Baustelle beauftragt",
  "EXPECTED": "Erwartetes Projekt",
  "FINISHED": "Fertig, nicht abgerechnet",
  "Subsistence small": "Auslösung",
  "Subsistence large": "Verpflegungszuschuss",
  "None": "Keine",
  "Responsible users": "Projektbeteiligte",
  "Location": "Standort",
  "Orders": "Bestellungen",
  "Deliveries": "Lieferscheine",
  "Delivery Notes": "Lieferscheine",
  "Project": "Projekt",
  "Order": "Bestellung",
  "Ordered at": "Bestelldatum",
  "Required by": "Benötigt am",
  "Earliest": "Frühestens",
  "Latest": "Spätestens",
  "Creator": "Besteller",
  "Actions": "Aktion",
  "Self-pickup": "Selbstabholung",
  "CONSUMABLE": "Verbrauchsstoffe",
  "RESOURCE": "Betriebsmittel",
  "OPEN": "Offen",
  "COMPLETE": "Abgeschlossen",
  "IN_PROGRESS": "in Bearbeitung",
  "Delivery number": "Lieferscheinnummer",
  "Source project": "Abholort",
  "Logistic user": "Logistiker",
  "Delivery required by": "Bereitstellungsdatum",
  "Unknown": "Bauhof",
  "Clear": "Löschen",
  "Search": "Suchen",
  "Role": "Rolle",
  "Logout": "Ausloggen",
  "Types": "Typen",
  "View": "Überblick",
  "Cart": "Warenkorb",
  "Picking Method": "Lieferoption",
  "Delivery": "Lieferung",
  "Selfpick": "Selbstabholung",
  "Disposition amount": "Disponierte Menge",
  "Assignee": "Beuaftragte",
  "Return deliveries": "Rücklieferscheine",
  "Members": "Mitglieder",
  "Project Members": "Projektbeteiligte",
  "Target location": "Zielort",
  "Self pickup": "Selbstabholung",
  "Creator user": "Ersteller",
  "Created at": "Erstelldatum",
  "Upload file": "Hochladen",
  "Clear cart": "Leeren",
  "Place order": "Bestellen",
  "Booking start": "Buchungsbegin",
  "Booking end": "Buchungsende",
  "Type": "Art",
  "Yard": "Bauhof",
  "Description": "Beschreibung",
  "Project Templates": "Projektvorlagen",
  "New project template": "Neue Projektvorlage",
  "Edit project template": "Projektvorlage bearbeiten",
  "Start date": "Start",
  "End date": "Ende",
  "Related items": "Zubehör",
  "amount": "Menge",
  "Too short": "Zu kurz",
  "Too long": "Zu lang",
  "Color": "Farbe",
  "Phone number": "Telefonnummer",
  "Last login": "Letzte Anmeldung",
  "Last login {{duration}} ago": "Letzte Anmeldung vor {{duration}}",
  "Telephone": "Telefonnummer",
  "Main group": "Hauptgruppe",
  "Main Group": "Hauptgruppe",
  "Subgroup": "Untergruppe",
  "Reserved amount": "Reservierte Menge",
  "Available amount": "Bauhofbestand",
  "List": "Liste",
  "Planned delivery date": "Liefertermin",
  "Destination": "Zielort",
  "Requests": "Anfragen",
  "Booking shrinks": "Freimeldungen",
  "Booking extensions": "Buchungsverlängerungen",
  "Booking shrink": "Freimeldung",
  "Booking extension": "Buchungsverlängerung",
  "Has no assignee.": "Keine Beuaftragte",
  "Checkin amount": "Check-in-Menge",
  "Load": "Beladen",
  "Sign": "Unterschreiben",
  "Booking period must be specified.": "Buchungszeitraum muss angegeben werden.",
  "Tour Planning": "Tourenplanung",
  "Article": "Artikel",
  "Retours": "Retouren",
  "Create": "Erstellen",
  "Cancel task": "Stornieren",
  "Not delayed": "Nicht fällig",
  "Delayed soon": "Bald fällig",
  "Overdued": "Überfällig",
  "Carried out": "Durchgeführt",
  "Damage report": "Schadenmeldung",
  "Plan task": "Neue Prüfung planen",
  "Edit task": "Prüfung durchführen",
  "Plan damage report": "Schadensmeldung planen",
  "Planned from": "Geplant am",
  "Inspected on": "Prüfungsende",
  "Internal tester": "Interner prüfer",
  "External tester": "Externer prüfer",
  "Performer": "Prüfer",
  "Task type": "Aufgabentyp",
  "Task subtype": "Task-Untertyp",
  "planned_repair": "Geplante Reparatur",
  "tyre_change": "Reifenwechsel",
  "service": "Kundendienst",
  "others": "Sonstige",
  "check_electrical_device": "Prüfung elektrischer Anlagen",
  "initial_check": "Inbetriebnahmeprüfung",
  "visual_check": "Sichtprüfung",
  "recheck": "Wiederholungsprüfung",
  "UVV_check": "Prüfung nach UVV",
  "expert_check": "Sachkundigenprüfung",
  "other_check": "Sonstige Prüfung",
  "recheck_done": "Wiederholungsprüfung erforderlich?",
  "Successful": "Erfolgreich",
  "Not Successful": "Nicht erfolgreich",
  "Performed on": "Durchgeführt am",
  "Task History": "Aufgabenverlauf",
  "Planned Tasks": "Geplante Aufgaben",
  "Recurring exam": "Wiederkehrende Prüfung",
  "Last recurring": "Zuletzt wiederkehrend",
  "Period": "Periode",
  "Every": "alle",
  "DAILY": "Jeden X. Tag",
  "WEEKLY": "Wöchentlich",
  "MONTHLY": "Monatlich an Tag X",
  "YEARLY": "Jedes X. Jahr",
  "Task Result": "Prüfergebnis",
  "Re-examination": "Wiederholungsprüfung",
  "Re-examination date": "erneute Prüfung",
  "End of examination": "Prüfungsende",
  "Enter address": "Adresse eingeben",
  "Resource data": "Ressourcendaten",
  "Const type": "Kostenart",
  "DISPOSITION": "Disposition",
  "Info": "Angaben",
  "Resource filters": "Ressourcenfilter",
  "Submit": "Einreichen",
  "Category": "Kategorie",
  "Project location": "Standort - Projekt",
  "created by": "erstellt von",
  "created on": "erstellt am",
  "sucessfully archived.": "erfolgreich archiviert.",
  "sucessfully activated.": "erfolgreich aktiviert.",
  "Booking is for [{{days}}] day/days.": "Die Buchung ist für [{{days}}] Tage angerfragt.",
  "Booking shrink is for [{{days}}] day/days.": "Freimeldung ist für [{{days}}] Tage.",
  "Booking extension is for [{{days}}] day/days.": "Buchungsänderung ist für [{{days}}] Tage.",
  "Booking is confirmed!": "Die Buchung ist geprüft!",
  "Personal information": "Persönliche Daten",
  "Invalid E-Mail address": "Ungültige E-Mail-Adresse",
  "Add more": "Mehr hinzufügen",
  "No items availabe at project [{{name}}]": "Keine Artikel sind im Projekt [{{name}}] vorhanden",
  "Can be replaced": "Darf ersetzt werden",
  "Replaceable": "ersetzbar",
  "Not replaceable": "nicht ersetzbar",
  "Ordered main group": "Bestellte Hauptgruppe",
  "Ordered group": "Bestellte Gruppe",
  "Ordered subgroup": "Bestellte Untergruppe",
  "Availabilities": "Verfügbarkeiten",
  "Available": "Verfügbar",
  "Booking period differs!": "Buchungszeitraum unterschiedlich!",
  "Add source": "Quelle hinzufügen",
  "Booked amount": "Gebucht Menge",
  "Remained amount": "Restmenge",
  "Amount of [{{overdraft}}] is overbooked!": "Betrag von [{{overdraft}}] ist überbucht!",
  "Days": "Tage",
  "days": "Tage",
  "Booking is for [{{days}}] day(s).": "Buchung ist für [{{days}}] Tag(e).",
  "Booking is for [{{days}}] day(s) but request is for [{{original}}] day/days!": "Die Buchung gilt für [{{days}}] Tag(e), aber die Anfrage gilt für [{{original}}] Tag/Tage!",
  "Cancel remaining": "Restmenge stornieren",
  "Date is in the past": "Das Datum liegt in der Vergangenheit",
  "Frequently ordered together": "Häufig zusammen bestellt",
  "[{{related_items_count}}] items are related.": "hat [{{related_items_count}}] Zubehör.",
  "Extend to": "Verlängern bis",
  "Overlapping bookings": "Überlappende Buchungen",
  "There [{{count}}] more overlapping bookings...": "Es gibt [{{count}}] weitere überlappende Buchungen...",
  "Booked": "Gebucht",
  "Extended": "Verlängert",
  "Planned repair": "Geplante Reparatur",
  "Fault": "Defekt",
  "Add item": "Artikel hinzufügen",
  "Active": "Aktiv",
  "Inactive": "Inaktiv",
  "Today": "Heute",
  "Queued Trips": "Trips in der Warteschlange",
  "New Tour": "Neue Tour",
  "No trips": "Keine Trips",
  "Driver": "Fahrer",
  "Logistics": "Logistik",
  "Calendar": "Kalender",
  "Delivery date": "Lieferdatum",
  "Transporter name": "Transportername",
  "Driver name": "Fahrername",
  "Transports": "Touren",
  "New Tours": "Neue Touren",
  "All items": "Alle Artikel",
  "Pending booking requests": "Ausstehende Buchungsanfragen",
  "Pending extend request": "Ausstehende Verlängerungsanfragen",
  "Pending shrink request": "Ausstehende Freimeldunganfragen",
  "Maximum acceptable file size is {{maxFileSize}}!": "Die maximale Dateigröße beträgt {{maxFileSize}}!",
  "Provided file type is invalid!": "Der angegebene Dateityp ist ungültig!",
  "File successfully uploaded.": "Datei erfolgreich hochgeladen.",
  "Invalid login credentials": "Ungültige Zugangsdaten",
  "Error": "Fehler",
  "Reject": "Ablehnen",
  "Ordered item for order {{order}} and booking request for project {{project}} will be rejected, are you sure?": "Bestellter Artikel für Bestellung {{order}} und Buchungsanfrage für Projekt {{project}} werden abgelehnt, sind Sie sicher?",
  "Booking extension request for project {{project}} will get rejected, are you sure?": "Die Anfrage zur Buchungsverlängerung für das Projekt {{project}} wird abgelehnt, sind Sie sicher?",
  "Required booking period": "Benötigter Buchungszeitraum",
  "New booking period": "Neuer Buchungszeitraum",
  "Selected": "Ausgewählt",
  "Decrease item quantity": "Artikelmenge reduzieren",
  "Increase item quantity": "Artikelmenge erhöhen",
  "Current stock": "Aktueller Lagerbestand",
  "Added stock": "Bestand hinzugefügt",
  "New stock": "Neue Bestände",
  "Order is not free to commission, another user started commissioning before": "Auftrag ist nicht kommissionsfrei, ein anderer Nutzer hat zuvor mit der Kommissionierung begonnen",
  "Shrink": "Freimeldung",
  "Extend": "Buchungsänderung",
  "Checkin": "Check-in",
  "Checkout": "Check-out",
  "Exporting": "Exportieren",
  "Export": "Export",
  "Total Records": "Gesamtrekorde",
  "Fields": "Felder",
  "Damage report comment": "Kommentar zur Schadensmeldung",
  "Damage report documents": "Unterlagen zur Schadensmeldung",
  "Task documents": "Aufgabendokumente",
  "Smallscale": "Kleingeräte",
  "SMALLSCALE": "Kleingeräte",
  "LARGESCALE": "Großgeräte",
  "Largescale": "Großgeräte",
  "Commissioned": "Kommissioniert",
  "Open": "Offen",
  "Delivery items": "Lieferumfang",
  "Invalid request": "Ungültige Anfrage",
  "Request is already audited!": "Die Anfrage ist schon geprüft!",
  "Book": "Erstellen",
  "Update": "Speichern",
  "Requested": "Bestellte Menge",
  "Remained": "Restmenge",
  "Summary": "Zusammenfassung",
  "Project History": "Projecthistorie",
  "Delivery/Back Delivery Number": "Lieferungs/Rücklieferungsnummer",
  "Cancel ordered item": "Bestellte Artikel stornieren",
  "Check-in/Check-out History": "Check-In/Check-Out-Historie",
  "Extend booking": "Buchung verlängern",
  "Report damage": "Schaden melden",
  "End booking": "Buchung beenden",
  "Project Image": "Projektbild",
  "Image": "Bild",
  "Equipment Image": "Gerätebild",
  "Delete": "Löschen",
  "No related users!": "Keine Beauftragten",
  "Rates": "Bewertungen",
  "Presents": "Geschenke",
  "Clone": "Klon",
  "Entity created.": "Datensatz erstellt.",
  "Entity updated.": "Datensatz aktualisiert.",
  "Entity removed.": "Datensatz gelöscht.",
  "Entity cloned.": "Datensatz geklont.",
  "Invitation": "Einladung",
  "The date is before the scheduled date": "Das Datum liegt vor dem Startdatum",
  "Not dispositioned yet.": "Noch nicht disponiert.",
  "Planned trips for this order": "Geplante Fahrten für diese Bestellung",
  "Choose one trip please": "Wählen Sie eine Fahrt bitte",
  "Province": "Bundesland",
  "Welcome!": "Willkommen!",
  "Repeat password": "Passwort wiederholen",
  "Company settings": "Firmeneinstellungen",
  "Project timeline": "Projektzeitplan",
  "Display name": "Angezeigter Name",
  "Nothing has changed on the form.": "Nichts hat sich geändert.",
  "Company name": "Firmenname",
  "company splash": "Firmensplash",
  "company logo": "Firmenlogo",
  "company secondary color": "Sekundäre Farbe",
  "company primary color": "Primärfarbe",
  "company address": "Firmenanshrift",
  "Add hours to": "Stunden hinzufügen zu",
  "Today tasks": "Heutige Aufgaben",
  "Upcoming tasks": "Anstehende Aufgaben",
  "Hours": "Arbeitszeit",
  "Current phase": "Aktuelle Phase",
  "Low": "Low",
  "Medium": "Mittel",
  "High": "Hoch",
  "Add hours": "Stunden hinzufügen",
  "Task": "Aufgabe",
  "Priority": "Priorität",
  "Tasks": "Aufgaben",
  "Active tasks": "Aktive Aufgaben",
  "Internal": "Intern",
  "System user": "Systembenutzer",
  "Task title": "Bezeichnung",
  "Task description": "Beschreibung",
  "Assign to": "Zuweisen an",
  "Employee": "Mitarbeiter",
  "Schedule": "Zeitplan",
  "Planned hours": "Geplante Stunden",
  "Project phase": "Projektphase",
  "Created by": "Erstellt von",
  "Reasign to": "Zuweisen",
  "Details": "Details",
  "Personal": "Persönlich",
  "Timeline": "Zeitleiste",
  "Tracked hours": "Nachverfolgte Stunden",
  "Task was closed successfully": "Die Aufgabe wurde erfolgreich geschlossen",
  "Task was reopend successfully": "Die Aufgabe wurde erfolgreich erneut geöffnet",
  "My tasks": "Meine Aufgaben",
  "Archive": "Archivieren",
  "Filter": "Filtern",
  "Commission more items": "Beauftragen Sie mehr Artikel",
  "Pickup": "Beladen",
  "Cancelation": "Stornieren",
  "Copy": "Kopieren",
  "Adhoc commission": "Ad-hoc kommissionieren",
  "Increase amount": "Bestand erhöhen",
  "Decrease amount": "Bestand reduzieren",
  "Delete Confirmation": "Löschung bestätigen",
  "Suggested": "Vorgeschlagen",
  "Bookings": "Buchungen",
  "Add a rating": "Bewertung hinzufügen",
  "Rating": "Bewertung",
  "Absence": "Abwesenheit",
  "Pick employee": "Mitarbeiter auswählen",
  "Note": "Anmerkung",
  "Vacation": "Urlaub",
  "Sick leave": "Krankheit",
  "School": "Schule",
  "Special": "Sonderurlaub",
  "Pick type": "Typ auswählen",
  "The end date is before the start date": "Das Enddatum liegt vor dem Startdatum",
  "Daily": "Täglich",
  "Substitute": "Ersatz",
  "Pick substitute employee": "Ersatzmitarbeiter auswählen",
  "Absences": "Abwesenheiten",
  "Add absence": "Abwesenheit hinzufügen",
  "Statistics": "Statistiken",
  "Pending": "Ausstehend",
  "Approved": "Genehmigt",
  "Rejected": "Abgelehnt",
  "Today absences": "Fehlzeiten heute",
  "Upcoming absences": "Anstehende Abwesenheiten",
  "Tomorrow": "Morgen",
  "Current week": "Diese Woche",
  "Current month": "Aktueller Monat",
  "Current year": "Laufendes Jahr",
  "Next week": "Nächste Woche",
  "Next month": "Nächsten Monat",
  "Previous week": "Letzte Woche",
  "Previous month": "Vorheriger Monat",
  "Previous year": "Vorheriges Jahr",
  "Absent": "Abwesend",
  "Created on": "Erstellt am",
  "Supervised by": "Überwacht von",
  "Employees": "Mitarbeiter",
  "Time": "Zeit",
  "Time of day": "Uhrzeit",
  "User": "Benutzer",
  "Language": "Sprache",
  "Transport Status": "Transportstatus",
  "Project data": "Projektdaten",
  "Ordered item number": "Bestellte Artikelnummer",
  "Reopened": "Wiedereröffnet",
  "Closed": "Geschlossen",
  "to": "Zu",
  "Reopen": "Wieder öffnen",
  "Qualifications": "Qualifikationen",
  "Expires on": "Läuft aus am",
  "Notifications": "Meldungen",
  "User profile": "Benutzerprofil",
  "Edit profile": "Profil bearbeiten",
  "Main view": "Hauptansicht",
  "Team size": "Teamgröße",
  "Team": "Team",
  "Teamlead": "Teamleiter",
  "Team leader": "Teamleiter",
  "Active members": "Aktive Mitglieder",
  "Member": "Mitglied",
  "Team name": "Teamname",
  "Profile picture": "Profilbild",
  "Hourly": "Stündlich",
  "Qualification": "Qualifikation",
  "Linked tasks": "Verknüpfte Aufgaben",
  "Link existing task": "Vorhandene Aufgabe verknüpfen",
  "Time tracking": "Zeiterfassung",
  "Project template": "Projectvorlage",
  "Project templates": "Projektvorlagen",
  "Continue to design page": "Weiter zur Designseite",
  "Template": "Vorlage",
  "Phase schedule": "Phasezeitplan",
  "Phase planning": "Phasenplanung",
  "Phases": "Phasen",
  "Continue to scopes": "Weiter zur Umfangsseite",
  "Expiration date": "Ablaufdatum",
  "Scopes were edited": "Bereiche wurden bearbeitet",
  "Scheduled tasks": "Geplante Aufgaben",
  "Pending tasks": "Ausstehende Aufgaben",
  "Scheduled": "Geplant",
  "Done tasks": "Erledigte Aufgaben",
  "Done": "Erledigt",
  "All": "Alle",
  "Add tags separated by comma": "Fügen Sie durch Komma getrennte Tags hinzu",
  "changed the first name to": "änderte den Vornamen in",
  "changed the last name to": "hat den Nachnamen geändert in",
  "changed the email to": "habe die E-Mail geändert in",
  "changed the phone number to": "habe die Telefonnummer geändert in",
  "logged in at": "eingeloggt bei",
  "unarchived the user on": "den Benutzer auf dearchiviert",
  "archived the user on": "archiviert den Benutzer auf",
  "modifed the tags from": "hat die Tags von geändert",
  "added a tag": "einen Tag hinzugefügt",
  "added a comment": "einen Kommentar hinzugefügt",
  "added a file": "eine Datei hinzugefügt",
  "changed the assignee user to": "hat den zugewiesenen Benutzer geändert in",
  "changed the title to": "änderte den Titel in",
  "changed the schedule from": "änderte den Zeitplan von",
  "changed the priority to": "änderte die Priorität auf",
  "changed the description to": "habe die Beschreibung geändert in",
  "changed the project to": "änderte das Projekt in",
  "changed the project phase to": "änderte die Projektphase in",
  "added a comment and a file": "einen Kommentar und eine Datei hinzugefügt",
  "changed the status from": "hat den Status geändert von",
  "Wrote a message": "Habe eine Nachricht geschrieben",
  "changed the done date to": "das Fertigstellungsdatum geändert in",
  "updated the data at": "aktualisierte die Daten unter",
  "and": "Und",
  "changed the schedule start period from": "hat den Startzeitraum des Zeitplans geändert von",
  "changed the schedule end period from": "hat den Endzeitraum des Zeitplans von geändert",
  "Project phase planning": "Projektphasenplanung",
  "Project type": "Projekttyp",
  "Planned budget": "Geplantes Budget",
  "Notification permission were edited": "Die Benachrichtigungsberechtigung wurde bearbeitet",
  "Settings": "Einstellungen",
  "Add personal booking": "Persönliche Buchung hinzufügen",
  "Edit personal booking": "Persönliche Buchung bearbeiten",
  "Send invitation email": "Einladungs-E-Mail vesenden",
  "Invitation email sent.": "Einladungs-E-Mail gesendet.",
  "Email change confirmed.": "E-Mail-Änderung bestätigt.",
  "Resend email change confirmation for": "Senden Sie die E-Mail-Änderungsbestätigung erneut an",
  "Email change confirmation sent.": "E-Mail-Änderungsbestätigung gesendet.",
  "Email change confirmation required for": "E-Mail-Änderungsbestätigung erforderlich für",
  "Create ad hoc return delivery note": "Ad-hoc-Rücklieferschein erstellen",
  "Increase stock": "Bestand erhöhen",
  "Decrease stock": "Bestand reduzieren",
  "Add consumable": "Verbrauchsstoff hinzufügen",
  "Create ad hoc delivery note": "Ad-hoc-Lieferschein erstellen",
  "Archive item": "Archivierte anzeigen",
  "Commission items": "Kommissionieren",
  "Add formwork": "Rüstung + Schalung hinzufügen",
  "Duplicate": "Duplizieren",
  "Add large scale": "Großgerät hinzufügen",
  "Add project": "Projekt hinzufügen",
  "Add small scale": "Kleingerät hinzufügen",
  "Add state": "Status hinzufügen",
  "Add tag": "Stichwort hinzufügen",
  "No project": "Kein Projekt",
  "Projects & Progress": "Projekte und Fortschritte",
  "Approve": "Genehmigen",
  "Absence request successfully approved.": "Abwesenheitsantrag erfolgreich genehmigt.",
  "Absence request successfully rejected.": "Abwesenheitsantrag erfolgreich abgelehnt.",
  "No weather data available": "Keine Wetterdaten verfügbar",
  "Filters": "Filtern",
  "Check": "Überprüfen",
  "Archive view": "Archivierte anzeigen",
  "Design": "Entwurf",
  "subgroup_one": "Untergruppe",
  "subgroup_other": "Untergruppen",
  "Categories": "Kategorien",
  "Subgroups": "Untergruppen",
  "Parent group": "Obergruppe",
  "Application": "Anwendung",
  "Photo": "Foto",
  "Code": "Code",
  "Activate": "Aktivieren",
  "Cost Centers": "Kostenstellen",
  "Show archived": "Archiviert anzeigen",
  "Show active": "Aktiv anzeigen",
  "Are you sure you want to delete {title}?": "Sind Sie sicher, dass Sie {} löschen möchten?",
  "Street and house number": "Straße und Hausnummer"
}

import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  NumericField,
  SelectField,
  TextField,
  TextAreaField,
} from 'common/widgets/form/field'
import { SimpleFormAction } from 'common/widgets/form/utils'
import { Row, Column } from 'common/widgets/grid'
import { SectionView } from 'common/widgets/view'
import {
  EquipmentCategoryFreeField,
  EquipmentMaincategoryFreeField,
  EquipmentSubcategoryFreeField,
} from 'modules/master-data/equipments/widgets/category'
import { CostCenterSelectField } from 'modules/master-data/cost-centers/widgets'

import { AccountingGroupField } from '../widgets/accounting-group'
import { AccountingTypeField } from '../widgets/accounting-type'
import { OrderableField } from '../widgets/orderable'
import { ItemProcessEnum, ItemTypeEnum } from '../utils'
import { TelematicsIdField } from '../widgets/telematics-id'

export const SmallScaleEquipmentFormContent = ({
  onAdd,
  onUpdate,
  onRemove,
  onSuccess,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Row>
        <Column flex n={6}>
          <SectionView title="Master data">
            <TextField label="Label" name="name" mandatory />
            <TextField label="Inventory number" name="inventory_number" />
            <EquipmentMaincategoryFreeField
              label="Main Group"
              mandatory
              params={[
                { type: ItemTypeEnum.RESOURCE },
                { process: ItemProcessEnum.COMMISSIONING },
              ]}
            />
            <EquipmentCategoryFreeField
              label="Group"
              mandatory
              dependable={!onAdd}
              params={[
                { type: ItemTypeEnum.RESOURCE },
                { process: ItemProcessEnum.COMMISSIONING },
              ]}
            />
            <EquipmentSubcategoryFreeField
              label="Subgroup"
              dependable={!onAdd}
              params={[
                { type: ItemTypeEnum.RESOURCE },
                { process: ItemProcessEnum.COMMISSIONING },
              ]}
            />
            <SelectField
              label="Commission type"
              name="commission_type"
              mandatory
              items={[
                { key: 'COMMISSIONER', title: 'COMMISSIONER' },
                { key: 'SELF', title: 'SELF' },
              ]}
            />
            <h5>{t('Accounting group')}</h5>
            <AccountingGroupField
              params={[{ type: 'RESOURCE' }, { process: 'COMMISSIONING' }]}
            />
          </SectionView>
        </Column>
        <Column flex n={6}>
          <SectionView title="Billing">
            <CostCenterSelectField
              label="Cost center"
              name="cost_center_id"
              removable
            />
            <AccountingTypeField label="Accounting type" mandatory />
            <NumericField
              label="Cost rate"
              name="cost_rate"
              preDecimalScale={4}
              decimalScale={2}
            />
            <h5>{t('Balance sheet depreciation')}</h5>
            <NumericField
              name="balance_sheet_depreciation"
              preDecimalScale={3}
              decimalScale={0}
            />
            <h5>{t('Calculated Depreciation (Months)')}</h5>
            <NumericField
              name="calculated_depreciation"
              preDecimalScale={3}
              decimalScale={0}
            />
            <OrderableField label="Orderable" mandatory selected={false} />
            <TextField label="Serial number" name="serial_number" />
          </SectionView>
        </Column>

        <Column n={6}>
          <SectionView title="Master data diverse">
            <TextField label="Manufacturer" name="manufacturer" />
            <TextField label="Supplier" name="supplier" />
            <TextField label="License plate" name="license_plate" />
            <NumericField
              label="Engine power"
              name="engine_power"
              preDecimalScale={4}
              decimalScale={2}
            />
            <NumericField
              label="Average fuel consumption"
              name="avg_fuel_consumption"
              preDecimalScale={4}
              decimalScale={2}
            />
            <h5>{t('Comment')}</h5>
            <TextAreaField name="comment" rows="4" />
          </SectionView>
        </Column>
        <Column n={6}>
          <SectionView>
            <h1>{t('Telematics data')}</h1>
            <h5>{t('Telematics id')}</h5>
            <TelematicsIdField assigned={false} />
          </SectionView>
        </Column>
      </Row>
      <SimpleFormAction
        onAdd={onAdd}
        onUpdate={onUpdate}
        onRemove={onRemove}
        onSuccess={onSuccess}
      />
    </>
  )
}

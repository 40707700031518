import { useForm } from 'common/widgets/form/context'
import { Column, Row } from 'common/widgets/grid'
import {
  FieldView,
  GridFieldView,
  GridLinkFieldView,
  SectionView,
} from 'common/widgets/view'
import { ItemProcessEnum } from 'modules/master-data/equipments/utils'
import {
  EquipmentCategorySelectField,
  EquipmentMaincategorySelectField,
  EquipmentSubcategorySelectField,
} from 'modules/master-data/equipments/widgets/category'
import { formatProjectName } from 'modules/projects/utils'
import { formatOrderNumber } from 'modules/orders/utils'
import { formatDate, formatDateRange } from 'common/utils/format'
import { formatUserName } from 'modules/security/users/utils'

export const PendingRequestData = ({ request }) => {
  const { values } = useForm()

  let color = '#303030'
  if (
    values.json.maincategory !== request.ordered_item.item.maincategory ||
    values.json.subcategory !== request.ordered_item.item.subcategory ||
    values.json.category !== request.ordered_item.item.category
  ) {
    // red
    color = '#FC4242'
  }

  return (
    <SectionView>
      <Row>
        <Column n={6}>
          <FieldView label="Main group">
            <EquipmentMaincategorySelectField
              params={[
                { type: request.ordered_item.item.type },
                { process: ItemProcessEnum.DISPOSITION },
              ]}
            />
          </FieldView>
        </Column>
        <GridFieldView n={6} label="Ordered main group">
          <h4 style={{ color: color }}>
            {request.ordered_item.item.maincategory}
          </h4>
        </GridFieldView>
        <Column n={6}>
          <FieldView label="Group">
            <EquipmentCategorySelectField
              params={[
                { type: request.ordered_item.item.type },
                { process: ItemProcessEnum.DISPOSITION },
              ]}
            />
          </FieldView>
        </Column>
        <GridFieldView n={6} label="Ordered group">
          <h4 style={{ color: color }}>{request.ordered_item.item.category}</h4>
        </GridFieldView>
        <Column n={6}>
          <FieldView label="Subgroup">
            <EquipmentSubcategorySelectField
              params={[
                { type: request.ordered_item.item.type },
                { process: ItemProcessEnum.DISPOSITION },
              ]}
            />
          </FieldView>
        </Column>
        <GridFieldView n={6} label="Ordered subgroup">
          <h4 style={{ color: color }}>
            {request.ordered_item.item.subcategory}
          </h4>
        </GridFieldView>
      </Row>
    </SectionView>
  )
}

export const PendingRequestOverview = ({ request }) => {
  return (
    <SectionView>
      <Row>
        <GridLinkFieldView
          n={6}
          s={6}
          label="Project"
          value={formatProjectName(request.project)}
          url={`/projects/${request.project.id}`}
        />
        <GridLinkFieldView
          n={6}
          s={6}
          label="Order"
          value={formatOrderNumber(request.ordered_item.order?.number)}
          url={`/orders/${request.ordered_item.order.id}`}
        />
        <GridFieldView n={6} s={6} label="Amount" value={request.amount} />
        <GridFieldView
          n={6}
          s={6}
          label="Required booking period"
          value={formatDateRange(request.booking_start, request.booking_end)}
        />
        <GridFieldView
          n={6}
          s={6}
          label="Earliest"
          value={formatDate(request.ordered_item.booking_start)}
        />
        <GridFieldView
          n={6}
          s={6}
          label="Latest"
          value={formatDate(request.ordered_item.booking_end, {
            friendly: true,
          })}
        />
        <GridFieldView
          n={6}
          s={6}
          label="Fixed date"
          value={formatDate(request.ordered_item.fixed_date)}
        />
        <GridFieldView
          n={6}
          s={6}
          label="Planned delivery date"
          value={formatDate(request.ordered_item.order.planned_delivery_date)}
        />
        <GridFieldView
          n={6}
          s={6}
          label="Orderer"
          value={formatUserName(request.requester_user)}
        />
        <GridFieldView
          n={6}
          s={6}
          label="Ordered at"
          value={formatDate(request.ordered_item.order.ordered_at)}
        />
      </Row>
    </SectionView>
  )
}

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import {
  calendarWeek,
  dateToISOString,
  formatDate,
  nameOfDay,
} from 'common/utils/format'
import { Container } from 'common/widgets/container'
import { PagedDataSource } from 'common/widgets/data-source'
import { CardView, GridFieldView, RowView } from 'common/widgets/view'
import { addDays } from 'common/utils/date'
import { Column, Row } from 'common/widgets/grid'
import {
  AddIconButton,
  NextButton,
  PreviousButton,
} from 'common/widgets/button'

import {
  CalendarFilters,
  CalendarLegend,
  getColorBasedOnTask,
  getResourceName,
  handleResourceStatus,
} from './calendar'
import styles from './maintenance.module.css'

/**
 * Renders the maintenance list.
 *
 * @returns ReactElemnt
 */

export const MaintenanceList = () => {
  const [start, setStart] = useState(new Date())
  const end = addDays(start, 14)
  const [url, setUrl] = useState(
    `/maintenance/${dateToISOString(
      addDays(start, -14),
      true
    )}/to/${dateToISOString(end, true)}`
  )

  useEffect(() => {
    setUrl(
      `/maintenance/${dateToISOString(
        addDays(start, -7),
        true
      )}/to/${dateToISOString(end, true)}`
    )
  }, [start])

  return (
    <PagedDataSource url={url} params={[{ archived: false }]} limit={5}>
      {({ data }) => {
        return (
          <MaintenanceRecords
            data={data}
            fromDate={start}
            setStart={setStart}
            toDate={end}
          />
        )
      }}
    </PagedDataSource>
  )
}

/**
 * MaintantenceList component
 *
 * @param {any} data baseitems array
 * @param {any} fromDate start date
 * @param {any} toDate end date
 *
 * @returns ReactElemnt
 */
const MaintenanceRecords = ({ data, fromDate, toDate, setStart }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  /**
   * Navigates to the create planned task screen
   *
   * @param {any} resource baseitem object
   */
  const handleCreateTask = (resource) => {
    navigate(`/yard/maintenance/plan?resourceId=${resource?.id}`)
  }

  /**
   * Handles the selection of task in the calendar
   *
   * @param {any} task planned task object
   */
  const handleTaskSelection = (task) => {
    const resourceId = task?.plan?.baseitem_id
    const startDate = task?.plan_date
    const endDate = task?.end_date
    const isDamage = task?.damage_report ? true : false
    navigate(
      `/yard/maintenance/plan?resourceId=${resourceId}&start=${dateToISOString(
        startDate,
        true
      )}&end=${dateToISOString(endDate, true)}&taskId=${
        task.id
      }&damage=${isDamage}`
    )
  }

  return (
    <Container title="Maintenance">
      <CardView background="white">
        <Container flex repel padding="5px">
          <h1>
            KW{calendarWeek(fromDate)} - KW{calendarWeek(toDate) - 1}
          </h1>
          <Container flex gap="3px">
            <PreviousButton
              className={styles.previousWeekButton}
              arrowColor="white"
              onClick={() => {
                setStart(addDays(fromDate, -7))
              }}
            />
            <NextButton
              className={styles.nextWeekButton}
              arrowColor="white"
              onClick={() => {
                setStart(addDays(fromDate, 7))
              }}
            />
          </Container>
        </Container>
        <CalendarFilters />
        <Container className={styles.outlinedContainer}>
          {data?.map((e, i) => {
            e.tasks
              .sort(
                (a, b) =>
                  new Date(a.plan_date).getTime() -
                  new Date(b.plan_date).getTime()
              )
              .reverse()
            return (
              <RowView
                key={i}
                vertical
                style={{
                  borderBottom: '1px solid #bababa',
                  margin: '0px',
                  padding: '5px',
                }}
              >
                <Container flex gap="5px">
                  <TaskStatusItem color={handleResourceStatus(e).color} />
                  <Row>
                    <Column n={12} m={12} s={12}>
                      <Container flex repel>
                        <h3>{getResourceName(e)}</h3>
                        <AddIconButton onClick={() => handleCreateTask(e)} />
                      </Container>
                    </Column>
                    <GridFieldView n={6} m={6} s={6} label="Main categoy">
                      <h4 className={styles.ellipsisedText}>
                        {e?.maincategory}
                      </h4>
                    </GridFieldView>
                    <GridFieldView n={6} m={6} s={6} label="Category">
                      <h4 className={styles.ellipsisedText}>{e?.category}</h4>
                    </GridFieldView>
                    <GridFieldView n={6} m={6} s={6} label="Subcategory">
                      <h4 className={styles.ellipsisedText}>
                        {e?.subcategory}
                      </h4>
                    </GridFieldView>
                    <GridFieldView n={6} m={6} s={6} label="Status">
                      <h4 style={{ color: handleResourceStatus(e).color }}>
                        {t(handleResourceStatus(e).title)}
                      </h4>
                    </GridFieldView>
                  </Row>
                </Container>
                {e.tasks.map((task, indx) => {
                  return (
                    <Container
                      key={indx}
                      onClick={() => handleTaskSelection(task)}
                      gap="5px"
                    >
                      <h3>
                        {nameOfDay(task.plan_date)} {formatDate(task.plan_date)}
                      </h3>
                      <CardView background={getColorBasedOnTask(task)}>
                        <Row>
                          <GridFieldView
                            n={12}
                            m={12}
                            s={12}
                            value={
                              task?.performer_name
                                ? task?.performer_name
                                : task?.performer?.fullname
                            }
                          />
                          <GridFieldView
                            n={12}
                            m={12}
                            s={12}
                            value={t(task.plan.task_type)}
                          />
                        </Row>
                      </CardView>
                    </Container>
                  )
                })}
              </RowView>
            )
          })}
        </Container>
        <CalendarLegend />
      </CardView>
    </Container>
  )
}

export const TaskStatusItem = ({ color, children, ...rest }) => {
  return (
    <div
      style={{ background: color ?? 'inherit' }}
      className={styles.taskStatusItem}
      {...rest}
    >
      {children}
    </div>
  )
}

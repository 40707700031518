import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { DetailViewPage } from 'system/utils/view'
import { Container } from 'common/widgets/container'
import { formatBackDeliveryNumber } from 'modules/yard/utils'
import { CancelIconButton, SubmitButton } from 'common/widgets/button'

import { BackDeliveredItems } from './items'
import { BackDeliveryHandoverOverlay } from './overlays/sign'
import { BackDeliveryViewDetails } from './details'
import { CancelBackDeliveryOverlay } from './overlays/cancel'

export const HandoverBackDeliveryViewPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [backDeliveryHandover, setBackDeliveryHandover] = useState(null)
  const [cancelBackDeliveryObject, setCancelBackDeliveryObject] = useState(null)

  return (
    <DetailViewPage
      url="/back_delivery"
      title={(backDelivery) =>
        `${t('Back delivery number')} : ${formatBackDeliveryNumber(
          backDelivery
        )}`
      }
      addExtraButtons={(r) => (
        <CancelIconButton
          onClick={(e) => {
            e.stopPropagation()
            setCancelBackDeliveryObject(r)
          }}
        />
      )}
    >
      {(backDelivery) => (
        <Container>
          {backDelivery && (
            <BackDeliveryContent
              backDelivery={backDelivery}
              setBackDeliveryHandover={setBackDeliveryHandover}
            />
          )}
          {backDeliveryHandover && (
            <BackDeliveryHandoverOverlay
              backDelivery={backDeliveryHandover}
              onClose={() => setBackDeliveryHandover(null)}
            />
          )}
          <CancelBackDeliveryOverlay
            backDelivery={cancelBackDeliveryObject}
            onClose={() => setCancelBackDeliveryObject(null)}
            onDeliveryCanceled={() => navigate(-1)}
          />
        </Container>
      )}
    </DetailViewPage>
  )
}

/**
 * Renders the back delivery content
 *
 * @param {any} backDelivery Back delivery object
 *
 * @returns ReactElemnt
 */
const BackDeliveryContent = ({ backDelivery, setBackDeliveryHandover }) => {
  const { t } = useTranslation()
  const resources = backDelivery.items?.filter(
    (item) => item.baseitem?.is_resource
  )
  const formworks = backDelivery.items?.filter(
    (item) => item.baseitem?.is_formwork
  )

  return (
    <>
      <BackDeliveryViewDetails backDelivery={backDelivery} />
      <Container flex vertical>
        {resources?.length > 0 && (
          <BackDeliveredItems backDeliveryItems={resources} title="RESOURCE" />
        )}
        {formworks?.length > 0 && (
          <BackDeliveredItems backDeliveryItems={formworks} title="FORMWORK" />
        )}
      </Container>
      <Container>
        <SubmitButton
          default
          text={t('Complete')}
          onClick={() => {
            setBackDeliveryHandover(backDelivery)
          }}
        />
      </Container>
    </>
  )
}

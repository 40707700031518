import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Container } from 'common/widgets/container'
import { SaveButton, FlagIconButton } from 'common/widgets/button'
import { useService } from 'common/service/context'
import { Column, Row } from 'common/widgets/grid'
import { GridFieldView, SectionView } from 'common/widgets/view'
import { Form, useForm } from 'common/widgets/form/context'
import { TextField } from 'common/widgets/form/field'
import { useToast } from 'system/toast/context'
import { DetailViewPage } from 'system/utils/view'
import { DocumentsView } from 'common/widgets/document'
import { DataSource } from 'common/widgets/data-source'
import { dateToISOString } from 'common/utils/format'
import { EquipmentSimpleView } from 'modules/master-data/equipments/widgets/simple-view'

export const DamageReportPage = () => {
  return (
    <DetailViewPage url="/items/resources" title="Report damage">
      {(baseitem) => {
        return (
          <Form
            data={{
              comment: null,
            }}
          >
            <DamageReportForm baseitem={baseitem} />
          </Form>
        )
      }}
    </DetailViewPage>
  )
}

export const DamageReportButton = ({ baseitemId }) => {
  const service = useService()
  const navigate = useNavigate()

  const getLastDamageReport = async () =>
    await service.get(`cmms/damages/last/${baseitemId}`)

  return (
    <DataSource fetch={getLastDamageReport}>
      {({ data }) => (
        <FlagIconButton
          danger={data && !data.fixed}
          onClick={() => {
            if (data && !data.fixed) {
              navigate(
                `/yard/maintenance/plan?resourceId=${baseitemId}&start=${dateToISOString(
                  data.created_at,
                  true
                )}&taskId=${data.tasks[0].id}&damage=true`
              )
            } else {
              navigate(`/yard/maintenance/damage/${baseitemId}/report`)
            }
          }}
          tooltip="Report damage"
        />
      )}
    </DataSource>
  )
}

const DamageReportForm = ({ baseitem }) => {
  const service = useService()
  const { toasts } = useToast()
  const { values, ready } = useForm()
  const [damageId, setDamageId] = useState(null)
  const navigate = useNavigate()

  const submitDamageReport = async () => {
    const [result, error] = await service.post(`/cmms/damages`, {
      baseitem_id: baseitem.id,
      comment: values.json.comment,
    })
    if (!error && result) {
      toasts.success('Damage report created!')
      setDamageId(result.data.id)
    } else {
      toasts.error('There is a damage report which is not resolved!')
    }

    return [result, error]
  }

  return (
    <>
      <DamageReportsView data={baseitem} damageId={damageId} />
      <Container flex gap="10px">
        <SaveButton
          default
          onClick={() => (damageId ? navigate(-1) : submitDamageReport())}
          disabled={!ready}
          text={!damageId ? 'Create' : 'Submit'}
        />
      </Container>
    </>
  )
}

const DamageReportsView = ({ data, damageId }) => {
  return (
    <Container>
      <Row>
        <Column n={6} s={6}>
          <SectionView>
            <EquipmentSimpleView equipment={data} />
          </SectionView>
        </Column>
      </Row>
      <Row>
        <Column n={6} s={6}>
          <SectionView style={{ marginTop: '10px' }}>
            <Row>
              <Column n={6} s={6}>
                <StepAction number="1" active={!damageId} text="Add comment" />
              </Column>
              <Column n={6} s={6}>
                <StepAction number="2" active={damageId} text="Add files" />
              </Column>
            </Row>
          </SectionView>
        </Column>
      </Row>
      <Row>
        <Column n={6} s={6}>
          <SectionView style={{ marginTop: '10px' }}>
            {!damageId && (
              <GridFieldView n={12} s={12} label="Comment">
                <TextField name="comment" disabled={damageId} mandatory />
              </GridFieldView>
            )}
            {damageId && (
              <GridFieldView n={12} s={12} label="Files">
                <DocumentsView docRef={`damage=${damageId}`} />
              </GridFieldView>
            )}
          </SectionView>
        </Column>
      </Row>
    </Container>
  )
}

const StepAction = ({ number, active, text }) => {
  const { t } = useTranslation()
  const circleStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    border: `1px solid ${active ? `lightblue` : `grey`}`,
    backgroundColor: 'white',
    color: `${active ? `lightblue` : `grey`}`,
    fontSize: '24px',
  }
  const textStyle = {
    color: `${active ? `lightblue` : `grey`}`,
    paddingBottom: '5px',
  }

  return (
    <Container flex gap="10px" style={{ alignItems: 'center' }}>
      <div style={circleStyle}>{number}</div>
      <h3 style={textStyle}>{t(text)}</h3>
    </Container>
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'

import { EditViewPage } from 'system/utils/edit'

import { AbsenceView } from './add'

/**
 * Renders page for editing an absence
 *
 * @returns ReactElement
 */
export const AbsenceEditPage = () => {
  const { t } = useTranslation()

  return (
    <EditViewPage
      url="/contact/absences"
      title={(item) => `${t('Absence')} #${item.id} - ${item.contact.fullname}`}
      archivable
    >
      {(data) => {
        return <AbsenceView absence={data} />
      }}
    </EditViewPage>
  )
}

import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useForm } from 'common/widgets/form/context'
import { CommentView } from 'common/widgets/view'
import { useToast } from 'system/toast/context'
import { useOverlayContext } from 'common/widgets/overlay'

import * as btn from '../button'
import { Container } from '../container'

import styles from './form.module.css'

export const SimpleFormAction = ({
  onAdd,
  onUpdate,
  onRemove,
  onClone,
  onClose,
  onSuccess,
  // this flag will disable all actions except the back button
  disabled,
  successMessage,
}) => {
  console.assert(
    !(onAdd && onUpdate),
    'Only one submit function should be present, not both.'
  )
  const navigate = useNavigate()
  const ctx = useForm()
  const { toasts } = useToast()
  const { t } = useTranslation()
  const overlayContext = useOverlayContext()

  // If there is an overlay context we are inside an overlay
  const isOverlay = !!overlayContext

  const handleAdd = async (e, ctx) => {
    const [response, error] = await onAdd(ctx.values.json, ctx)
    if (!error) {
      await handleSuccess(response, ctx, t('Entity created.'))
      !isOverlay && navigate(-1)
    }
  }

  const handleUpdate = async (e, ctx) => {
    const [response, error] = await onUpdate(ctx.values.dirty)
    if (!error) {
      await handleSuccess(response, ctx, t('Entity updated.'))
    }
  }

  const handleRemove = async (e, ctx) => {
    const [response, error] = await onRemove(ctx.values.dirty)
    if (!error) {
      await handleSuccess(response, ctx, t('Entity removed.'))
      !isOverlay && navigate(-1)
    }
  }

  const handleClone = async (e, ctx) => {
    const [response, error] = await onClone(ctx.values.dirty)
    if (!error) {
      await handleSuccess(response, ctx, t('Entity cloned.'))
    }
  }

  const handleSuccess = async (response, ctx, message) => {
    toasts.success(successMessage ?? message)
    if (isOverlay) {
      return onClose && onClose()
    }
    if (onSuccess) {
      await onSuccess(response, ctx)
    }
  }

  return (
    <div>
      <Container
        flex
        grow
        gap="10px"
        wrap="false"
        className={isOverlay ? styles.formModal : ''}
      >
        {onUpdate && (
          <btn.SaveButton
            default
            disabled={(ctx) => disabled || !ctx.ready || !ctx.dirty}
            onClick={handleUpdate}
            type="submit"
          />
        )}
        {onAdd && (
          <btn.AddButton
            default
            disabled={(ctx) => disabled || !ctx.ready || !ctx.dirty}
            onClick={handleAdd}
            type="submit"
          />
        )}
        {onClone && (
          <btn.CopyButton
            text="Clone"
            default
            disabled={(ctx) => disabled || !ctx.ready}
            onClick={handleClone}
          />
        )}
        {onRemove && (
          <btn.DeleteButton danger onClick={handleRemove} disabled={disabled} />
        )}
        {isOverlay ? (
          <btn.CancelButton onClick={() => overlayContext.close()} />
        ) : (
          <btn.BackButton onClick={onClose} />
        )}
      </Container>
      {!ctx.dirty && !ctx.readOnly && (
        <CommentView warning comment={t('Nothing has changed on the form.')} />
      )}
    </div>
  )
}

import './calendar.css'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  DataSource,
  PagedDataSource,
  useDataSourceContext,
} from 'common/widgets/data-source'
import { dateToISOString } from 'common/utils/format'
import { addDays } from 'common/utils/date'
import { CardView, GridFieldView } from 'common/widgets/view'
import { Container } from 'common/widgets/container'
import { Column, Row } from 'common/widgets/grid'
import { DropDown } from 'common/widgets/dropdown'
import { SearchInput } from 'common/widgets/search'
import { OverlayBody, OverlayFooter, OverlayForm } from 'common/widgets/overlay'
import {
  CancelButton,
  FilterIconButton,
  SubmitButton,
} from 'common/widgets/button'
import { FullCalendarWrapper } from 'common/widgets/calendar'
import { Legend } from 'common/widgets/legend'
import { useMediaQuery } from 'common/utils/hooks'

import styles from './maintenance.module.css'

export const MaintenanceCalendar = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [fromDate, setFromDate] = useState(addDays(new Date(), -14))
  const [toDate, setToDate] = useState(addDays(new Date(), 14))

  const handleDateSelection = (selection) => {
    const resource = selection?.resource
    const startDate = selection?.date
    navigate(
      `/yard/maintenance/plan?resourceId=${
        resource?.id
      }&start=${encodeURIComponent(dateToISOString(startDate))}`
    )
  }

  const handleEventSelection = (selection) => {
    const task = selection?.event?.extendedProps?.data
    if (!task) {
      return
    }
    const resourceId = task?.plan?.baseitem_id
    const startDate = task?.plan_date
    const endDate = task?.end_date
    const isDamage = task?.damage_report ? true : false
    navigate(
      `/yard/maintenance/plan?resourceId=${resourceId}&start=${encodeURIComponent(
        dateToISOString(startDate)
      )}&end=${encodeURIComponent(dateToISOString(endDate))}&taskId=${
        task.id
      }&damage=${isDamage}`
    )
  }

  return (
    <PagedDataSource
      url={`/maintenance/${dateToISOString(
        fromDate,
        true
      )}/to/${dateToISOString(toDate, true)}`}
      params={[{ archived: false }]}
      limit={5}
    >
      {({ data, offset, setOffset, setQueryParameter }) => {
        const resources = data?.map((e) => ({
          id: e.id,
          title: e.name,
          data: e,
        }))

        const events = data
          ?.map((resource) =>
            resource?.tasks?.map((e) => {
              const task = {
                id: e?.id,
                title: t(e?.plan?.task_type),
                resourceId: resource.id,
                color: getColorBasedOnTask(e),
                data: e,
                className: 'maintenance-event',
              }
              if (e.done_date) {
                if (e?.plan?.task_type === 'planned_repair') {
                  task['start'] = e.plan_date
                  task['end'] = addDays(e.plan_date, e.duration)

                  return task
                }
                task['start'] = e.done_date
                task['end'] = e.done_date

                return task
              }
              task['start'] = e.plan_date
              task['end'] = e.end_date
              // disabled, since it's buggy until fixed
              //task['title'] = makeEventTitle(e)

              return task
            })
          )
          .flat()

        return (
          <CardView flex vertical grow gap="10px">
            <FullCalendarWrapper
              datesSet={(data) => {
                setFromDate(addDays(data.start, -14))
                setToDate(addDays(data.end, 14))
                setOffset(offset + 1)
              }}
              initialView="customTimeline"
              eventResourceEditable={false}
              resourceLabelContent={(resource) => (
                <ResourceInfo data={resource} />
              )}
              resourceAreaHeaderContent={() => <CalendarFilters />}
              titleFormat={{ week: 'short' }}
              resources={resources}
              events={events}
              selectable={false}
              unselectAuto
              dateClick={handleDateSelection}
              eventClick={handleEventSelection}
              resourceAreaWidth="40%"
              contentHeight={850}
              views={{
                customTimeline: {
                  type: 'resourceTimeline',
                  buttonText: t('Main view'),
                  duration: { weeks: 2 },
                  slotDuration: { day: 1 },
                  slotLabelFormat: [
                    { month: 'long', year: 'numeric' },
                    { day: 'numeric' },
                  ],
                },
                customMonthTimeline: {
                  type: 'resourceTimeline',
                  duration: { weeks: 2 * 6 },
                  slotDuration: { day: 1 },
                  slotLabelInterval: { week: 1 },
                  slotLabelFormat: [
                    { year: 'numeric' },
                    { month: 'long' },
                    { week: 'short' },
                  ],
                  slotMinWidth: 45,
                },
              }}
              defaultAllDay
              scrollTime={{ weeks: 4 }}
              calendarType="maintenance"
              headerToolbar={{
                left: 'customTimeline',
              }}
            />
            <CalendarLegend />
          </CardView>
        )
      }}
    </PagedDataSource>
  )
}

/**
 * Renders the resource section of the calendar.
 *
 * @param {any} data represents a baseitem/resource
 *
 * @returns ReactElemnt
 */
const ResourceInfo = ({ data }) => {
  const { t } = useTranslation()
  /// Extracted resource object from the calendar
  const resource = data?.resource?._resource?.extendedProps?.data

  return (
    <Container flex grow repel style={{ padding: '5px', cursor: 'default' }}>
      <Row>
        <Column n={12}>
          <h3>{getResourceName(resource)}</h3>
        </Column>
        <GridFieldView n={6} label="Main categoy">
          <h4 className={styles.ellipsisedText}>{resource?.maincategory}</h4>
        </GridFieldView>
        <GridFieldView n={6} label="Category">
          <h4 className={styles.ellipsisedText}>{resource?.category}</h4>
        </GridFieldView>
        <GridFieldView n={6} label="Subcategory">
          <h4 className={styles.ellipsisedText}>{resource?.subcategory}</h4>
        </GridFieldView>
        <GridFieldView n={6} label="Status">
          <h4 style={{ color: handleResourceStatus(resource).color }}>
            {t(handleResourceStatus(resource).title)}
          </h4>
        </GridFieldView>
      </Row>
      <div
        className={styles.taskStatusItem}
        color={handleResourceStatus(resource).color}
      />
    </Container>
  )
}

export const CalendarLegend = () => {
  const ctx = useDataSourceContext()
  const [selected, setSelected] = useState()

  const handleStateSelect = (color) => {
    if (color === 'damage' && !ctx?.params?.find((e) => e.task_type)?.damage) {
      if (!(ctx?.params.find((e) => e.task_type)?.task_type === 'damage')) {
        ctx?.setQueryParameter('task_type', color)
      }
    } else if (color !== 'damage') {
      if (!(ctx?.params?.find((e) => e.flag)?.flag === color)) {
        ctx?.setQueryParameter('flag', color)
      }
    }
    setSelected(color)
  }

  const isRowBased = useMediaQuery('(max-width: 768px)')
  return (
    <Container flex={!isRowBased} gap="10px">
      <Legend
        text="Not delayed"
        color="#2DCE18"
        onClick={() => handleStateSelect('GREEN')}
        selected={selected == 'GREEN'}
      />
      <Legend
        text="Delayed soon"
        color="#F49537"
        onClick={() => handleStateSelect('YELLOW')}
        selected={selected == 'YELLOW'}
      />

      <Legend
        text="Overdued"
        color="#FDA0A0"
        onClick={() => handleStateSelect('RED')}
        selected={selected == 'RED'}
      />
      <Legend
        text="Carried out"
        color="#BABABA"
        onClick={() => handleStateSelect('GREY')}
        selected={selected == 'GREY'}
      />
      <Legend
        text="Damage report"
        color="#FC4242"
        onClick={() => handleStateSelect('damage')}
        selected={selected == 'damage'}
      />
    </Container>
  )
}

/**
 * Renders the calendar`s filter
 *
 * @returns ReactElemnt
 */
export const CalendarFilters = () => {
  const [shouldOpenTaskFilters, setShouldOpenTaskFilters] = useState(false)
  const [selectedTaskType, setSelectedTaskType] = useState(null)
  const [selectedTaskSubtype, setSelectedTaskSubtype] = useState(null)
  const [selectedMainCategory, setSelectedMainCategory] = useState(null)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [selectedSubcategory, setSelectedSubcategory] = useState(null)
  const [filterCount, setFilterCount] = useState(0)
  const taskTypes = {
    planned_repair: ['tyre_change', 'service', 'others'],
    check_electrical_device: ['initial_check', 'visual_check', 'recheck'],
    UVV_check: [],
    visual_check: [],
    expert_check: [],
    other_check: [],
  }

  return (
    <Container flex repel padding="5px">
      <FilterIconButton
        badge={filterCount}
        onClick={() => setShouldOpenTaskFilters(true)}
      />
      <SearchInput />
      <ResourceFilterOverlay
        open={shouldOpenTaskFilters}
        taskTypes={taskTypes}
        selectedTaskType={selectedTaskType}
        setSelectedTaskType={setSelectedTaskType}
        selectedTaskSubtype={selectedTaskSubtype}
        setSelectedTaskSubtype={setSelectedTaskSubtype}
        selectedMainCategory={selectedMainCategory}
        setSelectedMainCategory={setSelectedMainCategory}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        selectedSubcategory={selectedSubcategory}
        setSelectedSubcategory={setSelectedSubcategory}
        setFilterCount={setFilterCount}
        onClose={() => setShouldOpenTaskFilters(false)}
      />
    </Container>
  )
}

/**
 * Renders the calendar`s filter overlay.
 *
 * @param {any} open should the overlay be open or not
 * @param {array} taskTypes array with all task types
 * @param {any} selectedTaskType selected task type
 * @param {any} setSelectedTaskType setter for the selected task type
 * @param {any} selectedTaskSubtype selected task subtype
 * @param {any} setSelectedTaskSubtype setter for the selected task subtype
 * @param {any} selectedMainCategory selected baseitem main category
 * @param {any} setSelectedMainCategory setter for the selected baseitem main category
 * @param {any} selectedCategory selected baseitem category
 * @param {any} setSelectedCategory setter for the selected baseitem category
 * @param {any} selectedSubcategory selected baseitem subcategory
 * @param {any} setSelectedSubcategory setter for the selected baseitem subcategory
 * @param {any} setFilterCount setter for the number of active filters
 * @param {any} onClose state of closing the dialog
 *
 * @returns ReactElement
 */
const ResourceFilterOverlay = ({
  open,
  taskTypes,
  selectedTaskType,
  setSelectedTaskType,
  selectedTaskSubtype,
  setSelectedTaskSubtype,
  selectedMainCategory,
  setSelectedMainCategory,
  selectedCategory,
  setSelectedCategory,
  selectedSubcategory,
  setSelectedSubcategory,
  setFilterCount,
  onClose,
}) => {
  const ctx = useDataSourceContext()
  const { t } = useTranslation()
  const [taskType, setTaskType] = useState(selectedTaskType)
  const [taskSubtype, setTaskSubtype] = useState(selectedTaskSubtype)
  const [mainCategory, setMainCategory] = useState(selectedMainCategory)
  const [category, setCategory] = useState(selectedCategory)
  const [subcategory, setSubcategory] = useState(selectedSubcategory)
  const taskTypeItems = Object.keys(taskTypes).map((e) => ({
    key: e,
    title: `${t(e)}`,
  }))
  const taskSubtypeItems = !taskType
    ? []
    : taskTypes[taskType].map((e) => ({
        key: e,
        title: `${t(e)}`,
      }))

  const handleFilterSubmit = () => {
    var numberOfActiveFilters = 0

    if (taskType) {
      ctx?.setQueryParameter('task_type', taskType)
      setSelectedTaskType(taskType)
      numberOfActiveFilters = numberOfActiveFilters + 1
    } else {
      setSelectedTaskType(null)
    }
    if (taskSubtype) {
      ctx?.setQueryParameter('task_subtype', taskSubtype)
      setSelectedTaskSubtype(taskSubtype)
      numberOfActiveFilters = numberOfActiveFilters + 1
    } else {
      setSelectedTaskSubtype(null)
    }
    if (mainCategory) {
      ctx?.setQueryParameter('main_category', mainCategory)
      setSelectedMainCategory(mainCategory)
      numberOfActiveFilters = numberOfActiveFilters + 1
    } else {
      setSelectedMainCategory(null)
    }
    if (category) {
      ctx?.setQueryParameter('category', category)
      setSelectedCategory(category)
      numberOfActiveFilters = numberOfActiveFilters + 1
    } else {
      setSelectedCategory(null)
    }
    if (subcategory) {
      ctx?.setQueryParameter('subcategory', subcategory)
      setSelectedSubcategory(subcategory)
      numberOfActiveFilters = numberOfActiveFilters + 1
    } else {
      setSelectedSubcategory(null)
    }

    onClose()
    setFilterCount(numberOfActiveFilters)
  }

  return (
    <OverlayForm open={open} onClose={onClose} title="Resource filters">
      <OverlayBody>
        <Row>
          <GridFieldView n={6}>
            <DropDown
              disabled={false}
              title={t('Task type')}
              items={taskTypeItems}
              selectedIndex={taskTypeItems?.findIndex(
                (i) => i.key === taskType
              )}
              removable
              onSelectChange={(e) => {
                setTaskType(e?.key)
                setTaskSubtype(null)
              }}
            />
          </GridFieldView>
          <GridFieldView n={6}>
            <DropDown
              disabled={!taskType || taskTypes[taskType].length === 0}
              title={t('Task subtype')}
              items={taskSubtypeItems}
              selectedIndex={taskSubtypeItems?.findIndex(
                (i) => i.key === taskSubtype
              )}
              removable
              onSelectChange={(e) => {
                setTaskSubtype(e?.key)
              }}
            />
          </GridFieldView>
          <GridFieldView n={12}>
            <DataSource url="/items/resources/maincategory?archived=false">
              {({ data }) => {
                const items = data?.map((e) => ({
                  key: `${e}`,
                  title: `${e}`,
                }))
                return (
                  data && (
                    <DropDown
                      disabled={data.length === 0}
                      title={t('Main group')}
                      items={items}
                      selectedIndex={items?.findIndex(
                        (i) => i.key === mainCategory
                      )}
                      removable
                      onSelectChange={(e) => {
                        setMainCategory(e?.title)
                      }}
                    />
                  )
                )
              }}
            </DataSource>
          </GridFieldView>
          <GridFieldView n={12}>
            <DataSource url="/items/resources/category?archived=false">
              {({ data }) => {
                const items = data?.map((e) => ({
                  key: `${e}`,
                  title: `${e}`,
                }))
                return (
                  data && (
                    <DropDown
                      disabled={data?.length === 0}
                      title={t('Group')}
                      items={items}
                      selectedIndex={items?.findIndex(
                        (i) => i.key === category
                      )}
                      removable
                      onSelectChange={(e) => {
                        setCategory(e?.title)
                      }}
                    />
                  )
                )
              }}
            </DataSource>
          </GridFieldView>
          <GridFieldView n={12}>
            <DataSource url="/items/resources/subcategory?archived=false">
              {({ data }) => {
                const items = data?.map((e) => ({
                  key: `${e}`,
                  title: `${e}`,
                }))
                return (
                  data && (
                    <DropDown
                      disabled={data?.length === 0}
                      title={t('Subgroup')}
                      items={items}
                      selectedIndex={items?.findIndex(
                        (i) => i.key === subcategory
                      )}
                      removable
                      onSelectChange={(e) => {
                        setSubcategory(e?.title)
                      }}
                    />
                  )
                )
              }}
            </DataSource>
          </GridFieldView>
        </Row>
      </OverlayBody>
      <OverlayFooter repel gap="10px">
        <SubmitButton default onClick={handleFilterSubmit} />
        <CancelButton onClick={onClose} />
      </OverlayFooter>
    </OverlayForm>
  )
}

/**
 * Handles task status prioritization per resource
 * @returns string
 */
export const handleResourceStatus = (resource) => {
  if (resource?.tasks?.filter((e) => e.flag === 'DAMAGE').length > 0) {
    return { title: 'Damage report', color: '#FC4242' }
  } else if (resource?.tasks?.filter((e) => e.flag === 'RED').length > 0) {
    return { title: 'Overdued', color: '#FDA0A0' }
  } else if (resource?.tasks?.filter((e) => e.flag === 'YELLOW').length > 0) {
    return { title: 'Delayed soon', color: '#F49537' }
  } else if (resource?.tasks?.filter((e) => e.flag === 'GREEN').length > 0) {
    return { title: 'Not delayed', color: '#2DCE18' }
  } else if (resource?.tasks?.filter((e) => e.flag === 'GREY').length > 0) {
    return { title: 'Carried out', color: '#BABABA' }
  }

  return ''
}

/**
 * Returns the baseitem name with inventory number
 * @param {any} resource baseitem
 * @returns
 */
export const getResourceName = (resource) => {
  if (resource?.inventory_number && resource?.inventory_number !== '') {
    return `${resource?.inventory_number} - ${resource.name}`
  }

  return resource.name
}

/**
 * Returns the task color based on a flag
 * @param {any} task task object
 *
 * @returns String
 */
export const getColorBasedOnTask = (task) => {
  if ((task?.flag === 'DAMAGE' || task?.damage_report) && !task?.done_date) {
    return '#FC4242'
  } else if (task?.flag === 'RED' && !task?.done_date) {
    return '#FDA0A0'
  } else if (task?.flag === 'YELLOW' && !task?.done_date) {
    return '#F49537'
  } else if (task?.flag === 'GREEN' && !task?.done_date) {
    return '#2DCE18'
  } else if (task?.flag === 'GREY') {
    return '#BABABA'
  }
}

import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useQueryParams } from 'common/utils/routing'
import { useMediaQuery } from 'common/utils/hooks'
import { DropDown } from 'common/widgets/dropdown'

import styles from './tab.module.css'

export const Tab = ({ items, selected, onTabSelect, ...rest }) => {
  const { t } = useTranslation()

  const [, setSearch] = useSearchParams()
  const queryParams = useQueryParams()
  const selectedTabKey = queryParams['tab']
  const showMobileView = useMediaQuery('(max-width: 768px)')

  // Find selected item
  const selectedItem = items
    .filter((tab) => tab.visible !== false)
    .find((tab, i) => (selectedTabKey ? tab.key === selectedTabKey : i === 0))

  const tabNav = (
    <div className={styles.tabNav}>
      {items
        .filter((tab) => tab.visible !== false)
        .map((tab, i) => (
          <div
            key={i}
            onClick={() => {
              setSearch({ ...queryParams, tab: tab.key })
              onTabSelect && onTabSelect(tab)
            }}
            className={
              tab.key === selectedItem?.key
                ? styles.tabLinkActive
                : styles.tabLinkPassive
            }
          >
            <h4
              style={{
                color: tab.key === selectedItem?.key ? '#303030' : '#A3A3A3',
              }}
            >
              {t(tab.title)}
            </h4>
            <div className={styles.tabStatus} />
          </div>
        ))}
    </div>
  )

  const dropDownNav = (
    <DropDown
      items={items}
      selectedIndex={items.findIndex((t) => t.key == selectedItem?.key)}
      onSelectChange={(tab) => {
        setSearch({ ...queryParams, tab: tab.key })
        onTabSelect && onTabSelect(tab)
      }}
    />
  )

  return (
    <div className={styles.tabbedPane}>
      {showMobileView ? dropDownNav : tabNav}
      {selectedItem?.render && selectedItem.render()}
    </div>
  )
}

import React from 'react'

import { Form } from 'common/widgets/form/context'

import { ProjectFormContent } from './form'

export const ProjectAddPage = () => {
  return (
    <Form baseUrl="/projects" auto showActions>
      <ProjectFormContent />
    </Form>
  )
}

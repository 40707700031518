import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from '@nivo/core'

import { GridFieldView, SectionView } from 'common/widgets/view'
import { Row } from 'common/widgets/grid'

import styles from '../equipments.module.css'

/**
 * Renders master data diverse data of resource (large-scale and small scale)
 *
 * @returns ReactElement
 */
export const MasterDataDiverseView = ({ data }) => {
  const { t } = useTranslation()
  return (
    <SectionView>
      <h1>{t('Master data diverse')}</h1>
      <Container flex shrink>
        <Row>
          <GridFieldView n={6} label="Manufacturer" value={data.manufacturer} />
          <GridFieldView n={6} label="Supplier" value={data.supplier} />
          <GridFieldView
            n={6}
            label="License plate"
            value={data.license_plate}
          />
          <GridFieldView n={6} label="Engine power" value={data.engine_power} />
          <GridFieldView
            n={6}
            label="Average fuel consumption"
            value={data.avg_fuel_consumption}
          />
          <GridFieldView
            n={6}
            label="Accounting group"
            value={data.accounting_group}
          />
          <GridFieldView
            n={12}
            label="Comment"
            value={data.comment}
            className={styles.comment}
          />
        </Row>
      </Container>
    </SectionView>
  )
}

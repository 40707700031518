import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { CardView, GridFieldView, UserFieldView } from 'common/widgets/view'
import { Box, Container } from 'common/widgets/container'
import { formatDate, formatDateTime } from 'common/utils/format'
import { DocumentsView } from 'common/widgets/document'
import { toDate } from 'common/utils/date'

/**
 * Renders an activity view
 *
 * @param {array} activities activities array
 * @param {bool} noPadding remove the padding or not
 *
 * @returns ReactElement
 */
export const ActivityView = ({ activities, noPadding }) => {
  const { t } = useTranslation()

  const mappedActivities = {
    'comment/file': (activity) =>
      `${t('added a comment and a file')} ${activity.text}`,
    file: (_) => `${t('added a file')}`,
    comment: (activity) => `${t('added a comment')} - ${activity.text}`,
    updated_at: (activity) =>
      `${t('updated the data at')} ${formatDate(activity.to)}`,
    assignee_user: (activity) =>
      `${t('changed the assignee user to')} ${inspectMessage(
        activity.to?.__str__
      )}`,
    title: (activity) =>
      `${t('changed the title to')} ${inspectMessage(activity.to?.__str__)}`,
    schedule: (activity) => formatSchedule(activity),
    priority: (activity) =>
      `${t('changed the priority to')} ${inspectMessage(activity.to)}`,
    description: (activity) =>
      `${t('changed the description to')} ${inspectMessage(activity.to)}`,
    project: (activity) =>
      `${t('changed the project to')} ${inspectMessage(activity.to?.__str__)}`,
    phase: (activity) =>
      `${t('changed the project phase to')} ${inspectMessage(
        activity.to?.__str__
      )}`,
    status: (activity) =>
      `${t('changed the status from')} ${activity.from} ${t('to')} ${
        activity.to
      }`,
    tags: (activity) =>
      activity.from
        ? `${t('modifed the tags from')} ${activity.from} ${t('to')} ${
            activity.to
          }`
        : activity.to?.length > 0
        ? `${t('added a tag')} - ${activity.to}`
        : null,
    conversation: (activity) =>
      `${t('Wrote a message')} "${activity.to?.__str__}"`,
    done_date: (activity) =>
      `${t('changed the done date to')} ${formatDate(activity.to)}`,
    first_name: (activity) =>
      `${t('changed the first name to')} ${activity.to}`,
    last_name: (activity) => `${t('changed the last name to')} ${activity.to}`,
    email: (activity) => `${t('changed the email to')} ${activity.to}`,
    phone_number: (activity) =>
      `${t('changed the phone number to')} ${activity.to}`,
    last_login: (activity) =>
      `${t('logged in at')} ${formatDateTime(activity.to)}`,
    archived_at: (activity) =>
      activity.from
        ? `${t('unarchived the user on')} ${formatDate(activity.from)}`
        : `${t('archived the user on')} ${formatDate(activity.to)}`,
  }

  const formatSchedule = (activity) => {
    var startPeriod
    var endPeriod
    if (activity.from?.__details__) {
      startPeriod = `${t(
        'changed the schedule start period from'
      )} ${formatDate(activity.from.__details__.start_date, {
        friendly: true,
      })} ${t('to')} ${formatDate(activity.from.__details__.due_date, {
        friendly: true,
      })}`
    }
    if (activity.to?.__details__) {
      endPeriod = `${t('changed the schedule end period from')} ${formatDate(
        activity.to.__details__.start_date,
        { friendly: true }
      )} ${t('to')} ${formatDate(activity.to.__details__.due_date, {
        friendly: true,
      })}`
    }
    if (startPeriod && endPeriod) {
      return `${startPeriod} ${t('and')} ${endPeriod}}`
    }
    if (startPeriod) {
      return startPeriod
    }
    if (endPeriod) {
      return endPeriod
    }

    return null
  }

  const actions = []
  activities?.map((r) =>
    actions.push({
      creator: r.user?.fullname,
      creator_user_id: r.user?.id,
      from: r.from,
      to: r.to,
      text: r.text,
      attachments: r.attachments_snapshot,
      action: r.name,
      created_at: toDate(r.timestamp),
    })
  )
  const sortedActions = actions.sort((a, b) => b.created_at > a.created_at)

  const inspectMessage = (message) => {
    if (message === null || message === undefined) {
      return t('None')
    }

    return message
  }

  return (
    <CardView style={{ padding: '0px' }}>
      <Container
        title="Timeline"
        style={{ padding: noPadding ? '0px' : '15px' }}
      />
      <Box top>
        {sortedActions.map((e, i) => {
          const action = mappedActivities[e.action]

          return (
            action &&
            action(e) && (
              <GridFieldView key={i} n={12} m={12} s={12}>
                <MessageContainer>
                  <Container flex style={{ paddingLeft: '10px' }}>
                    <Bulletpoint />
                    <Container style={{ paddingBottom: '10px' }}>
                      <Container flex gap="10px">
                        <UserFieldView
                          id={e.creator_user_id}
                          value={<UnderlinedText>{e.creator}</UnderlinedText>}
                        />
                        <GreyText>{action(e)}</GreyText>
                      </Container>
                      {e.attachments && e.attachments.length > 0 && (
                        <DocumentsView docRef={e.attachments[0].ref} readonly />
                      )}
                      <GreyText>{formatDateTime(e.created_at)}</GreyText>
                    </Container>
                  </Container>
                </MessageContainer>
              </GridFieldView>
            )
          )
        })}
      </Box>
    </CardView>
  )
}

const UnderlinedText = styled.h4`
  color: black;
  text-decoration: underline;
`
const GreyText = styled.h4`
  color: grey;
`

const Bulletpoint = styled.ul`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: grey;
  margin-top: 8px;
  z-index: 2;
  position: absolute;
  left: -4px;
`
const MessageContainer = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid #e9e9e9;
  border-radius: 0px;
  border-top: none;
  border-bottom: none;
  border-right: none;
  z-index: 0;
  position: relative;
`

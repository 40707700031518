import { register } from 'system/container'
import { useMediaQuery } from 'common/utils/hooks'

import { MaintenanceCalendar } from './calendar'
import { MaintenanceList } from './list'
import { MaintenanceTaskPlanPage } from './plan'
import { DamageReportPage } from './damage_report'

const MaintenancePage = () => {
  const isRowBased = useMediaQuery('(max-width: 768px)')
  return <>{isRowBased ? <MaintenanceList /> : <MaintenanceCalendar />}</>
}

register({
  menu: true,
  render: () => <MaintenancePage />,
  title: 'Maintenance',
  path: '/yard/maintenance',
  priority: 1.0,
})

register({
  render: () => <MaintenanceTaskPlanPage />,
  title: 'Maintenance',
  path: '/yard/maintenance/plan',
})

register({
  render: () => <DamageReportPage />,
  title: 'Report damage',
  path: '/yard/maintenance/damage/:id/report',
})

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { Container } from 'common/widgets/container'
import { ConfirmButton, ClearButton, CancelButton } from 'common/widgets/button'
import { OverlayBody, OverlayFooter, OverlayForm } from 'common/widgets/overlay'
import { useForm } from 'common/widgets/form/context'
import {
  DateField,
  NumericField,
  SignatureField,
  TextField,
} from 'common/widgets/form/field'
import { useService } from 'common/service/context'
import { Row } from 'common/widgets/grid'
import { formatDateRange } from 'common/utils/format'
import { GridFieldView } from 'common/widgets/view'
import { calculateFaultAmount, extractFaults } from 'modules/yard/utils'
import { useSession } from 'common/session/context'

export const SubmitBackDeliveryOverlay = ({
  open,
  backDeliveryItems,
  projectId,
  targetProjectId,
  forDelivery,
  onClose,
}) => {
  const { t } = useTranslation()
  const service = useService()
  const navigate = useNavigate()
  const { currentUser } = useSession()
  const handler = {}

  const handleAdhookSubmit = async (data, values) => {
    const signature = values.json.signature
    data.recipient = values.json.recipient

    if (signature) {
      const [result, error] = await service.post(`back_delivery/adhoc`, {
        ...data,
        signature,
      })

      if (!error) {
        onClose()
        navigate(-1)
      }

      return [result, error]
    }
  }

  const handleDeliverySubmit = async (data) => {
    const [result, error] = await service.post(`back_delivery`, {
      ...data,
    })

    if (!error) {
      onClose()
      navigate(-1)
    }

    return [result, error]
  }

  const onOverlayClose = () => {
    clearSignature()
    onClose()
  }

  const clearSignature = async () => {
    handler.clear()
  }

  return (
    <OverlayForm
      title={t('Handover')}
      open={open}
      onClose={() => onOverlayClose()}
      data={{
        recipient: currentUser?.fullname,
        checking_date: new Date(),
        signature: null,
      }}
    >
      <OverlayBody>
        <BackDeliveryAdhookOverlayFormContent
          open={open}
          onClose={onClose}
          forDelivery={forDelivery}
          handler={handler}
        />
      </OverlayBody>
      <OverlayFooter repel gap="10px">
        <ConfirmButton
          disabled={(ctx) => !ctx.ready || !ctx.values.json.signature}
          onClick={(e, ctx) => {
            const data = {
              project_id: projectId,
              dest_project_id: targetProjectId ? targetProjectId : null,
              recipient: '',
              items: backDeliveryItems.map((e) => ({
                baseitem_id: e.baseitem.id,
                booking_id: e.booking.id,
                amount: e.commissionedAmount,
                ok: e.commissionedAmount - calculateFaultAmount(e),
                faults: extractFaults(e),
              })),
              signature: null,
            }
            if (forDelivery) {
              handleDeliverySubmit(data, ctx.values)
            } else {
              handleAdhookSubmit(data, ctx.values)
            }
          }}
        />
        {!forDelivery && <ClearButton danger onClick={clearSignature} />}
        <CancelButton onClick={onOverlayClose} />
      </OverlayFooter>
    </OverlayForm>
  )
}

const BackDeliveryAdhookOverlayFormContent = ({
  open,
  forDelivery,
  handler,
}) => {
  return (
    <Container>
      <Container>
        <TextField label="Recipient" autoFocus name="recipient" mandatory />
      </Container>
      {!forDelivery && (
        <Container>
          <DateField
            label="Date"
            name="checking_date"
            forbidPast
            mandatory
            clearable
          />
        </Container>
      )}
      <Container flex>
        {open && !forDelivery && (
          <SignatureField name="signature" handler={handler} />
        )}
      </Container>
    </Container>
  )
}

export const PickBackdeliveryItemOverlay = ({
  disabled,
  backdeliveryItem,
  onClose,
}) => {
  const { t } = useTranslation()
  const testAmount =
    backdeliveryItem && backdeliveryItem.faults.length > 0
      ? backdeliveryItem.faults.find((e) => e.type === 'inspection')?.amount ??
        0
      : 0
  const testComment =
    backdeliveryItem && backdeliveryItem.faults.length > 0
      ? backdeliveryItem.faults.find((e) => e.type === 'inspection')?.comment ??
        ''
      : ''
  const repairAmount =
    backdeliveryItem && backdeliveryItem.faults.length > 0
      ? backdeliveryItem.faults.find((e) => e.type === 'repair')?.amount ?? 0
      : 0
  const repairComment =
    backdeliveryItem && backdeliveryItem.faults.length > 0
      ? backdeliveryItem.faults.find((e) => e.type === 'repair')?.comment ?? ''
      : ''
  const removalAmount =
    backdeliveryItem && backdeliveryItem.faults.length > 0
      ? backdeliveryItem.faults.find((e) => e.type === 'disposal')?.amount ?? 0
      : 0
  const removalComment =
    backdeliveryItem && backdeliveryItem.faults.length > 0
      ? backdeliveryItem.faults.find((e) => e.type === 'disposal')?.comment ??
        ''
      : ''
  const okAmount =
    parseInt(backdeliveryItem?.amount ?? 0) -
    (parseInt(testAmount ?? 0) +
      parseInt(repairAmount ?? 0) +
      parseInt(removalAmount ?? 0))

  // TODO: what is this?
  const calculatedTotalAmount = (values) => {
    const okAmount = parseInt(
      values.json['okAmount'] ? values.json['okAmount'] : '0'
    )
    const testAmount = parseInt(
      values.json['testAmount'] ? values.json['testAmount'] : '0'
    )
    const repairAmount = parseInt(
      values.json['repairAmount'] ? values.json['repairAmount'] : '0'
    )
    const removalAmount = parseInt(
      values.json['removalAmount'] ? values.json['removalAmount'] : '0'
    )
    return okAmount + testAmount + repairAmount + removalAmount
  }

  const handleConfirm = (e, ctx) => {
    const okAmount = parseInt(ctx.values.json['okAmount'] ?? 0)
    const testAmount = parseInt(ctx.values.json['testAmount'] ?? 0)
    const repairAmount = parseInt(ctx.values.json['repairAmount'] ?? 0)
    const removalAmount = parseInt(ctx.values.json['removalAmount'] ?? 0)
    const testComment = ctx.values.json['testComment']
    const repairComment = ctx.values.json['repairComment']
    const removalComment = ctx.values.json['removalComment']
    const faults = []

    if (testAmount > 0) {
      faults.push({
        amount: testAmount,
        comment: testComment,
        type: 'inspection',
      })
    }
    if (repairAmount > 0) {
      faults.push({
        amount: repairAmount,
        comment: repairComment,
        type: 'repair',
      })
    }
    if (removalAmount > 0) {
      faults.push({
        amount: removalAmount,
        comment: removalComment,
        type: 'disposal',
      })
    }
    backdeliveryItem.okAmount = okAmount
    backdeliveryItem.commissionedAmount = calculatedTotalAmount(ctx.values)
    backdeliveryItem.faults = faults
    onClose()
  }

  return (
    <OverlayForm
      open={backdeliveryItem}
      onClose={onClose}
      data={{
        okAmount: okAmount ? okAmount : 0,
        testAmount: testAmount ? testAmount : 0,
        testComment: testComment ? testComment : '',
        repairAmount: repairAmount ? repairAmount : 0,
        repairComment: repairComment ? repairComment : '',
        removalAmount: removalAmount ? removalAmount : 0,
        removalComment: removalComment ? removalComment : '',
      }}
      title={t('Defect statement')}
    >
      <OverlayBody>
        <PickBackDeliveryItemFormContent
          backdeliveryItem={backdeliveryItem}
          disabled={disabled}
        />
      </OverlayBody>
      <OverlayFooter repel gap="10px">
        <ConfirmButton
          disabled={(ctx) => {
            if (disabled) {
              return true
            }
            const calculatedAmount = calculatedTotalAmount(ctx.values)
            return (
              !calculatedAmount || calculatedAmount > backdeliveryItem?.amount
            )
          }}
          onClick={handleConfirm}
        />
        <CancelButton onClick={onClose} />
      </OverlayFooter>
    </OverlayForm>
  )
}

const PickBackDeliveryItemFormContent = ({ backdeliveryItem, disabled }) => {
  const { values } = useForm()
  const calculatedAmount =
    parseInt(values.json['okAmount'] ? values.json['okAmount'] : '0') +
    parseInt(values.json['testAmount'] ? values.json['testAmount'] : '0') +
    parseInt(values.json['repairAmount'] ? values.json['repairAmount'] : '0') +
    parseInt(values.json['removalAmount'] ? values.json['removalAmount'] : '0')
  return (
    <Row>
      <GridFieldView
        n={12}
        s={12}
        label="Item"
        value={backdeliveryItem?.baseitem.name}
      />
      {!disabled && (
        <GridFieldView n={6} s={6} label="Menge">
          {calculatedAmount === backdeliveryItem?.amount && (
            <h4 style={{ color: '#303030' }}>{`${
              calculatedAmount ? calculatedAmount : 0
            } / ${backdeliveryItem?.amount}`}</h4>
          )}
          {calculatedAmount !== null &&
            backdeliveryItem?.amount > calculatedAmount && (
              <h4 style={{ color: '#95e68b' }}>{`${
                calculatedAmount ? calculatedAmount : 0
              } / ${backdeliveryItem?.amount}`}</h4>
            )}
          {calculatedAmount > backdeliveryItem?.amount && (
            <h4 style={{ color: '#fc4242' }}>{`${
              calculatedAmount ? calculatedAmount : 0
            } / ${backdeliveryItem?.amount}`}</h4>
          )}
        </GridFieldView>
      )}
      <GridFieldView
        n={!disabled ? 6 : 12}
        s={!disabled ? 6 : 12}
        label="Booking period"
        value={`${formatDateRange(
          backdeliveryItem?.booking?.booking_start,
          backdeliveryItem?.booking?.booking_end
        )}`}
      />
      {!disabled && (
        <GridFieldView n={12} s={12} label="For order">
          <NumericField autoFocus name="okAmount" disabled={disabled} />
        </GridFieldView>
      )}
      <GridFieldView n={6} s={6} label="For testing">
        <NumericField autoFocus name="testAmount" disabled={disabled} />
      </GridFieldView>
      <GridFieldView n={6} s={6} label="Comment">
        <TextField autoFocus name="testComment" disabled={disabled} />
      </GridFieldView>
      <GridFieldView n={6} s={6} label="For repair">
        <NumericField autoFocus name="repairAmount" disabled={disabled} />
      </GridFieldView>
      <GridFieldView n={6} s={6} label="Comment">
        <TextField autoFocus name="repairComment" disabled={disabled} />
      </GridFieldView>
      <GridFieldView n={6} s={6} label="For removal">
        <NumericField autoFocus name="removalAmount" disabled={disabled} />
      </GridFieldView>
      <GridFieldView n={6} s={6} label="Comment">
        <TextField autoFocus name="removalComment" disabled={disabled} />
      </GridFieldView>
    </Row>
  )
}

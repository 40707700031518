import { DateField, TextField, TextAreaField } from 'common/widgets/form/field'
import { SimpleFormAction } from 'common/widgets/form/utils'
import { Row, Column } from 'common/widgets/grid'
import { SectionView } from 'common/widgets/view'

export const ContactPresentFormContent = ({ onAdd, onUpdate, onRemove }) => {
  return (
    <>
      <Row>
        <Column flex n={6}>
          <SectionView>
            <TextField label="Event" name="event" mandatory />
            <DateField label="Date" name="date" />
            <TextAreaField label="Description" name="description" rows="4" />
          </SectionView>
        </Column>
      </Row>
      <SimpleFormAction onAdd={onAdd} onUpdate={onUpdate} onRemove={onRemove} />
    </>
  )
}

import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Container } from 'common/widgets/container'
import { SubmitButton, BackIconButton } from 'common/widgets/button'

import { BackDeliveredItems } from '../items'
import {
  PickBackdeliveryItemOverlay,
  SubmitBackDeliveryOverlay,
} from '../overlays/add'
import { BackDeliverySummaryOverlay } from '../overlays/summary'

import { BackAdHocDeliveryForm } from './details'

/**
 * Renders the back deliveries view page.
 *
 * @returns ReactElemnt
 */
export const CreateBackDeliveryAdHocViewPage = () => {
  const { t } = useTranslation()
  const [backDeliveryItems, setBackDeliveryItems] = useState([])
  const [projectId, setProjectId] = useState(null)
  const [addBackdeliveryItem, setAddBackdeliveryItem] = useState(null)
  const [
    submitAdHocBackDeliveryOverlayVisible,
    setSubmitAdHocBackDeliveryOverlayVisible,
  ] = useState(false)
  const [summaryOverlayVisible, setSummaryOverlayVisible] = useState(false)
  const resources = backDeliveryItems?.filter(
    (item) => item.baseitem?.is_resource
  )
  const formworks = backDeliveryItems?.filter(
    (item) => item.baseitem?.is_formwork
  )

  const handleBackDeliveryItemAdd = (backDeliveryItem) => {
    setAddBackdeliveryItem(backDeliveryItem)
  }

  return (
    <Container gap="5px">
      <Container flex align="center" gap="10px">
        <BackIconButton />
        <h1>{`${t('Ad Hoc Back Delivery')}`}</h1>
      </Container>
      <BackAdHocDeliveryForm
        setBackDeliveryItems={setBackDeliveryItems}
        setProjectId={setProjectId}
      />
      <Container flex vertical grow>
        {resources.length > 0 && (
          <BackDeliveredItems
            onAdd={handleBackDeliveryItemAdd}
            backDeliveryItems={resources}
            title="RESOURCE"
          />
        )}
        {formworks.length > 0 && (
          <BackDeliveredItems
            onAdd={handleBackDeliveryItemAdd}
            backDeliveryItems={formworks}
            title="FORMWORK"
          />
        )}
        <SubmitButton
          default
          disabled={
            backDeliveryItems.filter((item) => item.commissionedAmount > 0)
              .length === 0
          }
          onClick={() => {
            setSummaryOverlayVisible(true)
          }}
        />
      </Container>
      <PickBackdeliveryItemOverlay
        onClose={() => setAddBackdeliveryItem(null)}
        backdeliveryItem={addBackdeliveryItem}
      />
      {summaryOverlayVisible && (
        <BackDeliverySummaryOverlay
          open={summaryOverlayVisible}
          backDeliveryItems={backDeliveryItems.filter(
            (e) => e.commissionedAmount > 0
          )}
          projectId={projectId}
          onSubmit={() => setSubmitAdHocBackDeliveryOverlayVisible(true)}
          onClose={() => setSummaryOverlayVisible(false)}
        />
      )}
      {submitAdHocBackDeliveryOverlayVisible && (
        <SubmitBackDeliveryOverlay
          open={submitAdHocBackDeliveryOverlayVisible}
          onClose={() => setSubmitAdHocBackDeliveryOverlayVisible(false)}
          backDeliveryItems={backDeliveryItems.filter(
            (e) => e.commissionedAmount > 0
          )}
          projectId={projectId}
          forDelivery={false}
        />
      )}
    </Container>
  )
}

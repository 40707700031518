import { TextField } from 'common/widgets/form/field'
import { SectionView } from 'common/widgets/view'
import { Form } from 'common/widgets/form/context'

/**
 * Cost center data entry form.
 */
export const CostCenterForm = (props) => {
  return (
    <Form baseUrl="/accounting/cost-centers" showActions auto {...props}>
      <SectionView title="Cost Center">
        <TextField name="code" mandatory label="Code" />
        <TextField name="name" label="Name" />
      </SectionView>
    </Form>
  )
}

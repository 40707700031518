import { useNavigate } from 'react-router-dom'
import { PlusSquare, Slash, Square } from 'react-feather'
import { useTranslation } from 'react-i18next'

import { Avatar } from 'common/widgets/avatar'
import { Container } from 'common/widgets/container'
import { SimpleDataTable, SimpleColumn } from 'common/data-table/simple'
import { formatDateTime } from 'common/utils/format'
import { formatUserName } from 'modules/security/users/utils'
import { CommentView } from 'common/widgets/view'

export const UsersListPage = () => {
  const navigate = useNavigate()

  return (
    <SimpleDataTable
      name="users"
      title="Users"
      archive
      url="security/users?select=roles:role(id,key)"
      onAddClick={() => navigate('/security/users/add')}
      navBaseUrl="/security/users"
    >
      <SimpleColumn fixed>
        {(r) => <Avatar text={r.fullname} imgSrc={r.photo} />}
      </SimpleColumn>
      <SimpleColumn sortable n={12} s={12} header>
        {(r) => (
          <Container flex vertical>
            <h3>{formatUserName(r)}</h3>
            <UserStateComment user={r} />
          </Container>
        )}
      </SimpleColumn>
      <SimpleColumn s={6} n={4} sortable field="email" label="E-Mail" />
      <SimpleColumn
        s={6}
        n={2}
        sortable
        field="phone_number"
        label="Telephone"
      />
      <SimpleColumn
        s={6}
        n={6}
        sortable
        field="last_login"
        label="Last login"
        value={(r) => formatDateTime(r.last_login)}
      />
      <SimpleColumn
        n={6}
        s={12}
        field="role"
        label="Roles"
        filter={(e) => e?.id}
        title={(e) => e?.name}
        filterUrl="/security/roles"
        value={(r) => r.roles?.map((ur) => ur.key).join(', ')}
      />
    </SimpleDataTable>
  )
}

// TODO: delete this. This should arrive from the API.
const UserStateComment = ({ user }) => {
  const { t } = useTranslation(['security'])
  let icon = null
  let state = 'unknown'
  let color = 'grey'
  if (user?.archived_at != null) {
    icon = <Slash />
    state = 'Archived'
    color = '#FC4242'
  } else if (user?.last_login == null) {
    icon = <Square />
    state = 'New'
    color = '#888888'
  } else {
    icon = <PlusSquare />
    state = 'Active'
    color = '#95E68B'
  }
  return <CommentView icon={icon} comment={t(state)} color={color} />
}

import React, { useState, useEffect } from 'react'

import { useService } from 'common/service/context'
import { useSession } from 'common/session/context'
import { NotificationIconButton } from 'common/widgets/button'

import { NotificationOverlay } from './overlay'

export const NotificationIcon = ({ name, active, onClose }) => {
  const service = useService()
  const session = useSession()
  const [unreadCount, setUnreadCount] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      // If unread count is null, it means that it is the first time to call.
      const [response, error] = await service.get('notification')
      if (!error) {
        setUnreadCount(parseInt(response.data))
      }
    }
    if (unreadCount === null && session.isLoggedIn()) {
      setUnreadCount(0)
      fetchData()
    }
  }, [unreadCount, service])

  return (
    <>
      <NotificationIconButton badge={unreadCount <= 99 ? unreadCount : '99+'} />
      <NotificationOverlay name={name} open={active} onClose={onClose} />
    </>
  )
}

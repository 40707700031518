import { Container } from 'common/widgets/container'
import { Row } from 'common/widgets/grid'

const { useTranslation } = require('react-i18next')

const { formatDateTime, formatDate } = require('common/utils/format')
const { GridFieldView } = require('common/widgets/view')

/**
 * Renders order dates
 * (planned delivery, fixted delivery, min delivery, max delivery)
 * based on the delivery option.
 *
 * @param {any} order object
 * @returns ReactElement
 */
export const OrderDates = ({ order }) => {
  // translation context
  const { t } = useTranslation(['orders'])
  return (
    <Container flex vertical>
      {order.planned_delivery_date && (
        <Container>
          <Row>
            <GridFieldView
              n={4}
              label="Planned date"
              value={formatDate(order.planned_delivery_date)}
            />
          </Row>
        </Container>
      )}
      {(order.dispo_min_required_by ||
        order.dispo_max_required_by ||
        order.dispo_required_by) && (
        <Container>
          <h3>{t('Disposition')}</h3>
          {order.dispo_min_required_by || order.dispo_max_required_by ? (
            <Row>
              <GridFieldView
                n={4}
                label="Earliest required date"
                value={formatDateTime(order.dispo_min_required_by)}
              />
              <GridFieldView
                n={4}
                label="Latest required date"
                value={formatDateTime(order.dispo_max_required_by)}
              />
            </Row>
          ) : (
            <Row>
              <GridFieldView
                n={4}
                label="Fixed date"
                value={formatDateTime(order.dispo_required_by)}
              />
            </Row>
          )}
        </Container>
      )}
    </Container>
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'

import { GridFieldView, SectionView } from 'common/widgets/view'
import { Row } from 'common/widgets/grid'

import { OrderableColumnFieldView } from '../widgets/orderable'

/**
 * Renders resource (large-scale & small scalle) billings data
 *
 * @returns ReactElement
 */
export const BillingView = ({ data }) => {
  const { t } = useTranslation()
  return (
    <SectionView>
      <h1>{t('Billing')}</h1>
      <Row>
        <GridFieldView n={6} label="Cost center" value={data.cost_center} />
        <GridFieldView
          n={6}
          label="Accounting type"
          value={t(data.accounting_type)}
        />
        <GridFieldView n={6} label="Cost rate" value={data.cost_rate} />
        <GridFieldView
          n={6}
          label="Balance sheet depreciation"
          value={data.balance_sheet_depreciation}
        />
        <OrderableColumnFieldView n={6} value={data.is_orderable} />
      </Row>
    </SectionView>
  )
}

import React from 'react'

import { Row, Column } from 'common/widgets/grid'
import {
  ProjectBillingView,
  ProjectMasterDataView,
} from 'modules/projects/view/master-data'
import { DocumentsSectionView } from 'common/widgets/document'
import { SectionView } from 'common/widgets/view'
import { AddressMap } from 'common/widgets/map/address'
import { WeatherForecast } from 'common/widgets/weather'

import { AddressFieldView } from '../widgets/address'

import { ProjectStatusView } from './status'
import { ProjectMembersView } from './members'

export const ProjectOverviewTab = ({ project, update }) => {
  return (
    <Row>
      <Column n={6} m={6} s={12} gap="10px" flex vertical>
        <ProjectMasterDataView data={project} />
        <ProjectBillingView data={project} />
      </Column>
      <Column flex n={6} m={6} s={12}>
        <SectionView>
          <AddressMap address={project?.address} />
          <AddressFieldView address={project?.address} />
        </SectionView>
      </Column>
      <Column flex n={6} m={6} s={12}>
        <ProjectStatusView project={project} update={update} />
      </Column>
      <Column flex n={6} m={6} s={12}>
        <WeatherForecast
          lon={project?.address?.lon}
          lat={project?.address?.lat}
        />
      </Column>
      <Column flex n={12} m={12} s={12}>
        <ProjectMembersView project={project} update={update} />
      </Column>
      <Column flex n={12} m={12} s={12}>
        <DocumentsSectionView docRef={`project=${project.id}`} />
      </Column>
    </Row>
  )
}

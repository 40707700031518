import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Calendar, Clock, MapPin } from 'react-feather'

import { Container } from 'common/widgets/container'
import {
  PagedDataSource,
  useDataSourceContext,
} from 'common/widgets/data-source'
import { CardView, GridFieldView } from 'common/widgets/view'
import { SearchInput } from 'common/widgets/search'
import { DropDown } from 'common/widgets/dropdown'
import { Column, Row } from 'common/widgets/grid'
import { AddButton, ClockIconButton } from 'common/widgets/button'
import { formatDateRange } from 'common/utils/format'
import { Tab } from 'common/widgets/tab'
import { ArchiveIconButton } from 'common/widgets/helpers/archive'
import { TaskStatusItem } from 'modules/yard/maintenance/list'

import { BookingHoursOverlay } from './overlay'
import styles from './tasks.module.css'

export const TasksPage = () => {
  const navigate = useNavigate()

  return (
    <>
      <Container title="Tasks" flex>
        <AddButton
          text="New"
          default
          onClick={() => navigate('/projects/tasks/add')}
          style={{ maxWidth: '200px' }}
        />
      </Container>
      <Tab
        items={[
          {
            key: 'open',
            title: 'Open',
            render: () => <TasksList name="open" showOpen />,
          },
          {
            key: 'closed',
            title: 'Closed',
            render: () => <TasksList name="closed" showClosed />,
          },
          {
            key: 'all',
            title: 'All',
            render: () => <TasksList name="all" />,
          },
        ]}
      />
    </>
  )
}

export const TasksList = ({ name, projectId, showOpen, showClosed }) => {
  const priorities = [
    {
      key: 'Low',
      title: 'Low',
    },
    {
      key: 'Medium',
      title: 'Medium',
    },
    {
      key: 'High',
      title: 'High',
    },
  ]

  let effectiveParams = []
  if (showOpen) {
    effectiveParams.push({ done: false })
  } else if (showClosed) {
    effectiveParams.push({ done: true })
  }
  return (
    <PagedDataSource
      name={name}
      url={projectId ? `/tasks?project_id=${projectId}` : `/tasks`}
      params={effectiveParams}
      limit={5}
    >
      {(ctx) => {
        return (
          <CardView>
            <Container title="Tasks" flex gap="10px">
              <DropDown
                style={{ minWidth: '100px' }}
                title="Priority"
                items={priorities}
                name="priority"
                selectedIndex={priorities.findIndex(
                  (e) => e.key === ctx?.getQueryParameter('priority')
                )}
                removable
              />
              <ArchiveIconButton />
              <SearchInput />
            </Container>

            {showOpen && <MyTasks title="Open" />}
            {showClosed && <MyTasks title="Closed" />}
            {!(showOpen || showClosed) && <MyTasks title="All" />}
          </CardView>
        )
      }}
    </PagedDataSource>
  )
}

/**
 * Renders the user`s tasks
 *
 * @returns ReactElement
 */

const MyTasks = ({ title }) => {
  const { t } = useTranslation()
  const ctx = useDataSourceContext()

  return (
    <>
      <Row>
        <Column>
          <h1
            style={{
              color: '#376DF4',
              fontWeight: 'bold',
              fontSize: '25px',
            }}
          >
            {ctx?.totalCount}
          </h1>
          <h5 style={{ marginBottom: '10px' }}>{t(title)}</h5>
        </Column>
      </Row>
      {ctx?.data &&
        ctx?.data.map((e, i) => {
          return <TaskItem key={i} task={e} />
        })}
    </>
  )
}

const TaskItem = ({ task, disabled }) => {
  const navigate = useNavigate()
  const [selectedTask, setSelectedTask] = useState(null)
  const ctx = useDataSourceContext()

  const formatProjectAddress = (address) => {
    var formattedAddress = ''
    if (address) {
      if (address.state) {
        formattedAddress = address.state
      }
      if (address.city) {
        formattedAddress = `${formattedAddress} ${address.city}`
      }
      if (address.street) {
        formattedAddress = `${formattedAddress} ${address.street}`
      }
      if (address.street_number) {
        formattedAddress = `${formattedAddress} ${address.street_number}`
      }
      if (address.zip_code) {
        formattedAddress = `${formattedAddress} ${address.zip_code}`
      }
    }

    return formattedAddress
  }

  const getPriorityТаскColor = (priority) => {
    if (priority === 'Low') {
      return '#8B8B8B'
    } else if (priority === 'Medium') {
      return '#F49537'
    } else if (priority === 'High') {
      return '#FC4242'
    }

    return
  }

  return (
    <Container
      className={styles.tasks}
      onClick={() =>
        !disabled ? navigate(`/projects/tasks/${task.id}`) : null
      }
      divider
    >
      <Container flex>
        <TaskStatusItem
          color={getPriorityТаскColor(task.priority)}
          style={{ marginRight: '10px', width: '3px' }}
        />
        <Row style={{ paddingBottom: '10px' }}>
          <GridFieldView n={12} m={12} s={12}>
            <h4>{task.title}</h4>
          </GridFieldView>
          <Column n={2.4}>
            <Container flex>
              <TaskStatusItem
                color="#E9E9E9"
                style={{ marginRight: '10px', width: '3px' }}
              />
              <GridFieldView
                n={3}
                m={3}
                s={3}
                label="Project"
                value={task.project?.name}
              />
            </Container>
          </Column>
          <Column n={2.4}>
            <Container flex>
              <TaskStatusItem
                color="#E9E9E9"
                style={{ marginRight: '10px', width: '3px' }}
              />
              <GridFieldView
                n={3}
                m={3}
                s={3}
                label="Phase"
                value={task.phase?.name}
              />
            </Container>
          </Column>
          <Column n={2.4}>
            <Container flex>
              <TaskStatusItem
                color="#E9E9E9"
                style={{ marginRight: '10px', width: '3px' }}
              />
              <GridFieldView
                n={3}
                m={3}
                s={3}
                label="Hours"
                value={`${task.spent_time} / ${task.planned_time}`}
              />
            </Container>
          </Column>
          <Column n={2.4}>
            <Container flex>
              <TaskStatusItem
                color="#E9E9E9"
                style={{ marginRight: '10px', width: '3px' }}
              />
              <GridFieldView n={3} m={3} s={3} label="Priority">
                {task.priority && <StatusField priority={task.priority} />}
              </GridFieldView>
            </Container>
          </Column>
          <Column n={2.4}>
            <Container flex>
              <TaskStatusItem
                color="#E9E9E9"
                style={{ marginRight: '10px', width: '3px' }}
              />
              <GridFieldView n={3} m={3} s={3} label="Status">
                <StatusField priority={task.status} />
              </GridFieldView>
            </Container>
          </Column>
          <GridFieldView n={4} m={4} s={12}>
            <Container flex gap="5px">
              <Calendar color="grey" />
              <h5>
                {formatDateRange(
                  task.schedule?.start_date,
                  task.schedule?.due_date
                )}
              </h5>
            </Container>
          </GridFieldView>
          <GridFieldView n={4} m={4} s={12}>
            <Container flex gap="5px">
              <Clock color="grey" />
              <h5>{task.spent_time}</h5>
            </Container>
          </GridFieldView>
          {task.project?.address && (
            <GridFieldView n={4} m={4} s={12}>
              <Container flex gap="5px">
                <MapPin color="grey" />
                <h5>{formatProjectAddress(task.project.address)}</h5>
              </Container>
            </GridFieldView>
          )}
        </Row>
        {!disabled && (
          <ClockIconButton
            onClick={(r) => {
              r.stopPropagation()
              setSelectedTask(task)
            }}
            style={{ alignSelf: 'center' }}
          />
        )}
        {selectedTask && (
          <BookingHoursOverlay
            task={selectedTask}
            open={() => selectedTask !== null}
            onClose={() => setSelectedTask(null)}
            onSubmit={() => ctx?.reload()}
          />
        )}
      </Container>
    </Container>
  )
}

/**
 * Renders the task`s status field
 *
 * @param {any} priority task`s priority
 *
 * @returns ReactElement
 */
export const StatusField = ({ priority }) => {
  const { t } = useTranslation()

  /**
   * Builds the text priority color
   *
   * @returns string
   */
  const getPriorityTextColor = () => {
    if (priority === 'Low') {
      return '#8B8B8B'
    } else if (priority === 'Medium') {
      return '#F49537'
    } else {
      return '#FC4242'
    }
  }

  /**
   * Builds priority background color
   *
   * @param {any} priority task`s priority
   * @returns string
   */
  const getPriorityBackgroundColor = (priority) => {
    if (priority === 'Low') {
      return '#E9E9E9'
    } else if (priority === 'Medium') {
      return '#FDEAD7'
    } else {
      return '#FFD9D9'
    }
  }

  return (
    <Container flex>
      <div
        className={styles.statusContainer}
        style={{
          background: getPriorityBackgroundColor(priority),
        }}
      >
        <h4
          style={{
            color: getPriorityTextColor(priority),
            fontWeight: 'bold',
          }}
        >
          {t(priority)}
        </h4>
      </div>
    </Container>
  )
}

import { Form } from 'common/widgets/form/context'
import { TextField, FieldWrapper } from 'common/widgets/form/field'
import { SectionView } from 'common/widgets/view'

import { ScopeTree } from './widgets'

export const RoleForm = ({ data, onAdd, onUpdate, onRemove }) => {
  return (
    <Form
      data={data}
      baseUrl="/security/roles"
      params={[{ select: 'scopes:scope(id,key,description,parent_id)' }]}
      showActions
      auto
    >
      <SectionView>
        <TextField name="key" label="Name" mandatory />
        <TextField name="description" label="Description" />
        <ScopeField name="scopes" label="Scopes" />
      </SectionView>
    </Form>
  )
}

const ScopeField = ({ name, ...rest }) => {
  return (
    <FieldWrapper name={name} {...rest}>
      {(getValue, setValue) => {
        return (
          <ScopeTree
            disabled={false}
            scopes={getValue(name, true)}
            onChange={(scopes) => {
              const scopeIds = []
              Object.values(scopes).forEach((n) => {
                scopeIds.push(n.key)
              })
              setValue(scopeIds)
            }}
            {...rest}
          />
        )
      }}
    </FieldWrapper>
  )
}

import { Archive, Repeat } from 'react-feather'
import { useTranslation } from 'react-i18next'

import { useService } from 'common/service/context'
import { useToast } from 'system/toast/context'
import { useDataSourceContext } from 'common/widgets/data-source'
import * as btn from 'common/widgets/button'

/**
 * Renders a managed archive button.
 * @param {string} url base url to call put request.
 * @param {any} record record instance.
 * @param {Function} reload reload function, optional.
 * @param {Function} message to create test message, optional.
 * @returns ReactElement
 */
export const ArchiveButton = ({ url, record, reload, message }) => {
  const service = useService()
  const { t } = useTranslation()
  const { toasts } = useToast()

  /**
   * Calls archive action on the given record
   * @param {any} record record instance
   */
  const archive = async (record) => {
    await service.post(`${url}${record.id}`, {
      archived_at: record.archived_at === null ? new Date() : null,
    })
    const text = message
      ? message(record)
      : record.archived_at
      ? `${t('Sucessfully activated.')}`
      : `${t('Sucessfully archived.')}`
    toasts.success(text)
    if (reload) {
      reload()
    }
  }

  return (
    <btn.IconButton onClick={archive}>
      {record.archived_at ? <Repeat /> : <Archive />}
    </btn.IconButton>
  )
}

export function ArchiveIconButton({ onClick, ...rest }) {
  const ctx = useDataSourceContext()

  return (
    <btn.ArchiveIconButton
      tooltip="Archive"
      archived={ctx?.getQueryParameter('archived')}
      onClick={
        onClick ??
        ((e) =>
          ctx?.setQueryParameter(
            'archived',
            !ctx?.getQueryParameter('archived')
          ))
      }
      {...rest}
    />
  )
}

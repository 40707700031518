import { useParams } from 'react-router-dom'

import { Form } from 'common/widgets/form/context'
import { useService } from 'common/service/context'
import { DataSource } from 'common/widgets/data-source'

import { OrderedItemFormContent } from './form'

export const OrderedItemAddPage = () => {
  const service = useService()
  const { orderId, baseItemId } = useParams()

  const handleAdd = async (values) => {
    const normalized = { ...values, item: baseItemId }
    return await service.post(`orders/${orderId}/items`, normalized)
  }

  const fetch = async () =>
    await service.get(`orders/${orderId}/base-items/${baseItemId}`)

  return (
    <DataSource
      fetch={fetch}
      render={({ data }) => (
        <Form>
          {data && <OrderedItemFormContent data={data} onAdd={handleAdd} />}
        </Form>
      )}
    />
  )
}

import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'

import { Container } from 'common/widgets/container'
import { Button } from 'common/widgets/button'
import { OverlayBody, OverlayForm, OverlayFooter } from 'common/widgets/overlay'
import { useService } from 'common/service/context'
import { GridFieldView } from 'common/widgets/view'
import { Row, Column } from 'common/widgets/grid'
import { formatDateRange } from 'common/utils/format'
import { useToast } from 'system/toast/context'

/**
 * Renders confirmation overlay for canceling an order.
 *
 * @param {any} order order object
 * @param {any} open state of opening the dialog
 * @param {any} onClose state of closing the dialog
 * @returns ReactElement
 */

export const OrderCancelConfirmOverlay = ({ order, open, onClose }) => {
  const { t } = useTranslation(['orders'])
  const service = useService()
  const navigate = useNavigate()

  /**
   * Cancel the order
   * @returns Array[result, error]
   */
  const onOrderCancel = async () => {
    const [, error] = await service.put(`orders/${order.id}/cancel`)
    if (!error) {
      onClose()
      navigate(-1)
    }
  }

  return (
    <OverlayForm open={open} onClose={onClose} title="Cancel order">
      <OverlayBody>
        <Container flex gap="10px">
          <Button danger onClick={onOrderCancel}>
            <p>{t('Yes')}</p>
          </Button>
          <Button onClick={onClose}>
            <p>{t('No')}</p>
          </Button>
        </Container>
      </OverlayBody>
    </OverlayForm>
  )
}

/**
 * Renders confirmation overlay for canceling an ordered item.
 *
 * @param {any} item ordered item object
 * @param {any} open state of opening the dialog
 * @param {any} onClose state of closing the dialog
 * @returns ReactElement
 */
export const ConfirmItemCancelationOverlay = ({
  reload,
  item,
  open,
  onClose,
}) => {
  const { t } = useTranslation(['orders'])
  const service = useService()
  // Get system-wide user messages (toasts)
  const { toasts } = useToast()

  /**
   * Cancel a single ordered item
   * @returns Array[result, error]
   */
  const onCancelOrderedItem = async () => {
    const [, error] = await service.put(`orders/items/${item.id}/cancel`)
    if (!error) {
      toasts.success(t('Order item [{{id}}] cancelled.', { id: item.id }))
      onClose && onClose()
      await reload()
    }
  }

  return (
    item && (
      <OverlayForm open={open} onClose={onClose} title="Cancel item">
        <OverlayBody>
          <Row>
            <Column>
              <h3>{item.item.name}</h3>
            </Column>
            <GridFieldView n={6} s={6} label="Ordered amount">
              <h3>{item.original_amount}</h3>
            </GridFieldView>

            <GridFieldView n={6} s={6} label="Open amount">
              <h3>{item.open_amount}</h3>
            </GridFieldView>
            <GridFieldView n={6} s={6} label="Commissioned amount">
              <h3>{item.commission_amount}</h3>
            </GridFieldView>
            <GridFieldView n={6} s={6} label="Delivered amount">
              <h3>{item.delivered_amount}</h3>
            </GridFieldView>
            {item.item.is_formwork && (
              <GridFieldView n={6} s={6} label="Disposition amount">
                <h3>{item.booking?.amount}</h3>
              </GridFieldView>
            )}
            {(item.item.is_resource || item.item.is_formwork) && (
              <GridFieldView n={6} s={6} label="Booking period">
                <h3>
                  {formatDateRange(item?.booking_start, item?.booking_end)}
                </h3>
              </GridFieldView>
            )}
            {(item.item.is_consumable || item.item.is_formwork) && (
              <GridFieldView n={6} s={6} label="Packaging unit">
                <h3>{item.item.packaging_unit}</h3>
              </GridFieldView>
            )}
          </Row>
        </OverlayBody>
        <OverlayFooter gap="10px">
          <Button danger onClick={onCancelOrderedItem}>
            <p>{t('Yes')}</p>
          </Button>
          <Button onClick={onClose}>
            <p>{t('No')}</p>
          </Button>
        </OverlayFooter>
      </OverlayForm>
    )
  )
}

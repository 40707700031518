import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { dateToISOString } from 'common/utils/format'
import { Form, useForm } from 'common/widgets/form/context'
import { Column, Row } from 'common/widgets/grid'
import { SectionView } from 'common/widgets/view'
import {
  ItemProcessEnum,
  ItemTypeEnum,
} from 'modules/master-data/equipments/utils'
import { formatProjectName } from 'modules/projects/utils'
import { DetailViewPage } from 'system/utils/view'
import { useService } from 'common/service/context'
import { ConfirmButton, CancelButton } from 'common/widgets/button'
import { ConfirmOverlay } from 'common/widgets/overlay'
import { formatOrderNumber } from 'modules/orders/utils'
import {
  PendingRequestData,
  PendingRequestOverview,
} from 'modules/disposition/requests/new/data'
import { AuditedRequestOverlay } from 'modules/disposition/requests/utils'
import { addDays, startOfWeek } from 'common/utils/date'
import { Container } from 'common/widgets/container'

import { BookingConfirmLegends, BookingResourceTimeline } from './calendar'
import { BookingConfirmOverlay } from './overlay'

const BookingData = ({ request }) => {
  const { t } = useTranslation()
  const { values } = useForm()
  const { maincategory, category, subcategory } = values.json
  const service = useService()
  const navigate = useNavigate()
  const [dates, setDates] = useState({
    start: dateToISOString(startOfWeek(request.booking_start)),
    end: dateToISOString(addDays(startOfWeek(request.booking_start), 28)),
  })
  const [data, setData] = useState({
    resources: [],
    bookings: [],
    suggestions: [],
  })
  const [slot, setSlot] = useState({
    resource: null,
    start: null,
    end: null,
  })
  const [rejectOverlayVisible, showRejectOverlay] = useState(false)

  /**
   * Cancels ordered item and the current booking request.
   * @param {any} request booking request data
   * @returns
   */
  const cancelOrderedItem = async (request) => {
    const [result, error] = await service.put(
      `/orders/items/${request.ordered_item.id}/cancel`
    )
    if (!error) {
      navigate('/disposition/requests/new')
    }
    return [result, error]
  }

  /**
   * Fetches resources and related bookings
   * @returns Array
   */
  const reload = async () => {
    /**
     * A small wrapper on top of fetch to make many calls at once.
     * @param {string} key call key
     * @param {string} url api url
     * @param {Array} params api params
     * @returns
     */
    const fetch = async (key, url, params) => {
      const [result, error] = await service.get(url, params)
      return [key, result, error]
    }
    // Pair of key and value to pass as params to api.
    const params = [
      ['type', ItemTypeEnum.RESOURCE],
      ['process', ItemProcessEnum.DISPOSITION],
      ['maincategory', maincategory],
      ['category', category],
      ['subcategory', subcategory],
    ]
      .filter((e) => e[1] !== null)
      .map((e) => ({ [e[0]]: e[1] }))

    // Runs request in parallel to fetch all required results
    const results = await Promise.all([
      fetch(
        'resources',
        `/items/resources?archived=false&is_orderable=true`,
        params
      ),
      fetch(
        'bookings',
        `/disposition/bookings?is_orderable=true&booking_start[lte]=${encodeURIComponent(
          dates.booking_end
        )}&booking_end[gte]=${encodeURIComponent(dates.booking_start)}`,
        params
      ),
      fetch(
        'suggestions',
        `/disposition/requests/suggest?booking_start=${encodeURIComponent(
          request.booking_start
        )}&booking_end=${encodeURIComponent(request.booking_end)}`,
        params
      ),
    ])

    // Inflates collected data in a different form to use to populate UI.
    setData(
      Object.fromEntries(
        results.map((e) => {
          const [key, result, error] = e
          const data = error ? [] : result.data
          return [key, data]
        })
      )
    )
  }

  // Registers an effect so when the dates or category changes it reloads data
  useEffect(() => {
    reload()
  }, [dates, maincategory, category, subcategory])

  return (
    <Row>
      <Column flex n={6}>
        <PendingRequestData request={request} />
      </Column>
      <Column flex n={6}>
        <PendingRequestOverview request={request} />
      </Column>
      <Column flex n={12}>
        <SectionView>
          <BookingResourceTimeline
            request={request}
            onSelectSlot={(resource, start, end) =>
              setSlot({ resource: resource, start: start, end: end })
            }
            onCalendarDateChange={(start, end) =>
              setDates({ start: start, end: end })
            }
            {...data}
          />
          <BookingConfirmLegends />
        </SectionView>
      </Column>
      <Column flex n={12}>
        <Container flex gap="5px">
          <ConfirmButton
            onClick={() => setSlot({ resource: null, start: null, end: null })}
          />
          <CancelButton
            text="Reject"
            danger
            onClick={() => showRejectOverlay(true)}
          />
        </Container>
      </Column>
      {slot.resource && (
        <BookingConfirmOverlay
          onClose={() => setSlot({ resource: null, start: null, end: null })}
          request={request}
          {...slot}
        />
      )}
      <ConfirmOverlay
        open={rejectOverlayVisible}
        onReject={() => showRejectOverlay(false)}
        onConfirm={async () => await cancelOrderedItem(request)}
        title={t('Remove')}
      >
        <p>
          {t(
            'Ordered item for order <b>{{order}}</b> and booking request for project [{{project}}] will be rejected, are you sure?',
            {
              project: formatProjectName(request.project),
              order: formatOrderNumber(request.ordered_item.order?.number),
            }
          )}
        </p>
      </ConfirmOverlay>
    </Row>
  )
}

export const PendingRequestConfirm = () => {
  return (
    <DetailViewPage url="/disposition/requests" title="Booking">
      {(data) => (
        <>
          {data && (
            <Form
              data={{
                maincategory: data.ordered_item.item.maincategory,
                category: data.ordered_item.item.category,
                subcategory: data.ordered_item.item.subcategory,
              }}
            >
              <BookingData request={data} />
            </Form>
          )}
          <AuditedRequestOverlay request={data} />
        </>
      )}
    </DetailViewPage>
  )
}

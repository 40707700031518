import { useService } from 'common/service/context'

import { BaseMap } from './base'
import styles from './map.module.css'

export const AddressMap = ({ address, title, onAddressChange, ...rest }) => {
  const service = useService()

  const addrPin =
    address?.lat && address?.lon
      ? {
          lat: address?.lat,
          lon: address?.lon,
          popup: (
            <div className={styles.addrPopup}>
              {<h3>{title}</h3>}
              <p>
                {formatAddress(address)?.map((line, index) => (
                  <span key={index}>
                    {line}
                    <br />
                  </span>
                ))}
                <br />
                <small>
                  Latitude: {address.lat}
                  <br />
                  Longitude: {address.lon}
                </small>
              </p>
            </div>
          ),
        }
      : null

  const handleMarkerChange = async (e, map, latlon) => {
    console.log('latlon', latlon)
    if (latlon && onAddressChange) {
      const [addr, error] = await service.get('/gis/reverse', {
        ...latlon,
        format: 'json',
      })
      if (!error && addr?.data) {
        onAddressChange({ ...addr.data, ...latlon })
      }
    }
  }

  return (
    <BaseMap
      singleMarker
      markers={addrPin ? [addrPin] : null}
      geofences={address?.geofence ? [address?.geofence] : null}
      onMarkerAdd={(e, { map, latlon }) => {
        console.debug('Marker added', e, latlon)
        handleMarkerChange(e, map, latlon)
      }}
      onMarkerRemove={(e, { map, latlon }) => {
        console.debug('Marker removed', e, latlon)
        onAddressChange && onAddressChange(null)
      }}
      onMarkerChange={(e, { map, latlon }) => {
        console.debug('Marker changed', e, latlon)
        handleMarkerChange(e, map, latlon)
      }}
      onGeofenceAdd={(e, { map, geofence }) => {
        console.debug('Geofence added', e, geofence)
        // Convert the leaflet format to PostGIS (closed ring)
        const polygon = [...geofence, geofence[0]]
        onAddressChange && onAddressChange({ ...address, geofence: polygon })
      }}
      onGeofenceRemove={(e, { map, geofence }) => {
        console.debug('Geofence removed', e, geofence)
        onAddressChange && onAddressChange({ ...address, geofence: null })
      }}
      onGeofenceChange={(e, { map, geofence }) => {
        console.debug('Geofence changed', e, geofence)
        onAddressChange && onAddressChange({ ...address, geofence })
      }}
      {...rest}
    />
  )
}

const formatAddress = (address) => {
  const lines = []
  if (address) {
    if (address.street && address.street_number) {
      lines.push(`${address.street} ${address.street_number}`)
    }
    if (address.zip_code && address.city) {
      lines.push(`${address.zip_code} ${address.city}`)
    }
    if (address.country) {
      lines.push(`${address.country}`)
    }
  }
  return lines.length > 0 ? lines : ['...']
}

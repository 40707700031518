import React from 'react'

import { Avatar } from 'common/widgets/avatar'
import { Container } from 'common/widgets/container'
import { ProjectMapOverview } from 'modules/projects/widgets/map'
import { SimpleColumn, SimpleDataTable } from 'common/data-table/simple'
import { formatDateRange } from 'common/utils/format'
import { CommentView } from 'common/widgets/view'
import { UserView } from 'modules/security/users/widgets'
import { QueryProvider } from 'common/query/context'
import { useMediaQuery } from 'common/utils/hooks'

export const ProjectsPage = () => {
  const isRowBased = useMediaQuery('(max-width: 768px)')

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: isRowBased ? '1fr' : '1fr 1fr',
        gap: 0,
        padding: 0,
      }}
    >
      <QueryProvider
        name="project-list-provider"
        url="/projects"
        params={{
          select:
            'creator_user:user!creator(id,first_name,last_name),avatar:file!avatar(id,path)',
        }}
      >
        <SimpleDataTable
          name="projects"
          title="Projects"
          addTooltip="Add project"
          navBaseUrl="/projects"
          archive
          showAddBtn
          style={{ padding: '10px' }}
        >
          <SimpleColumn fixed>
            {(r) => <Avatar text={r.name} imgSrc={r.avatar?.path} />}
          </SimpleColumn>
          <SimpleColumn sortable n={12} s={12} header>
            {(r) => (
              <>
                <h3>{r.name}</h3>
                <Container flex repel>
                  <h5>{r.short_name}</h5>
                  <h5>{formatDateRange(r.start_date, r.end_date)}</h5>
                </Container>
              </>
            )}
          </SimpleColumn>
          <SimpleColumn
            n={6}
            s={6}
            sortable
            filter={(e) => e?.id}
            title={(e) => e?.name}
            label="Project template"
            field="type"
            filterUrl="/projects/types"
          />
          <SimpleColumn
            n={6}
            s={6}
            sortable
            filter={(e) => e?.id}
            title={(e) => e?.name}
            label="Current phase"
            field="phase"
            filterUrl="/projects/phases"
          />
          <SimpleColumn sortable n={6} s={6}>
            {(r) => (
              <Container flex vertical gap="5px">
                {r.assignees?.length === 0 && (
                  <CommentView warning comment="No related users!" />
                )}
                {r.assignees?.map((e, i) => (
                  <UserView key={i} user={e.assignee} />
                ))}
              </Container>
            )}
          </SimpleColumn>
          <SimpleColumn n={6} s={6} label="Created by">
            {(r) => <UserView user={r.creator_user} />}
          </SimpleColumn>
        </SimpleDataTable>
        <ProjectMapOverview
          visible={!isRowBased}
          style={{
            position: 'sticky',
            top: 0,
            borderRadius: '0',
            height: 'calc(100vh - 60px)',
          }}
        />
      </QueryProvider>
    </div>
  )
}

import { register } from 'system/container'

import { TripsList } from './list'

register({
  render: () => <TripsList />,
  menu: true,
  title: 'Trips',
  path: '/logistics/planning/trips',
})

import { Truck } from 'react-feather'

import { register } from 'system/container'

register({
  icon: <Truck />,
  title: 'Yard',
  // Subsystem base route, no trailing slash
  path: '/yard',
  // mainmenu priority, lower is higher
  priority: 30,
  render: () => 'Welcome to yard index page',
})

import { SystemToolbar } from 'system/toolbar/toolbar'
import { SystemMenu } from 'system/menu/core'
import { useSession } from 'common/session/context'

import { RootTemplate } from '../template'

import styles from './core.module.css'

export const PageTemplate = ({ children }) => {
  const { currentUser } = useSession()
  if (!currentUser) {
    //return '...'
  }
  return (
    <RootTemplate>
      <div className={styles.app}>
        <SystemMenu
          style={{
            display: 'flex',
            gridRow: '1 / 3',
            gridColumn: '1',
          }}
        />
        {/* Toolbar also contains top-level overlays */}
        <SystemToolbar style={{ gridRow: '1', gridColumn: '2 / 3' }} />
        <div className={styles.content}>{children}</div>
      </div>
    </RootTemplate>
  )
}

import { SimpleColumn, SimpleDataTable } from 'common/data-table/simple'
import { formatDistanceFromNow } from 'common/utils/format'

export const RolesListPage = () => {
  return (
    <SimpleDataTable
      name="roles"
      title="Roles"
      url="security/roles"
      navBaseUrl="/security/roles"
      showAddBtn
    >
      <SimpleColumn n={3} sortable label="Name" field="key" header />
      <SimpleColumn n={2} sortable label="System" field="system" />
      <SimpleColumn n={4} sortable label="Description" field="description" />
      <SimpleColumn
        n={3}
        sortable
        label="Last updated"
        field="updated_at"
        value={(r) => formatDistanceFromNow(r.updated_at)}
      />
    </SimpleDataTable>
  )
}

import { useTranslation } from 'react-i18next'

import { useService } from 'common/service/context'
import { Form, useForm } from 'common/widgets/form/context'
import { CardView, Divider, GridFieldView } from 'common/widgets/view'
import { Container } from 'common/widgets/container'
import { Column, Row } from 'common/widgets/grid'
import {
  DateField,
  DropDownField,
  NumericField,
  TextField,
  TextAreaField,
} from 'common/widgets/form/field'
import { BackIconButton } from 'common/widgets/button'
import { DataSource } from 'common/widgets/data-source'
import { SimpleFormAction } from 'common/widgets/form/utils'
import { useQueryParams } from 'common/utils/routing'
import { ProjectSelectField } from 'modules/projects/widgets/select'

import { ProjectPhaseIdSelectField } from '../widgets/type'

export const TaskAddPage = () => {
  const { start, end, project_id, assignee_user_id } = useQueryParams()
  return (
    <TaskView
      start={start}
      end={end}
      projectId={parseInt(project_id)}
      assigneeUserId={parseInt(assignee_user_id)}
    />
  )
}

export const TaskView = ({ task, start, end, projectId, assigneeUserId }) => {
  const { t } = useTranslation()

  return (
    <Form
      data={{
        title: task?.title,
        description: task?.description,
        planned_time: task?.planned_time,
        project_id: task?.project_id ?? projectId,
        phase_id: task?.phase_id,
        assignee_user_id: task?.assignee_user_id ?? assigneeUserId,
        priority: task?.priority,
        start_date: task?.schedule?.start_date ?? start,
        done_date: task?.schedule?.due_date ?? end,
        tag: task?.tags?.join(', '),
      }}
    >
      {!task && (
        <Container flex gap="10px" style={{ alignItems: 'center' }}>
          <BackIconButton />
          <h3>{t('Details')}</h3>
        </Container>
      )}
      <AddTaskForm task={task} />
    </Form>
  )
}

const AddTaskForm = ({ task }) => {
  const service = useService()
  const { values } = useForm()

  const constructRequestBody = () => {
    const request = {
      title: values.json.title,
      description: values.json.description,
      planned_time: values.json.planned_time,
      project_id: values.json.project_id,
      // this is nessesary because if the phase is selected and then a project is deselected we need to pass null for phase_id
      phase_id: values.json.project_id === null ? null : values.json.phase_id,
      assignee_user_id: values.json.assignee_user_id,
      priority: values.json.priority,
      tags:
        values.json.tag
          ?.split(',')
          .map((e) => e.trim())
          .filter((e) => e !== '') ?? [],
    }
    if (values.json.start_date || values.json.end_date) {
      request.schedule = {
        start_date: values.json.start_date,
        due_date: values.json.done_date,
      }
    }

    return request
  }

  const handleSubmit = async (values) => {
    const [result, error] = await service.post(
      `/tasks`,
      constructRequestBody(values)
    )

    return [result, error]
  }

  const handleEdit = async (values) => {
    const requestBody = constructRequestBody(values)
    if (task.schedule_id) {
      requestBody['schedule_id'] = task.schedule_id
    }
    const [result, error] = await service.put(`/tasks/${task.id}`, requestBody)

    return [result, error]
  }

  return (
    <CardView>
      <Row style={{ padding: '10px' }}>
        <Column n={4} m={4} s={12}>
          <TaskDetails />
        </Column>
        <Column n={4} m={4} s={12}>
          <ScheduleDetails />
        </Column>
        <Column n={4} m={4} s={12}>
          <ProjectDetails />
        </Column>
        <Column n={4} m={4} s={12}>
          <AssigneeDetails />
        </Column>
        <Column n={4} m={4} s={12}>
          <TaskTags />
        </Column>
      </Row>
      <Divider />
      <SimpleFormAction
        onAdd={!task && handleSubmit}
        onUpdate={task && handleEdit}
      />
    </CardView>
  )
}

const TaskDetails = () => {
  const { t } = useTranslation()

  return (
    <Container>
      <h3>{t('Task')}</h3>
      <Row>
        <GridFieldView n={12} m={12} s={12} label="Task title">
          <TextField name="title" mandatory />
        </GridFieldView>
        <GridFieldView n={12} m={12} s={12} label="Task description">
          <TextAreaField name="description" rows={4} />
        </GridFieldView>
      </Row>
    </Container>
  )
}

const ScheduleDetails = () => {
  const { t } = useTranslation()
  const { values } = useForm()

  return (
    <Container>
      <h3>{t('Schedule')}</h3>
      <Row>
        <GridFieldView n={6} m={6} s={12} label="Start date">
          <DateField name="start_date" excludeTime />
        </GridFieldView>
        <GridFieldView n={6} m={6} s={12} label="End date">
          <DateField
            name="done_date"
            inclusive
            excludeTime
            validate={(date) => {
              if (values.json.start_date > date) {
                return t('The date is before the scheduled date')
              }
            }}
          />
        </GridFieldView>
        <GridFieldView n={12} m={12} s={12} label="Planned hours">
          <NumericField name="planned_time" nullable={false} />
        </GridFieldView>
      </Row>
    </Container>
  )
}

const ProjectDetails = () => {
  const { t } = useTranslation()
  const { values } = useForm()

  return (
    <DataSource
      url={`projects?archived=false`}
      render={({ data }) => (
        <Container>
          <h3>{t('Project')}</h3>
          <Row>
            <GridFieldView n={12} m={12} s={12}>
              <ProjectSelectField
                removable
                name="project_id"
                label="Project"
                onSelectChange={(e) => {
                  if (e) {
                    values.set('phase_id', null)
                  }
                }}
              />
            </GridFieldView>
            <GridFieldView n={12} m={12} s={12}>
              {values?.json.project_id && (
                <ProjectPhaseIdSelectField
                  name="phase_id"
                  label="Project phase"
                  removable
                  projectTypeId={
                    data?.find((e) => values?.json?.project_id === e?.id)?.type
                      ?.id
                  }
                />
              )}
            </GridFieldView>
          </Row>
        </Container>
      )}
    />
  )
}

const AssigneeDetails = () => {
  const { t } = useTranslation()
  const priorities = [
    {
      key: 'Low',
      title: 'Low',
    },
    {
      key: 'Medium',
      title: 'Medium',
    },
    {
      key: 'High',
      title: 'High',
    },
  ]

  return (
    <Container>
      <h3>{t('Assign to')}</h3>
      <Row>
        <GridFieldView n={12} m={12} s={12} label="Employee">
          <DataSource
            url={`/contacts/internal`}
            render={({ data }) => (
              <DropDownField
                removable
                title={t('Employee')}
                name="assignee_user_id"
                items={data?.map((e) => ({
                  key: e.id,
                  title: e.fullname,
                }))}
              />
            )}
          />
        </GridFieldView>
        <GridFieldView n={12} m={12} s={12} label="Priority">
          <Container>
            <DropDownField
              title={t('Priority')}
              removable
              name="priority"
              items={priorities}
            />
          </Container>
        </GridFieldView>
      </Row>
    </Container>
  )
}

const TaskTags = () => {
  const { t } = useTranslation()

  return (
    <Container>
      <h3>{t('Tags')}</h3>
      <Row>
        <GridFieldView n={12} m={12} s={12} label="Add tags separated by comma">
          <TextField name="tag" rows={1} />
        </GridFieldView>
      </Row>
    </Container>
  )
}

import { useState } from 'react'

import { DataTable } from 'common/data-table/core'
import { useQuery } from 'common/query/context'
import { useService } from 'common/service/context'
import { formatDistanceFromNow } from 'common/utils/format'
import {
  FoldButtons,
  ArchiveIconButton,
  SendIconButton,
  FavoriteIconButton,
  MarkAsReadIconButton,
} from 'common/widgets/button'
import { CheckBox } from 'common/widgets/checkbox'
import { Container } from 'common/widgets/container'
import { Tag } from 'common/widgets/tag'

export const NotificationList = ({ name, open, onClose }) => {
  const service = useService()

  return (
    <DataTable
      url="/notification"
      name="notification"
      title="Notifications"
      sortables={[
        {
          key: 'send_date',
          title: 'Send date',
        },
      ]}
      filters={[
        {
          key: 'module',
          section: 'Modules',
          fetch: (params) => service.get('notifications/modules'),
        },
        {
          key: 'sender_user_id',
          section: 'Sender',
          title: (r) => r.fullname || 'System',
          value: (r) => r.id,
          fetch: (params) => service.get('notifications/senders'),
        },
        {
          key: 'important',
          section: 'Favorite',
          boolean: true,
        },
        {
          key: 'read',
          section: 'Read',
          boolean: true,
        },
        {
          key: 'archived',
          section: 'Archived',
          boolean: true,
        },
      ]}
    >
      <NotificationRecords />
    </DataTable>
  )
}

export const NotificationRecords = ({ isArchivedView = false }) => {
  const query = useQuery()
  const service = useService()
  const [checkedNotificationIds, setCheckedNotificationIds] = useState([])

  const flag = query.parameters.get('archived')
  if (flag && flag.length == 1) {
    isArchivedView = JSON.parse(flag[0])
  }

  /**
   * Mark many notifications as read based on the checked notifications.
   */
  const readMany = async () => {
    await service.post(`notifications/read/many`, {
      notification_ids: checkedNotificationIds,
    })
    query.reload()
  }

  /**
   * Archive many notifications based on the checked notificaftions.
   */
  const archiveMany = async () => {
    await service.post(`notifications/archive/many`, {
      notification_ids: checkedNotificationIds,
    })
    setCheckedNotificationIds([])
    query.reload()
  }

  /**
   * Unarchive many notifications based on the checked notificaftions.
   */
  const unarchiveMany = async () => {
    await service.post(`notifications/unarchive/many`, {
      notification_ids: checkedNotificationIds,
    })
    setCheckedNotificationIds([])
    query.reload()
  }

  /**
   * Updates the id list of checked notifications.
   *
   * @param {int} id notification id
   * @param {boolean} check boolean for check or uncheck
   */
  const toggleCheckSingle = (id, check) => {
    const updatedIds = check
      ? Array.from(new Set([...checkedNotificationIds, id])) // add id and remove duplicates from list
      : checkedNotificationIds.filter((e) => e !== id) // remove id from list
    setCheckedNotificationIds(updatedIds)
  }

  /**
   * Toggles check for all records
   *
   * @param {boolean} check boolean for check and uncheck
   */
  const toggleCheckAll = (check) => {
    if (check) {
      // check all
      setCheckedNotificationIds(query.records?.map((e) => e.id))
    } else {
      // uncheck all
      setCheckedNotificationIds([])
    }
  }

  /**
   *
   * @param {int} id notification id
   * @returns boolean. If notification is checked or not.
   */
  const isChecked = (id) => checkedNotificationIds.includes(id)

  return (
    <div>
      {checkedNotificationIds.length > 0 && (
        <Container flex repel nopadding>
          <Container flex gap="5px">
            <MarkAsReadIconButton onClick={readMany} />
            <ArchiveIconButton
              onClick={isArchivedView ? unarchiveMany : archiveMany}
              archived={isArchivedView}
            />
          </Container>
          <CheckBox
            value={checkedNotificationIds.length === query.records?.length}
            onChange={toggleCheckAll}
          />
        </Container>
      )}
      {query.records?.map((e, idx) => (
        <Container flex key={idx} divider>
          <Container flex vertical grow>
            <p>{e.title}</p>
            <Container flex gap="5px">
              <Tag color="#303030" text={formatDistanceFromNow(e.send_date)} />
              <Tag color="#303030" text={e.sender_user?.fullname || 'System'} />
              <Tag color="#303030" text={e.module} />
            </Container>
          </Container>
          <Container flex vertical shrink align="start">
            <Actions
              message={e}
              reload={query.reload}
              isArchivedView={isArchivedView}
            >
              <CheckBox
                onChange={(check) => toggleCheckSingle(e.id, check)}
                value={isChecked(e.id)}
              />
            </Actions>
          </Container>
        </Container>
      ))}
    </div>
  )
}

const Actions = ({ message, reload, isArchivedView, children }) => {
  // Accesses service context
  const service = useService()

  /**
   * Calls a put call, updates a notification and reloads records.
   * @param {int} id notification id
   * @param {object} data payload
   */
  const updateNotification = async (id, data) => {
    await service.post(`notifications/${id}`, data)
    reload()
  }

  /**
   * Archive single notification.
   *
   * @param {*} id notification id
   */
  const archiveSingle = async (id) => {
    await service.delete(`notifications/${id}`)
    reload()
  }

  /**
   * Unarchive single notification.
   *
   * @param {*} id notification id
   */
  const unarchiveSingle = async (id) => {
    await service.post(`notifications/${id}`, { archived_at: null })
    reload()
  }

  return (
    <FoldButtons noborder>
      <a className="mailto" href={`mailto: ?body=${message.title || ''}`}>
        <SendIconButton background="#ffffff" />
      </a>
      <FavoriteIconButton
        starred={message.important}
        onClick={() =>
          updateNotification(message.id, { important: !message.important })
        }
      />
      <ArchiveIconButton
        onClick={() => {
          message?.archived_at
            ? unarchiveSingle(message.id)
            : archiveSingle(message.id)
          reload()
        }}
        background="#ffffff"
        archived={message?.archived_at}
      />
      {children}
    </FoldButtons>
  )
}

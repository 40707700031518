import { register } from 'system/container'

const PimOverview = () => <p>TODO</p>

register({
  menu: true,
  render: () => <PimOverview />,
  title: 'Product Information Management',
  path: '/master-data/pim',
})

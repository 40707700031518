import { useTranslation } from 'react-i18next'

import { SimpleColumn, SimpleDataTable } from 'common/data-table/simple'
import { formatDistanceFromNow } from 'common/utils/format'
import { Avatar } from 'common/widgets/avatar'

export const CategoryList = ({ path }) => {
  const { t } = useTranslation()

  return (
    <SimpleDataTable
      name="categories"
      url="/pim/categories"
      title="Categories"
      showAddBtn
      navBaseUrl="/master-data/pim/categories"
      archive
    >
      <SimpleColumn fixed>
        {(r) => <Avatar text={r.name} id={r.photo_id} />}
      </SimpleColumn>
      <SimpleColumn n={12} sortable field="name" header />
      <SimpleColumn
        n={3}
        s={6}
        label="Creation date"
        field="created_at"
        sortable
        value={(r) => formatDistanceFromNow(r.created_at)}
      />
      <SimpleColumn
        n={3}
        s={6}
        sortable
        field="creator_user"
        label="Creator user"
        value={(r) => r.creator_user?.fullname}
      />
      <SimpleColumn
        n={3}
        s={6}
        label="Parent group"
        field="parent_id"
        sortable
        value={(r) => r.parent?.name}
        link={(r) =>
          r.parent ? `/master-data/pim/categories/${r.parent.id}` : null
        }
      />
      <SimpleColumn
        n={3}
        s={6}
        label="Subgroups"
        value={(r) =>
          r.child_count
            ? `${r.child_count} ${t('subgroup', { count: r.child_count })}`
            : '-'
        }
        link={(r) => `/master-data/pim/categories?parent_id=${r.id}`}
      />
    </SimpleDataTable>
  )
}

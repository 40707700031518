import { useParams } from 'react-router-dom'

import { Form } from 'common/widgets/form/context'
import { useService } from 'common/service/context'
import { DataSource } from 'common/widgets/data-source'

import { ContactPresentFormContent } from './form'

/**
 * Renders contact present edit page.
 *
 * @returns ReactElement
 */
export const ContactPresentEditPage = () => {
  const { presentId } = useParams()
  const service = useService()

  /**
   * Calls a put call and saves information.
   *
   * @param {any} values contact present data.
   * @returns
   */
  const handleSave = async (values) => {
    return await service.put(`contacts/presents/${presentId}`, values)
  }

  /**
   * Makes a delete call and removes the present
   *
   * @returns
   */
  const handleRemove = async () =>
    await service.delete(`/contacts/presents/${presentId}`)

  /**
   * Gets information of tag using it's id.
   *
   * @returns any
   */
  const fetch = async () => await service.get(`/contacts/presents/${presentId}`)

  return (
    <DataSource
      fetch={fetch}
      render={({ data, error, loading }) => (
        <>
          {data && (
            <Form data={data}>
              <ContactPresentFormContent
                onUpdate={handleSave}
                onRemove={handleRemove}
              />
            </Form>
          )}
        </>
      )}
    />
  )
}

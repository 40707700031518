import { useTranslation } from 'react-i18next'

import { DataSource } from 'common/widgets/data-source'
import { DropDown } from 'common/widgets/dropdown'
import { FieldWrapper } from 'common/widgets/form/field'

export const CostCenterSelectField = ({
  onSelectChange,
  removable,
  name,
  ...rest
}) => {
  return (
    <FieldWrapper name={name} {...rest}>
      {(getValue, setValue, values) => (
        <CostCenterSelect
          removable
          selectedCostCenterId={getValue()}
          onSelectChange={(costCenter) => {
            if (costCenter) {
              setValue(costCenter.id)
            } else {
              setValue(null)
            }
            if (onSelectChange) {
              onSelectChange(costCenter)
            }
          }}
          {...rest}
        />
      )}
    </FieldWrapper>
  )
}

export const CostCenterSelect = ({
  handler,
  onSelectChange,
  hint,
  disabled,
  removable,
  selectedCostCenterId,
}) => {
  const { t } = useTranslation()

  const render = ({ data }) => {
    var items =
      data?.map((costCenter) => ({
        key: costCenter.id,
        title: costCenter.code,
        data: costCenter,
      })) || []

    const sIndex =
      selectedCostCenterId &&
      items.findIndex((p) => p.key == selectedCostCenterId)

    return (
      <DropDown
        minWidth="320px"
        maxWidth="320px"
        title={t(hint) || t('Cost center')}
        items={items}
        selectedIndex={sIndex}
        disabled={disabled}
        removable={removable}
        onSelectChange={(e) => {
          if (onSelectChange) {
            onSelectChange(e?.data)
          }
        }}
        searchFilter={(option, searchTerm) => {
          const st = searchTerm.toLowerCase()
          return (
            option?.title.toLowerCase().includes(st) ||
            option?.data?.name.toLowerCase().includes(st) ||
            option?.data?.code.toLowerCase().includes(st)
          )
        }}
      />
    )
  }

  return (
    <DataSource
      url="/accounting/cost-centers"
      handler={handler}
      render={render}
    />
  )
}

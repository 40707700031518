import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'

import { useSession } from 'common/session/context'
import { useService } from 'common/service/context'
import { SubmitButton } from 'common/widgets/button'
import { Container } from 'common/widgets/container'
import { Form } from 'common/widgets/form/context'
import { EmailField, PasswordField, URLField } from 'common/widgets/form/field'

import { NoSessionTemplate } from './template'
import { AuthFormHeader } from './widgets'

export const LoginPage = () => {
  const session = useSession()
  const { offline, apiUrl } = useService()
  const { t } = useTranslation(['security'])
  const navigate = useNavigate()
  const location = useLocation()

  const from = location.state?.from?.pathname || '/'

  const handleSubmit = async (data) => {
    await session.login({
      email: data['email'],
      password: data['password'],
      apiUrl: data?.api,
      next: from,
    })
  }

  return (
    <NoSessionTemplate>
      <Form onSubmit={handleSubmit}>
        <Container
          style={{ display: 'grid', gridAutoRows: 'max-content' }}
          vertical
          maxWidth="420px"
          gap="10px"
        >
          <AuthFormHeader />
          <p>
            {from !== '/'
              ? t('Please login to access {{from}}.', { from })
              : t('Please login to your account.')}
          </p>
          <EmailField name="email" label="E-Mail" mandatory />
          <PasswordField name="password" label="Password" mandatory />
          {offline && (
            <URLField label="API Endpoint" name="api" placeholder={apiUrl} />
          )}
          <a
            style={{ color: '#8b8b8b' }}
            href="security/users/password/new"
            onClick={(e) => {
              // In case this doesn't work, href would work. Moreover, user
              // can treat this as a normal link (which it really is).
              e.preventDefault()
              navigate('/users/password/new')
            }}
          >
            {t('Forgot your password?')}
          </a>
          <SubmitButton disabled={(ctx) => !ctx.ready} text="Sign in" />
        </Container>
      </Form>
    </NoSessionTemplate>
  )
}

import { useTranslation } from 'react-i18next'

import { DetailViewPage } from 'system/utils/view'
import { GridFieldView, SectionView } from 'common/widgets/view'
import { Row } from 'common/widgets/grid'
import { formatDate } from 'common/utils/format'

export const CostCenterViewPage = () => {
  const { t } = useTranslation()
  return (
    <DetailViewPage
      archivable
      removable
      editable
      toggleArchiveURL="toggle-archive"
      url="/accounting/cost-centers"
      navUrl="/master-data/cost-centers"
      title={(e) => e.name}
    >
      {(data, reload) => (
        <SectionView>
          <Row>
            <GridFieldView n={12}>
              <h2>{t('Details')}</h2>
            </GridFieldView>
            <GridFieldView n={6} s={12} label="Code" value={data.code} />
            <GridFieldView n={6} s={12} label="Name" value={data.name} />
            <GridFieldView
              n={6}
              s={12}
              label="Created at"
              value={formatDate(data.created_at)}
            />
            <GridFieldView
              n={6}
              s={12}
              label="Created by"
              value={data.creator_user.fullname}
            />
          </Row>
        </SectionView>
      )}
    </DetailViewPage>
  )
}

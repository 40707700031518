import { Form } from 'common/widgets/form/context'
import { useService } from 'common/service/context'

import { ContactFormContent } from './form'

export const ContactAddPage = () => {
  const service = useService()

  return (
    <Form>
      <ContactFormContent
        onAdd={async (values) => await service.post('contacts', values)}
      />
    </Form>
  )
}

import { useContext, createContext, useEffect, useState } from 'react'

import { useService } from 'common/service/context'

const SettingsContext = createContext()

export const SettingsProvider = ({ children }) => {
  const service = useService()
  const [settings, setSettings] = useState(null)
  const [apiError, setApiError] = useState(null)
  const [dataURLs, setDataURLs] = useState({})

  const reload = async () => {
    const [response, error] = await service.get('/settings?as_dataurl=y')
    if (error) {
      // Reuse old settings if available
      const oldSettings = localStorage.getItem('settings')
      if (oldSettings) {
        setSettings(settings)
      } else {
        setSettings([])
        const msg = 'Can not load settings. Probably the API is unreachable.'
        setApiError(`${msg}\n\n${error}\n\n${error?.response?.data}`)
      }
    } else {
      const s = {}
      response.data.forEach((e) => {
        s[e.key] = e
      })
      try {
        localStorage.setItem('settings', JSON.stringify(s))
      } catch (error) {
        // Writing to localStorage might raise quota exceptions
        // in that case we remove the key (might contain files)
        localStorage.removeItem('settings')
      }
      setSettings(s)
    }
  }

  // Run once to load settings
  useEffect(() => {
    if (settings === null) {
      reload()
    }
  }, [])

  const set = async (key, value) => {
    const [result, error] = await service.post(`settings/${key}`, { value })
    if (!error) {
      await reload()
    }
    return [result, error]
  }

  const get = (key, fetchDataURL) => {
    const config = settings[key]
    if (
      fetchDataURL &&
      // We have to compensate for the lack of corrct file and mimetype
      // information on the server side, FILE with a mimetype would be
      // correct.
      (config?.type === 'FILE' || config?.type === 'IMAGE') &&
      !(key in dataURLs)
    ) {
      if (config?.value) {
        // Fetch data URL for this entry
        reloadDataURL(key, config?.value)
      }
    }
    return fetchDataURL ? config?.dataURL : config?.value
  }

  const reloadDataURL = async (key, fileId) => {
    const [response, error] = await service.get(`/settings/${key}/dataurl`)
    if (!error) {
      setDataURLs((prev) => {
        return { ...prev, [key]: response?.data }
      })
      setSettings((prev) => {
        prev[key] = { ...prev[key], dataURL: response?.data }
        return prev
      })
    }
  }

  const update = async (values) => {
    const [result, error] = await service.patch(`settings/`, values)
    if (!error) {
      await reload()
    }

    return [result, error]
  }

  return (
    <SettingsContext.Provider
      value={{
        settings,
        set,
        get,
        update,
      }}
    >
      {(settings !== null && children) || <pre>{apiError}</pre>}
    </SettingsContext.Provider>
  )
}

export const useSettings = () => useContext(SettingsContext)

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { dateToISOString } from 'common/utils/format'
import { Form, useForm } from 'common/widgets/form/context'
import { Column, Row } from 'common/widgets/grid'
import { SectionView } from 'common/widgets/view'
import { ItemTypeEnum } from 'modules/master-data/equipments/utils'
import { formatProjectName } from 'modules/projects/utils'
import { DetailViewPage } from 'system/utils/view'
import { useService } from 'common/service/context'
import { ConfirmButton, CancelButton } from 'common/widgets/button'
import { ConfirmOverlay } from 'common/widgets/overlay'
import { formatOrderNumber } from 'modules/orders/utils'
import {
  PendingRequestData,
  PendingRequestOverview,
} from 'modules/disposition/requests/new/data'
import { EquipmentConditionField } from 'modules/master-data/equipments/formworks/widgets/condition'
import { SourceOfSupplyField } from 'modules/master-data/equipments/formworks/widgets/source-of-supply'
import { DropDownField } from 'common/widgets/form/field'
import { addDays, startOfWeek } from 'common/utils/date'
import { AuditedRequestOverlay } from 'modules/disposition/requests/utils'
import { Container } from 'common/widgets/container'

import { BookingFormworkTimeline, BookingConfirmLegends } from './calendar'
import { AddSourceOverlay } from './add'
import { BookOverlay } from './book'
import { UpdateSourceOverlay } from './update'

const BookingData = ({ request }) => {
  const { t } = useTranslation()
  const { values } = useForm()
  // Extracts values out of form
  const {
    maincategory,
    category,
    subcategory,
    condition,
    transform,
    baseitem_id,
  } = values.json
  const navigate = useNavigate()
  const service = useService()
  const [dates, setDates] = useState({
    start: dateToISOString(startOfWeek(request.booking_start)),
    end: dateToISOString(addDays(startOfWeek(request.booking_start), 28)),
  })
  const [data, setData] = useState({
    formworks: [],
    bookings: [],
    sources: [],
  })
  const [slot, setSlot] = useState({
    source: null,
    start: null,
    end: null,
    id: null,
  })
  const [selectedSources, setSelectedSources] = useState([])
  const [showBookOverlay, setShowBookOverlay] = useState(false)
  const [rejectOverlayVisible, showRejectOverlay] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)

  /**
   * Fetches resources and related bookings
   * @returns Array
   */
  const reload = async () => {
    /**
     * A small wrapper on top of fetch to make many calls at once.
     * @param {string} key call key
     * @param {string} url api url
     * @param {Array} params api params
     * @returns
     */
    const fetch = async (key, url, params) => {
      const [result, error] = await service.get(url, params)
      return [key, result, error]
    }
    // Pair of key and value to pass as params to api.
    const params = [
      ['transform', transform],
      ['condition', condition],
      ['type', ItemTypeEnum.FORMWORK],
      ['archived', false],
      ['maincategory', maincategory ?? null],
      ['category', category ?? null],
      ['subcategory', subcategory ?? null],
    ]
      .filter((e) => e[1] !== null)
      .map((e) => ({ [e[0]]: e[1] }))

    // Runs request in parallel to fetch all required results
    const results = await Promise.all([
      fetch('formworks', `/items/formworks`, params),
      fetch(
        'bookings',
        `/disposition/bookings?booking_start[lte]=${encodeURIComponent(
          dates.booking_end
        )}&booking_end[gte]=${encodeURIComponent(dates.booking_start)}`,
        params
      ),
      fetch(
        'sources',
        `/disposition/bookings/sources?booking_start=${encodeURIComponent(
          dates.booking_start
        )}&booking_end=${encodeURIComponent(dates.booking_end)}`,
        params
      ),
    ])

    // Inflates collected data in a different form to use to populate UI.
    setData(
      Object.fromEntries(
        results.map((e) => {
          const [key, result, error] = e
          const data = error ? [] : result.data
          return [key, data]
        })
      )
    )
  }

  /**
   * Cancels order item and booking request.
   * @param {any} request booking request data
   * @returns
   */
  const cancelOrderedItem = async (request) => {
    const [result, error] = await service.put(
      `/orders/items/${request.ordered_item.id}/cancel`
    )
    if (!error) {
      navigate('/disposition/requests/new')
    }
    return [result, error]
  }

  // Registers an effect so when the dates or category changes it reloads data
  useEffect(() => {
    reload()
  }, [dates, maincategory, category, subcategory, condition, transform])

  // On react 18, setting the state with initial value on state definition does not
  // result in a rerender, so we have to bind to `baseitem_id` change event to render
  // resources for that item on page load.
  useEffect(() => {
    setSelectedItem(baseitem_id)
  }, [baseitem_id])

  return (
    <Row>
      <Column flex n={6}>
        <PendingRequestData request={request} />
      </Column>
      <Column flex n={6}>
        <PendingRequestOverview request={request} />
      </Column>
      <Column flex n={12}>
        <SectionView>
          <Row>
            <Column n={6}>
              <h5>{t('Condition')}</h5>
              <EquipmentConditionField mandatory={false} toggle />
            </Column>
            <Column n={6}>
              <h5>{t('Supplier')}</h5>
              <SourceOfSupplyField mandatory={false} toggle />
            </Column>
            <Column n={12}>
              <h5>{t('Name')}</h5>
              <DropDownField
                title="bezeic"
                name="baseitem_id"
                onSelectChange={(key) => {
                  setSelectedItem(key)
                }}
                items={data.formworks
                  .filter((e) => e.is_orderable)
                  .map((e) => ({
                    key: e.id,
                    title: e.name,
                  }))}
              />
            </Column>
          </Row>
        </SectionView>
      </Column>
      <Column flex n={12}>
        <SectionView>
          <BookingFormworkTimeline
            request={request}
            selectedItem={selectedItem}
            sources={data.sources}
            bookings={data.bookings}
            formworks={data.formworks}
            selectedSources={selectedSources}
            onAdd={(source, start, end) =>
              setSlot({ source, start, end, id: null })
            }
            onUpdate={(source, id) =>
              setSlot({ source, start: null, end: null, id })
            }
            onMove={({ id, start, end, project, baseitem }) => {
              const source = selectedSources.find((e) => e.id === id)
              setSelectedSources([
                ...selectedSources.filter((e) => e.id !== id),
                {
                  ...source,
                  id,
                  start,
                  end,
                  source_project: project,
                  baseitem,
                },
              ])
            }}
            onCalendarDateChange={(start, end) => setDates({ start, end })}
          />
          <BookingConfirmLegends />
        </SectionView>
      </Column>
      <Column flex n={12} gapByMargin="10px">
        <Container flex gap="5px">
          <ConfirmButton
            disabled={selectedSources.length === 0}
            onClick={() => setShowBookOverlay(true)}
          />
          <CancelButton
            text="Reject"
            danger
            onClick={() => showRejectOverlay(true)}
          />
        </Container>
      </Column>
      {slot.source && !slot.id && (
        <AddSourceOverlay
          request={request}
          selectedSources={selectedSources}
          onClose={() =>
            setSlot({ source: null, start: null, end: null, id: null })
          }
          onAdd={(source) => {
            source.id = selectedSources.length + 1
            setSelectedSources([...selectedSources, source])
            setSlot({ source: null, start: null, end: null, id: null })
          }}
          {...slot}
        />
      )}
      {slot.source && slot.id && (
        <UpdateSourceOverlay
          request={request}
          selectedSources={selectedSources}
          onClose={() =>
            setSlot({ source: null, start: null, end: null, id: null })
          }
          onUpdate={(id, source) => {
            setSelectedSources([
              ...selectedSources.filter((e) => e.id !== id),
              { ...source, id },
            ])
            setSlot({ source: null, start: null, end: null, id: null })
          }}
          onRemove={(id) => {
            setSelectedSources([...selectedSources.filter((e) => e.id !== id)])
            setSlot({ source: null, start: null, end: null, id: null })
          }}
          {...slot}
        />
      )}
      {showBookOverlay && (
        <BookOverlay
          open={showBookOverlay}
          selectedSources={selectedSources}
          formworks={data.formworks}
          request={request}
          onClose={() => setShowBookOverlay(false)}
        />
      )}
      {rejectOverlayVisible && (
        <ConfirmOverlay
          open={rejectOverlayVisible}
          onReject={() => showRejectOverlay(false)}
          onConfirm={async () => await cancelOrderedItem(request)}
          title={t('Remove')}
        >
          <p>
            {t(
              'Ordered item for order {{order}} and booking request for project {{project}} will be rejected, are you sure?',
              {
                project: `<b>${formatProjectName(request.project)}</b>`,
                order: `<b>${formatOrderNumber(
                  request.ordered_item.order?.number
                )}</b>`,
              }
            )}
          </p>
        </ConfirmOverlay>
      )}
    </Row>
  )
}

export const FormworkPendingRequestConfirm = () => {
  return (
    <DetailViewPage url="/disposition/requests" title="Booking">
      {(data) => (
        <>
          {data && (
            <Form
              data={{
                maincategory: data.ordered_item.item.maincategory,
                category: data.ordered_item.item.category,
                subcategory: data.ordered_item.item.subcategory,
                condition: data.ordered_item.item.condition,
                transform: data.ordered_item.item.transform,
                baseitem_id: data.ordered_item.item.id,
              }}
            >
              <BookingData request={data} />
            </Form>
          )}
          <AuditedRequestOverlay request={data} />
        </>
      )}
    </DetailViewPage>
  )
}

import { useTranslation } from 'react-i18next'
import { Star } from 'react-feather'
import { useState } from 'react'

import { Container } from 'common/widgets/container'
import { SimpleFormAction } from 'common/widgets/form/utils'
import { OverlayBody, OverlayFooter, OverlayForm } from 'common/widgets/overlay'
import { FieldWrapper, TextAreaField } from 'common/widgets/form/field'
import { useService } from 'common/service/context'
import { SimpleColumn, SimpleDataTable } from 'common/data-table/simple'
import { formatDate } from 'common/utils/format'
import { formatUserName } from 'modules/security/users/utils'
import { DeleteIconButton } from 'common/widgets/button'

import styles from './view.module.css'

const ContactRatingOverlay = ({ contact, open, onClose, reload }) => {
  const { t } = useTranslation(['contacts'])
  const service = useService()

  const handleAdd = async (values) => {
    const [result, error] = await service.post('contacts/rates', {
      ...values,
      contact_id: contact.id,
    })
    if (!error) {
      onClose && onClose()
      reload && reload()
    }
    return [result, error]
  }

  return (
    <OverlayForm
      title={t('Add a rating')}
      open={open}
      onClose={onClose}
      data={{ rate: 0, comment: '' }}
    >
      <OverlayBody>
        <Container flex vertical grow>
          <RateField name="rate" label="Rating" mandatory />
          <TextAreaField name="comment" label="Comment" rows={3} />
        </Container>
      </OverlayBody>
      <OverlayFooter>
        <SimpleFormAction onAdd={handleAdd} onClose={onClose} />
      </OverlayFooter>
    </OverlayForm>
  )
}

export const RateLabel = ({ rate }) => {
  const value = Math.max(0, Math.min(5.0, rate ? rate : 0.0))
  const width = (value / 5.0) * 100

  return (
    <Container width="100px" height="20px">
      <div className={styles.rateLabelBack}>
        <StyledStar on="false" />
        <StyledStar on="false" />
        <StyledStar on="false" />
        <StyledStar on="false" />
        <StyledStar on="false" />
      </div>
      <div className={styles.rateLabelFront} style={{ width: width }}>
        <StyledStar on="true" style={{ position: 'absolute', marginLeft: 0 }} />
        <StyledStar
          on="true"
          style={{ position: 'absolute', marginLeft: '20px' }}
        />
        <StyledStar
          on="true"
          style={{ position: 'absolute', marginLeft: '40px' }}
        />
        <StyledStar
          on="true"
          style={{ position: 'absolute', marginLeft: '60px' }}
        />
        <StyledStar
          on="true"
          style={{ position: 'absolute', marginLeft: '80px' }}
        />
      </div>
    </Container>
  )
}

export const ContactRatesList = ({ contact, reload }) => {
  const service = useService()
  const [ratingOverlayVisiblity, setRatingOverlayVisiblity] = useState(false)
  const handler = {}

  const handleRemove = async (id) => {
    await service.delete(`/contacts/rates/${id}`)
    handler.reload()
  }

  const fetch = async (params) =>
    await service.get('contacts/rates', [
      ...params,
      { contact_id: parseInt(contact.id) },
    ])

  return (
    <SimpleDataTable
      name="rates"
      nopadding
      nomargin
      handler={handler}
      title="Rates"
      fetch={fetch}
      onAddClick={() => setRatingOverlayVisiblity(true)}
    >
      {ratingOverlayVisiblity && (
        <ContactRatingOverlay
          before
          contact={contact}
          onClose={() => setRatingOverlayVisiblity(false)}
          open={ratingOverlayVisiblity}
          reload={() => reload() && handler.reload()}
        />
      )}
      <SimpleColumn sortable n={12} s={12}>
        {(r) => <h3>{r.comment}</h3>}
      </SimpleColumn>
      <SimpleColumn s={6} n={4} sortable field="rate" label="Rate">
        {(r) => <RateLabel rate={r.rate} />}
      </SimpleColumn>
      <SimpleColumn
        s={6}
        n={4}
        sortable
        field="created_at"
        label="Created at"
        value={(r) => formatDate(r.created_at)}
      />
      <SimpleColumn
        s={12}
        n={4}
        sortable
        field="creator_user_id"
        label="Users"
        value={(r) => formatUserName(r.creator_user)}
      />
      <SimpleColumn fixed>
        {(r) => (
          <DeleteIconButton
            danger
            onClick={async () => await handleRemove(r.id)}
          />
        )}
      </SimpleColumn>
    </SimpleDataTable>
  )
}

export const RateInput = ({ value, disabled, onChange }) => (
  <Container height="20px" width="100px" flex>
    <RatingStar
      number={1}
      disabled={disabled}
      onChange={onChange}
      value={value}
    />
    <RatingStar
      number={2}
      disabled={disabled}
      onChange={onChange}
      value={value}
    />
    <RatingStar
      number={3}
      disabled={disabled}
      onChange={onChange}
      value={value}
    />
    <RatingStar
      number={4}
      disabled={disabled}
      onChange={onChange}
      value={value}
    />
    <RatingStar
      number={5}
      disabled={disabled}
      onChange={onChange}
      value={value}
    />
  </Container>
)

const RateField = ({ ...rest }) => (
  <FieldWrapper {...rest}>
    {(getValue, setValue) => (
      <RateInput value={getValue()} onChange={setValue} {...rest} />
    )}
  </FieldWrapper>
)

const RatingStar = ({ number, disabled, value, onChange }) => (
  <StyledStar
    onMouseOver={() => !disabled && onChange(number)}
    onClick={() => !disabled && onChange(number)}
    on={value && value > number - 1 ? 'true' : 'false'}
  />
)

const StyledStar = ({ on }) => {
  return (
    <Star
      className={styles.star}
      style={{
        color: on === 'true' ? '#f49537' : '#e0e0e0',
        fill: on === 'true' ? '#f49537' : '#e0e0e0',
      }}
    />
  )
}

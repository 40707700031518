import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import {
  AddIconButton,
  ExportIconButton,
  FilterIconButton,
  ArchiveIconButton,
  FoldButtons,
} from 'common/widgets/button'
import { SearchInput } from 'common/widgets/search'
import { useQuery } from 'common/query/context'
import { ExportOverlay } from 'common/data-table/export/overlay'

/**
 *
 * @param {string} title datatable title
 * @param {function} onAddClick add button click handler
 * @param {function} onFilterClick filter button click handler
 * @param {Array} sortables definition of sortable fields
 * @param {Array} filters definition of filters
 * @param {bool} archive flag to specify whether data table handles archived records
 * @param {ReactElement} extraIconButtons extra widget to add to header
 * @param {Boolean} exportable specifies that this datatable is exportable to csv
 * @returns
 */
export const DataTableHeader = ({
  title,
  navBaseUrl,
  showAddBtn,
  onAddClick,
  addTooltip = 'Create',
  onFilterClick,
  showFilterBtn,
  archive,
  extraIconButtons,
  white,
  disabled,
  exportable = true,
}) => {
  const { t } = useTranslation()
  const query = useQuery()
  const stream = query.stream()
  const navigate = useNavigate()
  const extraWidgetIsAvailable =
    extraIconButtons !== null && extraIconButtons !== undefined
  const [visibleExportOverlay, setVisibleExportOverlay] = useState(false)
  // Gets search value from query, when user navigates to another
  // page and comes back, query get loaded with last filters.
  const searchValue = query.parameters.get('search') ?? ''

  const archived = query.parameters.get('archived') == 'true'

  return (
    <div
      style={{
        display: 'flex',
        gap: '5px',
        alignItems: 'center',
        marginBottom: '5px',
        justifyContent: 'flex-end',
      }}
    >
      {title && (
        <h2 style={{ flexGrow: 1 }}>
          {t(title)} ({stream.count()})
        </h2>
      )}
      <SearchInput
        onChange={(value) => query.parameters.set('search', value)}
        loading={query.loading}
        disabled={disabled}
        defaultValue={searchValue}
        style={{ width: '125px' }}
      />
      <FoldButtons>
        {archive && (
          <ArchiveIconButton
            tooltip={!archived ? t('Show archived') : t('Show active')}
            onClick={() => {
              const flag = archived
              query.parameters.set('archived', !flag)
            }}
            value={archived}
          />
        )}
        {showFilterBtn && (
          <FilterIconButton
            onClick={onFilterClick}
            tooltip="Filters"
            badge={query.parameters.size}
          />
        )}
        <ExportIconButton
          tooltip="Exporting"
          disabled={!(exportable && stream?.count() > 0)}
          onClick={() => stream?.count() > 0 && setVisibleExportOverlay(true)}
        />
        {onAddClick && (
          <AddIconButton tooltip={addTooltip} onClick={onAddClick} />
        )}
        {showAddBtn && navBaseUrl && (
          <AddIconButton
            tooltip={addTooltip}
            onClick={() => navigate(`${navBaseUrl}/add`)}
          />
        )}
        {extraWidgetIsAvailable && extraIconButtons}
      </FoldButtons>
      {visibleExportOverlay && exportable && stream?.count() > 0 && (
        <ExportOverlay
          open={visibleExportOverlay}
          onClose={() => setVisibleExportOverlay(false)}
        />
      )}
    </div>
  )
}

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import {
  dateToISOString,
  formatDate,
  formatDateRange,
} from 'common/utils/format'
import { Column, Row } from 'common/widgets/grid'
import { CommentView, GridFieldView, SectionView } from 'common/widgets/view'
import { formatProjectName } from 'modules/projects/utils'
import { DetailViewPage } from 'system/utils/view'
import { useService } from 'common/service/context'
import { Container } from 'common/widgets/container'
import { ConfirmButton, CancelButton } from 'common/widgets/button'
import { ConfirmOverlay } from 'common/widgets/overlay'
import { EquipmentLink } from 'modules/master-data/equipments/widgets/link'
import { Card } from 'common/widgets/card'
import {
  startOfWeek,
  addDays,
  startOfMonth,
  endOfMonth,
} from 'common/utils/date'
import { BookingRequestTypeEnum } from 'modules/disposition/requests/utils'

import { BookingChangeLegends, BookingChangeCalendar } from './calendar'
import { BookingConfirmOverlay } from './overlay'

const BookingData = ({ request }) => {
  const { booking } = request
  const { baseitem } = booking
  const service = useService()
  const [dates, setDates] = useState({
    booking_start: dateToISOString(
      startOfWeek(startOfMonth(request.booking_start))
    ),
    booking_end: dateToISOString(addDays(endOfMonth(request.booking_start), 7)),
  })
  const [data, setData] = useState({
    bookings: [],
    tasks: [],
    requests: [],
  })

  const reload = async () => {
    const fetch = async (key, url, params) => {
      const [result, error] = await service.get(url, params)
      return [key, result, error]
    }

    const apis = [
      fetch(
        'bookings',
        `disposition/bookings?booking_start[lte]=${encodeURIComponent(
          request.booking_end
        )}&booking_end[gte]=${encodeURIComponent(
          request.booking_start
        )}&baseitem_id=${request.booking.baseitem.id}`
      ),
    ]

    if (baseitem?.is_resource) {
      apis.push(
        fetch(
          'tasks',
          `maintenance/tasks?archived=false&baseitem_id=${
            baseitem.id
          }&done=false&plan_date[gte]=${encodeURIComponent(
            dates.booking_start
          )}&plan_date[lte]=${encodeURIComponent(dates.booking_end)}`
        )
      )
    }

    // Runs request in parallel to fetch all required results
    const results = await Promise.all(apis)

    // Inflates collected data in a different form to use to populate UI.
    setData((data) => ({
      ...data,
      ...Object.fromEntries(
        results.map((e) => {
          const [key, result, error] = e
          const data = error ? [] : result.data
          return [key, data]
        })
      ),
    }))
  }

  // Registers an effect so when the dates or category changes it reloads data
  useEffect(() => {
    reload()
  }, [dates])

  return (
    <Row>
      <Column flex n={6}>
        <BookingRequestData request={request} />
      </Column>
      <Column flex n={6}>
        {request.type === BookingRequestTypeEnum.EXTEND ? (
          <BookingConflicts request={request} {...data} />
        ) : (
          <SiblingBookings request={request} {...data} />
        )}
      </Column>
      <Column flex n={12}>
        <SectionView>
          <BookingChangeCalendar
            request={request}
            onCalendarDateChange={(start, end) => setDates({ start, end })}
            {...data}
          />
          <BookingChangeLegends request={request} />
        </SectionView>
      </Column>
    </Row>
  )
}

const BookingConflicts = ({ request, bookings }) => {
  const { t } = useTranslation()

  // Finds conflicting bookings
  const conflicts = bookings.filter(
    (e) =>
      e.project.id !== request.project.id &&
      e.booking_end >= request.booking.booking_end &&
      e.booking_start <= request.booking_end
  )

  // Max number of conflicts to render
  const MAX_ITEMS = 5

  return (
    <SectionView title="Conflicts">
      {conflicts.length === 0 && <CommentView comment="No conflicts found!" />}
      {conflicts.length > MAX_ITEMS && (
        <CommentView
          warning
          comment={t('There are [{{count}}] more conflicts...', {
            count: conflicts.length - MAX_ITEMS,
          })}
        />
      )}
      {conflicts
        .filter((_, i) => i < MAX_ITEMS)
        .map((e, i) => (
          <Card divide key={i}>
            <Row>
              <GridFieldView
                n={4}
                s={4}
                label="Project"
                value={formatProjectName(e.project)}
              />
              <GridFieldView
                n={5}
                s={5}
                label="Booking period"
                value={formatDateRange(e.booking_start, e.booking_end)}
              />
              <GridFieldView n={3} s={3} label="Amount" value={e.amount} />
            </Row>
          </Card>
        ))}
    </SectionView>
  )
}

const SiblingBookings = ({ request, bookings }) => {
  // Finds conflicting bookings
  const siblings = bookings.filter(
    (e) =>
      e.project.id === request.project.id &&
      e.baseitem.id === request.booking.baseitem.id
  )

  // Max number of conflicts to render
  const MAX_ITEMS = 5

  return (
    <SectionView title="Bookings">
      {siblings.length > MAX_ITEMS && (
        <CommentView
          warning
          comment={
            ('There are [{{overflow}}] more results...',
            {
              count: siblings.length - MAX_ITEMS,
            })
          }
        />
      )}
      {siblings
        .filter((_, i) => i < MAX_ITEMS)
        .map((e, i) => (
          <Card divide key={i}>
            <Row>
              <GridFieldView
                n={4}
                s={4}
                label="Project"
                value={formatProjectName(e.project)}
              />
              <GridFieldView
                n={5}
                s={5}
                label="Booking period"
                value={formatDateRange(e.booking_start, e.booking_end)}
              />
              <GridFieldView n={3} s={3} label="Amount" value={e.amount} />
            </Row>
          </Card>
        ))}
    </SectionView>
  )
}

const BookingRequestData = ({ request }) => {
  return (
    <SectionView>
      <Container flex shrink>
        <Row>
          <GridFieldView n={12}>
            <EquipmentLink equipment={request.booking.baseitem} />
          </GridFieldView>
          <GridFieldView
            n={6}
            label="Project"
            value={formatProjectName(request.project)}
          />
          <GridFieldView n={6} s={6} label="Amount" value={request.amount} />
          <GridFieldView
            n={6}
            s={6}
            label="Start"
            value={formatDate(request.booking_start)}
          />
          <GridFieldView
            n={6}
            s={6}
            label="End"
            value={formatDate(request.booking_end, { friendly: true })}
          />
        </Row>
      </Container>
    </SectionView>
  )
}

export const ChangeRequestConfirm = ({ backUrl }) => {
  const { t } = useTranslation(['equipments'])
  const navigate = useNavigate()
  const service = useService()
  const [showBookOverlay, setShowBookOverlay] = useState(false)
  const [rejectOverlayVisible, showRejectOverlay] = useState(false)

  const reject = async (request) => {
    const [, error] = await service.post(
      `/disposition/requests/${request.id}/reject`
    )
    if (!error) {
      navigate(backUrl)
    }
  }

  return (
    <DetailViewPage
      url="/disposition/requests"
      title={(e) =>
        e.type == BookingRequestTypeEnum.EXTEND
          ? 'Booking extensions'
          : 'Booking shrinks'
      }
    >
      {(data) => (
        <>
          {data && <BookingData request={data} />}
          <Container flex gap="10px">
            <ConfirmButton onClick={() => setShowBookOverlay(true)} />
            <CancelButton
              text="Reject"
              onClick={() => showRejectOverlay(true)}
            />
          </Container>
          {showBookOverlay && (
            <BookingConfirmOverlay
              open={showBookOverlay}
              onClose={() => setShowBookOverlay(false)}
              request={data}
            />
          )}
          {rejectOverlayVisible && (
            <ConfirmOverlay
              open={rejectOverlayVisible}
              onReject={() => showRejectOverlay(false)}
              onConfirm={async () => await reject(data)}
              title="Remove"
            >
              <p>
                {t(
                  'Booking extension request for project {{project}} will get rejected, are you sure?',
                  {
                    project: `<b>${formatProjectName(
                      data.booking.project
                    )}</b>`,
                  }
                )}
              </p>
            </ConfirmOverlay>
          )}
        </>
      )}
    </DetailViewPage>
  )
}

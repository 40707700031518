import React from 'react'
import { useTranslation } from 'react-i18next'

import { GridFieldView, SectionView } from 'common/widgets/view'
import { Column, Row } from 'common/widgets/grid'

import { EquipmentIdentificationView } from './identification'

/**
 * Renders resource (large-scale & small scalle) master data
 *
 * @returns ReactElement
 */
export const MasterDataView = ({ data, children }) => {
  const { t } = useTranslation()
  return (
    <SectionView>
      <Row>
        <Column n={6} s={8}>
          <h1>{t('Master data')}</h1>
          <Row>
            <GridFieldView
              n={12}
              label="Inventory number"
              value={data.inventory_number}
            />
            <GridFieldView
              n={12}
              label="Main group"
              value={data.maincategory}
            />
            <GridFieldView n={12} label="Group" value={data.category} />
            <GridFieldView n={12} label="Subgroup" value={data.subcategory} />
            <GridFieldView
              n={12}
              label="Commission type"
              value={t(data.commission_type)}
            />
            <GridFieldView
              n={12}
              label="Accounting group"
              value={data.accounting_group}
            />
          </Row>
        </Column>
        <Column n={6} s={4}>
          <EquipmentIdentificationView data={data} />
        </Column>
        {children}
      </Row>
    </SectionView>
  )
}

import { Calendar } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { SimpleColumn, SimpleDataTable } from 'common/data-table/simple'
import { useService } from 'common/service/context'
import { formatProjectName } from 'modules/projects/utils'
import { formatDateRange, formatDate } from 'common/utils/format'
import { Container } from 'common/widgets/container'
import { CommentView } from 'common/widgets/view'
import { EquipmentLink } from 'modules/master-data/equipments/widgets/link'

import { BookingRequestTypeEnum } from './utils'

export const ExtendRequestsPage = () => {
  const { t } = useTranslation(['disposition'])
  const navigate = useNavigate()
  const service = useService()

  /**
   * Calls orders get api and loads the data from server side.
   *
   * @param {Array} params query params
   * @returns
   */
  const fetch = async (params) =>
    await service.get('disposition/requests?audited=false', [
      ...params,
      { type: BookingRequestTypeEnum.EXTEND, audited: false },
    ])

  return (
    <SimpleDataTable
      name="extentions"
      fetch={fetch}
      title={t('Pending extend request')}
      onRowClick={(r) =>
        navigate(`/disposition/requests/extend/${r.id}/confirm`)
      }
    >
      <SimpleColumn n={12} s={12} sortable filterable>
        {(r) => (
          <Container flex vertical>
            <EquipmentLink equipment={r.booking.baseitem} />
            <CommentView
              icon={<Calendar />}
              comment={formatDateRange(
                r.booking.booking_start,
                r.booking.booking_end
              )}
            />
          </Container>
        )}
      </SimpleColumn>
      <SimpleColumn
        n={4}
        s={6}
        sortable
        field="project_id"
        label="Project"
        value={(r) => formatProjectName(r.project)}
        link={(r) => (r.project_id ? `/projects/${r.project_id}` : null)}
      />
      <SimpleColumn
        n={2}
        s={6}
        sortable
        label="Extend to"
        value={(r) => formatDate(r.booking_end, { friendly: true })}
      />
      <SimpleColumn
        n={2}
        s={3}
        sortable
        field="maincategory"
        label="Main group"
        value={(r) => r.booking.baseitem.maincategory}
      />
      <SimpleColumn
        n={2}
        s={3}
        sortable
        field="category"
        label="Group"
        value={(r) => r.booking.baseitem.category}
      />
      <SimpleColumn
        n={2}
        s={3}
        sortable
        field="subcategory"
        label="Subgroup"
        value={(r) => r.booking.baseitem.subcategory}
      />
    </SimpleDataTable>
  )
}

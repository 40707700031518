import React, { createContext, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { LOCAL_STORAGE_TOKEN_KEY, useService } from 'common/service/context'
import { getPages } from 'system/container'
import { useToast } from 'system/toast/context'

const SessionContext = createContext({
  pages: null,
  getPage: null,
})

export const SessionProvider = ({ children }) => {
  const service = useService()
  const [token, setToken] = useState(
    localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)
  )
  const [currentUser, setCurrentUser] = useState(null)
  const navigate = useNavigate()
  const { toasts } = useToast()

  const storeToken = async (authnData) => {
    const token = authnData?.access_token ?? null
    if (!token) {
      localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY)
    } else {
      localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token)
    }
    setToken(token)
    setCurrentUser(authnData?.user ?? null)
  }

  useEffect(() => {
    const handleInvalidToken = (statusCode, message) => {
      if (statusCode === 401) {
        if (token) {
          // We only want to dismiss toasts upon coming from
          // app pages, because otherwise we would have multiple
          // error messages for each failed service call.
          toasts.dismiss()
          toasts.error('Session is expired.')
        }
        setToken(null)
      }
    }
    service.addErrorHandler(handleInvalidToken)
    return () => service.removeErrorHandler(handleInvalidToken)
  }, [])

  useEffect(() => {
    if (!token) {
      localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY)
      setCurrentUser(null)
      navigate('/users/sign_in')
    } else {
      localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token)
      // when doing a hard refresh (e.g. F5) we need to fetch
      // the user data, since we don't have it in the storage
      if (!currentUser) {
        refresh()
      }
    }
  }, [token])

  const login = async ({ email, password, apiUrl, next = '/' }) => {
    toasts.dismiss()
    const [response, error] = await service.post(
      'security/authn',
      {
        email,
        password,
      },
      null,
      apiUrl
    )
    storeToken(response?.data)
    if (!error) {
      toasts.clear()
      navigate(next)
    }
  }

  const refresh = async () => {
    if (token) {
      const [result] = await service.get(`/security/authn/refresh`)
      storeToken(result?.data)
    } else {
      storeToken(null)
    }
  }

  const isLoggedIn = () => {
    return token != null
  }

  const getPage = (path) => {
    return getPages()
      .reverse()
      .find((e) => e.path.startsWith(path))
  }

  const completeRegistration = async ({ token, password }) => {
    const [result, error] = await service.post(
      `/security/authn/complete-registration/${token}`,
      {
        password: password,
      }
    )
    if (!error) {
      setToken(result.data.token)
    }
    return [result, error]
  }

  const resetPassword = async ({ token, password }) => {
    const [result, error] = await service.post(
      `/security/authn/reset-password/${token}`,
      {
        password,
      }
    )
    return [result, error]
  }

  return (
    <SessionContext.Provider
      value={{
        login,
        logout: () => {
          document.cookie = 'access_token=null;path=/'
          setToken(null)
        },
        isLoggedIn,
        currentUser,
        refresh,
        pages: getPages(),
        getPage,
        completeRegistration,
        resetPassword,
      }}
    >
      {children}
    </SessionContext.Provider>
  )
}

export const useSession = () => {
  return useContext(SessionContext)
}

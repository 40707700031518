import { Avatar } from 'common/widgets/avatar'
import { SimpleDataTable, SimpleColumn } from 'common/data-table/simple'
import { QueryProvider } from 'common/query/context'
import { useMediaQuery } from 'common/utils/hooks'

import { FormworkDistributionMap } from '../widgets/map'

export const FormworksListPage = () => {
  const isRowBased = useMediaQuery('(max-width: 768px)')

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: isRowBased ? '1fr' : '1fr 1fr',
        gap: 0,
        padding: 0,
      }}
    >
      <QueryProvider
        url="/items/formworks?select=base_item(id,name),cost_center(id,code)"
        name="small-scales-list-provider"
      >
        <SimpleDataTable
          name="formworks"
          title="Formworks"
          showAddBtn
          navBaseUrl="/master-data/equipments/formworks"
          addTooltip="Add formwork"
          archive
          style={{ padding: '10px' }}
        >
          <SimpleColumn fixed>
            {(r) => <Avatar text={r.name} id={r.image?.id} />}
          </SimpleColumn>
          <SimpleColumn
            sortable
            n={12}
            s={12}
            field="name"
            parentField="base_item"
            header
          />
          <SimpleColumn
            n={3}
            s={6}
            filterable
            sortable
            field="maincategory"
            label="Main group"
            filterUrl="items/formworks/maincategory"
          />
          <SimpleColumn
            n={3}
            s={6}
            filterable
            sortable
            field="category"
            label="Group"
            filterUrl="items/formworks/category"
          />
          <SimpleColumn
            n={2}
            s={6}
            filterable
            sortable
            field="subcategory"
            label="Subgroup"
            filterUrl="items/formworks/subcategory"
          />
          <SimpleColumn
            n={2}
            s={6}
            sortable
            label="Cost center"
            field="code"
            parentField="cost_center"
          />
          <SimpleColumn
            n={2}
            s={6}
            sortable
            label="Inventory number"
            field="inventory_number"
          />
        </SimpleDataTable>
        <FormworkDistributionMap
          visible={!useMediaQuery('(max-width: 768px)')}
          style={{
            position: 'sticky',
            top: 0,
            borderRadius: '0',
            height: 'calc(100vh - 60px)',
          }}
        />
      </QueryProvider>
    </div>
  )
}

.searchResult {
  padding: 5px 10px 5px 10px;
  box-sizing: border-box;
  cursor: pointer;
}
.searchResult :hover {
  background: #e1e5e8;
}

.banner {
  position: absolute;
  top: 0;
  width: 100%;
  height: 40px;
  background-image: repeating-linear-gradient(
    -45deg,
    transparent 0 20px,
    gold 20px 40px
  );
  display: flex;
  justify-content: center;
  align-items: center;
}

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useService } from 'common/service/context'
import { formatDate } from 'common/utils/format'
import { SimpleColumn, SimpleDataTable } from 'common/data-table/simple'
import { formatProjectName } from 'modules/projects/utils'
import {
  FoldButtons,
  DetailsIconButton,
  EditIconButton,
  ArchiveIconButton,
} from 'common/widgets/button'
import { useToast } from 'system/toast/context'

import { formatOrderNumber, OrderStateColor } from './utils'

export const OrderListPage = ({ actions }) => {
  const { t } = useTranslation(['orders'])
  const service = useService()
  const navigate = useNavigate()
  const { toasts } = useToast()

  const archiveOrder = async (r) => {
    await service.put(`orders/${r.id}`, {
      archived_at: r.archived_at === null ? new Date() : null,
    })
    r.archived_at
      ? toasts.success(
          `${t('Order')} ${t(r.number)} ${t('sucessfully activated.')}`
        )
      : toasts.success(
          `${t('Order')} ${t(r.number)} ${t('sucessfully archived.')}`
        )
  }

  return (
    <SimpleDataTable
      url="/orders"
      name="orders"
      archive
      title="Orders"
      rowColor={(r) => OrderStateColor[r.state]}
    >
      <SimpleColumn
        n={3}
        s={6}
        sortable
        default="desc"
        field="number"
        label="Order number"
        value={(r) => formatOrderNumber(r?.number)}
      />
      <SimpleColumn
        n={3}
        s={6}
        sortable
        label="Category"
        value={(r) => r.baseitem_types?.map((e) => t(e.type)).join(', ')}
      />
      <SimpleColumn
        n={3}
        s={6}
        sortable
        field="project_id"
        label="Project"
        value={(r) => formatProjectName(r.project)}
        link={(r) => (r.project_id ? `/projects/${r.project_id}` : null)}
      />
      <SimpleColumn
        n={3}
        s={6}
        sortable
        field="creator_user_id"
        label="Creator"
        value={(r) => r.creator.fullname}
        link={(r) => `/security/users/${r.creator?.id}`}
      />
      <SimpleColumn
        n={3}
        s={6}
        sortable
        field="ordered_at"
        label="Ordered at"
        value={(r) => formatDate(r.ordered_at)}
      />
      <SimpleColumn
        n={3}
        s={6}
        sortable
        field="requires_delivery"
        label="Delivery option"
        value={(r) =>
          r.requires_delivery ? t('Requires delivery') : t('Self-pickup')
        }
      />
      <SimpleColumn
        n={3}
        s={6}
        sortable
        filter={(e) => e}
        field="state"
        label="Status"
        value={(r) => t(r.state)}
        filterUrl="orders/states"
      />
      <SimpleColumn
        n={3}
        s={6}
        sortable
        field="planned_delivery_date"
        label="Planned delivery date"
        value={(r) => formatDate(r.planned_delivery_date)}
      />
      <SimpleColumn
        n={3}
        s={6}
        label="Transport Status"
        value={(r) =>
          r.transport_planned ? `${r.transport_count} PLANNED` : 'NOT PLANNED'
        }
      />
      <SimpleColumn fixed>
        {(r, reload) =>
          actions ? (
            actions(r, reload)
          ) : (
            <FoldButtons>
              {r.archivable && (
                <ArchiveIconButton
                  tooltip="Archive"
                  white
                  onClick={async () => {
                    await archiveOrder(r)
                    await reload()
                  }}
                  archived={r.archived_at}
                />
              )}
              {r.editable && (
                <EditIconButton
                  tooltip="Edit"
                  white
                  onClick={() => navigate(`/orders/${r.id}/edit`)}
                />
              )}
              <DetailsIconButton
                tooltip="Details"
                white
                onClick={() => navigate(`/orders/${r.id}`)}
              />
            </FoldButtons>
          )
        }
      </SimpleColumn>
    </SimpleDataTable>
  )
}

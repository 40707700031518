import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { Column, Row } from 'common/widgets/grid'
import { ConfirmIconButton, CancelIconButton } from 'common/widgets/button'
import { DetailViewPage } from 'system/utils/view'
import { CardView, GridFieldView } from 'common/widgets/view'
import { Container } from 'common/widgets/container'
import { formatDate, formatDateTime } from 'common/utils/format'
import { UserView } from 'modules/security/users/widgets'
import { useService } from 'common/service/context'
import { useToast } from 'system/toast/context'

import { StateField, TypeField } from './widgets'

/**
 * Renders the absence detail view page
 *
 * @returns ReactElement
 */
export const AbsenceDetailViewPage = () => {
  const navigate = useNavigate()
  const service = useService()
  const { toasts } = useToast()
  const { t } = useTranslation()
  const { id } = useParams()

  /**
   * Approve the absence.
   *
   * @param {any} absence absence data
   */
  const approve = async (absence) => {
    const [, error] = await service.put(
      `/contact/absences/${absence.id}/approve`
    )
    if (!error) {
      toasts.success(t('Absence request successfully approved.'))
      navigate(0)
    }
  }

  /**
   * Reject the absence.
   *
   * @param {any} absence absence data
   */
  const reject = async (absence) => {
    const [, error] = await service.put(
      `/contact/absences/${absence.id}/reject`
    )
    if (!error) {
      toasts.success(t('Absence request successfully rejected.'))
      navigate(0)
    }
  }

  return (
    <DetailViewPage
      url="/contact/absences"
      navUrl="/master-data/contacts/absences"
      title={(item) => `${t('Absence')} #${id} - ${item.contact.fullname}`}
      removable={(item) => item.is_changeable}
      editable={(item) => item.is_changeable}
      addExtraButtons={(item) => {
        return (
          <>
            {item?.is_supervisable && (
              <>
                <ConfirmIconButton
                  tooltip="Approve"
                  onClick={async () => await approve(item)}
                />
                <CancelIconButton
                  tooltip="Reject"
                  onClick={async () => await reject(item)}
                />
              </>
            )}
          </>
        )
      }}
    >
      {(data) => {
        return (
          data && (
            <Row>
              <Column n={12} m={12} s={12}>
                <AbsenceDetails data={data} />
              </Column>
            </Row>
          )
        )
      }}
    </DetailViewPage>
  )
}

/**
 * Renders the absence details
 *
 * @param {any} data absence object
 *
 * @returns ReactElement
 */
export const AbsenceDetails = ({ data, showTitle = true }) => {
  const { t } = useTranslation()

  return (
    <CardView>
      {showTitle && <h1>{t('Details')}</h1>}
      <Row>
        <Column n={3} m={12} s={12}>
          <AbsenceInfo absence={data} />
        </Column>
        <Column n={5} m={12} s={12}>
          <ScheduleInfo absence={data} />
        </Column>
        <Column n={4} m={12} s={12}>
          <SubstituteInfo absence={data} />
        </Column>
      </Row>
    </CardView>
  )
}

/**
 * Renders the absence info
 *
 * @param {any} absence absence object
 *
 * @returns ReactElement
 */
const AbsenceInfo = ({ absence }) => {
  return (
    <>
      <Row title="Absence">
        <GridFieldView n={12} m={12} s={12} label="Employee">
          <UserView user={absence.contact.system_user} />
        </GridFieldView>
        <GridFieldView
          n={12}
          m={12}
          s={12}
          label="Note"
          value={absence.comment}
        />
        <GridFieldView n={12} m={12} s={12} label="Created by">
          <UserView user={absence.creator_user} />
        </GridFieldView>
        <GridFieldView
          n={12}
          m={12}
          s={12}
          label="Created on"
          value={formatDateTime(absence.created_at)}
        />
      </Row>
    </>
  )
}

/**
 * Renders the absence schedule
 *
 * @param {any} absence absence object
 *
 * @returns ReactElement
 */
const ScheduleInfo = ({ absence }) => {
  return (
    <>
      <Row title="Schedule">
        <Column n={12} m={12} s={12}>
          <Container flex>
            <GridFieldView
              n={6}
              m={6}
              s={12}
              style={{ minWidth: '150px' }}
              label="Start date"
              value={
                absence.daily
                  ? formatDate(absence.absence_start)
                  : formatDateTime(absence.absence_start)
              }
            />
            <GridFieldView
              n={6}
              m={6}
              s={12}
              style={{ marginLeft: '50px', minWidth: '150px' }}
              label="End date"
              value={
                absence.daily
                  ? formatDate(absence.absence_end, { friendly: true })
                  : formatDateTime(absence.absence_end)
              }
            />
          </Container>
        </Column>
        <Column n={12} m={12} s={12}>
          <Container flex>
            <GridFieldView n={6} m={6} s={6}>
              <TypeField type={absence.type} />
            </GridFieldView>
            <GridFieldView n={6} m={6} s={6}>
              <StateField state={absence.state} />
            </GridFieldView>
          </Container>
        </Column>
        <GridFieldView n={12} m={12} s={12} label="Supervised by">
          {absence.supervisor_user_id ? (
            <UserView user={absence.supervisor_user} />
          ) : (
            '-'
          )}
        </GridFieldView>
      </Row>
    </>
  )
}

/**
 * Renders the absence substitute info
 *
 * @param {any} absence absence object
 *
 * @returns ReactElement
 */
const SubstituteInfo = ({ absence }) => {
  return (
    <Row title="Substitute">
      <GridFieldView n={12} m={12} s={12} label="Employee">
        {absence.substitute_id ? (
          <UserView user={absence.substitute?.system_user} />
        ) : (
          '-'
        )}
      </GridFieldView>
    </Row>
  )
}

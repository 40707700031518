import React from 'react'

import { EditViewPage } from 'system/utils/edit'

import { FormworksForm } from './form'

export const FormworksEditPage = () => {
  return (
    <EditViewPage url="/items/formworks" title={(e) => e.name}>
      {(data, reload) => {
        return <FormworksForm data={data} />
      }}
    </EditViewPage>
  )
}

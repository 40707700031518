import { createContext, useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useService } from 'common/service/context'
import { useOverlayContext } from 'common/widgets/overlay'

const QueryContext = createContext()

export const QueryProvider = ({
  url,
  name,
  fetch,
  defaultSort = {
    field: 'id',
    direction: 'desc',
  },
  defaultParams,
  // key value object of query parameters for GET requests
  params = {},
  limit,
  // Prefix is just a workaround for multiple query providers on the
  // same page, it breaks direct urls linking to the page.
  usePrefix = false,
  children,
}) => {
  // For programming errors we just need to inform the developer. "params"
  // prop is not user provided, so if it is not an array, then programmer
  // has made an error and should fix it.
  // An array allows us to repeat query parameters, per HTTP specs
  console.assert(
    !defaultParams || Array.isArray(defaultParams),
    'Programming error. "params" prop must be an array. Fix your code.'
  )
  console.assert(
    !(url && fetch),
    'Programming error. "fetch" and "url" props are mutually exclusive.'
  )
  console.assert(
    !name?.includes('.'),
    '"." is a separator. Choose a different name.'
  )
  if (!name) {
    console.warn(
      'Without a prefix name, query parameters might clash.\n',
      'Consider naming this QueryProvider.'
    )
  }
  const [data, setData] = useState({
    error: null,
    records: null,
    totalCount: null,
    loading: false,
    // This is manily to keep previous query parameters in order to
    // compare them with updated query parameters to figure out if
    // we should reload or not in case some of our paramters have
    // changed.
    prevUrlParameters: undefined,
  })
  // In overlays don't touch the query strings, overlays are supposed
  // to be ephemeral
  const overlayContext = useOverlayContext()
  const [nav_search, nav_setSearch] = useSearchParams()
  const [prv_search, prv_setSearch] = useState(new URLSearchParams())
  const search = overlayContext ? prv_search : nav_search
  const setSearch = overlayContext ? prv_setSearch : nav_setSearch

  const service = useService()

  // Uses the name as key for state in case there are multiple
  // query provides with different variables on the same page
  const prefix = usePrefix ? (name ? `qp${name}.` : 'qp.') : ''
  const page = Number.parseInt(search.get(addPrefix('page'))) || 1
  const ipp = Number.parseInt(search.get(addPrefix('ipp'))) || limit || 10

  useEffect(() => {
    //conditionalReload()
    // Reload once if there is no previous URL parameters
    // because it would trigger the useEffect above
    if (!data.prevUrlParameters) {
      reload()
    }
  }, [])

  useEffect(() => {
    if (data.prevUrlParameters?.size >= 0) {
      //conditionalReload()
      reload()
    }
  }, [search])

  function conditionalReload() {
    // Reload only if any of our parameters have changed
    // in case there are different providers on the same page
    if (shouldReload()) {
      console.debug(`[${name}] shouldReload.YES`)
      reload()
    } else {
      console.debug(`[${name}] shouldReload.NO`)
    }
  }

  // A workaround when not using query strings (overlay, etc.)
  // TODO: remove this as soon as conflicting data table problem
  // is solved. Query string parameters should be only used for
  // lists
  function updateIfNotUsingQuery() {
    if (overlayContext) {
      conditionalReload()
    }
  }

  // Has any of our URL parameters changed?
  function shouldReload() {
    const oldQParams = data.prevUrlParameters
    // Conditions for reload:
    // A parameter is added, removed, or changed
    for (const [prefixedKey, value] of search) {
      if (hasMyPrefx(prefixedKey)) {
        if (!oldQParams.has(prefixedKey)) {
          // New query parameter
          return true
        }
        if (oldQParams.has(prefixedKey)) {
          const values = oldQParams.getAll(prefixedKey)
          if (values.length > 0) {
            if (!values.includes(value)) {
              return true
            }
          } // With implicit conversion
          else if (oldQParams.get(prefixedKey) != value) {
            // Value of existing parameter has changed
            return true
          }
        }
      } else {
        console.debug(`[${name}] Does not have my prefix:`, prefixedKey)
      }
    }
    // Now check old parameters. At this point no new parameter
    // has matched any existing parameter
    for (const [prefixedKey] of oldQParams) {
      if (hasMyPrefx(prefixedKey)) {
        if (!search.has(prefixedKey)) {
          // Removed query parameter
          return true
        }
        if (
          search.getAll(prefixedKey).length !=
          oldQParams.getAll(prefixedKey).length
        ) {
          return true
        }
      } else {
        console.debug(`[${name}] Does not have my prefix:`, prefixedKey)
      }
    }
    // By default we don't reload
    return false
  }

  function getParameterArray() {
    // Add page, ipp, and archived flags by default. These flags
    // will be overrieded if they exist among query parameters.
    const fetchParams = { page, ipp, archived: false, ...params }
    for (const [prefixedKey, value] of search) {
      if (hasMyPrefx(prefixedKey)) {
        const values = search.getAll(prefixedKey)
        if (values.length > 0) {
          fetchParams[dropPrefix(prefixedKey)] = values
        } else {
          fetchParams[dropPrefix(prefixedKey)] = value
        }
      }
    }
    // We have to unfortunately convert the object into an array of
    // key value pair objects, because many fetch functions assume
    // that absurd data structure.
    // TODO: remove the assumption that params is an array of k,v objs
    const paramPairs = Object.entries(fetchParams).map((q) => ({
      [q[0]]: q[1],
    }))
    return paramPairs
  }

  function cloneURLSearchParams(urlSearch) {
    const newUrlSeach = new URLSearchParams()
    for (const [k, v] of urlSearch) {
      newUrlSeach.append(k, v)
    }
    return newUrlSeach
  }

  async function reload() {
    setData((prev) => ({ ...prev, loading: true }))
    fetch ??= async (params) => await service.get(url, params)
    const [response, error, totalCount] = await fetch(getParameterArray())
    setData({
      loading: false,
      error,
      records: response?.data ?? [],
      totalCount,
      prevUrlParameters: cloneURLSearchParams(search),
    })
  }

  function addPrefix(key, isSortOrder) {
    if (isSortOrder) {
      return `${prefix}order[${key}]`
    }
    return `${prefix}${key}`
  }

  function dropPrefix(key) {
    if (key.startsWith(prefix)) {
      return key.substr(prefix.length)
    }
    return key
  }

  function hasMyPrefx(key, isSortOrder) {
    if (isSortOrder) {
      return key.startsWith(`${prefix}order[`)
    }
    return key.startsWith(prefix)
  }

  function getQueryParameter(key) {
    return search.get(addPrefix(key))
  }

  function getAllQueryParameter(key) {
    return search.getAll(addPrefix(key))
  }

  async function setQueryParameter(key, value, isSortOrder) {
    setSearch((prev) => {
      const prefKey = addPrefix(key, isSortOrder)
      prev.set(prefKey, value)
      return prev
    })
    updateIfNotUsingQuery()
  }

  function setManyQueryParameters(parameters) {
    setSearch((prev) => {
      Object.entries(parameters).forEach((p) => {
        const prefKey = addPrefix(p[0])
        if (p[1] === undefined) {
          prev.delete(prefKey)
        } else {
          prev.set(prefKey, p[1])
        }
      })
      return prev
    })
    updateIfNotUsingQuery()
  }

  function appendQueryParameter(key, value) {
    setSearch((prev) => {
      const prefKey = addPrefix(key)
      prev.append(prefKey, value)
      return prev
    })
    updateIfNotUsingQuery()
  }

  function deappendQueryParameter(key, value) {
    setSearch((prev) => {
      const prefKey = addPrefix(key)
      const newArray = prev.getAll(prefKey).filter((i) => i != value)
      prev.delete(prefKey)
      newArray.forEach((i) => prev.append(prefKey, i))
      return prev
    })
    updateIfNotUsingQuery()
  }

  function deleteQueryParameter(key) {
    setSearch((prev) => {
      prev.delete(addPrefix(key))
      return prev
    })
    updateIfNotUsingQuery()
  }

  function clearQueryParameters() {
    setSearch((prev) => {
      // Iterate not over the prev, but over the current query
      for (const [prefixedKey] of search) {
        if (hasMyPrefx(prefixedKey) && !isSortOrder(prefixedKey)) {
          prev.delete(prefixedKey)
        }
      }
      return prev
    })
    updateIfNotUsingQuery()
  }

  function countQueryParameters() {
    let count = 0
    for (const [prefixedKey] of search) {
      if (hasMyPrefx(prefixedKey) && !isSortOrder(prefixedKey)) {
        count++
      }
    }
    return count
  }

  function toggleQueryParameter(key, value) {
    const currentValue = getQueryParameter(key)
    if (!currentValue) {
      setQueryParameter(key, value)
    } else {
      deleteQueryParameter(key)
    }
  }

  function revereseDirection(direction) {
    return direction == 'desc' ? 'asc' : 'desc'
  }

  const toggleOrder = (field) => {
    const currentSortOrder = getQueryParameter(field, true)
    setQueryParameter(field, revereseDirection(currentSortOrder), true)
  }

  const setOffset = (value) => {
    setQueryParameter('page', value + 1)
  }

  const setLimit = (newLimit) => {
    if (newLimit * page > data.totalCount) {
      setQueryParameter('ipp', 10)
      deleteQueryParameter('page')
    } else {
      setQueryParameter('ipp', newLimit)
      deleteQueryParameter('page')
    }
  }

  function isSortOrder(key) {
    return key.startsWith(`${prefix}order[`)
  }

  function clearSortOrders() {
    setSearch((prev) => {
      // Iterate not over the prev, but over the current query
      for (const [prefixedKey] of search) {
        if (isSortOrder(prefixedKey)) {
          prev.delete(prefixedKey)
        }
      }
      return prev
    })
    updateIfNotUsingQuery()
  }

  function countSortOrders() {
    let count = 0
    for (const [prefixedKey] of search) {
      if (isSortOrder(prefixedKey)) {
        count++
      }
    }
    return count
  }

  const ctxValue = {
    loading: data.loading,
    error: data.error,
    records: data.records,
    totalCount: data.totalCount,
    offset: page ? page - 1 : 0,
    limit: ipp ?? 10,
    setOffset,
    setLimit,
    reload,
    // In this section we are basically reimplementing URLSearchParams
    // API which is bad, but is a compromise to support multiple query
    // providers on the same page (using prefixes).
    parameters: {
      // Notice: this is for repetitive query parameters
      // See below to learn more
      // https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
      getAll: getAllQueryParameter,
      get: getQueryParameter,
      set: setQueryParameter,
      setMany: setManyQueryParameters,
      append: appendQueryParameter,
      deappend: deappendQueryParameter,
      delete: deleteQueryParameter,
      clear: clearQueryParameters,
      toggle: toggleQueryParameter,
      size: countQueryParameters(),
    },
    sortOrders: {
      get: (key) => getQueryParameter(key, true),
      toggle: (field) => toggleOrder(field),
      clear: clearSortOrders,
      size: countSortOrders(),
    },
    stream: (chunk = 1000) =>
      stream(fetch, getParameterArray(), data.records, data.totalCount, chunk),
  }

  return (
    <QueryContext.Provider value={ctxValue}>
      {typeof children === 'function' ? children(ctxValue) : children}
    </QueryContext.Provider>
  )
}

export const useQuery = () => useContext(QueryContext)

// TODO: erase this. It is unnecessary by all means.
export const QueryConsumer = ({ children }) => {
  const query = useQuery()

  return <>{children(query)}</>
}

/**
 * Makes a stream on top of query context to fetch records.
 * @param {Array} params query params
 * @param {Array} records records
 * @param {int} totalCount total count
 * @param {int} chunk chunk size
 * @returns
 */
const stream = (fetch, params, records, totalCount, chunk) => {
  const cache = {
    totalCount: totalCount ? totalCount : 0,
    page: 0,
    records: [],
  }
  // Points to the current record
  var cursor = 0

  /**
   * Returns true if the desired data is in cache
   * @param {int} row
   * @returns boolean
   */
  const isInCache = (row) =>
    row >= cache.page * chunk && row < cache.page * chunk + cache.records.length

  /**
   * Loads paged data according to the given row index
   * @param {int} row row index
   */
  const load = async (row) => {
    if (row < cache.totalCount) {
      // Calculates page number
      const page = parseInt(row / chunk)
      // Loads data using page number and chunk
      const [response, error, totalCount] = await fetch([
        ...params,
        { ipp: chunk },
        { page },
      ])
      // Puts records in cache
      cache.records = error ? [] : response.data
      // Updates total count using the last fetch
      cache.totalCount = error ? 0 : totalCount
      // Updates page inside cache
      cache.page = page
    }
  }

  return {
    first: () => (records?.length > 0 ? records[0] : null),
    next: async () => {
      if (cursor < cache.totalCount) {
        if (!isInCache(cursor)) {
          await load(cursor)
        }
        const index = cursor % chunk
        cursor += 1
        return index < cache.records.length ? cache.records[index] : undefined
      } else {
        return undefined
      }
    },
    hasNext: () => {
      return cursor < cache.totalCount
    },
    count: () => cache.totalCount,
  }
}

import React from 'react'

import {
  ColorPickerField,
  TextField,
  FileField,
} from 'common/widgets/form/field'
import { Form } from 'common/widgets/form/context'
import { useSettings } from 'system/settings/context'
import { SectionView } from 'common/widgets/view'
import { AddressField } from 'common/widgets/form/address'

import { LanguageSelectField } from './fields'

export const SettingsPage = () => {
  const ctx = useSettings()
  const data = {}
  for (const [key, value] of Object.entries(ctx.settings)) {
    console.log(`${key}: ${value}`)
    data[key] = value.value
  }

  return (
    <Form
      data={data}
      //data={ctx.settings}
      //baseUrl="/settings"
      showActions
      onSubmit={(values) => {
        console.log('form.onsubmit', values)
        return ctx.update(values)
        //return [null, null]
      }}
    >
      <SectionView title="Company">
        <TextField
          label="Company name"
          name="company.name"
          disabled={false}
          rows={1}
          field="value"
        />
        <AddressField
          label="Address"
          name="company.address"
          showMap
          editGeofence
        />
        <FileField
          label="Logo"
          name="company.logo"
          accept="image/png, image/jpeg, image/webp"
          preview
          previewSize="128px"
          nullable
        />
        <FileField
          label="Splash"
          name="company.splash"
          accept="image/png, image/jpeg, image/webp"
          preview
          previewSize="128px"
          nullable
        />
      </SectionView>
      <SectionView title="Other">
        <div className="flex gap-2">
          <ColorPickerField
            name="company.color.primary"
            label="company primary color"
          />
          <ColorPickerField
            name="company.color.secondary"
            label="company secondary color"
          />
        </div>
        <LanguageSelectField label="Language" />
      </SectionView>
    </Form>
  )
}

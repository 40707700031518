import { Tab } from 'common/widgets/tab'
import { DetailViewPage } from 'system/utils/view'
//import { RoleNotificationsSelectView } from './notifications'

import { ScopeTree } from './widgets'

export const RoleViewPage = () => {
  return (
    <DetailViewPage
      url="/security/roles"
      params={[{ select: 'scopes:scope(id,key,description,parent_id)' }]}
      title={(e) => e.description || e.key}
      editable
    >
      {(role) => (
        <Tab
          items={[
            {
              key: 'permission',
              title: 'Permissions',
              render: () => <ScopeTree scopes={role.scopes} showSelectedOnly />,
            },
            // {
            //   key: 'notification',
            //   title: 'Notifications',
            //   render: () => <RoleNotificationsSelectView role={role} />,
            // },
          ]}
        />
      )}
    </DetailViewPage>
  )
}

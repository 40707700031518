import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { Container } from 'common/widgets/container'
import { Row, Column } from 'common/widgets/grid'
import { GridFieldView, SectionView } from 'common/widgets/view'
import { DetailViewPage } from 'system/utils/view'
import { RelatedItemView } from 'modules/master-data/equipments/view/related-items'
import { BookingHistoriesView } from 'modules/master-data/equipments/view/booking-histories'
import { DocumentsSectionView } from 'common/widgets/document'
import { SingleImageFieldSectionView } from 'common/widgets/image'
import { AdHocDeliveryIconButton } from 'common/widgets/button'
import { DamageReportButton } from 'modules/yard/maintenance/damage_report'

import { EquipmentIdentificationView } from '../view/identification'
import { OrderableColumnFieldView } from '../widgets/orderable'
import { ResourceLocationMap } from '../widgets/map'
import { ItemHistory } from '../view/history'

/**
 * Renders page for viewing a formwork
 *
 * @returns ReactElement
 */
export const FormworkViewPage = () => {
  const { t } = useTranslation(['equipments'])
  // Extracts id from url params.
  const { id } = useParams()
  const navigate = useNavigate()

  return (
    <DetailViewPage
      url="/items/formworks"
      params={[
        {
          select:
            'base_item(name,category,subcategory,maincategory),cost_center(id,code)',
        },
      ]}
      title={(e) => e?.base_item?.name}
      navUrl="/master-data/equipments/formworks"
      editable
      addExtraButtons={() => (
        <>
          <AdHocDeliveryIconButton
            tooltip="Adhoc commission"
            onClick={() => {
              navigate(`/yard/commission/deliveries/adhoc?baseitemId=${id}`)
            }}
          />

          <DamageReportButton baseitemId={id} />
        </>
      )}
    >
      {(data, reload) => (
        <Container flex>
          <Row>
            <Column flex n={6} m={6} s={12}>
              <FormworkMasterDataSection formwork={data} />
            </Column>
            <Column flex n={6} m={6} s={12}>
              <SectionView>
                <ResourceLocationMap showYard items={[data]} />
              </SectionView>
            </Column>
            <Column flex n={6} m={6} s={12}>
              <FormworkInventorySection formwork={data} />
            </Column>
            <Column flex n={6} m={6} s={12}>
              <FormworkDimensionSection formwork={data} />
            </Column>
            <Column flex n={5} m={12} s={12}>
              <RelatedItemView data={data} />
            </Column>
            <Column flex n={5} m={9} s={12}>
              <DocumentsSectionView docRef={`baseitem=${data.id}`} />
            </Column>
            <Column flex n={2} m={3} s={12}>
              <SingleImageFieldSectionView
                imageRef={data.image_ref}
                title={t('Equipment Image')}
                section_title={t('Image')}
              />
            </Column>
            <Column flex n={12} m={12} s={12}>
              <SectionView>
                <BookingHistoriesView data={data} />
              </SectionView>
            </Column>
            <Column flex n={12} m={12} s={12}>
              <SectionView>
                <ItemHistory itemID={id} />
              </SectionView>
            </Column>
          </Row>
        </Container>
      )}
    </DetailViewPage>
  )
}

/**
 * Renders formwork's inventory section.
 *
 * @param {any} formwork formwork data
 * @returns ReactElement
 */
export const FormworkInventorySection = ({ formwork }) => {
  const { t } = useTranslation(['equipments'])

  return (
    <SectionView>
      <h1>{t('Inventory')}</h1>
      <Row>
        <GridFieldView
          n={6}
          label="Accounting group"
          value={t(formwork.accounting_group)}
        />
        <GridFieldView n={6} label="Condition" value={t(formwork.condition)} />
        <GridFieldView n={6} label="Cost center" value={formwork.cost_center} />
        <GridFieldView
          n={6}
          label="Price when new"
          value={formwork.cost_rate}
        />
        <GridFieldView
          n={6}
          label="Quantity unit"
          value={formwork.quantity_unit}
        />
        <GridFieldView
          n={6}
          label="Total stock"
          value={formwork.total_amount}
        />
        <GridFieldView
          n={6}
          label="Available amount"
          value={formwork.stock_level}
        />
        <GridFieldView n={6} label="Storage" value={formwork.storage} />
        <OrderableColumnFieldView n={6} value={formwork.is_orderable} />
      </Row>
    </SectionView>
  )
}

/**
 * Renders formwork's master data section.
 *
 * @param {any} formwork formwork data
 * @returns ReactElement
 */
export const FormworkMasterDataSection = ({ formwork }) => {
  const { t } = useTranslation(['equipments'])

  return (
    <SectionView>
      <Row>
        <Column n={6} s={8}>
          <Row>
            <Column n={12}>
              <h1>{t('Master data')}</h1>
            </Column>
            <GridFieldView
              n={12}
              label="Inventory number"
              value={formwork.inventory_number}
            />
            <GridFieldView
              n={12}
              label="Main group"
              value={formwork.maincategory}
            />
            <GridFieldView n={12} label="Group" value={formwork.category} />
            <GridFieldView
              n={12}
              label="Subgroup"
              value={formwork.subcategory}
            />
            <GridFieldView
              n={12}
              label="Commission type"
              value={t(formwork.commission_type)}
            />
            <GridFieldView n={12} label="Supplier" value={formwork.supplier} />
            <GridFieldView
              n={12}
              label="Supplier"
              value={t(formwork.transfrom)}
            />
          </Row>
        </Column>
        <Column n={6} s={4}>
          <EquipmentIdentificationView data={formwork} />
        </Column>
      </Row>
    </SectionView>
  )
}

/**
 * Renders formwork's dimension section.
 *
 * @param {any} formwork formwork data
 * @returns ReactElement
 */
export const FormworkDimensionSection = ({ formwork }) => {
  const { t } = useTranslation(['equipments'])

  return (
    <SectionView>
      <h1>{t('Dimensions and cargo')}</h1>
      <Row>
        <GridFieldView n={6} label="Weight" value={formwork.weight} />
        <GridFieldView
          n={6}
          label="Packaging unit"
          value={formwork.packaging_unit}
        />
        <GridFieldView
          n={6}
          label="Transportation"
          value={formwork.transportation}
        />
        <GridFieldView
          n={6}
          label="Clear inner dimensions (LxWxH)"
          value={formwork.dimensions_1}
        />
        <GridFieldView
          n={6}
          label="Outer dimensions (LxWxH)"
          value={formwork.dimensions_2}
        />
        <GridFieldView
          n={6}
          label="Maximum outer dimensions (LxWxH)"
          value={formwork.dimensions_3}
        />
        <GridFieldView n={6} label="Comment" value={formwork.comment} />
      </Row>
    </SectionView>
  )
}

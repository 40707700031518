import React, { useState } from 'react'

import { FieldWrapper } from 'common/widgets/form/field'
import { Select } from 'common/widgets/select'
import { GridFieldView } from 'common/widgets/view'

export const OrderableField = ({ mandatory, selected }) => {
  const [selection, setSelection] = useState(null)
  const items = [
    { key: true, title: 'Yes' },
    { key: false, title: 'No' },
  ]

  return (
    <FieldWrapper name="is_orderable" mandatory={mandatory}>
      {(getValue, setValue) => (
        <Select
          selected={selected ? getValue() : selection}
          items={items}
          onSelectChange={(item) => {
            if (!selected) {
              setSelection(item.key)
            }
            setValue(item.key)
          }}
        />
      )}
    </FieldWrapper>
  )
}

export const OrderableColumnFieldView = ({ value, ...rest }) => {
  return (
    <GridFieldView {...rest} label="Orderable" value={value ? 'Yes' : 'No'} />
  )
}

import { useService } from 'common/service/context'
import { DataSource } from 'common/widgets/data-source'
import { DropDown } from 'common/widgets/dropdown'
import { FieldWrapper } from 'common/widgets/form/field'

/**
 * Select a project entity
 *
 * @param {Function} format format function
 * @returns ReactElement
 */
export const ProjectSelectField = ({ onSelectChange, removable, ...rest }) => {
  return (
    <FieldWrapper name={name} {...rest}>
      {(getValue, setValue, values) => (
        <ProjectSelect
          removable
          selectedProjectId={getValue()}
          onSelectChange={(project) => {
            if (project) {
              setValue(project.id)
            } else {
              setValue(null)
            }
            if (onSelectChange) {
              onSelectChange(project)
            }
          }}
          {...rest}
        />
      )}
    </FieldWrapper>
  )
}

/**
 * Renders a project select widget.
 *
 * @param {object} handler handler to control params and loadings
 * @param {Function} onSelect gets called when a project selected
 * @param {string} hint hint of the dropdown menu
 * @param {Array} bookings dispo bookings array
 * @param {int} excludeProjectId project witch should be excluded from the list of projects
 * @returns
 */
export const ProjectSelect = ({
  handler,
  onSelectChange,
  hint,
  disabled,
  removable,
  excludeProjectId,
  allowYard,
  selectedProjectId,
}) => {
  const service = useService()

  const fetch = async () =>
    await service.get('projects', [{ limit: true }, { archived: false }])

  const render = ({ data }) => {
    var items = data
      ? data.map((project) => ({
          key: project.id,
          title: formatUniqueProjectName(project),
          data: project,
        }))
      : []
    if (excludeProjectId) {
      items = items.filter((e) => e.key !== excludeProjectId)
    }
    if (allowYard) {
      items.push({
        key: null,
        title: 'Building yard',
        data: {},
      })
    }

    const sIndex =
      selectedProjectId && items.findIndex((p) => p.key == selectedProjectId)

    return (
      <DropDown
        minWidth="320px"
        maxWidth="320px"
        title={hint || 'Projects'}
        items={items}
        selectedIndex={sIndex}
        disabled={disabled}
        removable={removable}
        onSelectChange={(e) => {
          if (onSelectChange) {
            onSelectChange(e?.data)
          }
        }}
        searchFilter={(option, searchTerm) => {
          const st = searchTerm.toLowerCase()
          return (
            option?.title.toLowerCase().includes(st) ||
            option?.data?.name.toLowerCase().includes(st)
          )
        }}
      />
    )
  }

  return <DataSource fetch={fetch} handler={handler} render={render} />
}

const formatUniqueProjectName = (project) => {
  if (!project) {
    return '-'
  }

  const displayName = project.name
  const paddedId = project.id.toString().padStart(3, '0')

  if (project.cost_center) {
    const paddedCC = project.cost_center.padStart(5, '0')
    return `[${paddedId}] ${paddedCC} - ${displayName}`
  } else {
    return `[${paddedId}] - ${displayName}`
  }
}

import { useTranslation } from 'react-i18next'

import { DetailViewPage } from 'system/utils/view'
import { SectionView, GridFieldView, CommentView } from 'common/widgets/view'
import { Row, Column } from 'common/widgets/grid'
import { useService } from 'common/service/context'
import { UserEmailChangeConfirmation } from 'common/widgets/email-change'
import { MailIconButton } from 'common/widgets/button'
import { Avatar } from 'common/widgets/avatar'
import { useToast } from 'system/toast/context'
import { ActivityView } from 'common/widgets/activity'

import { UserRoleSelect } from './roles'

export const UserViewPage = () => {
  const { t } = useTranslation(['security'])
  const service = useService()
  const { toasts } = useToast()

  const sendInvitationEmail = async (user) => {
    const [, error] = await service.post(
      `security/users/${user.id}/resend-invitation`
    )
    if (!error) {
      toasts.success(t('Invitation email sent.'))
    }
  }

  return (
    <DetailViewPage
      url="security/users"
      params={[{ select: 'roles:role(id,key)' }]}
      navUrl="/security/users"
      title={(e) => e.fullname}
      editable
      archivable
      addExtraButtons={(user) => {
        return (
          <>
            {!user?.last_login && (
              <MailIconButton
                tooltip="Send invitation email"
                onClick={async () => await sendInvitationEmail(user)}
              />
            )}
            {user?.pending_email && <UserEmailChangeConfirmation user={user} />}
          </>
        )
      }}
    >
      {(data, reload) => (
        <SectionView title="Details">
          <Row>
            <Column n={12} m={12} s={12}>
              <h5>{t('Profile picture')}</h5>
              <Avatar text={data.first_name} imgSrc={data.photo} />
            </Column>
            <Column n={12} m={12} s={12}>
              <h1>{t('Name')}</h1>
            </Column>
            <GridFieldView
              n={6}
              m={6}
              s={12}
              label="First name"
              value={data.first_name}
            />
            <GridFieldView
              n={6}
              m={6}
              s={12}
              label="Last name"
              value={data.last_name}
            />
            <Column n={12} m={12} s={12}>
              <h1>{t('Contact information')}</h1>
            </Column>
            <GridFieldView
              n={6}
              m={6}
              s={12}
              label="Email"
              value={data.email}
            />
            <GridFieldView
              n={6}
              m={6}
              s={12}
              label="Phone number"
              value={data.phone_number}
            />
            {data?.pending_email && (
              <Column n={12} m={12} s={12}>
                <CommentView
                  warning
                  comment={
                    t('Email change confirmation required for') +
                    ` ${data?.pending_email}`
                  }
                />
              </Column>
            )}
            <Column n={12} m={12} s={12}>
              <h1>{t('Roles')}</h1>
            </Column>
            <Column n={12} m={12} s={12}>
              {data?.roles && <UserRoleSelect value={data.roles} disabled />}
            </Column>
            <Column n={12} m={12} s={12}>
              <ActivityView activities={data.activity ?? []} noPadding />
            </Column>
          </Row>
        </SectionView>
      )}
    </DetailViewPage>
  )
}
